import { useState, useEffect, useRef } from 'react';
import './TransactionPin.css';

function TransactionPin(props) {
  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = useRef([]);
  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const setPin = (e, index) => {
    const newOtp = [...otp];
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      newOtp[index] = input;
      setOtp(newOtp);
      props.setPin(newOtp.join(''));
      if (input !== '' && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text/plain').split('');
    let newOtp = [...otp];
    for (let i = 0; i < pasteData.length && i < 4; i++) {
      const input = pasteData[i];
      if (/^\d*$/.test(input)) {
        newOtp[i] = input;
      }
    }
    setOtp(newOtp);
    props.setPin(newOtp.join(''));
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="flex justify-center items-center py-2">
      {otp.map((digit, index) => (
        <input
          key={index}
          type={"password"}
          inputMode={"numeric"}
          maxLength={1}
          value={digit ? digit : ''}
          onChange={e => setPin(e, index)}
          onPaste={handlePaste}
          onKeyDown={e => handleKeyDown(e, index)}
          className={digit ? props.class : props.class + 'focus'}
          style={{
            width: '60px',
            margin: '6px',
            height: '60px',
            textAlign: 'center',
            border: '1px solid #CED1D4',
            fontSize: '19px',
            borderRadius: '5px',
          }}
          ref={el => (inputRefs.current[index] = el)}
        />
      ))}
    </div>
  );
}

export default TransactionPin;
