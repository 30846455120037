import React, { useState } from 'react';
import {Button,Avatar} from '@chakra-ui/react';
import { EditIcon} from '@chakra-ui/icons';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';
import { storage } from '../../firebase';

function YoungsterAvatar(props) {
  const [image,setImage] = useState("")

  const createAccount = () =>{
    props.createAccount()
  }

  const [avatarArray] = useState([
    {id:1,image:"https://drive.google.com/uc?export=view&id=1hzjYiFQ2mVC23Nc8p9idHOjW4xZIGGpq"},
    {id:2,image:"https://drive.google.com/uc?export=view&id=1ppG0s5svhO6CiFvWBJuZuUk9HsmWFsdB"},
    {id:3,image:"https://drive.google.com/uc?export=view&id=17k6NlCUGVRYxZe7Nosp8mY6NRiyVYjqb"},
    {id:4,image:"https://drive.google.com/uc?export=view&id=196Siivq5gNCXm-PaDRP9-QWXmKNlg-ys"},

    {id:5,image:"https://drive.google.com/uc?export=view&id=1HZzZ1KgOUyikBwOOqpvIS8Cp3VAM5T4p"},
    {id:6,image:"https://drive.google.com/uc?export=view&id=1-Pz4GVQtI_8WBofcDgLXn7HsVt_kZoev"},
    {id:7,image:"https://drive.google.com/uc?export=view&id=1b3pKhoksVX0YwzYGKkAVHt71AW3V7fNC"},
    {id:8,image:"https://drive.google.com/uc?export=view&id=1okhJUfLZ8wdXSSPp7pfzWwCdf0qs34qF"},
    {id:9,image:"https://drive.google.com/uc?export=view&id=1oYqeIT_WmzuSv-oKRWTmU2-GsGJnHpcD"},
    {id:10,image:"https://drive.google.com/uc?export=view&id=1CVYk45-72MrYoTxNLn8nOzT9mIUmrW8I"},
    {id:11,image:"https://drive.google.com/uc?export=view&id=1AV0V87Y_A19u0uRLBAz9ZG-aZBLuDQZw"},
    {id:12,image:"https://drive.google.com/uc?export=view&id=1lBJTmEPCKDfI4UirbewC4LELSxTyfLgI"},
    {id:13,image:"https://drive.google.com/uc?export=view&id=1PL4SzkF8fLjQ4oJUirzMfJ7V7ty25vWG"}, 
  
  ])


  function imageModal(){
    let input = document.getElementById("imageInput")
    input.click()
  }

  function uploadImage(event){
    uploadFile(event.target.files[0])
    var input = event.target;
    if (input.files) {
      var reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        props.setImage(image)
      };
      reader.readAsDataURL(input.files[0]);
      console.log(image)
    }
  }

  const uploadFile = (image) => {
    const id = toast.loading('Uploading Image. Please wait...');
    // Create the file metadata
    /** @type {any} */
    const storageRef = ref(storage, props.email === undefined ? props.phone:props.email);
    const uploadTask = uploadBytesResumable(
      storageRef,
      image
    );

    uploadTask.on(
      'state_changed',
      snapshot => {
        console.log(snapshot);
      },
      error => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          toast.update(id, {
            render: 'Image uploaded successfully',
            type: 'success',
            isLoading: false,
            autoClose:3000
          });
          props.setImage(downloadURL)
        });
      }
    );
  };

  function chooseAvatar(image){
    setImage(image)
    props.setImage(image)
  }
 

 

 

  
  

  return (
    <div id={props.id} className="hiddenForm">
      <div className="mb-5 flex justify-end">
       

        <Button loadingText="Creating account" className={props.isSkipLoading?"w-1/3 text-blue-600":"w-1/5 text-blue-600"} onClick={createAccount} rounded={"full"} isLoading={props.isSkipLoading}>{props.isSkipLoading}Skip</Button>
      </div>
   

   


     <div className="flex justify-center">
    <div className="relative">
      <Avatar className="avatarSize" cursor={"pointer"} rounded={"full"}  onClick={imageModal} src={image}/>
      <EditIcon cursor={"pointer"} color={"white"} className="imageAbsolute" onClick={imageModal} boxSize={"40px"}/>
    </div>
      </div>

      <input onChange={uploadImage} id="imageInput" type="file" hidden accept='image/*' />

     <div className="scrollAvatar my-6">
      {avatarArray.map((image,i)=>(
        <div key={i} className="pr-3 flexAvatar">
        <Avatar size={'xl'} src={image.image} onClick={()=>chooseAvatar(image.image)} />
        
        </div>
      ))}
    </div>


    
      

    
      <div>

        <Button
          rounded={'full'}
          background={"#005FEB"}
           color={'white'}
           size="lg"
           id="nextButton"
           width={'100%'}
           p={9}
           onClick={createAccount}
           isLoading={props.loading}
           _hover={{background:"#0066f5"}}
        >
          Done 
        </Button>
      </div>
    </div>
  );
}

export default YoungsterAvatar;
