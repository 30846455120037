import React from 'react';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import TransactionList from '../../../components/MinieWallet/TransactionList';
import TransactionLoading from '../../../components/MinieWallet/TransactionLoading';
import transactionsData from "../../../data/Transactions.json"

function WalletHistory(props) {
  const goToOverview = () => {
    window.scrollTo(0, 0);
    const overview = document.getElementById('overview');
    const fundwallet = document.getElementById('fundwallet');
    const walletHistory = document.getElementById('history');
    overview.style.display = 'block';
    fundwallet.style.display = 'none';
    walletHistory.style.display = 'none';
  };


  return (
    <div id={props.id} className="dashboardCenter hiddenForm pb-8">
      <div className="my-4">
        <div className="mx-3 my-4">
          <WalletScreenHeader
            goBack={goToOverview}
            title={'Transaction History'}
          />
        </div>

        <Tabs variant="unstyled" colorScheme="blue">
          <TabList background={'#FBFBFB'} padding={'8px'} borderRadius={'8px'}>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#7A58EF', bg: '#F2EEFD' }}
              borderRadius={'8px'}
            >
              All
            </Tab>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#7A58EF', bg: '#F2EEFD' }}
              borderRadius={'8px'}
            >
              Credit
            </Tab>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#7A58EF', bg: '#F2EEFD' }}
              borderRadius={'8px'}
            >
              Debit
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div>
              {props.transactionsLoading && transactionsData.map((transaction,i) => (
                <TransactionLoading key={i}/>
              ))
              }
              </div>
              <div>
                {props.transactions &&
                  props.transactions
                    .map((transaction, i) => (
                      <TransactionList id={props.id} key={i} transaction={transaction} />
                    ))}
              </div>
            </TabPanel>
            <TabPanel>
            <div>
              {props.transactionsLoading && transactionsData.map((transaction,i) => (
                <TransactionLoading key={i}/>
              ))
              }
              </div>

              {props.transactions &&
                props.transactions
                  .filter(transaction => transaction.type === 'deposit' || transaction.type==='earnit' || (props.user.accountType==="youngster" && transaction.type==="allowance"))
                  .map((transaction, i) => (
                    <TransactionList id={props.id} key={i} transaction={transaction} />
                  ))}
            </TabPanel>

            <TabPanel>
            <div>
              {props.transactionsLoading && transactionsData.map((transaction,i) => (
                <TransactionLoading key={i}/>
              ))
              }
              </div>
              
              {props.transactions &&
                props.transactions
                  .filter(transaction => transaction.type === 'transfer' || transaction.type==='withdrawal' || transaction.type==='kolosave' || (props.user.accountType==="adult" && transaction.type==="allowance"))
                  .map((transaction, i) => (
                    <TransactionList id={props.id} key={i} transaction={transaction} />
                  ))}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
}

export default WalletHistory;
