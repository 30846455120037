import React from 'react'

function ErrorComponent(props) {
  return (
    <div className="errorMessage text-center">
        <h1 className="text-4xl font-bold">{props.error}</h1>
        <p className="my-4">Are you sure you didn't make a typo?</p>

        <button onClick={()=>window.location.href="https://miniemoney.com"} className="homeButton">BACK TO HOME</button>
    </div>
  )
}

export default ErrorComponent