import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import './MinieWallet.css';


function TransactionLoading() {
 
  return (
    <div>
      <div
        className="transactionList flex py-5 justify-between cursor-pointer"
      >
        <div className="flex">
        <Skeleton height="50px" width="50px" borderRadius={'full'}/>
          <div className="ml-3">
            <h3 className="font-medium">
              <Skeleton margin={"4px 0px 0px 0px"} height="18px" width={"130px"}/>
            </h3>
            <span className="text-xs">
              <Skeleton margin={"4px 0px 0px 0px"} height="10px" width={"50px"}/>
            </span>
          </div>
        </div>
        <div>
          <span className={'font-bold'}>
            <Skeleton height="19px" width={"100px"}/>
          </span>
        </div>
      </div>
    </div>
  );
}

export default TransactionLoading;
