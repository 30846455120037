import React, { Fragment, useState } from 'react';
import terms from '../../Image/terms.png';
import back from '../../Icons/backButton.svg';
import Modal from '../Modal/Modal';
import success from '../../Icons/successIcon.svg';
import axios from 'axios';
import { Button, Checkbox } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';
import closeButtonGreen from '../../Icons/closeButtonGreen.svg';

function KolosaveTerms(props) {
  const accountType = window.location.pathname.split("/")[2]
  const dispatch = useDispatch()
  window.scrollTo(0,0)
  const Back = () => {
    props.Back();
  };

  const [agree, setAgree] = React.useState(false);
  const [loading,setLoading] = React.useState(false)
  
  const checkboxHandler = () =>{
    setAgree(!agree)
  }

  const agreeToTerms = () => {
    setLoading(true)
    axios({
      method:"GET",
      url:process.env.REACT_APP_API_URL+"/accept-kolosave-terms",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json",
        Accept:"*/*"
      },
    }).then(()=>{
      dispatch(fetchUser(accountType)).then(()=>{
        setLoading(false)
        setShowModal(true)
      })
    }).catch((error)=>{
      setLoading(false)
      Swal.fire({
        icon:"error",
        title:"Something went wrong",
        text:error.response.data.error,
        confirmButtonColor:"#669f2a"
      })
    })
    
  };
  const [showModal2, setShowModal] = useState(false);
  
  const closeModal = () => {
    setShowModal(false);
  };
  const showInstantDate =() =>{
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('review').style.display = 'none';
    document.getElementById('instantsave').style.display = 'block';

    closeModal()
  }
  return (
    <Fragment>
      <div id="terms" className="dashboardCenter hiddenForm">
        <div onClick={Back} className="cursor-pointer pb-4">
          <img src={back} alt="" />

          <h2 className="walletScreenTitle text-center">KoloSave Terms</h2>
        </div>
        <div className="kycList my-8">
          <div className="flex justify-between items-center">
            <div>
              <img
                className="max-w-[100%]"
                src={terms}
                alt="user-verification"
              />
            </div>

            <div className="kycText">
              <p className="text-sm font-medium">
                To participate in the KoloSave savings challenge, you have to
                read and accept our KoloSave terms..
              </p>
            </div>
          </div>
        </div>
        <div className="text-[#151618]">
          <p className="mb-8">
            Carefully read our KoloSave terms and agree to them before you start
            saving.
          </p>

          <p className="mb-8">
            Kindly note that by accepting our KoloSave terms, you agree to;
          </p>
          <ul className="list-disc marker:text-black">
            <li className="py-2">
              {' '}
              “I can only break my savings on the 1st of December.”.
            </li>
            <li className="py-2">
              “I can only access my transaction history and breakdown after the
              offical breaking day”.
            </li>
            <li className="py-2">
              “I can only see my savings balance 4 times a year”.
            </li>
            <li className="py-2">
              “I agree to be posted on the leaderboard if I top the list of
              savers ”.
            </li>
          </ul>
          <div className="mt-4">


            <div className="mb-7">
            <Checkbox readOnly={props.user.kolosave && props.user.kolosave.isAgreed} defaultChecked={props.user.kolosave && props.user.kolosave.isAgreed} colorScheme={"green"} onChange={checkboxHandler}> I hereby agree and accept all the KoloSave terms listed above.</Checkbox>
            </div>

            <Button
              onClick={agreeToTerms}
              disabled={!agree|| (props.user.kolosave && props.user.kolosave.isAgreed)}
              rounded={"full"}
              className="confirmButton"
              width={"full"}
              backgroundColor={"#669F2A"}
              color={"white"}
              variant={"solid"}
              _hover={"#669F2A"}
              isLoading={loading}
            >
              Accept KoloSave terms
            </Button>
          </div>
        </div>
      </div>
      <Modal closeButtonGreen={closeButtonGreen} isVisible={showModal2} onClose={() => closeModal()}>
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            KoloSave terms accepted
          </h5>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            You have successfully read, accepted and agreed to the KoloSave
            Terms. Happy saving!
          </p>
          <button
           onClick={showInstantDate}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2"
          >
            <span className="font-semibold text-base">
            Start saving now
            </span>
          </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default KolosaveTerms;
