import React from 'react'
import sendMoneyIcon from "../../Icons/send-money.svg"

function PaymentComponent(props) {
 const [name,setName] = React.useState("")
 const [email,setEmail] = React.useState("")
 const [amount,setAmount] = React.useState("")
 const [reason,setReason] = React.useState("")
 const [errors,setErrors] = React.useState([])


 const goToNextPage = () =>{
    const payload = {
        name:name,
        email:email,
        amount:amount,
        reason:reason
    }
    props.setData(payload)
    document.getElementById("firstpage").style.visibility='hidden'
    document.getElementById("firstpage").style.display='none'
    document.getElementById("secondpage").style.visibility='visible'
    document.getElementById("secondpage").style.display='block'

 }

 const sendMoney = (e) =>{
    e.preventDefault()
    const data = [name,email,amount]
    const message = []
    if(name ==='' || email === '' || amount === '' || amount <= 0){
        for (let i = 0; i < data.length; i++) {
            if(data[i] === ""){
                message[i] = "Please fill this field"
                setErrors([...message])
            }
        }
    }

    else{
        setErrors([])
        goToNextPage()
    }
 }

  return (
    <div id={props.id} className="paymentContainer">
        <div>
        <div className="flex justify-center">
    {!props.loading &&<img className="w-20 h-20 rounded-full" src={props.user.profileImage} alt="" />}
    
    {props.loading && <div role="status" className="max-w-sm animate-pulse">
    <div className="w-20 h-20 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
    </div>}

    <div className="ml-4 mt-2">
        {!props.loading &&<h2 className="text-xl font-bold">{props.user.firstName} {props.user.lastName}</h2>}
        {props.loading && 
        <div role="status" className="max-w-sm animate-pulse">
        <div className="w-36 h-5 bg-gray-200 dark:bg-gray-700 mb-4"></div>
        </div>
        }
        <span>{props.minietag}</span>
    </div>
    </div>
    <div className="text-center my-4">{window.location.href}</div>

    <div className="paymentPageContainer">
        <form className="space-y-4">
            <div>
            <label>Name</label>
            <input onChange={(e)=>setName(e.target.value)} className="appearance-none border w-full rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="What's your name?" />
            <p className="text-red-500">{errors[0]}</p>
            </div>
            <div>
            <label>Email</label>
            <input onChange={(e)=>setEmail(e.target.value)} className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="What's your email?" />
            <p className="text-red-500">{errors[1]}</p>
            </div>

            <div>
            <label>Amount</label>
            <input onChange={(e)=>setAmount(e.target.value)} type="number" className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="amount" placeholder="Enter the amount" />
            <p className="text-red-500">{errors[2]}</p>
            </div>

            
            <div>
            <label>Reason For Sending Money</label>
            <input onChange={(e)=>setReason(e.target.value)} type="text" className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="transfer" placeholder="Enter reason for transfer" />
            </div>

            <button onClick={(e)=>sendMoney(e)} className="sendMoneyBtn flex justify-center text-white">
                <img className="mt-1" src={sendMoneyIcon} alt="send-money"/><span className="ml-2">Send money to {props.user.firstName}</span>
            </button>
        </form>
    </div>
    </div>
        </div>
  )
}

export default PaymentComponent