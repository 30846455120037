import { Avatar, Button } from '@chakra-ui/react';
import React from 'react';
import './DashboardCenter.css';
import Notification from '../../../Icons/notifications.svg';
import Wallet from './Wallet';
import walletadd from '../../../Icons/wallet-add.svg';
import UserVerification from '../../../Icons/user-tick.svg';
import UserTick from '../../../Icons/user-verify.svg';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import YoungsterAccount from './YoungsterAccount';
import NoYoungster from './NoYoungster';
import Swal from 'sweetalert2';
import { BsArrowRight } from 'react-icons/bs';
import 'react-phone-input-2/lib/bootstrap.css';
import InfoTip from './InfoTip';
import YoungsterLoading from './YoungsterLoading';
import TransactionPinModal from '../../../components/Modal/TransactionPinModal';
import ShareSocialMediaModal from '../../../components/KoloSave/ShareMediaModal';
import QuoteModal from '../../Modal/QuoteModal';
// import SterlingAccountModal from '../../Modal/SterlingAccountModal';

function DashboardCenter(props) {
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const [isOpen, setIsOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  // const [showSterlingModal, setShowSterlingModal] = React.useState(true)

  //show the set pin if tour has been completed
  React.useEffect(() => {
    if (JSON.parse(localStorage.getItem('isTour'))) {
      setIsOpen(true);
    }
  }, []);

  //check if user has done tour, set pin and a valid quote is available before showing the quote
  React.useEffect(() => {
    if (
      JSON.parse(localStorage.getItem('isTour')) &&
      props.user.hasPin &&
      props.quotesLoading &&
      Date.now() > localStorage.getItem('quoteViewed')
    ) {
      setShowModal(true);
    }
  }, [props]);

  //show the set pin after tour has been cancelled or closed
  const showSetPin = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem('quoteViewed', Date.now() + 86400000);
  }

  const gotonotifications = () => {
    history('/notifications/' + accountType);
  };
  const gotowallet = () => {
    history('/fund-wallet/' + accountType);
  };

  const requestMoney = () => {
    if (props.user.minietag === '' || props.user.minietag === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Request Money Not Available 😢',
        text: 'You cannot use this feature until you have added your Minietag',
        confirmButtonColor: '#0066f5',
        confirmButtonText: 'Set Minietag',
      }).then(result => {
        if (result.isConfirmed) {
          history(`/account/${props.user.accountType}/edit-profile`);
        }
      });
      return;
    }
    if (props.parents && props.parents.length === 0) {
      history('/request-money/youngster');
      return;
    }
    if (props.user.canRequest) {
      history('/request-money/youngster');
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Request Money Disabled',
        text: 'Your parents have disabled your ability to request money. Kindly ask them to reactivate this feature for you',
        confirmButtonColor: '#0066f5',
      });
    }
  };

  return (
    <div className="dashboardCenter" style={{maxWidth:"655px"}}>
      {/* the user info */}
      <div className="flex justify-between w-full">
        <div className="flex">
          <Avatar bg="blue.500" src={props.user.profileImage} />
          <div className="ml-3">
            <h2 className="font-bold text-xl -mb-1">
              Hey {props.user.firstName}
            </h2>
            {accountType === 'youngster' && (
              <span className="titleSpan my-0 py-0">
                MinieTAG:{' '}
                <strong>
                  {'@' + props.user.minietag} <InfoTip />
                </strong>
              </span>
            )}
            {accountType === 'adult' && (
              <span className="titleSpan my-0 py-0">
                You're now a super-adult
              </span>
            )}
          </div>
        </div>
        <div className="relative cursor-pointer" onClick={gotonotifications}>
          {props.user.notifications.filter(
            notification => notification.status === 'recent'
          ).length > 0 && (
            <div className="notificationsBadge">
              {
                props.user.notifications.filter(
                  notification => notification.status === 'recent'
                ).length
              }
            </div>
          )}
          <img src={Notification} alt="notification" />
        </div>
      </div>

      {/* the wallets */}
      {accountType === 'youngster' && (
        <Wallet
          user={props.user}
          balances={props.balances}
          loading={props.balanceLoading}
          showSetPin={showSetPin}
        />
      )}
      {accountType === 'adult' && (
        <Wallet
          user={props.user}
          balances={props.balances}
          loading={props.balanceLoading}
          showSetPin={showSetPin}
        />
      )}

      <div className="w-5/6 my-6 mx-auto" data-tut="reactour__fundwallet">
        {accountType === 'adult' && (
          <Button
            height="48px"
            width="100%"
            border="1px"
            borderColor="blue.500"
            variant="outline"
            rounded={'full'}
            padding={'29px 0px'}
            onClick={gotowallet}
          >
            <img className="mr-4" src={walletadd} alt="wallet-add" /> Fund
            Wallet
          </Button>
        )}
        {accountType === 'youngster' && (
          <Button
            height="48px"
            width="100%"
            border="1px"
            borderColor="blue.500"
            variant="outline"
            rounded={'full'}
            padding={'29px 0px'}
            onClick={() => requestMoney()}
          >
            <img className="mr-4" src={walletadd} alt="wallet-add" /> Fund
            Wallet
          </Button>
        )}
      </div>

      {/* Todo list */}
      <div data-tut="reactour__todo">
        <div>
          {props.kycs && props.kycs.length === 0 && (
            <h2 className="text-2xl font-bold">
              {props.user.minietag !== '' && accountType === 'youngster'
                ? ''
                : 'To-Do'}
            </h2>
          )}

          {props.parents && props.parents.length === 0 && (
            <h2 className="text-2xl font-bold">
              {props.user.minietag !== '' && accountType === 'youngster'
                ? 'To-Do'
                : ''}
            </h2>
          )}

          {accountType === 'adult' && props.kycs && props.kycs.length === 0 && (
            <div
              onClick={() => history('/account/adult/kyc')}
              className="cursor-pointer kycList"
            >
              <div className="kycType">
                <div>
                  <img
                    className="kycIcon"
                    src={UserVerification}
                    alt="user-verification"
                  />
                </div>

                <div className="kycText">
                  <h2 className="text-xl font-bold">Verify your KYC</h2>
                  <p>We need to know who you are</p>
                </div>
              </div>

              <div className="arrowIcon">
                <BsArrowRight />
              </div>
            </div>
          )}
          {accountType === 'youngster' &&
            (props.user.minietag === '' ||
              props.user.minietag === undefined) && (
              <div
                className="kycList"
                onClick={() => history('/account/youngster/edit-profile')}
              >
                <div className="kycType cursor-pointer">
                  <div>
                    <img
                      className="kycIcon"
                      src={UserTick}
                      alt="user-verification"
                    />
                  </div>

                  <div className="kycText cursor-pointer">
                    <h2 className="text-xl font-bold">Set up your account</h2>
                    <p>
                      Fully set up your account to start your financial journey
                    </p>
                  </div>
                </div>

                <div className="arrowIcon cursor-pointer">
                  <BsArrowRight />
                </div>
              </div>
            )}
        </div>

        {accountType === 'adult' && props.kycs && props.kycs.length === 0 && (
          <div
            onClick={() => history('/account/adult/edit-profile')}
            className="cursor-pointer kycList my-5"
          >
            <div className="kycType">
              <div>
                <img
                  className="kycIcon"
                  src={UserTick}
                  alt="user-verification"
                />
              </div>

              <div className="kycText cursor-pointer">
                <h2 className="text-xl font-bold">Set your account</h2>
                <p>
                  {window.innerWidth < 600
                    ? 'Fully set up your account to....'
                    : 'Fully set up your account to start your financial journey'}
                </p>
              </div>
            </div>

            <div className="arrowIcon">
              <BsArrowRight />
            </div>
          </div>
        )}
        {props.parents &&
          props.parents.length === 0 &&
          accountType === 'youngster' && (
            <div
              onClick={() => history('/account/youngster/parents')}
              className="kycList my-5 cursor-pointer"
            >
              <div className="kycType">
                <div>
                  <img
                    className="kycIcon"
                    src={UserVerification}
                    alt="user-verification"
                  />
                </div>

                <div className="kycText">
                  <h2 className="text-xl font-bold">
                    Invite your parent/guardian
                  </h2>
                  <p>
                    {window.innerWidth < 600
                      ? 'Invite your parent to ....'
                      : 'Invite your parent to fully activate your account'}
                  </p>
                </div>
              </div>

              <div className="arrowIcon">
                <BsArrowRight />
              </div>
            </div>
          )}
      </div>

      {accountType === 'adult' &&
        props.youngsterLoading &&
        props.user.youngsters &&
        props.user.youngsters.length !== 0 &&
        props.user.youngsters.map((youngster, i) => (
          <div key={i}>
            <div>
              <YoungsterLoading youngster={youngster} />
            </div>
          </div>
        ))}

      {accountType === 'adult' &&
        props.user &&
        props.user.youngsters.length === 0 && <NoYoungster />}

      {accountType === 'adult' &&
        !props.youngsterLoading &&
        props.user &&
        props.user.youngsters.length !== 0 && (
          <div className="text-right">
            <Button
              onClick={() => history('/create-youngster/adult')}
              rounded={'full'}
              background="#0073E5"
              color="white"
              variant="solid"
              _hover={{ background: '#0073E5' }}
            >
              <AddIcon className="mr-2" /> Add Youngster
            </Button>
          </div>
        )}
      {!props.youngsterLoading &&
        props.youngsters &&
        props.youngsters.length !== 0 &&
        props.youngsters.map((youngster, i) => (
          <div key={i}>
            <div>
              <YoungsterAccount youngster={youngster} />
            </div>
          </div>
        ))}
      {(props.user.hasPin === undefined || !props.user.hasPin) && (
        <TransactionPinModal isOpen={isOpen} />
      )}

      {props.position &&
      props.position.position &&
      props.position.position !== 0 &&
      props.position.position !== undefined ? (
        <ShareSocialMediaModal
          profileImage={props.user.profileImage}
          firstName={props.user.firstName}
          lastName={props.user.lastName}
          minietag={props.user.minietag}
          position={props.position.position}
        />
      ) : (
        ''
      )}

      {/* show quote from GPT */}
      {props.quotes && props.quotes.length !== undefined && (
        <QuoteModal
          showModal={showModal}
          quotes={props.quotes}
          closeModal={() => closeModal()}
          title={'Money Facts 🤔'}
        />
      )}


   {/* only show the account number thing when the user has: 
   1) Completed the tour
   2) Has set pin
   3) Is a youngster
   4) Account number is empty
   5) Money facts have been viewed
   */}

      {/* {JSON.parse(localStorage.getItem('isTour')) && props.user.hasPin && accountType === 'youngster' && !showModal && (props.user.accountNumber === '' || props.user.accountNumber === undefined) && (
        <SterlingAccountModal
          title={'Introducing Bank Account Number for Kids!'}
          showModal={showSterlingModal}
          closeModal = {()=>setShowSterlingModal(false)}

        />
      )} */}
    </div>
  );
}

export default DashboardCenter;
