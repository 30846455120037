import { Avatar,Button} from '@chakra-ui/react'
import React from 'react'
import "./ParentCard.css"


function ParentCard(props) {
  return (
    <div className="parentCard">
        <Avatar src={props.parent.profileImage} alt="adult"/>
        <div className="ml-2">
            <h2>{props.parent.firstName} {props.parent.lastName}</h2>
            <p className="text-xs">Email: {props.parent.email}, Phone: {props.parent.phone}</p>
        </div>

        <Button onClick={()=>props.selectParent(props.parent)} color={"white"} fontSize={"14px"} rounded={"full"} background={"#7a58ef"} _hover={{background:"#7a58ef"}}>
          Request Money
        </Button>
    </div>
  )
}

export default ParentCard