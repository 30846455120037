import React from 'react'
import youngstertotal from "../../Icons/youngstertotal.svg"
import youngstersavings from "../../Icons/youngstersaving.svg"
import youngsterearnit from "../../Icons/youngsterearnit.svg"
import youngsterwallet from "../../Icons/youngsterwallet.svg"
import {Button} from "@chakra-ui/react"
import setallowance from "../../Icons/set-allowance.svg"
import assigntask from "../../Icons/assign-task.svg"
import sendmoney from "../../Icons/send-money.svg"
// import eyeslash from "../../Icons/eye-slash.svg"
import {useNavigate} from "react-router"
import YoungsterAvatarHeading from './YoungsterAvatarHeading'
import Swal from 'sweetalert2'
import { standardAmountFormat } from '../../utils/amountUtil'
import slugify from 'slugify'

function YoungsterAccount(props) {
  const history = useNavigate()


  const showMaintenance = () =>{
    // history("/account/adult/assign-task/youngster")
    Swal.fire({
      icon:"info",
      title:"Under maintenance 🤓",
      text:"We're currently revamping the EarnIT feature to give a better experience",
      confirmButtonColor:"#06aeda"
    })
  }
 

  const setAllowance = () =>{
    if(props.youngster.minietag ==="" || props.youngster.minietag === undefined){
      Swal.fire({
        icon:"info",
        title:"Youngster doesn't have a Minietag 🥺",
        text:`Sorry, you can't send an allowance to a youngster that doesn't have a minietag.`,
        confirmButtonColor:"#0066f5"
      })
      return
    }
    const youngsterName =
    slugify(props.youngster.firstName, { lower: true }) +
    '-' +
    slugify(props.youngster.lastName, { lower: true });

    history("/account/adult/set-allowance/"+youngsterName)
  }


  const showYoungsterBalance = (e) =>{
    if(e.target.checked === true){
    document.getElementById("youngsterAccount").style.display="block"
    document.getElementById("youngsterAccount").style.visibility="visible"
    document.getElementById("youngsterAccount").style.height="auto"
    }
    else{
    document.getElementById("youngsterAccount").style.display="none"
    document.getElementById("youngsterAccount").style.visibility="hidden"
    document.getElementById("youngsterAccount").style.height="0"
    }
  }
  






  return (
    <div>

        <YoungsterAvatarHeading showYoungsterBalance={(e)=>showYoungsterBalance(e)} youngster={props.youngster}/>

        {/* youngster cards */}
        {/* add hideYoungsterAccount as a class to do the toggle thing */}
        <div id="youngsterAccount" className="youngsterAccount ">
        <div className="youngsterbalanceCards">
        <div className="totalBalanceCard">
        <div>
        <img src={youngstertotal} alt="" />
        </div>
        <div className="cardDetails">
        <span>Total balance</span>
        <h2 className="youngsterbalancetext">{standardAmountFormat(props.youngster.walletBalance)}</h2>
        </div>
        </div>
        <div className="walletBalanceCard">
        <div>
        <img src={youngsterwallet} alt="" />
        </div>
        <div className="cardDetails">
        <span>Minie Wallet</span>
        <h2 className="youngsterbalancetext">{standardAmountFormat(props.youngster.walletBalance)}</h2>
        </div>
        </div>
        <div className="savingsBalanceCard">
        <div>
        <img src={youngstersavings} alt="" />
        </div>
        <div className="cardDetails">
        <span>{window.innerWidth<500?"Minie savings":"Minie savings"}</span>
        <h2 className="youngsterbalancetext">{standardAmountFormat(props.youngster.savingsBalance)}</h2>
        </div>
        </div>
        <div className="earnitCard">
        <div>
        <img src={youngsterearnit} alt=""/>
        </div>
        <div className="cardDetails">
        <span>EarnIT</span>
        <h2 className="youngsterbalancetext">{standardAmountFormat(props.youngster.transactions && props.youngster.transactions.filter((transaction)=>transaction.type==='earnit').reduce((a,b)=>(a + b.amount),0))}</h2>
        </div>
        </div>
        </div>

        <div>
        <div className="my-6 flex justify-end">
          {/* <div className="flex">
         <img src={eyeslash} alt="eye-slash"/> <span className="balanceText mt-2">Turn off balance amount</span>
          </div> */}
        </div>
        <div className="buttonsGrid">
       <Button onClick={()=>setAllowance()} variant='outline' className="text-md buttonText" size="lg" rounded={"full"}>
        <img src={setallowance} alt="set-allowance" className="mr-2"/>
        Set allowance
       </Button>

       <Button onClick={()=>showMaintenance()} variant='outline' className="text-md buttonText" rounded={"full"} size="lg">
        <img src={assigntask} className="mr-2" alt="set-allowance"/>
        Assign task
       </Button>

       <Button onClick={()=>history("/account/adult/send-money/youngster")} background={"#005FEB"} className="text-md sendMoneyMobile buttonText" color="white" size="lg" variant="#005FEB" rounded={"full"}>
        <img src={sendmoney} className="mr-2" alt="set-allowance"/>
        Send Money
       </Button>
        </div>
        </div>
           </div>

    </div>
  )
}

export default YoungsterAccount