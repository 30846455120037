import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  value: "",
  loading: false,
};


export const fetchTasks = createAsyncThunk("task/fetchTasks", async () => {
  const idType = JSON.parse(sessionStorage.getItem("userData")).accountType === "adult"?"parentId":"kidId"
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/fetch-tasks?idType="+idType,
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
            ContentType: "application/json",
        }
      })
      sessionStorage.setItem("tasks",JSON.stringify(response.data.data))
     
      return response.data.data
    } catch (error) {
      if(error.response.status === 401){
        setTimeout(()=>{
          localStorage.removeItem("_expiredTime")
          sessionStorage.removeItem('minieToken');
          sessionStorage.removeItem('userData');
          window.location.href = '/login/';
        },3000)
      }
    }
  });
  
  export const fetchTask = createSlice({
    name: "task",
    initialState,
    extraReducers: {
      [fetchTasks.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchTasks.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchTasks.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchTask.reducer;