import React from 'react';
import Modal from './Modal';
import closePurpleButton from '../../Icons/closePurpleButton.svg';
import TransactionPin from '../Misc/TransactionPin';
import { Button } from '@chakra-ui/react';

function EnterTransactionPinModal(props) {

  const setPin = e => {
    if (e.length === 4) {
      props.setDisabled(false)
    }
    else{
      props.setDisabled(true)
    }
    props.setPin(e);
  };

  return (
    <Modal
      closeButtonGreen={props.closeButton ==="blue" ? "":closePurpleButton}
      isVisible={props.showModal}
      onClose={() => props.closeModal()}
    >
      <div className="p-6 flex justify-between flex-col items-center">
        <h2 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
          Enter Your Transaction PIN
        </h2>

        <TransactionPin class={props.class} bg={props.pinBg} color={props.color} setPin={e => setPin(e)} />

        <Button
          onClick={() => props.confirm()}
          background={props.bg}
          color={'white'}
          width={'full'}
          rounded={'full'}
          className="confirmButton my-4"
          disabled={props.disabled}
          isLoading={props.loading}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

export default EnterTransactionPinModal;



// props available 
// closeModal: to close the Modal
// confirm: to fire the function
// loading: to load the button within the Modal
// setLoading: to set the button within the modal to load
// setPin: to set the value of the otp
// disabled: to set the button within the modal to disabled
// bg: background color of the 
// pinBg: to set the background color of the pin input
// color: to set the color of the input 
