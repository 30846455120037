import React, { useEffect } from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import DashboardCenter from '../../../components/Dashboard/DashboardCenter/DashboardCenter';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import './Dashboard.css';
import { useSelector } from 'react-redux';
import KYCBanner from '../../../components/Dashboard/KYCBanner/KYCBanner';
// import { getUsers } from "./../../store/userReducer"
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../store/userLogin';
// import { fetchTasks } from '../../../store/fetchTasks';
import { fetchUserBanks } from '../../../store/fetchUserBanks';
import { fetchParents } from '../../../store/fetchParents';
import { fetchAdultYoungsters } from '../../../store/fetchAdultYoungsters';
import runOneSignal from '../../../utils/OneSignal';
import { fetchKolosavePositions } from '../../../store/fetchPosition';
import { fetchBalances } from '../../../store/fetchBalances';
import { fetchKYCs } from '../../../store/fetchKyc';
import { fetchQuotes } from '../../../store/fetchQuote';
import { fetchAnnouncements } from '../../../store/fetchAnnouncement';

function Dashboard() {
  document.title = 'Dashboard | MinieMoney';
  const user = useSelector(state => state.user.value);
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const dispatch = useDispatch();
  const parents = useSelector(state => state.parent.value);
  const youngsters = useSelector(state => state.youngsters.value);
  const youngsterLoading = useSelector(state => state.youngsters.loading);
  const balances = useSelector(state => state.balances.value)
  const balanceLoading = useSelector(state => state.balances.loading)
  const position = useSelector(state => state.kolosavePosition.value)
  const kycs = useSelector((state) => state.kycs.value)
  const kycloading = useSelector((state) => state.kycs.loading)
  const quotes = useSelector((state)=>state.quotes.value)
  const quotesLoading = useSelector((state)=>state.quotes.loading)

  //get users to subscribe for notifications
  React.useEffect(() => {
    runOneSignal();
  });

  //disable customer support
  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      };
    }, 500);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      sessionStorage.getItem('minieToken') == null &&
      sessionStorage.getItem('userData') == null
    ) {
      history('/login/');
    }
    dispatch(fetchUser(accountType))
    dispatch(fetchBalances(accountType))
    dispatch(fetchAnnouncements())
    dispatch(fetchQuotes())
    // dispatch(fetchTasks());
    if (accountType === 'adult') {
      dispatch(fetchKYCs())
      dispatch(fetchUserBanks());
      dispatch(fetchAdultYoungsters());
    }

    if (accountType === 'youngster') {
      dispatch(fetchParents());
      dispatch(fetchKolosavePositions());
    }

    if (accountType === 'youngster' && user && !user.accountStatus) {
      Swal.fire({
        icon: 'error',
        title: 'Your account is temporarily disabled',
        text: 'Your parents have temporarily disabled your account, hence there is a limit to the transactions you can perform. Kindly ask them to reactivate your account for you',
        confirmButtonColor: '#0066f5',
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          sessionStorage.removeItem('minieToken');
          sessionStorage.removeItem('userData');
          history('/login/');
        }
      });
    }
  }, [history, accountType, user, dispatch]);

  return (
    <div>
      {kycs && kycs.length === 0 && accountType === 'adult' && (
        <KYCBanner />
      )}

      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            {user && (
              <DashboardCenter
                user={user}
                parents={parents}
                youngsters={youngsters}
                youngsterLoading={youngsterLoading}
                balances={balances}
                balanceLoading={balanceLoading}
                position={position}
                kycs={kycs}
                kycloading={kycloading}
                quotes={quotes}
                quotesLoading={quotesLoading}
              />
            )}
          </div>

          <div>
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default Dashboard;
