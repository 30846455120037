import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  value: JSON.parse(sessionStorage.getItem("userData")),
  loading: false,
};

export const getUsers = createAsyncThunk("users/getUsers", async () => {
  try {
    return JSON.parse(sessionStorage.getItem("userData"))
  } catch (error) {
    if(error.response.status === 401){
      setTimeout(()=>{
        localStorage.removeItem("_expiredTime")
        sessionStorage.removeItem('minieToken');
        sessionStorage.removeItem('userData');
        window.location.href = '/login/';
      },3000)
    }
  }
});

export const usersSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.value = action.payload;
    },
    [getUsers.rejected]: (state, action) => [(state.loading = "failed")],
  },
});

export default usersSlice.reducer;