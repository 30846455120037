import React from 'react';
import twit from '../../../Image/twit.png';
import ig from '../../../Image/ig.png';
import tik from '../../../Image/tik.png';
import logo from '../../../Image/Logo.svg';
import { Circle, Avatar } from '@chakra-ui/react';

function ShareBadge({
  bgImage,
  remarkCol,
  rank,
  date,
  image,
  image2,
  title,
  userName,
}) {
  return (
    <div className="flex flex-col border rounded-2xl w-[360px] sm:w-[440px]">
      <div
        className={`flex bg-[${bgImage}] flex-col items-center 
    justify-between w-[360px] sm:w-[440px] border rounded-tl-2xl rounded-tr-2xl`}
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <img
          src={logo}
          alt="logo"
          className="max-w-full w-[152px] h-[24px] my-5"
        />
        <Avatar
          style={{
            borderColor: `${remarkCol}`,
          }}
          src={image}
          alt="leader"
          className={`max-w-full border-[${remarkCol}]`}
          size={'lg'}
        />
        <h3 className={`font-bold text-[18px] text-[#FFFFFF] text-center`}>
          {title}
        </h3>
        <p
          className={`font-medium text-[14px] sm:text-[16px] text-[#E7E8E9] text-center`}
        >
          {userName}
        </p>
        <div className="flex flex-col items-center justify-center my-3">
          <img src={image2} alt="gem" />
          <Circle
            bg={`${remarkCol}`}
            color="white"
            className="font-bold text-xl -mt-4 border-[#fff] border text-[#fff] w-[49px] h-[34px]"
          >
            {rank}
          </Circle>
        </div>
        <div className="py-3 px-1 sm:px-6 flex rounded-2xl bg-[#ECF3FD] w-[222px] sm:w-[392px] mb-4 -mt-2">
          <p
            style={{
              color: `${remarkCol}`,
            }}
            className={`flex text-[${remarkCol}] text-[12px] sm:text-[14px] font-semibold text-center`}
          >
            {`I was the ${rank} top saver on MinieMoney’s KoloSave as at ${date}.`}
          </p>
        </div>
      </div>
      <div
        style={{
          color: `${remarkCol}`,
          borderColor: `${remarkCol}`,
        }}
        className={`text-[${remarkCol}] bg-[#ECF3FD] -mt-2 py-2 px-6 flex flex-col sm:flex-row justify-between items-center border rounded-bl-2xl rounded-br-2xl border-[${remarkCol}]`}
      >
        <div className="flex flex-row items-center gap-1">
          <img src={twit} alt="socials-miniemoney" />
          <img src={ig} alt="socials-miniemoney" />
          <img src={tik} alt="socials-miniemoney" />
          <span className="text-[12px] font-semibold">@myminiemoney</span>
        </div>
        <div>
          <span className="text-[12px] font-semibold ">www.miniemoney.com</span>
        </div>
      </div>
    </div>
  );
}

export default ShareBadge;
