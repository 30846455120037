import React, { useState } from 'react';
import Logo from '../../../Image/Logo.png';
// import ImageCarousel from '../../../components/CreateAccount/ImageCarousel';
import EmailVerificationForm from '../../../components/EmailSignup/EmailVerificationForm';
import NameForm from '../../../components/EmailSignup/NameForm';
import ProfileImageForm from '../../../components/EmailSignup/ProfileImageForm';
import ImageForm from '../../../components/EmailSignup/ImageForm';
import axios from 'axios';
import { useNavigate } from 'react-router';
import AnimatedPage from '../../Animation/AnimatedPage';
import InviteParent from '../../../components/EmailSignup/InviteParent';
import SignupImageModal from '../../../components/Modal/SignupImageModal';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

function EmailSignup() {
  document.title = 'Email Signup | MinieMoney';
  const history = useNavigate();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [image, setImage] = useState('');
  const [isSkipLoading, setIsSkipLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [processId, setProcessId] = useState('');
  const userType = window.location.pathname.split('/')[2];

  React.useEffect(() => {
    if (
      sessionStorage.getItem('minieToken') != null ||
      sessionStorage.getItem('userData') != null
    ) {
      history('/dashboard/adult');
    }
  }, [history, firstName, lastName, username, password]);

  function nextStep(e, payload) {
    setEmail(payload.email);
    let emailVerification = document.getElementById('emailVerification');
    let nameform = document.getElementById('nameform');
    let profileform = document.getElementById('profileform');
    let inviteParent = document.getElementById('inviteParent');
    let imageform = document.getElementById('imageform');
    emailVerification.style.display = e === 1 ? 'block' : 'none';
    nameform.style.display = e === 2 ? 'block' : 'none';
    profileform.style.display = e === 3 ? 'block' : 'none';
    inviteParent.style.display = e === 7 ? 'block' : 'none';
    imageform.style.display = e === 8 ? 'block' : 'none';
  }

  function prevStep(e) {
    let emailVerification = document.getElementById('emailVerification');
    let nameform = document.getElementById('nameform');
    let profileform = document.getElementById('profileform');
    let imageform = document.getElementById('imageform');
    let inviteParent = document.getElementById('inviteParent');
    let imageCarousel = document.getElementById('imageCarousel');

    emailVerification.style.display = e === 1 ? 'block' : 'none';
    nameform.style.display = e === 2 ? 'block' : 'none';
    profileform.style.display = e === 3 ? 'block' : 'none';
    inviteParent.style.display = e === 7 ? 'block' : 'none';
    imageform.style.display = e === 8 ? 'block' : 'none';
    if (e === 7) {
      imageCarousel.style.display = 'none';
    }
  }

  const closeModal = () => {
    setShowModal(false);
  };

  const setEmailValue = () => {
    console.log('creating account');
  };

  const gotodashboard = () => {
    try{
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    userData.profileImage = image;
    localStorage.setItem('isTour', false);
    sessionStorage.setItem('userData', JSON.stringify(userData));
    window.location.href = '/dashboard/' + userType;
    }
    catch(error){
      window.location.reload()
    }
  };

  function createAccount(e) {
    setIsSkipLoading(true);
    setLoading(true);
    let id = 'none';
    if (window.location.search !== '') {
      id = new URLSearchParams(window.location.search).get('id');
    }

    axios({
      method: 'POST',
      url:
        process.env.REACT_APP_API_URL+'/signup/' +
        userType +
        '?id=' +
        id,
      headers: {
        ContentType: 'application/json',
      },
      data: {
        firstName: firstName,
        lastName: lastName,
        email: email.trim(),
        phone: '',
        minietag: username,
        profileImage: image,
        dob: sessionStorage.getItem('dob') ? sessionStorage.getItem('dob') : "",
        youngsters:[],
        password: password,
        processId: processId,
      },
    })
      .then(response => {
        sessionStorage.setItem('minieToken', response.data.data.data.token);
        sessionStorage.setItem(
          'userData',
          JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: '',
            nickname: '',
            minietag: username,
            profileImage: image,
            balance: 0,
            walletBalance: 0,
            savingsBalance: 0,
            accountType: userType,
            notifications: [],
            transactions: [],
            friends: [],
            youngsters:[],
            securityQuestion: '',
            securityAnswer: '',
            dob: '',
            gender: '',
            maritalStatus: '',
            kyc: [],
            accountNumber: '',
            accountName: '',
            bank: '',
            status: 'unverified',
            canTransfer: true,
            canRequest: true,
            accountStatus: true,
          })
        );
        setIsSkipLoading(false);
        setLoading(false);
        setShowModal(true);
      })
      .catch(error => {
        console.log(error)
        setIsSkipLoading(false);
        setLoading(false);
        Swal.fire({
          icon:"error",
          title:"Something's wrong 🥺",
          text:error.response.data.data.message,
          confirmButtonColor:"#0066f5"
        })
      });
  }

  const showToast = message => {
    toast.success(message);
    setTimeout(() => toast.dismiss(), 3000);
  };

  return (
    <div className="bgContainer">
      <div>
        <h2 className="appText text-start">Signup </h2>
        <div className="flex justify-center my-4">
          <img
            className="cursor-pointer appLogo"
            onClick={() => history('/')}
            src={Logo}
            alt="miniemoneylogo"
          />
        </div>
        <AnimatedPage>
          <div className="formWithImage">
            {/* <div className="bg-white showLarge">
              <ImageCarousel id={'imageCarousel'} />
            </div> */}

            <div className="formCard">
              <EmailVerificationForm
                id={'emailVerification'}
                nextStep={(e, email) => nextStep(e, email)}
                step={0}
                showToast={e => showToast(e)}
                setProcessId={e => setProcessId(e)}
              />
              <NameForm
                id={'nameform'}
                setUsername={e => setUsername(e)}
                setFirstName={e => setFirstName(e)}
                setLastName={e => setLastName(e)}
                setPassword={e => setPassword(e)}
                setEmail={e => setEmailValue(e)}
                nextStep={e => prevStep(e)}
                step={1}
              />
              <ProfileImageForm
                skipLoading={isSkipLoading}
                isLoading={isLoading}
                id={'profileform'}
                progress={90}
                createAccount={e => createAccount(e)}
                nextStep={e => prevStep(e)}
                setImage={e => setImage(e)}
                email={email}
                step={2}
              />
              <InviteParent
                history={history}
                createAccount={e => createAccount(e)}
                id={'inviteParent'}
                skipLoading={isSkipLoading}
                progress={100}
                nextStep={e => prevStep(e)}
              />

              <ImageForm name={firstName} image={image} id={'imageform'} />
            </div>
          </div>
        </AnimatedPage>

        <SignupImageModal
          showModal={showModal}
          closeModal={closeModal}
          image={image}
          title={'Welcome, ' + firstName + '!'}
          description={
            "You're all set and ready to start your financial journey."
          }
          btnTitle={'Let the journey begin!'}
          onClick={gotodashboard}
        />
      </div>

      <div>
        <ul className="conditionsList">
          <li>Contact</li>
          <li className="conditions">Terms and Conditions</li>
          <li className="conditions">Privacy Policy</li>
        </ul>
      </div>

      <div className="copyrightText text-center my-3">
        <p>&copy; 2022 MinieMoney</p>
      </div>
    </div>
  );
}

export default EmailSignup;
