import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import './BottomMenu.css';
import {
  Home,
  HomeActive,
  // EarnIt,EarnItActive,
  MinieWallet,
  MinieWalletActive,
  MinieSave,
  MinieSaveActive,
  User,
  UserActive,
  Youngster,
  YoungsterActive,
  MinieQuizActive,
  MinieQuiz,
} from './BottomMenuIcons';
import { Icon } from '@chakra-ui/react';

function BottomMenu() {
  const [url] = useState(window.location.pathname.split('/')[1]);
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  return (
    <div className="bottomMenu">
      <div
        onClick={() => history('/dashboard/' + accountType)}
        className={url === 'dashboard' ? 'active' : ''}
      >
        <div className="flex justify-center">
          <Icon as={url === 'dashboard' ? HomeActive : Home} alt="earnit" />
        </div>
        <span> Home</span>
      </div>
      {/* <div onClick={()=>history("/earnit/"+accountType)}  className={url==="earnit"? "earnitActive":""}>
        <div className="flex justify-center">
        <Icon as={url==="earnit"?EarnItActive:EarnIt} alt="earnit"/>
        </div>
        <span>EarnIT</span>
        </div> */}
      <div
        onClick={() => history('/wallet/' + accountType)}
        className={url === 'wallet' ? 'walletActive' : ''}
      >
        <div className="flex justify-center">
          <Icon
            as={url === 'wallet' ? MinieWalletActive : MinieWallet}
            alt="miniewallet"
          />
        </div>
        <span>Wallet</span>
      </div>

      {accountType === 'adult' && (
        <div
          onClick={() => history('/youngsters/' + accountType)}
          className={url === 'youngsters' ? 'active' : ''}
        >
          <div className="flex justify-center">
            <Icon
              as={url === 'youngsters' ? YoungsterActive : Youngster}
              alt="youngster"
            />
          </div>
          <span>Youngsters</span>
        </div>
      )}

      {accountType === 'youngster' && (
        <div
          onClick={() => history('/save/' + accountType)}
          className={
            url === 'save' || url === 'kolosave' ? 'savingsActive' : ''
          }
        >
          <div className="flex justify-center">
            <Icon
              as={
                url === 'save' || url === 'kolosave'
                  ? MinieSaveActive
                  : MinieSave
              }
              alt="miniesave"
            />
          </div>
          <span> Save</span>
        </div>
      )}

      <div
        onClick={() => history('/kahoot/' + accountType)}
        className={url === 'kahoot' ? 'quizActive' : ''}
      >
        <div className="flex justify-center">
          <Icon as={url === 'kahoot' ? MinieQuizActive : MinieQuiz} alt="user" />
        </div>
        <span> Quiz</span>
      </div>

      <div
        onClick={() => history('/account/' + accountType)}
        className={url === 'account' ? 'active' : ''}
      >
        <div className="flex justify-center">
          <Icon as={url === 'account' ? UserActive : User} alt="user" />
        </div>
        <span> Account</span>
      </div>
    </div>
  );
}

export default BottomMenu;
