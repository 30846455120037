import React,{useState} from 'react'
// import sad from "../../../Icons/sad.svg"
import star from "../../../Icons/star.svg"
import starActive from "../../../Icons/starActive.svg"
import "./YoungsterRating.css"

function YoungsterStaticRating(props) {
    const [rating] = useState(0)

  return (
    <div className="youngsterRating rounded-lg">
        <h2 className="font-bold text-center">Youngster’s task rating</h2>
        <div className="my-4 flex justify-center spaceStars lg:space-x-9">
        {/* <div className="ratingStar">
        <img  src={props.task.rating >  0  || rating > 0?starActive:sad} alt="sad"/>
        <div className={props.task.rating === 0  || rating >0 ? "ratingText text-xs text-center textActive":"ratingText text-xs text-center text"}>0%</div>
        </div> */}
        <div className="ratingStar">
        <img src={props.task.rating >=  1  || rating >=1 ? starActive:star} alt="sad"/>
        <div className={props.task.rating === 1  || rating >=1 ? "ratingText text-xs text-center textActive":"ratingText text-xs text-center text"}>20%</div>
        </div>
        <div className="ratingStar">
        <img  src={props.task.rating >= 2  || rating >=2 ? starActive:star} alt="sad"/>
        <div className={props.task.rating === 2  || rating >=2 ? "ratingText text-xs text-center textActive":"ratingText text-xs text-center text"}>40%</div>
        </div>
        <div   className="ratingStar">
        <img  src={props.task.rating >= 3  || rating >=3 ? starActive:star} alt="sad"/>
        <div className={props.task.rating === 3  || rating >=3 ? "ratingText text-xs text-center textActive":"ratingText text-xs text-center text"}>60%</div>
        </div>
        <div  className="ratingStar">
        <img  src={props.task.rating >= 4  || rating >=4 ? starActive:star} alt="sad"/>
        <div className={props.task.rating === 4  || rating >=4 ? "ratingText text-xs text-center textActive":"ratingText text-xs text-center text"}>80%</div>
        </div>
        <div  className="ratingStar">
        <img src={props.task.rating === 5  || rating >=5 ? starActive:star} alt="sad"/>
        <div className={props.task.rating === 5  || rating >=5 ? "ratingText text-xs text-center textActive":"ratingText text-xs text-center text"}>100%</div>
        </div>
        </div>
        
    </div>
  )
}

export default YoungsterStaticRating