import React,{useState} from 'react'
import { Select,Input,Button, IconButton } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import axios from 'axios'
import Swal from 'sweetalert2'
import { fetchUser } from '../../../store/userLogin'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'


function TaskDuration(props) {
    const [startDate,setStartDate] = useState("")
    const [endDate,setEndDate] = useState("")
    const [frequency,setTaskFrequency] = useState("")
    const [startError,setStartError] = useState("")
    const [endError,setEndError] = useState("")
    const [frequencyError,setFrequencyError] = useState("")
    const [loading,setLoading] = useState("")
    const dispatch = useDispatch()
    const history = useNavigate()
    

    const createTask = () =>{
        if(props.taskDetails.reward > props.user.walletBalance){
            Swal.fire({
                icon:"info",
                title:"Insufficient balance",
                text:"Sorry, you don't have enough money in your wallet to create this task. Kindly top-up your wallet and try again",
                confirmButtonColor:"#06AEDA",
                confirmButtonText:"Fund Wallet"
            }).then((result)=>{
                if(result.isConfirmed){
                    history("/fund-wallet/adult")
                }
            })
            return
        }

        
        if(startDate !== "" && endDate !=="" && frequency !== ""){
            startDate ===""?setStartError("Please start date"):setStartError("")
            endDate ===""? setEndError("Please enter  end date"):setEndError("")
            frequency ===""? setFrequencyError("Please enter task frequency"):setFrequencyError("")  
        const payload = {startDate,endDate,frequency}
        props.createTask(payload)
        }
       
        if(startDate !== "" && endDate !=="" && frequency !== ""){
            startDate ===""?setStartError("Please start date"):setStartError("")
            endDate ===""? setEndError("Please enter  end date"):setEndError("")
            frequency ===""? setFrequencyError("Please enter task frequency"):setFrequencyError("") 
            
       
        if(Date.parse(startDate) > Date.parse(endDate)){
            Swal.fire({
                icon:"warning",
                title:"That's not right!",
                text:"The task start date cannot be later than the end date. Your child is not a time traveller, you know",
                confirmButtonColor:"#00b5d8"
            })
            return
        }

        if(Date.parse(endDate) < Date.now()){
            Swal.fire({
                icon:"warning",
                title:"That's not right!",
                text:"The task end date cannot be in the past. Your child is not a time traveller, you know",
                confirmButtonColor:"#00b5d8"
            })
            return
        }

        if(Date.parse(startDate) < (Date.now() - 864000000)){
            Swal.fire({
                icon:"warning",
                title:"That's not right!",
                text:"The task start date cannot be in the past. Your child is not a time traveller, you know",
                confirmButtonColor:"#00b5d8"
            })
            return
        }

        setLoading(true)
        axios({
            method:"POST",
            url: process.env.REACT_APP_API_URL+"/create-task",
            headers:{
                Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
                ContentType:"application/json"
            },
            data:{
            kidId:props.youngster._id,
            title:props.taskDetails.title,
            reward:props.taskDetails.reward,
            description:props.taskDetails.description,
            startDate:startDate,
            endDate:endDate,
            frequency:frequency,
            kidEmail:props.youngster.email,
            rating:0,
            kidName:props.youngster.firstName,
            relationship:props.youngster.relationship?props.youngster.relationship:"parent",
            parentName:props.user.firstName,
            parentEmail:props.user.email,
            status:"pending",
            profileImage:props.youngster.profileImage,
            kidLastName:props.youngster.lastName
            }
        }).then((response)=>{
            dispatch(fetchUser("adult")).then(()=>{
                setLoading(false)
                Swal.fire({
                    icon:"success",
                    title:"EarnIt task created successfully",
                    text:response.data.data.message,
                    confirmButtonColor:"#06AEDA"
                }).then((result)=>{
                    if(result.isConfirmed){
                        window.location.href="/earnit/adult"
                    }
                })
            })
           
        }).catch((error)=>{
            setLoading(false)
            Swal.fire({
                icon:"error",
                title:"EarnIt task not created",
                text:error.response.data.data.message,
                confirmButtonColor:"#06AEDA"

            })
        })
       
        }
        else{
            startDate ===""?setStartError("Please start date"):setStartError("")
           endDate ===""? setEndError("Please enter  end date"):setEndError("")
           frequency ===""? setFrequencyError("Please enter task frequency"):setFrequencyError("")
        }


    }

   const goBack = () =>{
    const taskdetails = document.getElementById("taskdetails")
    const taskduration = document.getElementById("taskduration")
    const createTask = document.getElementById("createTaskHeading")
    taskdetails.style.display ="block"
    taskduration.style.display = "none"
    createTask.style.display = "block"
   }



  
  return (
    <div id={props.id} className="hiddenForm space-y-4">
        <IconButton onClick={goBack} background={"#E6F7FB"} rounded={"full"} icon={<ArrowBackIcon color={"#06AEDA"}/>}/>
        <div>
        <label>Start Date</label>
        <Input onChange={(e)=>setStartDate(e.target.value)} placeholder="Start Date" type="date"  focusBorderColor='#06AEDA'></Input>
        <span className="text-sm text-red-600">{startError}</span>
        </div>
        <div>
        <label>End Date</label>
        <Input onChange={(e)=>setEndDate(e.target.value)} placeholder="End Date" type="date" focusBorderColor='#06AEDA' ></Input>
        <span className="text-sm text-red-600">{endError}</span>
        </div>
        <div>
        <label>Task Frequency</label>
        <Select placeholder='Select Frequency' onChange={(e)=>setTaskFrequency(e.target.value)} focusBorderColor='#06AEDA'>
            <option>Once</option>
            <option>Daily</option>
            <option>Weekly</option>
            <option>Monthly</option>
        </Select>
        <span className="text-sm text-red-600">{frequencyError}</span>
        </div>
        <Button isLoading={loading} onClick={createTask} className="confirmButton" rounded="full" width="full" backgroundColor={"#06AEDA"} colorScheme={"#06AEDA"}>Create Task</Button>
    </div>
  )
}

export default TaskDuration