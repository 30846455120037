import React from 'react';
import { Avatar } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import youngstertotal from '../../../Icons/youngstertotal.svg';
import youngstersavings from '../../../Icons/youngstersaving.svg';
import youngsterearnit from '../../../Icons/youngsterearnit.svg';
import youngsterwallet from '../../../Icons/youngsterwallet.svg';
import { Button } from '@chakra-ui/react';
import setallowance from '../../../Icons/set-allowance.svg';
import assigntask from '../../../Icons/assign-task.svg';
import sendmoney from '../../../Icons/send-money.svg';
import Swal from 'sweetalert2';
import { standardAmountFormat } from '../../../utils/amountUtil';
// import eyeslash from "../../../Icons/eye-slash.svg"

import { useNavigate } from 'react-router';
import slugify from 'slugify';

function YoungsterAccount(props) {
  const history = useNavigate();
  const youngsterName =
    slugify(props.youngster.firstName, { lower: true }) +
    '-' +
    slugify(props.youngster.lastName, { lower: true });

  const goToYoungster = e => {
    sessionStorage.setItem('youngster', JSON.stringify(e));
    history(
      '/account/adult/youngster/' +
        slugify(e.firstName, { lower: true }) +
        '-' +
        slugify(e.lastName, { lower: true })
    );
  };

  const setAllowance = e => {
    if (e.minietag === '' || e.minietag === undefined) {
      Swal.fire({
        icon: 'info',
        title: "Youngster doesn't have a Minietag 🥺",
        text: `Sorry, you can't send an allowance to a youngster that doesn't have a minietag.`,
        confirmButtonColor: '#0066f5',
      });
      return;
    }
    sessionStorage.setItem('youngster', JSON.stringify(e));
    history('/account/adult/set-allowance/' + youngsterName);
  };

  const createTask = e => {
    // sessionStorage.setItem('youngster', JSON.stringify(e));
    // history('/account/adult/assign-task/' + youngsterName);
    Swal.fire({
      icon: 'info',
      title: 'Under maintenance 🤓',
      text: "We're currently revamping the EarnIT feature to give a better experience",
      confirmButtonColor: '#06aeda',
    });
  };

  const sendMoney = e => {
    sessionStorage.setItem('youngster', JSON.stringify(e));
    history('/account/adult/send-money/' + youngsterName);
  };

  return (
    <div className="youngsterAccount">
      <div
        onClick={() => goToYoungster(props.youngster)}
        className="flex justify-between cursor-pointer"
      >
        <div className="flex">
          <Avatar
            onClick={() => alert(window.location.pathname)}
            src={props.youngster.profileImage}
            alt="youngsterImage"
          />
          <div className="ml-2">
            <h2 className="font-bold">
              {props.youngster.firstName} {props.youngster.lastName}
            </h2>
            <p>@{props.youngster.minietag}</p>
          </div>
        </div>
        <div
          onClick={() => goToYoungster(props.youngster)}
          className="cursor-pointer mt-2"
        >
          <ArrowForwardIcon />
        </div>
      </div>
      {/* youngster cards */}
      <div className="youngsterbalanceCards">
        <div className="totalBalanceCard">
          <div>
            <img src={youngstertotal} alt="" />
          </div>
          <div className="cardDetails">
            <span>Total balance</span>
            <h2 className="youngsterbalancetext">
              {standardAmountFormat(props.youngster.walletBalance)}
            </h2>
          </div>
        </div>
        <div className="walletBalanceCard">
          <div>
            <img src={youngsterwallet} alt="" />
          </div>
          <div className="cardDetails">
            <span>Minie Wallet</span>
            <h2 className="youngsterbalancetext">
              {standardAmountFormat(props.youngster.walletBalance)}
            </h2>
          </div>
        </div>
        <div className="savingsBalanceCard">
          <div>
            <img src={youngstersavings} alt="" />
          </div>
          <div className="cardDetails">
            <span>
              {window.innerWidth < 500 ? 'Minie savings' : 'Minie savings'}
            </span>
            <h2 className="youngsterbalancetext">
              {standardAmountFormat(props.youngster.savingsBalance)}
            </h2>
          </div>
        </div>
        <div className="earnitCard">
          <div>
            <img src={youngsterearnit} alt="" />
          </div>
          <div className="cardDetails">
            <span>EarnIT</span>
            <h2 className="youngsterbalancetext">
              {standardAmountFormat(
                props.youngster.transactions &&
                  props.youngster.transactions
                    .filter(transaction => transaction.type === 'earnit')
                    .reduce((a, b) => a + b.amount, 0)
              )}
            </h2>
          </div>
        </div>
      </div>
      <div className="mt-9">
        <div className="buttonsGrid">
          <Button
            variant="outline"
            onClick={() => setAllowance(props.youngster)}
            className="text-md buttonText"
            size="lg"
            rounded={'full'}
          >
            <img src={setallowance} alt="set-allowance" className="mr-2" />
            Set allowance
          </Button>

          <Button
            onClick={() => createTask(props.youngster)}
            variant="outline"
            className="text-md buttonText"
            rounded={'full'}
            size="lg"
          >
            <img src={assigntask} className="mr-2" alt="set-allowance" />
            Assign task
          </Button>

          <Button
            onClick={() => sendMoney(props.youngster)}
            background={'#005FEB'}
            className="text-md sendMoneyMobile buttonText"
            color="white"
            size="lg"
            variant="#005FEB"
            rounded={'full'}
          >
            <img src={sendmoney} className="mr-2" alt="set-allowance" />
            Send Money
          </Button>
        </div>
      </div>
    </div>
  );
}

export default YoungsterAccount;
