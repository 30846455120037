import React, { useState } from 'react';
import './Forms.css';
import CheckBoxCircle from './CheckBoxCircle';
import { InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

function PasswordInput(props) {
  const [isUpper, setUpper] = useState(false);
  const [isLower, setLower] = useState(false);
  const [isSpecial, setSpecial] = useState(false);
  const [isNumber, setNumber] = useState(false);
  const [isEight, setEight] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const special = /[^a-zA-Z0-9]/

  function setPasswordValue(e) {
    if (/[A-Z]/.test(e.target.value)) {
      setUpper(true);
    } else {
      setUpper(false);
    }

    if (/[a-z]/.test(e.target.value)) {
      setLower(true);
    } else {
      setLower(false);
    }

    if (special.test(e.target.value)) {
      setSpecial(true)
    } else {
      setSpecial(false)
    }

    if (/\d/.test(e.target.value)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    if (e.target.value.length >= 8) {
      setEight(true);
    } else {
      setEight(false);
    }

    props.setPassword(e.target.value);
  }

  return (
    <div className='text-left'>
     <label>{props.label}</label>
      <InputGroup size="lg">
        <Input
          pr="4.5rem"
          type={show ? 'text' : 'password'}
          placeholder={props.placeholder}
          onChange={e => setPasswordValue(e)}
        />
        <InputRightElement>
          <Button
            variant={'unstyled'}
            background={'white'}
            size="sm"
            onClick={handleClick}
          >
            {show ? <FiEye size={'20px'} />: <FiEyeOff size={'20px'} />}
          </Button>
        </InputRightElement>
      </InputGroup>

      <div className="my-3 checkbox-container">
        <CheckBoxCircle checked={isUpper} label={'Uppercase'} />
        <CheckBoxCircle checked={isLower} label={'Lower'} />
        <CheckBoxCircle checked={isSpecial} label={'Special character'} />
        <CheckBoxCircle checked={isNumber} label={'Number'} />
        <CheckBoxCircle checked={isEight} label={'Atleast 8 characters'} />
      </div>
    </div>
  );
}

export default PasswordInput;
