import React, { useState } from 'react';
import './Kyc.css';
import KYCHeader from '../../../components/KYC/KYCHeader';
import passport from '../../../Icons/passport-photo.svg';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import PhotoInstructions from './PhotoInstructions';
import { Button } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { fetchUser } from '../../../store/userLogin';
import { useDispatch } from 'react-redux';
import { ref, getDownloadURL, uploadString } from 'firebase/storage';
import { toast } from 'react-toastify';
import { storage } from '../../../firebase';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

function Passport(props) {
  const [image, setImage] = useState('');
  const [btnloading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.value);
  const accountType = window.location.pathname.split('/')[2];
  const history = useNavigate();
  const [disabled,setDisabled] = useState(true)

  const goToOVerview = () => {
    history('/account/adult/kyc');
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (
      sessionStorage.getItem('minieToken') == null &&
      sessionStorage.getItem('userData') == null
    ) {
      history('/login/');
    }
  }, [history, accountType, user]);

  React.useEffect(()=>{
    if(image !== ''){
      setDisabled(false)
    }
    else{
      setDisabled(true)
    }
  },[image])

  const handleTakePhoto = dataUri => {
    // Do stuff with the photo...
    setImage(dataUri);
  };

  const uploadBase64Image = () => {
    const id = toast.loading('Uploading Image. Please wait...');
    // Create the file metadata
    /** @type {any} */
    const storageRef = ref(
      storage,
      user.email === undefined
        ? user.phone + '_passport'
        : user.email + '_passport'
    );
    uploadString(storageRef, image, 'data_url', {
      contentType: 'image/jpg',
    }).then(() => {
      getDownloadURL(storageRef).then(downloadURL => {
        toast.update(id, {
          render: 'File uploaded successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        setImage(downloadURL);
        uploadPhoto(downloadURL);
      });
    });
  };

  const uploadPhoto = image => {
    if (image === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Snap a picture!',
        text: 'Please snap a picture you would like to upload for KYC',
        confirmButtonColor: '#005FEB',
      });
      return;
    }
    setBtnLoading(true);

    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/upload-passport-photograph',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        file: image,
        status: 'pending',
        type: 'passport',
      },
    })
      .then(response => {
        setBtnLoading(false);
        dispatch(fetchUser('adult'));
        Swal.fire({
          icon: 'success',
          title: 'Means of Id Uploaded',
          text: response.data.data.message,
          confirmButtonColor: '#005FEB',
          confirmButtonText: 'Done',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.href = '/account/adult/kyc';
          }
        });
      })
      .catch(error => {
        setBtnLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Means of Id Not Uploaded',
          text: error.response.data.data.message,
          confirmButtonColor: '#005FEB',
          confirmButtonText: 'Try again',
        });
      });
  };

  return (
    <div className="dashboardCenter walletScreensContainer pb-12">
      <KYCHeader goBack={goToOVerview} title="Passport Photograph" />
      <div className="my-5">
        <img className="mx-auto" src={passport} alt="passport" />
        <div className="text-center">
          <h2 className="font-bold text-lg mt-7">Take a Selfie</h2>
          <p>
            kindly take a clear photo of your face for us to verify your
            identity.
          </p>
        </div>

        {image === '' && (
          <div className="my-4">
            <Camera
              onTakePhoto={dataUri => {
                handleTakePhoto(dataUri);
              }}
            />

            <PhotoInstructions />
          </div>
        )}

        {image !== '' && (
          <div className="my-4">
            <img src={image} alt="snappedImage" />
          </div>
        )}

        {image !== '' && (
          <Button
            className="my-3"
            textAlign={'center'}
            onClick={() => setImage('')}
          >
            Retake Picture
          </Button>
        )}

        <Button
          isLoading={btnloading}
          onClick={uploadBase64Image}
          className="confirmButton"
          colorScheme={'#0066f5'}
          background={'#0066f5'}
          rounded={'full'}
          width={'full'}
          disabled={disabled}
        >
          Upload Selfie
        </Button>
      </div>
    </div>
  );
}

export default Passport;
