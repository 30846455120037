import React,{useState} from 'react'
import { Input } from '@chakra-ui/react';

function EmailInput(props) {
  const [error, setError] = useState('');
  const emailRegex = /.+@.+\..+/;

  const handleInput = (event) =>{
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  
  function setEmailValue(e) {
    props.setEmail(e.target.value);
    setError("Please enter a valid email address")
    if(e.target.value.match(emailRegex)){
    setError("")
    props.setDisabled(false)
  }
  else{
    props.setDisabled(true)
  }
  }

  return (
    <div>
      <label>{props.label}</label>
      <Input
        onChange={e => setEmailValue(e)}
        placeholder={props.placeholder}
        size={props.size}
        onKeyPress={handleInput}
        type={"email"}
      />
      <span className="text-red-500">{error}</span>
    </div>
  );
}

export default EmailInput;
