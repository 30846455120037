import React from 'react';

function Instructions() {
  return (
    <div className="instructionContainer">
        Your phone number will become your MinieMoney bank account number.
        <br/> <br/>
        Daily Transfer Limit: <strong>NGN 50,000</strong>
        <br/>
        Maximum Holding Balance: <strong>NGN 300,000 </strong>
    </div>
  );
}

export default Instructions;
