import React from 'react'
import {Button} from "@chakra-ui/react"
import { useNavigate } from 'react-router'
import users from '../../../Icons/users.svg'

function NoYoungster() {
 const history = useNavigate()

  return (
    <div className="notificationsCard">
    <div>
      <div className="my-4 flex justify-center">
        <img src={users} alt="notificationBell" />
      </div>
      <p className="my-3">Create an account for your youngsters to help them start their
            financial journey and unlock their world of many possibilities.</p>
      <Button fontSize={"11px"} onClick={()=>history("/create-youngster/adult")} rounded={"full"} background="#0073E5" color="white" variant="solid">
          {window.innerWidth < 500
              ? 'Create Youngster account'
              : 'Create Youngster account'}
          </Button>
    </div>
    </div>
  )
}

export default NoYoungster