import React from 'react'
import { ArrowBackIcon} from '@chakra-ui/icons';
import {  IconButton } from '@chakra-ui/react'
// import SettingsIcon from "../../Icons/settingsIcon.svg"



function WalletScreenHeader(props) {


  return (
        <div>
        <IconButton
          onClick={()=>props.goBack()}
          className="walletAccountArrow"
          rounded={'full'}
          icon={<ArrowBackIcon />}
        />
        <h2 className="walletScreenTitle text-center">
       {props.title}
        </h2>

   
    </div>
  )
}

export default WalletScreenHeader