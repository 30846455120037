import React from 'react';
import './MinieSavings.css';
import Notification from '../../Icons/savingsNotification.svg';
import { useNavigate } from 'react-router';
import SavingsCard from './SavingsCard';
import Kolosave from '../../Icons/kolosave.svg';
import Target from '../../Icons/target.svg';
import Safeguard from '../../Icons/safeguard.svg';
import Dollars from '../../Icons/dollars.svg';
import { ArrowForwardIcon } from '@chakra-ui/icons'

function DashboardCenter(props) {
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const gotonotifications = () => {
    history('/notifications/' + accountType);
  };
  const [features] = React.useState([
    {
      name: 'Target',
      image: Target,
      description:
        'Save money towards your unique individual, group or family goal',
      color: '#15B79E',
      background: '#E8F8F5',
      amount:''
    },
    {
      name: 'Safeguard',
      image: Safeguard,
      description:
        'Lock your money for a fixed period of time to avoid spending temptations. ',
      color: '#6172F3',
      background: '#EFF1FE',
      amount:''
    },
    {
      name: 'Minie Dollar',
      image: Dollars,
      description: 'Save, invest and grow your money in dollar (USD).',
      color: '#EF6820',
      background: '#FDF0E9',
      amount:''
    },
  ]);
  const gotokolosave = () => {
    history("/kolosave/"+accountType)
  };
  return (
    <div className="dashboardCenter" id='saving'>
      {/* the user info */}
      <div className="flex justify-between w-full">
        <div className="flex">
          <h2 className="font-bold text-2xl">Minie Savings</h2>
        </div>
        <div className="relative cursor-pointer" onClick={gotonotifications}>
          {props.user.notifications.filter(
            notification => notification.status === 'recent'
          ).length > 0 && (
            <div className="notificationsBadge">
              {
                props.user.notifications.filter(
                  notification => notification.status === 'recent'
                ).length
              }
            </div>
          )}
          <img src={Notification} alt="notification" />
        </div>
      </div>

      <SavingsCard balances={props.balances} />
      <div className="featureGrid">
        <div
          style={{ background: '#F0F5EA' }}
          className="featureCard cursor-pointer "
          onClick={gotokolosave}
        >
          <img className="iconSize" src={Kolosave} alt="Kolosave" />
          <div className="flex flex-col justify-between">
          <div>
            <h2 style={{ color: '#669F2A' }}>Kolosave</h2>
            <p>
            Save your money automatically or at any time you want. (6% per year)
            </p>
            </div>
            <span className='pt-8 font-bold text-[#669F2A]'>**********</span>
          </div>
          <div className="arrowIcon">
              <ArrowForwardIcon />
            </div>
        </div>
        {features.map((feature, i) => (
          <div
            style={{ background: feature.background }}
            className="featureCard "
            key={i}
          >
            <img className="iconSize" src={feature.image} alt="" />
           <div className="flex flex-col justify-between">
            <div>
              <h2 style={{ color: feature.color }}>{feature.name}</h2>
              <p>{feature.description}</p>
            </div>
              <span style={{ color: feature.color }} className='pt-8 font-bold'>{feature.amount}</span>

           </div>
           <div className="arrowIcon">
              <ArrowForwardIcon />
            </div>
            <div className="comingSoon">Coming soon</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardCenter;
