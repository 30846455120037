import React, { useState } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import './EmailVerificationForm.css';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Swal from 'sweetalert2';
import Steppers from '../../components/Misc/Steppers/Steppers';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import ConfirmOtpModal from '../Modal/ConfirmOtpModal';
import EmailInput from '../Misc/Forms/EmailInput';

function EmailVerificationForm(props) {
  const [emailaddress, setEmailAddress] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2]
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [payload, setPayload] = useState({});

  const goBack = () => {
    history(-1);
  };

  function setEmail(e) {
    setEmailAddress(e)
  }

  function verifyEmail() {
    setLoading(true);
    if (emailaddress !== '') {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/verify-email',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          email: emailaddress,
          accountType: accountType,
        },
      })
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            setSessionId(response.data.data.sessionId);
            setShowModal(true);
            setPayload({
              email: emailaddress,
              sessionId: response.data.data.sessionId,
            });
          } else if (response.status === 201) {
            setLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Email already in database',
              text: response.data.data.message,
              confirmButtonColor: '#0073E5',
            }).then(result => {
              if (result.isConfirmed) {
                history('/login/');
              }
            });
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Email invalid',
            text: error.response.data.error,
            confirmButtonColor: '#0073E5',
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Please enter your email address',
        confirmButtonColor: '#0073E5',
      });
    }
  }

  //check if otp is correct
  function checkOtp() {
    if (otp !== '') {
      setLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/check-otp',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          otp: otp,
          sessionId: sessionId,
        },
      })
        .then(response => {
          props.showToast(response.data.data.message);
          props.setProcessId(response.data.data.processId);
          setLoading(false);
          setShowModal(false);
          props.nextStep(2, payload);
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Wrong OTP',
            text: 'The OTP you entered is wrong/invalid',
            confirmButtonColor: '#0073E5',
          });
        });
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Please enter your OTP',
        confirmButtonColor: '#0073E5',
      });
    }
  }

  const showToast = e => {
    props.showToast(e);
  };

  return (
    <div className="emailVerificationCard" id={props.id}>
      <div className="relative">
        <Steppers step={props.step} />
      </div>

      <h2 className="mt-7">Let's start here</h2>
      <p>Enter your email address to get a special code.</p>

      <EmailInput
        label={'Email Address'}
        setEmail={e => setEmail(e)}
        setDisabled={e => setDisabled(e)}
        placeholder={"Enter your email address"}
        size={"lg"}
      />

      <div
        onClick={() => history('/phone-signup/' + accountType)}
        className="my-3 text-blue-600 cursor-pointer"
      >
        Use Phone Number instead <ArrowForwardIcon />{' '}
      </div>

      <PrimaryButton
        loading={isLoading}
        onClick={e => verifyEmail(e)}
        title={'Get Started'}
        disabled={disabled}
      />

      <div className="text-center my-3">
        <button className={'brandcolor font-semibold'} onClick={goBack}>
          Back to previous
        </button>
      </div>

      <ConfirmOtpModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        confirm={() => checkOtp()}
        loading={isLoading}
        setLoading={e => setLoading(e)}
        setPin={e => setOtp(e)}
        disabled={() => setDisabled(false)}
        bg={'#005FEB'}
        pinBg={'#C6DBFB'}
        color={'#005FEB'}
        class={"auth"}
        closeButton={'blue'}
        userDetail={emailaddress}
        sessionId={sessionId}
        setSessionId={e => setSessionId(e)}
        showToast={e => showToast(e)}
        link={'resend-email-otp'}
        dataType={'email'}
      />
    </div>
  );
}

export default EmailVerificationForm;
