import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { 
  IconButton, 
  Spinner } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import walletIcon from '../../../Icons/walletIcon.svg';
import balanceIcon from '../../../Icons/balanceIcon.svg';
import savingsIcon from '../../../Icons/savingsIcon.svg';
import eyeIcon from '../../../Icons/eyeBlack.svg';
import './Wallet.css';
import Tour from 'reactour';
import { amountFormat,formatZero } from '../../../utils/amountUtil';

function Wallet(props) {
  const accountType = window.location.pathname.split('/')[2] 
  const [showBalance, setShowBalance] = React.useState(
    localStorage.getItem('showBalance') !== null
      ? JSON.parse(localStorage.getItem('showBalance'))
      : true
  )
  const [isTourOpen, setTourOpen] = useState(false);


  //set tour to false if it is null or undefined
  React.useEffect(()=>{
    if(JSON.parse(localStorage.getItem('isTour')) === null || JSON.parse(localStorage.getItem('isTour')) === undefined){
      setTourOpen(true)
    }
  },[])


  const closeTour = () => {
    setTourOpen(false)
    localStorage.setItem('isTour', true);
    props.showSetPin()
  };

  const setShowBalanceValue = e => {
    localStorage.setItem('showBalance', e);
    setShowBalance(e);
  };

  const changeCard = e => {
    const circle2 = document.getElementById('wallet2');
    if (e > 0 && circle2.classList.contains('circleActive')) {
      thirdCircle();
    } else if (e < 0 && circle2.classList.contains('circleActive')) {
      firstCircle();
    } else if (e > 0) {
      secondCircle();
    } else if (e < 0) {
      secondCircle();
    }
  };

  const firstCircle = () => {
    const circle1 = document.getElementById('wallet1');
    const circle2 = document.getElementById('wallet2');
    const circle3 = document.getElementById('wallet3');
    const centerCard = document.getElementById('centerCard');
    const walletBalance = document.getElementById('walletBalance');
    const balanceBalance = document.getElementById('balanceBalance');

    walletBalance.classList.add('activeCard');
    balanceBalance.classList.remove('activeCard');
    balanceBalance.classList.add('inactiveCardRight');

    circle1.classList.add('circleActive');
    circle2.classList.remove('circleActive');
    circle3.classList.remove('circleActive');
    centerCard.scrollLeft = 0;
    document.getElementById('leftArrow').style.display = 'none';
  };

  const secondCircle = () => {
    const circle1 = document.getElementById('wallet1');
    const circle2 = document.getElementById('wallet2');
    const circle3 = document.getElementById('wallet3');

    const savingsBalance = document.getElementById('savingsBalance');
    const balanceBalance = document.getElementById('balanceBalance');
    const walletBalance = document.getElementById('walletBalance');

    balanceBalance.classList.add('activeCard');
    walletBalance.classList.add('inactiveCardLeft');
    savingsBalance.classList.remove('activeCard');
    walletBalance.classList.remove('activeCard');

    const centerCard = document.getElementById('centerCard');
    circle1.classList.remove('circleActive');
    circle2.classList.add('circleActive');
    circle3.classList.remove('circleActive');
    centerCard.scrollLeft =
      (centerCard.scrollWidth - centerCard.clientWidth) / 2;
    document.getElementById('leftArrow').style.display = 'block';
    document.getElementById('rightArrow').style.display = 'block';
  };

  const thirdCircle = () => {
    const circle1 = document.getElementById('wallet1');
    const circle2 = document.getElementById('wallet2');
    const circle3 = document.getElementById('wallet3');
    const savingsBalance = document.getElementById('savingsBalance');
    const balanceBalance = document.getElementById('balanceBalance');

    savingsBalance.classList.add('activeCard');
    balanceBalance.classList.remove('activeCard');
    balanceBalance.classList.remove('inactiveCardRight');
    balanceBalance.classList.add('inactiveCardLeft');
    const centerCard = document.getElementById('centerCard');
    circle3.classList.add('circleActive');
    circle1.classList.remove('circleActive');
    circle2.classList.remove('circleActive');
    centerCard.scrollLeft = 668;
    document.getElementById('rightArrow').style.display = 'none';
  };

  const steps = [
    {
      selector: '[data-tut="reactour__intro"]',
      content:
        'Hi ' +
        props.user.firstName +
        ' 👋. Welcome to MinieMoney. My name is Wendy and I would be giving you a quick guide on how to use your MinieMoney account.',
    },


    {
      selector: '[data-tut="reactour__balance"]',
      content: 'View your total balance, wallet balance and savings balance all in one place 💰',
    },

    {
      selector: '[data-tut="reactour__fundwallet"]',
      content:
        accountType ==='adult' ? 'Seamlessly fund your MinieWallet with your card or bank transfer'
        : 'Seamlessly request money from friends and family using the request money feature 💸',
    },


    {
      selector: '[data-tut="reactour__wallet"]',
      content: 'Instantly send and receive money to other MinieMoney users using our Wallet feature ⚡',
    },

    {
      selector: '[data-tut="reactour__account"]',
      content:
        'Manage your account details and tell us a bit about you 🤗',
    },

    {
      selector: '[data-tut="reactour__todo"]',
      content:
        "Don't forget to complete your To-Dos to have full access to all the benefits on MinieMoney 📃",
    },


    {
      selector: '[data-tut="reactour__end"]',
      content:
        `Thank you for sticking with me till the end of the tour, ${props.user.firstName}. I hope you enjoyed it. Have fun doing amazing stuff with your finance on MinieMoney ✌`,
    },
  ];

  useEffect(() => {
    if (
      localStorage.getItem('isTour') === 'false' ||
      localStorage.getItem('isTour') === false
    ) {
      setTourOpen(true);
    }

    setTimeout(() => {
      const centerCard = document.getElementById('centerCard');
      centerCard.scrollLeft =
        (centerCard.scrollWidth - centerCard.clientWidth) / 2;
    }, 200);
  }, []);
  return (
    <div data-tut="reactour__balance">
      {/* wallet cards */}
      <div id="centerCard" className="walletCards space-x-5">
        <div
          onClick={() => firstCircle()}
          id="walletBalance"
          className="inactiveCardLeft walletBalance"
        >
          <img className="walletIcon" src={walletIcon} alt="walletIcon" />
          <div>
            <span>Minie Wallet Balance</span>
            <h2>
            {amountFormat(props.balances && props.balances.walletBalance, showBalance)}
            </h2>
          </div>
          <img
            onClick={() => setShowBalanceValue(!showBalance)}
            src={eyeIcon}
            alt="eyeIcon"
            className="eyeIcon"
          />
        </div>
        <div
          onClick={() => secondCircle()}
          id="balanceBalance"
          className="balanceBalance"
        >
          <img className="walletIcon" src={balanceIcon} alt="walletIcon" />
          <div>
            <span>Total Balance</span>
            <h2>
              {props.balances && props.balances.totalBalance ? (
                amountFormat(props.balances && props.balances.totalBalance, showBalance)
              ) : props.balances && props.balances.totalBalance === 0 ? (
                formatZero(props.balances && props.balances.totalBalance,showBalance)
              ) : (
                <Spinner />
              )}
            </h2>
          </div>
          <img
            onClick={() => setShowBalanceValue(!showBalance)}
            src={eyeIcon}
            alt="eyeIcon"
            className="eyeIcon"
          />
        </div>
        <div
          onClick={() => thirdCircle()}
          id="savingsBalance"
          className="inactiveCardRight savingsBalance"
        >
          <img className="walletIcon" src={savingsIcon} alt="walletIcon" />
          <div>
            <span>Savings Balance</span>
            <h2>
            {props.balances && props.balances.savingsBalance ? (
                amountFormat(props.balances && props.balances.savingsBalance, showBalance)
              ) :props.balances && props.balances.savingsBalance === 0 ? (
               formatZero(props.balances && props.balances.savingsBalance,showBalance)
              ) : (
                <Spinner />
              )}
            </h2>
          </div>
          <img
            onClick={() => setShowBalanceValue(!showBalance)}
            src={eyeIcon}
            alt="eyeIcon"
            className="eyeIcon"
          />
        </div>
        
        <div className="leftArrow">
        <IconButton
          id="leftArrow"
          rounded={'full'}
          aria-label="LeftArrow"
          onClick={() => changeCard(-1)}
          icon={<ArrowBackIcon />}
        />
        </div>

        <div className="rightArrow">
        <IconButton
          id="rightArrow"
          rounded={'full'}
          onClick={() => changeCard(+1)}
          aria-label="RightArrow"
          icon={<ArrowForwardIcon />}
        />
        </div>
      
      </div>
      <div className="carsouselCircle">
        <div id="wallet1" onClick={firstCircle} className="circle"></div>
        <div
          id="wallet2"
          onClick={secondCircle}
          className="circle circleActive"
        ></div>
        <div id="wallet3" onClick={thirdCircle} className="circle"></div>
      </div>
      <Tour steps={steps} isOpen={isTourOpen} onRequestClose={closeTour} />
    </div>
  );
}

export default Wallet;
