import { Button, Icon } from '@chakra-ui/react';
import React from 'react';
import "./Buttons.css"

function TertiaryButton(props) {
  return (
    <Button
      onClick={() => props.onClick()}
      isLoading = {props.loading}
      rounded={"full"}
      width={props.width? props.width:"full"}
      color={"#54595E"}
      background={"#F3F3F4"}
      disabled={props.disabled}
      className="secondaryButton"
    >
      <div className="flex gap-1 items-center">
        {props.preIcon && <Icon as={props.preIcon}/>}
        <span className="font-semibold text-base">{props.title}</span>
        {props.postIcon && <Icon fontSize="sm" as={props.postIcon}/>}
      </div>
    </Button>
  );
}

export default TertiaryButton;
