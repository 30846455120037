import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
  value: "",
  loading: false,
};


export const fetchKolosaveParticipants = createAsyncThunk("kolosave/kolosaveParticipants", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/kolosave-participants",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
            ContentType: "application/json",
        }
      })
      return response.data.data
    
    } catch (error) {
      if(error.response.status === 401){
        alert("Your session has expired, click ok to logout")
        setTimeout(()=>{
          localStorage.removeItem("_expiredTime")
          sessionStorage.removeItem('minieToken');
          sessionStorage.removeItem('userData');
          window.location.href = '/login/';
        },3000)
      }
    }
  });
  
  export const fetchKolosaveParticipant = createSlice({
    name: "participants",
    initialState,
    extraReducers: {
      [fetchKolosaveParticipants.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchKolosaveParticipants.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchKolosaveParticipants.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchKolosaveParticipant.reducer;