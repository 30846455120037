import React , { useState } from 'react';
import {
  IconButton,
  Button,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/react';
import axios from 'axios';
import Swal from 'sweetalert2';

function InviteParent(props) {
  const userType = window.location.pathname.split('/')[2];
  const [coparentName,setCoparentName] = useState("")
  const [coparentEmail,setCoparentEmail] = useState("")
  const [error,setError] = useState("")
  const [loading,setLoading] = useState(false)
  const userData = JSON.parse(sessionStorage.getItem("userData"))
  

  const inviteParent = () =>{
    if(coparentName === '' || coparentEmail === ''){
        setError("You need to enter your coparent details")
        return
    }
    setLoading(true)
    const coparent = {
      firstName:coparentName,
      email:coparentEmail
    }
    axios({
      method:"POST",
      url: process.env.REACT_APP_API_URL+"/adult-invite-coparent-auth",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json",
        Accept:"*/*"
      },
      data:{
        coparent:coparent,
        parentName:userData.firstName,
        youngsterId:props.youngsterId
      }
    }).then(()=>{
      setLoading(false)
      setLoading(false)
      Swal.fire({
        icon:"success",
        title:"Invite sent successfully!",
        text:"An invite has been sent to your co-parent to join MinieMoney",
        confirmButtonColor:"#2b5fd0"
      }).then((result) =>{
        if(result.isConfirmed){
          props.nextStep(4)
        }
      })
    }).catch((error)=>{
      console.log(error)
      Swal.fire({
        icon:"error",
        title:"Invite not sent!",
        text:"We could not send an invite to your parents. Try again later",
        confirmButtonColor:"#2b5fd0"
      })
      setLoading(false)
    })
  }





  return (
    <div className="hiddenForm" id={props.id}>
      <div className="mb-5 flex justify-between">
        <IconButton
          onClick={() => props.nextStep(3)}
          className="backIconButtonStyle"
          rounded={'full'}
          icon={<ArrowBackIcon />}
        />

        <Button
        //   isDisabled={disabled}
          loadingText="Creating account"
          className={
            props.isSkipLoading ? 'w-1/3 text-blue-600' : 'w-1/5 text-blue-600'
          }
          onClick={() => props.nextStep(4)}
          rounded={'full'}
          isLoading={props.isSkipLoading}
        >
          Skip
        </Button>
      </div>

      <h2 className="mt-7 text-2xl font-bold">Invite your {userType==='adult'?'co-parent':'parents'}</h2>
      <p>
        You can invite your {userType==='adult'?'co-parent':'parents'} to have access to your {userType==='adult'?'youngster\'s':''} MinieMoney account
      </p>


      <h3 className="parentTitle">Co-parent</h3>
      <div className="flex">
      <div className="mr-5">
      <label>Full Name</label>
      <Input onChange={(e)=>setCoparentName(e.target.value)} placeholder="First Name"/>
      </div>

      <div>
        <label>Email address</label>
        <Input onChange={(e)=>setCoparentEmail(e.target.value)} placeholder="Email address"/>
      </div>
      </div>

      <p className="text-red-500">{error}</p>

      <div className="my-6">
        <Button
         onClick={inviteParent}
          rounded={'full'}
          background={"#005FEB"}
          color={'white'}
          size="lg"
          isLoading={loading}
          width={'100%'}
          id="nextButton"
          p={9}
          _hover={{background:"#0066f5"}}
        >
          Next <ArrowForwardIcon className="ml-3" />
        </Button>
      </div>
    </div>
  );
}

export default InviteParent;
