import React, { Fragment } from 'react';
import back from '../../Icons/backButton.svg';
import brek from '../../Image/brek.png';
import { Button, Input } from '@chakra-ui/react';
import Modal from '../Modal/Modal';
import success from '../../Icons/successIcon.svg';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import closeButtonGreen from '../../Icons/closeButtonGreen.svg';
import moment from 'moment';

function KoloSaveStatement(props) {
  window.scrollTo(0, 0);
  const Back = () => {
    props.Back();
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const [showModal2, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [email, setEmail] = React.useState('');
  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Title', key: 'title' },
    { label: 'Description', key: 'description' },
    { label: 'Amount (₦)', key: 'amount' },
  ];

  const showSaving = () => {
    document.getElementById('kolosave').style.display = 'block';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('instantsave').style.display = 'none';
    document.getElementById('koloSaveStatement').style.display = 'none';
    closeModal();
  };

  const generateStatement = () => {
    setLoading(true);
    const kolosaveTransaction = props.transactions.filter(
      transaction => transaction.type === 'kolosave'
    );
    const newArr = kolosaveTransaction.map(transaction => ({
      date: moment(transaction.date).format('LLL'),
      title: transaction.title,
      amount:transaction.amount,
      description: transaction.message,
    }));
    setData(newArr);

    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/kolosave-statement',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        Accept: '*/*',
        ContentType: 'application/json',
      },
      data: {
        email: email === '' ? props.user.email : email,
        name: props.user.firstName,
      },
    })
      .then(response => {
        document.getElementById('csvDownloader').click();
        setLoading(false);
        setShowModal(true);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <div id="koloSaveStatement" className="dashboardCenter hiddenForm">
        <div onClick={Back} className="cursor-pointer pb-8">
          <img src={back} alt="" />

          <h2 className="walletScreenTitle text-sm sm:text-xl text-center">
            Generate KoloSave Statement
          </h2>
        </div>
        <div className="my-3 flex rounded-lg py-4 px-4 gap-2 items-center bg-[#F0F5EA] flex-row ">
          <div>
            <img src={brek} className="max-w-[100%]" alt="break kolo" />
          </div>
          <p className="text-sm font-normal">
            You will get your savings history when you generate your KoloSave
            Statement
          </p>
        </div>
        <div className="my-5">
          <label>Email Address</label>
          <Input
            type="email"
            size="md"
            focusBorderColor="#669F2A"
            color={'#AEB2B7'}
            onChange={e => setEmail(e.target.value)}
            placeholder={props.user.email}
          />
        </div>

        <CSVLink
          hidden
          id="csvDownloader"
          filename={
            props.user.firstName +
            ' ' +
            props.user.lastName +
            '_kolosaveStatement.csv'
          }
          data={data}
          headers={headers}
        >
          Download me
        </CSVLink>

        <div className="mt-6">
          <Button
            onClick={generateStatement}
            padding={'28px 32px'}
            width={'100%'}
            rounded={'full'}
            colorScheme={'#669F2A '}
            background={'#669F2A '}
            isLoading={loading}
          >
            Generate
          </Button>
        </div>
      </div>
      <Modal
        closeButtonGreen={closeButtonGreen}
        isVisible={showModal2}
        onClose={() => closeModal()}
      >
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center mt-3">
            KoloSave Statement Generated
          </h5>
          <p className="text-base font-normal max-w-[456px] text-center my-4">
            You have successfully generated your KoloSave statement, kindly
            check your email to view it.
          </p>
          <button
            onClick={showSaving}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2"
          >
            <span className="font-semibold text-base">Done</span>
          </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default KoloSaveStatement;
