import React from 'react';
import './Kyc.css';
import KYCHeader from '../../../components/KYC/KYCHeader';
import { Select, Input, Button, Progress } from '@chakra-ui/react';
import FileUpload from '../../../Icons/document-upload.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchUser } from '../../../store/userLogin';
import { useDispatch } from 'react-redux';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';
import { storage } from '../../../firebase';
import {DateInput} from "../../../components/Misc/Forms/DateInput"
import { isValidDate } from '../../../components/Misc/Forms/FormChecks/checkDate';

function Id(props) {
  const [id, setId] = React.useState('');
  const [idNumber, setIdNumber] = React.useState('');
  const [issue, setIssue] = React.useState('');
  const [expiry, setExpiry] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [image, setImage] = React.useState('');
  const [value, setValue] = React.useState(20);
  const [imageName, setImageName] = React.useState('');
  const [btnloading, setBtnLoading] = React.useState(false);
  const dispatch = useDispatch();
  const accountType = window.location.pathname.split('/')[2];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(()=>{
    console.log(isValidDate(issue))
    if(idNumber !== '' && isValidDate(issue) && image !== ''){
      setDisabled(false)
    }
    else{
      setDisabled(true)
    }
  },[idNumber,issue,image])

  const goToOVerview = () => {
    const overview = document.getElementById('overview');
    const bvn = document.getElementById('id');
    overview.style.display = 'block';
    bvn.style.display = 'none';
  };

  const uploadId = () => {
    setBtnLoading(true);
    setValue(100);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_PAYMENT_URL+'/smile-verify-kyc',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        firstName:props.user.firstName,
        lastName:props.user.lastName,
        idType: id,
        idNumber: idNumber,
        dob:props.user.dob ? props.user.dob:"",
        gender:props.user.gender ? props.user.gender :"",
        phone:props.user.phone ? props.user.phone:"",
        accountType:accountType,
        dateOfIssue: issue,
        dateOfExpiry: expiry,
        file: image,
      },
    })
      .then(response => {
        console.log(response)
        setBtnLoading(false);
        dispatch(fetchUser('adult'));
        Swal.fire({
          icon: 'success',
          title: 'Means of Id Uploaded',
          text: "We've received your uploaded documents. Tap the button below to view the status of your KYC",
          confirmButtonColor: '#005FEB',
          confirmButtonText: 'Done',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch(() => {
        setBtnLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Means of Id Not Uploaded',
          text: 'Uploaded file might be too large',
          confirmButtonColor: '#005FEB',
          confirmButtonText: 'Try again',
        });
      });
  };

  const imageModal = () => {
    let input = document.getElementById('imageInput');
    input.click();
  };

  function uploadImage(event) {
    setImageName(event.target.value);
    uploadFile(event.target.files[0]);
    var input = event.target;
    if (input.files) {
      var reader = new FileReader();
      reader.onload = e => {
        setImage(e.target.result);
      };
      //   setUploadImage(event.target.files);
      reader.readAsDataURL(input.files[0]);
      setImage(image);
    }
  }

  const uploadFile = image => {
    const id = toast.loading('Uploading Image. Please wait...');
    // Create the file metadata
    /** @type {any} */
    const storageRef = ref(
      storage,
      props.user.email === undefined
        ? props.user.phone + '_meansofid'
        : props.user.email + '_meansofid'
    );
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setValue(progress);
      },
      error => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          toast.update(id, {
            render: 'File uploaded successfully',
            type: 'success',
            isLoading: false,
            autoClose:3000
            
          });
          setImage(downloadURL);
          setValue(100);
        });
      }
    );
  };

  return (
    <div
      id={props.id}
      className="dashboardCenter hiddenForm walletScreensContainer"
    >
      <KYCHeader goBack={goToOVerview} title="Means of Id" />

      <div className="my-6">
        <label>Means of ID</label>
        <Select
          onChange={e => setId(e.target.value)}
          placeholder="Select your means of ID"
          size={"lg"}
          fontSize={"16px"}
        >
          {/* <option value="PASSPORT">International Passport</option> */}
          <option value="DRIVERS_LICENSE">Driver's License</option>
          <option value="VOTER_ID">Permanent Voter's Card</option>
          <option value="NIN">NIN card</option>
        </Select>

        {id !== '' && (
          <div>
            {image === '' && (
              <div className="fileUploadContainer">
                <div>
                  <img className="mx-auto" src={FileUpload} alt="file" />
                  <p className="my-3 text-center">
                    <span
                      onClick={imageModal}
                      className="cursor-pointer font-medium brandcolor"
                    >
                      Upload a file
                    </span>{' '}
                    (max filesize: 5MB)
                  </p>
                  <p className="fileType text-center">
                    PNG, JPG, GIF up to 5MB
                  </p>
                </div>
              </div>
            )}

            {image && (
              <div className="my-3">
                {imageName.split('\\')[2]}

                <Progress rounded={'full'} value={value} />
              </div>
            )}

            <input
              onChange={uploadImage}
              id="imageInput"
              type="file"
              hidden
              accept="image/*"
            />

            <div className="space-y-4 w-full">
              <div>
                <label>ID Number</label>
                <Input
                  placeholder={'Enter your ID Number'}
                  onChange={e => setIdNumber(e.target.value)}
                />
                {id === 'VOTER_ID' && <span className="helperText">Enter your 19-digit Voter's Identification Number</span>}
              </div>

              <div>
                <label>Date of Issue</label>
                <DateInput setDate={e => setIssue(e)} date={issue}/>
              </div>

              {id !== 'VOTER_ID' && (
                <div>
                  <label>Date of Expiry</label>
                  <DateInput setDate={e => setExpiry(e)} date={expiry}/>
                </div>
              )}

              <Button
                isLoading={btnloading}
                isDisabled={disabled}
                className="confirmButton"
                onClick={uploadId}
                colorScheme={'#0066f5'}
                background={'#0066f5'}
                rounded={'full'}
                width={'full'}
              >
                Upload
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Id;
