import React from 'react';

function Instructions() {
  return (
    <div className="instructionContainer">
      
        <p>By entering your BVN, we will only have access to your: </p>
        <ul>
         <li> Full Name </li>
         <li> Date of Birth </li>
        <li> Phone Number </li>
        </ul>
        <p className="my-2"><b>NOTE:</b> Your BVN does not give us access to your bank accounts, account balance or transactions</p>
        
        <p className="my-5">To get your BVN, dial <span className="brandcolor font-bold">*565*0#</span> on your registered phone number.</p>
    </div>
  );
}

export default Instructions;
