import React, { Fragment } from 'react';
import EditProfileHeader from '../EditProfile/EditProfileHeader';
import { useNavigate } from 'react-router';
import KahootInfo from './KahootInfo';

function Kahoot(props) {
  const history = useNavigate();
  const goBack = () => {
    if(document.getElementById("kahoot").style.display !=="none"){
        document.getElementById("kahoot").style.display = "none"
        document.getElementById("kahootInfo").style.display = "block"
    } else{
        history(-1);
    }
  };

  return (
    <Fragment>
      <div className="dashboardCenter" id={props.id}>
        <EditProfileHeader goBack={goBack} title={props.title} />
        <div className="my-4">
          <KahootInfo quiz={props.quiz} id={"kahootInfo"}/>
          <iframe
            id="kahoot"
            className="hiddenForm"
            src={props.quiz.link}
            title="Embedded Link"
            width="100%"
            height="700px"
          ></iframe>
        </div>
      </div>
    </Fragment>
  );
}

export default Kahoot;
