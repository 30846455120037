import React, { useState } from 'react';
import Logo from '../../../Image/Logo.png';
import { useNavigate } from 'react-router';
import './Login.css';
import AnimatedPage from '../../Animation/AnimatedPage';
import { Input, Button, FormControl } from '@chakra-ui/react';
import axios from 'axios';
import MinieTagForm from '../../../components/Misc/Forms/MinieTagForm';
import PasswordInput from '../../../components/Misc/Forms/PasswordInput';
import { checkPassword } from '../../../components/Misc/Forms/FormChecks/CheckPassword';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../store/userLogin';

function YoungsterSignup() {
  document.title = 'Invite | MinieMoney';
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [minietagError, setMinietagError] = useState(true);
  const [password, setPassword] = useState('');
  const [disabled,setDisabled] = useState(true)
  const [id,setId] = useState("")
  const dispatch = useDispatch()

  React.useEffect(() => {
    let id = new URLSearchParams(window.location.search).get('id');
    setId(id)
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/fetch-youngster?id=' + id,
      headers: {
        ContentType: 'application/json',
        Accept: '*/*',
      },
    }).then(response => {
      console.log(response);
      setFirstName(response.data.data.firstName);
      setLastName(response.data.data.lastName);
      setEmail(response.data.data.email);
    });
  }, [])

  React.useEffect(()=>{
    if(!minietagError &&
    checkPassword(password)){
      setDisabled(false)
    }
    else{
      setDisabled(true)
    }
  },[minietagError,password])

  const createAccount = () => {
    setLoading(true)
    setDisabled(true)
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/complete-signup",
      data:{
        id:id,
        minietag:username,
        password:password
      }
    }).then((response)=>{
      sessionStorage.setItem('minieToken', response.data.data.data.token);
      dispatch(fetchUser('youngster')).then(() => {
        setLoading(false)
        setDisabled(false)
        window.location.href = '/dashboard/youngster';
      });
    }).catch((error)=>{
      setLoading(false)
      setDisabled(false)
      Swal.fire({
        icon:"error",
        title:"Something's wrong 😢",
        text:error.response.data.data.message,
        confirmButtonColor:"#0066f5"
      })
    })
  };

  return (
    <div className="bgContainer">
      <AnimatedPage>
        <div>
          <div className="flex justify-center my-4">
            <img
              className="cursor-pointer appLogo"
              onClick={() => history('/')}
              src={Logo}
              alt="miniemoneylogo"
            />
          </div>

          <div className="formWithImage">
            <div className="formCard">
              <h3 className="mt-3 inviteTitle">Onboard to Get Started</h3>
              <span>
                Your name and email have been submitted by your parent, you're
                to choose your MinieTAG and set your Password.
              </span>
              <FormControl className="inviteForm">
                <div>
                  <label>First Name</label>
                  <Input
                    size="lg"
                    placeholder="First Name"
                    readOnly={true}
                    defaultValue={firstName}
                    backgroundColor={'#f9fafb'}
                  />
                </div>

                <div className="mt-3">
                  <label>Last Name</label>
                  <Input
                    size="lg"
                    placeholder="Last Name"
                    readOnly={true}
                    defaultValue={lastName}
                    backgroundColor={'#f9fafb'}
                  />
                </div>

                <div className="mt-3">
                  <label>Email</label>
                  <Input
                    size="lg"
                    placeholder="Email"
                    readOnly={true}
                    defaultValue={email}
                    backgroundColor={'#f9fafb'}
                  />
                </div>

                <div className="mt-3">
                  <MinieTagForm
                    setUsername={e => setUsername(e)}
                    setMinietagError={e => setMinietagError(e)}
                  />
                </div>

                <div className="my-4">
                  <PasswordInput
                    label={'Password'}
                    setPassword={setPassword}
                    placeholder={'Enter your password'}
                    password={password}
                  />
                </div>

                <div className="my-5">
                  <Button
                    rounded={'full'}
                    size="lg"
                    onClick={e => createAccount(e)}
                    textColor={'white'}
                    backgroundColor="#0073E5"
                    colorScheme={'#0073E5'}
                    width={'100%'}
                    className="loginButton"
                    isLoading={loading}
                    disabled={disabled}
                  >
                    Get Started
                  </Button>
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      </AnimatedPage>

      <div>
        <ul className="conditionsList">
          <li>Contact</li>
          <li className="conditions">Terms and Conditions</li>
          <li className="conditions">Privacy Policy</li>
        </ul>
      </div>

      <div className="copyrightText text-center my-3">
        <p>&copy; 2022 MinieMoney</p>
      </div>
    </div>
  );
}

export default YoungsterSignup;
