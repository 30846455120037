import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import Paystack from './Paystack'

function ConfirmationPage(props) {
  const goToPayment = () =>{
    document.getElementById("firstpage").style.visibility='visible'
    document.getElementById("firstpage").style.display='block'
    document.getElementById("secondpage").style.visibility='hidden'
    document.getElementById("secondpage").style.display='none'
  }

  const handlePayment = (reference) =>{
    props.handlePayment(reference)
  }
  return (
    <div id={props.id} className="hiddenForm confirmPageContainer">
      <button onClick={()=>goToPayment()} className="text-blue-600 flex"><BsArrowLeft className="m-1"/> Change amount</button>
        <div className="flex justify-center confirmContainer">
        <div className="flex justify-center">
    <img className="w-20 h-20 rounded-full" src={props.user.profileImage} alt="" />
    <div className="ml-4 mt-2">
        <h2 className="text-xl font-bold">{props.user.firstName} {props.user.lastName}</h2>
        <span>{props.minietag}</span>
    </div>
    </div>
        </div>
        <hr className="mx-auto my-5"/>

        <div className="flex justify-between">
          <div>
          <span>Amount</span>
          <p className="font-bold text-xl">₦ {props.paymentData.amount}</p>
          </div>

          <div>
          <span>Reason</span>
          <p className="font-bold text-xl">{props.paymentData.reason}</p>
          </div>
        </div>

        <div className="my-9">
          <Paystack  user={props.user} handlePayment={(reference)=>handlePayment(reference)} paymentData={props.paymentData} /> 
        </div>
    </div>
  )
}

export default ConfirmationPage