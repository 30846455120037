import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyB_w8t7LiwcqQ5OssTRMYpOtYYor0NMPVQ",
  authDomain: "miniemoneyappstorage.firebaseapp.com",
  projectId: "miniemoneyappstorage",
  storageBucket: "miniemoneyappstorage.appspot.com",
  messagingSenderId: "783792664073",
  appId: "1:783792664073:web:3ccb55c1b672982c1a52d8",
  measurementId: "G-RKGQ3KBQC1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
