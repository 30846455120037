export function numberth(number) {
  if (number.toString().endsWith('1')) {
    return 'st';
  }
  if (number.toString().endsWith('2')) {
    return 'nd';
  }

  if (number.toString().endsWith('3')) {
    return 'rd';
  } else {
    return 'th';
  }
}
