import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  await OneSignal.init({
    appId: '5c31cdab-469b-48fd-8a40-46cc97948ef0',
    allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.showSlidedownPrompt()
  if (OneSignal.isPushNotificationsEnabled) {
    await OneSignal.getUserId(function (userId) {
      console.log('player_id of the subscribed user is : ' + userId);
      localStorage.setItem('oneSignalPlayerId', userId);
    });
  }
}
