import React, { useEffect } from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import "./Notifications.css"
import NotificationsCard from '../../../components/Notifications/Notifications';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../store/userLogin'

function Notifications() {
  document.title = "Notifications | MinieMoney"
  const user = useSelector((state) => state.user.value)
  const accountType = window.location.pathname.split("/")[2]
  const dispatch = useDispatch()

  useEffect(()=>{
    window.scrollTo(0,0)
    dispatch(fetchUser(accountType))
  },[dispatch,accountType])




  return (
    <div>
      <div className="dashboardPage">
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
        <NotificationsCard user={user} accountType={accountType}/>
        </div>

        <div className="hideOnMobile">
        <DashboardRight accountType={accountType}/>
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default Notifications