import {
  ArrowForwardIcon
} from '@chakra-ui/icons';
import { Switch } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal/Modal';
import ConfirmModal from './Settings/confirmModal';
import OtpModal from './Settings/otpModal';

function Setting(props) {
  const history = useNavigate();
  const [otp, setOtp] = useState('');
  const [sessionId, setSessionId] = React.useState('');
  const [notifications, setNotifications] = React.useState(true);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [btnTitle,setBtnTitle] = useState("")

  const logOut = () => {
    Swal.fire({
      icon: 'question',
      title: 'Sure you want to logout?',
      text: "Done spending money today and you'd like to move on to other stuff?",
      confirmButtonText: 'Yes, log me out',
      confirmButtonColor: '#0d60d8',
      showCancelButton: true,
      cancelButtonColor: 'black',
      cancelButtonText: 'No, let me stay',
    }).then(result => {
      if (result.isConfirmed) {
        toast.loading("Logging out, please wait...")
        axios({
          method: 'GET',
          url: process.env.REACT_APP_API_URL+'/logout',
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
            ContentType: 'application/json',
          },
        })
          .then(() => {
            sessionStorage.removeItem('minieToken');
            sessionStorage.removeItem('userData');
            history('/login/');
            toast.dismiss();
          })
          .catch(error => {
            toast.dismiss();
            sessionStorage.removeItem('minieToken');
            sessionStorage.removeItem('userData');
            history('/login/');
          });
      } else {
        return;
      }
    });
  };

  const sendOtp = () => {
    setLoading(true);
    if (props.user.email !== '') {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL + '/verify-email-auth',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
          ContentType: 'application/json',
        },
        data: {
          email: props.user.email,
        },
      })
        .then(response => {
          if (response.status === 200) {
            toast.success('Otp sent Successfully')
            setSessionId(response.data.data.sessionId);
            setLoading(false);
            showOtpModal();
          }
        })
        .catch(error => {
          toast.error("Otp not sent. Kindly try again")
          setLoading(false);
          setTimeout(() => toast.dismiss(), 3000);
          Swal.fire({
            icon: 'error',
            title: 'Email invalid',
            text: error.message,
            confirmButtonColor: '#0073E5',
          });
        });
    } else {
      toast.error("Otp not sent. Kindly try again")
      setTimeout(() => toast.dismiss(), 3000);
      Swal.fire({
        icon: 'error',
        title: 'Email Not Found',
        text: 'Please enter your email address',
        confirmButtonColor: '#0073E5',
      });
    }
  };

  const showOtpModal = () => {
    document.getElementById("otp-modal").style.display = "block"
    document.getElementById("confirm-modal").style.display = "none"
  };

  const resendOtp = () => {
    const id = toast.loading("Resending otp...")
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/resend-otp-auth',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        email:props.user.email,
        sessionId: sessionId,
      },
    })
      .then(response => {
        toast.update(id, {
          render: 'Otp resent successfully',
          type: 'success',
          isLoading: false,
          autoClose:3000
        });
        setSessionId(response.data.data.sessionId);
      })
      .catch(error => {
        toast.update(id, {
          render: 'Otp not sent. Kindly try again',
          type: 'error',
          isLoading: false,
          autoClose:3000
        });
  
        Swal.fire({
          icon: 'error',
          title: 'Invalid session',
          text: error,
          confirmButtonColor:"#0066f5"
        });
      });
  };

  const verifyOtp = () => {
    if (otp !== '') {
      setLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL + '/check-otp',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          otp: otp,
          sessionId: sessionId,
        },
      })
        .then(response => {
          setLoading(false);
          toast.success(response.data.data);
          //take me to the page to set password or pin
           props.setting.type === "change-pin" ? 
           history("/account/"+props.accountType+"/change-pin")
           : 
           history("/account/"+props.accountType+"/change-password")
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Wrong OTP',
            text: 'The OTP you entered is wrong/invalid',
            confirmButtonColor: '#0073E5',
          })
        });
    } else {
      setError('Please enter your OTP');
    }
  };

  const goToLink = e => {
    if (props.setting.type === 'change-password') {
      setShowModal(true);
      setTitle('Change Password');
      setDescription('Are you sure you want to change your password?');
      setBtnTitle("Yes, change password")
      return;
    }

    if (props.setting.type === 'change-pin') {
      // sendOtp();
      setShowModal(true);
      setTitle('Change Transaction Pin');
      setDescription('Are you sure you want to change your transaction PIN?');
      setBtnTitle("Yes, change pin")
      return;
    }

    if (props.setting.type === 'contact-us') {
      window.open(e, '_blank');
      return;
    }

    if (props.setting.link === 'logout') {
      logOut();
      return;
    } else {
      if (
        props.setting.title === 'Read Our Blog' ||
        props.setting.title === 'Join our community' ||
        props.setting.title === 'Visit our website' ||
        props.setting.title === 'Privacy Policy' ||
        props.setting.title === 'Terms of Use'
      ) {
        window.open(e, '_blank');
      } else {
        history(e);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="cursor-pointer transactionList flex py-5 justify-between">
      <div onClick={() => goToLink(props.setting.link)} className="flex">
        <img alt={props.setting.title} size="32px" src={props.setting.image} />
        <div className="ml-3">
          <h2 className="mt-1 font-medium">{props.setting.title}</h2>
        </div>
      </div>
      <div onClick={() => goToLink(props.setting.link)}>
        {props.setting.title !== 'Allow Notifications' && <ArrowForwardIcon />}
        {props.setting.title === 'Allow Notifications' && (
          <Switch
            isChecked={notifications}
            onChange={() => setNotifications(!notifications)}
          />
        )}
      </div>

      <Modal isVisible={showModal} onClose={() => closeModal()}>
        <ConfirmModal
          id={'confirm-modal'}
          loading={loading}
          title={title}
          description={description}
          btnTitle={btnTitle}
          sendOtp={sendOtp}
          closeModal={closeModal}
        />
        <OtpModal
          id={'otp-modal'}
          error={error}
          verifyOtp={verifyOtp}
          otp={otp}
          setOtp={setOtp}
          closeModal={closeModal}
          resendOtp={resendOtp}
          email={props.user.email}
          loading={loading}
        />
      </Modal>
    </div>
  );
}

export default Setting;
