import React,{useEffect} from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import ParentCenter from '../../../components/Parent/Parent';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import "./Parent.css"
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux'
import { fetchParents } from '../../../store/fetchParents'

function Dashboard() {
  const dispatch = useDispatch()
  const history = useNavigate()
  const user = useSelector((state) => state.user.value)
  const parents = useSelector((state) => state.parent.value)



  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      };
    }, 1000);
  }, []);

  
  useEffect(()=>{
    dispatch(fetchParents())
  },[dispatch])


  

  React.useEffect(()=>{
   window.scrollTo(0,0)
   if(sessionStorage.getItem("minieToken")==null || sessionStorage.getItem("userData") == null ){
    history("/login")
   }
  
  },[history])

  return (
    <div>
      <div className="dashboardPage">
        
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
         
        {user && <ParentCenter user={user} parents={parents} />}
        </div>

        <div className="hideOnMobile">
        <DashboardRight />
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default Dashboard