import React from 'react';
import PrimaryButton from '../../Misc/Buttons/PrimaryButton';
import SecondaryButton from '../../Misc/Buttons/SecondaryButton';

function ConfirmModal(props) {
  return (
    <div id={props.id} className="p-4 flex justify-between flex-col items-center">
      <h1 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
        {props.title}
      </h1>
      <p className="text-base font-normal max-w-[456px] text-center my-5 sm:mb-4">
        {props.description}
      </p>

      <PrimaryButton loading={props.loading} onClick={() => props.sendOtp()} title={props.btnTitle} />
      <br />
      <SecondaryButton onClick={() => props.closeModal()} title={'No, cancel'} />
    </div>
  );
}

export default ConfirmModal;
