import React, { Fragment, useState } from 'react';
import TransactionPin from '../Misc/TransactionPin';
import EditProfileHeader from '../../components/EditProfile/EditProfileHeader';
import { Center } from '@chakra-ui/react';
import Modal from '../Modal/Modal';
import success from '../../Image/Success.png';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';

function ConfirmPin(props) {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);
  const { confirmPin, setConfirmPin, pin } = props;
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const accountType = window.location.pathname.split('/')[2];
  const apiUrl = props.user.hasPin ? '/change-pin':'/create-pin'

  const enterPin = e => {
    if (confirmPin.length === 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setConfirmPin(e);
  };

  const setTransactionPin = () => {
    if (pin !== confirmPin) {
      setError('The Transaction Pin and Confirm Pin are not the same');
      return;
    }
    setError('');
    setLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + apiUrl,
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json",
        Accept:"*/*"
      },
      data: {
        pin: pin,
        accountType: accountType,
      },
    }).then((response)=>{
      dispatch(fetchUser(accountType)).then(()=>{
        setLoading(false)
        setShowModal(true)
       })
    }).catch((error)=>{
      setLoading(false)
      Swal.fire({
        icon:"error",
        title:"Something went wrong 🤔",
        text:error.response.data.data.message,
        confirmButtonColor:"#0066f5"
      })
    })
  };
  const closeModal = () => {
    setShowModal(false);
    window.location.href ='/dashboard/'+accountType
  };

  const goBack = () => {
    document.getElementById('setup-pin').style.display = 'block';
    document.getElementById('confirm-pin').style.display = 'none';
  };

  return (
    <Fragment>
      <div className="dashboardCenter hiddenForm" id={props.id}>
        <EditProfileHeader goBack={goBack} title={props.title} />
        <p className="py-4 text-[#151618] font-semibold text-[16px]">
          Your 4-Digits transaction PIN helps keep your money safe. You will
          also use it to approve transactions and stop others from accessing
          your account.
        </p>
        <TransactionPin class={"auth"} setPin={e => enterPin(e)} />
        <div className="text-red-500 text-center">{error}</div>
        <Center className="my-3">
          <PrimaryButton
            loading={loading}
            disabled={disabled}
            onClick={setTransactionPin}
            title="Continue"
          />
        </Center>
      </div>
      <Modal isVisible={showModal} onClose={() => closeModal()}>
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h2 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            Transaction PIN Set
          </h2>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            You have successfully set your transaction PIN.
          </p>

          <button
            onClick={() => closeModal()}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
             bg-[#005FEB] text-[#E6EFFD] rounded-full mt-2 hover:border-[2px]"
          >
            <span className="font-semibold text-base">Done</span>
          </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ConfirmPin;
