import React, { useState, useEffect } from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import MinieWallet from '../../../components/MinieWallet/MinieWallet';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import './MinieWallet.css';
import { useSelector } from 'react-redux';
import Transfer from './Transfer';
import SendMoneyFamily from './SendMoneyFamily';
import SendMoneyUser from './SendMoneyUser';
import SendMoneyBank from './SendMoneyBank';
import FundWallet from './FundWallet';
import WalletHistory from './WalletHistory';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { fetchUser } from '../../../store/userLogin';
import { fetchBanks } from '../../../store/fetchBanks';
import { fetchUserBanks } from '../../../store/fetchUserBanks';
import { fetchParents } from '../../../store/fetchParents';
import TransactionSummary from './TransactionSummary';
import { fetchTransactions } from '../../../store/fetchTransactions';

function Dashboard() {
  document.title = 'Miniewallet | MinieMoney';
  const user = useSelector(state => state.user.value);
  const history = useNavigate();
  const [id, setId] = useState('');
  const accountType = window.location.pathname.split('/')[2];
  const dispatch = useDispatch();
  const userbanks = useSelector(state => state.userbanks.value);
  const parents = useSelector(state => state.parent.value);
  const transactions = useSelector(state => state.transactions.value)
  const transactionsLoading = useSelector(state => state.transactions.loading)

  useEffect(() => {
    dispatch(fetchUser(accountType));
    if (accountType === 'adult') {
      dispatch(fetchBanks());
    }
    if (
      user &&
      user.youngsters &&
      user.youngsters.length === 0 &&
      id === 'sendmoneyfamily' &&
      accountType === 'adult'
    ) {
      Swal.fire({
        icon: 'info',
        title: 'Kid Transfer not yet available',
        text: "You can't send money to kid yet because you haven't added a youngster to your account.",
        confirmButtonText: 'Add Youngster',
      }).then(result => {
        if (result.isConfirmed) {
          history('/create-youngster/adult');
        }
      });
    }
  }, [user, history, id, accountType, dispatch, userbanks]);

  useEffect(() => {
    if (accountType === 'adult') {
      dispatch(fetchUserBanks());
    }

    if (accountType === 'youngster') {
      dispatch(fetchParents());
    }
  }, [dispatch, accountType]);

  //logout user with invalid session
  React.useEffect(() => {
    if (
      sessionStorage.getItem('minieToken') == null ||
      sessionStorage.getItem('userData') == null
    ) {
      console.log(sessionStorage.getItem('minieToken'));
      history('/login/');
    }
  }, [history]);

  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      };
    }, 500);
  }, []);

  //fetch all transactions
  React.useEffect(()=>{
    dispatch((fetchTransactions(accountType)))
  },[dispatch,accountType])

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          {user && (
            <div>
              <MinieWallet
                accountType={accountType}
                id={'overview'}
                user={user}
                parents={parents}
                transactions={transactions} 
                transactionsLoading={transactionsLoading}
              />
              <Transfer
                accountType={accountType}
                setId={e => setId(e)}
                user={user}
                id={'transfer'}
                title={'Transfer'}
              />
              <WalletHistory id={'history'} user={user} transactions={transactions} transactionsLoading={transactionsLoading}/>
              <FundWallet user={user} id={'fundwallet'} title={'Fund Wallet'} />
              <SendMoneyFamily
                user={user}
                id={'sendmoneyfamily'}
                title={
                  window.innerWidth > 600
                    ? `Send Money to ${
                        user && user.accountType === 'adult'
                          ? "Kid's"
                          : "Parent's"
                      } Account`
                    : `Money to ${
                        user && user.accountType === 'adult' ? 'Kid' : 'Parent'
                      }`
                }
                accountType={accountType}
              />
              <SendMoneyUser
                accountType={accountType}
                user={user}
                id={'sendmoneyuser'}
                title={
                  window.innerWidth > 390
                    ? 'Send Money to User'
                    : 'Money to user'
                }
              />
              <SendMoneyBank
                user={user}
                accountType={accountType}
                userbanks={userbanks}
                id={'sendmoneybank'}
                title={
                  window.innerWidth > 390
                    ? 'Withdraw to Bank'
                    : 'Withdraw to Bank'
                }
              />
              <TransactionSummary id={'transactions-summary'} user={user} />
            </div>
          )}

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default Dashboard;
