import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router';
import { Center } from '@chakra-ui/react';
import EditProfileHeader from '../EditProfile/EditProfileHeader';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import PasswordInput from '../Misc/Forms/PasswordInput';
import Password from '../Misc/Forms/Password';
import axios from 'axios';
import Swal from 'sweetalert2';
import SwalError from '../Misc/SwalError';
import SuccessModal from '../Modal/SuccessModal';
import { checkPassword } from '../Misc/Forms/FormChecks/CheckPassword';

function SetPassword(props) {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const history = useNavigate();
  const goBack = () => {
    history(-1);
  };

  const changePassword = () => {
    setLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/change-password',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        oldPassword: oldPassword,
        password: password,
        accountType: props.accountType,
      },
    })
      .then(response => {
        console.log(response);
        setShowModal(true);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire(SwalError(error.response.data.data.message));
      });
  };

  const goToDashboard = () => {
    history('/dashboard/' + props.accountType);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(checkPassword(password));
    if (
      password.length >= 8 &&
      checkPassword(password) &&
      password === confirmPassword
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password, confirmPassword]);

  return (
    <Fragment>
      <div className="dashboardCenter" id={props.id}>
        <EditProfileHeader goBack={goBack} title={props.title} />
        <p className="py-4 text-[#151618] font-semibold text-[16px]">
          A strong password keeps your account safe and stops people you don't
          know from using it. This is what you will use to enter your MinieMoney
          account.
        </p>

        {/*old password component */}
        <Password
          label={'Old Password'}
          placeholder={'Enter your old password'}
          setPassword={setOldPassword}
          password={oldPassword}
        />

        <div className="my-4">
          {/* password component */}
          <PasswordInput
            label={'New Password'}
            setPassword={setPassword}
            placeholder={'Enter your new password'}
            password={password}
          />
        </div>

        <Password
          label={'Confirm Password'}
          placeholder={'Confirm your new password'}
          setPassword={setConfirmPassword}
          password={confirmPassword}
        />

        <Center className="my-5">
          <PrimaryButton
            disabled={disabled}
            loading={loading}
            title="Continue"
            onClick={changePassword}
          />
        </Center>
      </div>

      <SuccessModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        setShowModal={setShowModal}
        title={'Password Changed'}
        next={() => goToDashboard()}
        description={'You have successfully changed your password.'}
      />
    </Fragment>
  );
}

export default SetPassword;
