import React from 'react';
import './Forms.css';

function SelectChip(props) {
  const setValue = value => {
    props.setValue(value);
  };
  return (
    <div className={props.type}>
      {props.items.map((value, i) => (
        <div
          onClick={() => setValue(value)}
          className={
            props.value === value
              ? 'chip chipActive '
              : props.defaultValue === value
              ? 'chip chipSelected'
              : 'chip'
          }
          key={i}
        >
          {value}
        </div>
      ))}
    </div>
  );
}

export default SelectChip;
