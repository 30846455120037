import React, { Fragment, useState } from 'react';
import EditProfileHeader from './EditProfileHeader';
import tel from '../../Image/phone.svg';
import success from '../../Icons/successIcon.svg';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import Modal from '../Modal/Modal';
import TransactionPin from '../Misc/TransactionPin';
import TertiaryButton from '../Misc/Buttons/TertiaryButton';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import PhoneInput from '../Misc/Forms/PhoneInput';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDisclosure } from '@chakra-ui/react';
function ChangeNumber(props) {
  const [showModal, setShowmodal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [pin, setPin] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const { onClose } = useDisclosure();

  const [loading, setBtnLoading] = useState(false);
  //   const { onClose } = useDisclosure();

  const showChangeNumber = () => {
    setShowmodal(true);
    document.getElementById('change_number').style.display = 'block';
  };
  const closeModal = () => {
    setShowmodal(false);
  };
  const enterPin = e => {
    if (pin.length === 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setPin(e);
  };
  const editPhone = () => {
    document.getElementById('change_number').style.display = 'block';
    document.getElementById('firstModal').style.display = 'none';
    document.getElementById('thirdModal').style.display = 'none';
    document.getElementById('secondModal').style.display = 'block';
  };
  const updateNumber = () => {
    document.getElementById('firstModal').style.display = 'none';
    document.getElementById('secondModal').style.display = 'none';
    document.getElementById('thirdModal').style.display = 'none';
    document.getElementById('fourtModal').style.display = 'block';
    document.getElementById('change_number').style.display = 'block';
  };

  const Back = () => {
    props.Back();
  };
  const addPhoneNumber = () => {
    if (phone === '') {
      setError('Please enter your phone number');
      setDisabled(true);
    }
    setBtnLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/verify-phone',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
      },
      data: {
        phone: phone,
      },
    }).then(response => {
      setBtnLoading(false);
      if (response.status === 201) {
        onClose();
        Swal.fire({
          icon: 'info',
          title: 'Phone Number already used',
          text: 'This phone number is already being used by another account',
          confirmButtonColor: '#0166f4',
        });
        return;
      }
    });

    document.getElementById('change_number').style.display = 'block';
    document.getElementById('firstModal').style.display = 'none';
    document.getElementById('secondModal').style.display = 'none';
    document.getElementById('thirdModal').style.display = 'block';
  };
  const goback = () => {
    document.getElementById('firstModal').style.display = 'none';
    document.getElementById('secondModal').style.display = 'block';
    document.getElementById('thirdModal').style.display = 'none';
    document.getElementById('change_number').style.display = 'block';
  };
  const back2 = () => {
    document.getElementById('firstModal').style.display = 'none';
    document.getElementById('secondModal').style.display = 'none';
    document.getElementById('thirdModal').style.display = 'none';
    document.getElementById('change_number').style.display = 'block';
    closeModal();
  };
  return (
    <Fragment>
      <div id="change_number" className="dashboardCenter hiddenForm">
        <EditProfileHeader
          color={'walletAccountArrow'}
          title="Phone Number"
          goBack={Back}
        />
        <div className="flex items-center justify-center py-4 gap-6 flex-col">
          <img src={tel} alt="" />
          <p className="text-[14px] sm:text-[18px] font-bold text-[#151618]">
            +2348123456789
          </p>
          <p className="font-medium text-[14px]">
            We use your phone number for security and to confirm who you are.
          </p>
          <PrimaryButton
            title={'Change phone number'}
            onClick={() => showChangeNumber()}
          />
        </div>
      </div>
      <Modal isVisible={showModal} onClose={() => closeModal()}>
        <div id="firstModal">
          <h2 className="font-bold text-xl sm:text-2xl text-center mb-4">
            Enter Your TransactionPIN
          </h2>
          <TransactionPin setPin={e => enterPin(e)} />
          <div className="flex w-full items-center gap-3">
            <TertiaryButton
              preIcon={ArrowBackIcon}
              title={'Back'}
              disabled={disabled}
              onClick={back2}
            />

            <PrimaryButton
              postIcon={ArrowForwardIcon}
              title={'Continue'}
              disabled={disabled}
              onClick={editPhone}
            />
          </div>
        </div>
        <div className="p-4 hiddenForm" id="secondModal">
          <form>
            <h2 className="font-medium text-xl text-center mb-4">
              Change Phone Number
            </h2>
            <label>Kindly enter your new phone number</label>
            <div className="mt-4"> </div>
            <PhoneInput
              setPhone={e => setPhone(e)}
              setDisabled={e => setDisabled(e)}
            />

            <p className="text-red-500">{error}</p>
            <div className="mt-8"> </div>
            <PrimaryButton
              title={'Verify phone number'}
              disabled={disabled}
              onClick={() => addPhoneNumber()}
            />
          </form>
        </div>

        <div id="thirdModal" className="hiddenForm">
          <EditProfileHeader
            color={'walletAccountArrow'}
            title="Verification Code"
            goBack={goback}
          />

          <p className="px-6 py-4 text-center">
            Kindly enter the verification code sent to your phone number{' '}
            {/* {props.setting.type} */}
          </p>

          <TransactionPin setPin={e => enterPin(e)} />

          <div className="flex items-center gap-3 pb-6 justify-center">
            <p>Didn’t receive a code?</p>
            <p className="text-[#005FEB] p-2 bg-[#E6EFFD] text-[12px] rounded-2xl">
              Resend in 59s
            </p>
          </div>
          <div className="flex w-full items-center gap-3">
            <TertiaryButton
              preIcon={ArrowBackIcon}
              title={'Back'}
              disabled={disabled}
              onClick={goback}
            />

            <PrimaryButton
              postIcon={ArrowForwardIcon}
              title={'Confirm'}
              disabled={disabled}
              onClick={updateNumber}
              isLoading={loading}
            />
          </div>
        </div>
        <div id="fourtModal" className="hiddenForm">
          <div className="p-4 flex justify-between flex-col items-center">
            <img
              src={success}
              alt="error&Success"
              width="92px"
              height="92px"
              className="max-w-[100%]"
            />
            <h3 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
              Phone Number Updated
            </h3>
            <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
              You have successfully updated your phone number.
            </p>
            <PrimaryButton onClick={Back} title={'Done'}></PrimaryButton>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ChangeNumber;
