import { Button } from '@chakra-ui/react';
import React from 'react';
import "./Buttons.css"

function SecondaryButton(props) {
  return (
    <Button
      onClick={() => props.onClick()}
      isLoading = {props.loading}
      rounded={"full"}
      width={"full"}
      color={"#005FEB"}
      background={"#E6EFFD"}
      disabled={props.disabled}
      className="secondaryButton"
    >
      <div className="flex gap-1 items-center">
        <span className="font-semibold text-base">{props.title}</span>
      </div>
    </Button>
  );
}

export default SecondaryButton;
