import React, { useEffect } from 'react';
import './App.css';
import './layout/layout.css';
import { ChakraProvider, theme } from '@chakra-ui/react';
import IdleTimer from './utils/IdleTimer';
import { store } from './store/store';
import { Provider } from 'react-redux';
import CreateAccount from './pages/Adult/Auth/CreateAccount';
import AppPage from './pages/App/App';
import AppLogin from './pages/App/AppLogin';
import EmailSignup from './pages/Adult/Auth/EmailSignup';
// import Login from './pages/Adult/Auth/Login';
import Invite from './pages/Adult/Auth/Invite';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ForgotPassword from './pages/Adult/Auth/ForgotPassword';
import Dashboard from './pages/Adult/Dashboard/Dashboard';
import PhoneSignup from './pages/Adult/Auth/PhoneSignup';
import ForgotPasswordPhone from './pages/Adult/Auth/ForgotPasswordPhone';
import CreateYoungster from './pages/Adult/CreateYoungster/CreateYoungster';
import Notifications from './pages/Adult/Notifications/Notification';
import MinieWallet from './pages/Adult/MinieWallet/MinieWallet';
import MinieSavings from './pages/MinieSavings/MinieSavings';
import KoloSave from './pages/MinieSavings/Kolosave';
import EarniT from './pages/Adult/EarnIt/Earnit';
import CreateTask from './pages/Adult/CreateTask/CreateTask';
import Account from './pages/Adult/Account/Account';
import EditProfile from './pages/Adult/EditProfile/EditProfile';
import KYC from './pages/Adult/KYC/Kyc';
import Withdrawal from './pages/Adult/Withdrawal/Withdrawal';
import Youngster from './pages/Adult/Account/Youngster';
import YoungsterSingle from './pages/Adult/Account/YoungsterSingle';
import SendMoney from './pages/Adult/SendMoney/SendMoney';
import SetAllowance from './pages/Adult/SetAllowance/SetAllowance';
import AssignTask from './pages/Adult/AssignTask/AssignTask';
import FundWallet from './pages/Adult/FundWallet/FundWallet';
import MinieQuiz from './pages/Youngster/MinieQuiz/MinieQuiz';
import RequestMoney from './pages/Youngster/RequestMoney/RequestMoney';
import PaymentLink from './pages/PaymentLink/PaymentLink';
import Parent from './pages/Youngster/Parent/Parent';
import { toast, ToastContainer } from 'react-toastify';
import LoginPage from './pages/Adult/Auth/LoginPage';
import axios from 'axios';
import PassportPage from './pages/Adult/KYC/PassportPage';
import SetPin from './pages/SetPin/SetTransactionPin';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import ChangePin from './pages/ChangePin/ChangePin';
import YoungsterSignup from './pages/Adult/Auth/YoungsterSignup';
import AccountNumber from './pages/Youngster/SterlingBank/AccountNumber';
import CreateAccountNumber from './pages/Youngster/SterlingBank/CreateAccountNumber';
import Kahoot from './pages/Kahoot/Kahoot';

function App() {
  const accountType = window.location.pathname.split('/')[2];

  //hide chat widget at initial
  React.useEffect(() => {
    window.Tawk_API.onLoad = function () {
      window.Tawk_API.hideWidget();
    };
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      };
    }, 500);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('minieToken') !== null) {
      const timer = new IdleTimer({
        timeout: 300, //expire after 300 seconds
        onTimeout: () => {
          toast.warn('Session expired, Logging out...');
          setTimeout(() => {
            axios({
              method: 'GET',
              url: process.env.REACT_APP_API_URL + '/logout',
              headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
                ContentType: 'application/json',
                Accept: '*/*',
              },
            })
              .then(() => {
                localStorage.removeItem('_expiredTime');
                sessionStorage.removeItem('minieToken');
                sessionStorage.removeItem('userData');
                window.location.href = '/login/';
              })
              .catch(error => {
                localStorage.removeItem('_expiredTime');
                sessionStorage.removeItem('minieToken');
                sessionStorage.removeItem('userData');
                window.location.href = '/login/';
              });
          }, 3000);
        },
        onExpired: () => {
          // do something if expired on load
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [accountType]);

  return (
    <ChakraProvider theme={theme}>
      <ToastContainer />
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<CreateAccount />} />
            <Route path="/app" element={<AppPage />} />
            <Route path="/app/login" element={<AppLogin />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/email-signup/:id" element={<EmailSignup />} />
            <Route path="/login" element={<LoginPage />} />
            {/* <Route path="/login/:id" element={<Login />} /> */}
            <Route path="/invite" element={<Invite />} />
            <Route path="/youngster-signup" element={<YoungsterSignup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/forgot-password/phone"
              element={<ForgotPasswordPhone />}
            />
            <Route path="/forgot-password/adult" element={<ForgotPassword />} />
            <Route
              path="/forgot-password/youngster"
              element={<ForgotPassword />}
            />
            <Route
              path="/forgot-password/phone/adult"
              element={<ForgotPasswordPhone />}
            />
            <Route
              path="/forgot-password/phone/youngster"
              element={<ForgotPasswordPhone />}
            />
            <Route path="/phone-signup/:id" element={<PhoneSignup />} />
            <Route path="/dashboard/adult" element={<Dashboard />} />
            <Route path="/dashboard/youngster" element={<Dashboard />} />
            <Route
              path="/create-youngster/adult"
              element={<CreateYoungster />}
            />
            <Route path="/notifications/adult" element={<Notifications />} />
            <Route
              path="/notifications/youngster"
              element={<Notifications />}
            />

            <Route
              path="/kahoot/:id"
              element={<Kahoot />}
            />
            <Route path="/wallet/adult" element={<MinieWallet />} />
            <Route path="/wallet/youngster" element={<MinieWallet />} />
            <Route path="/fund-wallet/adult" element={<FundWallet />} />
            <Route path="/request-money/youngster" element={<RequestMoney />} />
            <Route path="/save/adult" element={<MinieSavings />} />
            <Route path="/save/youngster" element={<MinieSavings />} />
            <Route path="/kolosave/youngster" element={<KoloSave />} />
            <Route path="/earnit/adult" element={<EarniT />} />
            <Route path="/earnit/youngster" element={<EarniT />} />
            <Route path="/create-task/adult" element={<CreateTask />} />
            <Route path="/account/adult" element={<Account />} />
            <Route path="/account/youngster" element={<Account />} />
            <Route path="/youngsters/adult" element={<Youngster />} />
            <Route
              path="/account/adult/edit-profile"
              element={<EditProfile />}
            />
            <Route
              path="/account/youngster/edit-profile"
              element={<EditProfile />}
            />
            <Route path="/account/adult/kyc" element={<KYC />} />
            <Route path="/account/adult/passport" element={<PassportPage />} />
            <Route path="/account/adult/youngster" element={<Youngster />} />
            <Route path="/account/youngster/parents" element={<Parent />} />
            <Route
              path="/account/adult/youngster/:id"
              element={<YoungsterSingle />}
            />
            <Route path="/account/:id/setpin" element={<SetPin />} />
            <Route path="/account/:id/change-pin" element={<ChangePin />} />

            <Route
              path="/account/:id/change-password"
              element={<ChangePassword />}
            />
            <Route
              path="/account/adult/send-money/:id"
              element={<SendMoney />}
            />
            <Route
              path="/account/adult/set-allowance/:id"
              element={<SetAllowance />}
            />
            <Route
              path="/account/adult/assign-task/:id"
              element={<AssignTask />}
            />
            <Route path="/account/adult/withdrawal/" element={<Withdrawal />} />
            <Route path="/miniequiz/youngster" element={<MinieQuiz />} />
            <Route
              path="/wallet/youngster/account-number"
              element={<AccountNumber />}
            />
            <Route
              path="/wallet/youngster/create-account-number"
              element={<CreateAccountNumber />}
            />
            <Route path="/:id" element={<PaymentLink />} />
          </Routes>
        </Provider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
