import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  value: "",
  loading: false,
};


export const fetchAdultYoungsters = createAsyncThunk("user/fetchAdultYoungsters", async () => {
    try {
     if(window.location.pathname.split("/")[2] === 'adult'){
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/user/fetch-youngsters",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
            ContentType: "application/json",
        }
      })
     
      return response.data.data
    }
    } catch (error) {
      if(error.response.status === 401){
        setTimeout(()=>{
          localStorage.removeItem("_expiredTime")
          sessionStorage.removeItem('minieToken');
          sessionStorage.removeItem('userData');
          window.location.href = '/login/';
        },3000)
      }
    }
  });
  
  export const fetchAdultYoungster = createSlice({
    name: "youngsters",
    initialState,
    extraReducers: {
      [fetchAdultYoungsters.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchAdultYoungsters.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchAdultYoungsters.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchAdultYoungster.reducer;