import React from 'react'
import MinieMoneyLogo from "../../Icons/miniemoneylogo.svg"

function NavMenu() {

  const gotoCreateAccount = () =>{
    window.location.href="/create-account"
  }

  return (
    <div className="navMenu">
        <div onClick={()=>window.location.href="https://miniemoney.com"} className="mt-3"><img src={MinieMoneyLogo} alt=""/></div>
        <button className="freeAccount" onClick={()=>gotoCreateAccount()}>Create a free Account</button>
    </div>
  )
}

export default NavMenu