import React from 'react';
import EditProfileHeader from '../../components/EditProfile/EditProfileHeader';
import {
  Avatar,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { fetchUser } from '../../store/userLogin';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import YoungsterAvatarHeading from '../Account/YoungsterAvatarHeading';
import EnterTransactionPinModal from '../../components/Modal/EnterTransactionPinModal';
import AmountInput from '../Misc/Forms/AmountInput';
import { standardAmountFormat } from '../../utils/amountUtil';

function ConfirmDetails(props) {
  return (
    <div>
      <div className="confirmYoungsterDetails">
        <Avatar src={props.youngster.profileImage} alt="youngsterImg" />
        <div>
          <h2>
            {props.youngster.firstName} {props.youngster.lastName}
          </h2>
          <p>{props.youngster.email}</p>
        </div>
      </div>
      <hr />
      <div className="my-3 flex justify-between">
        <div>
          <p className="text-sm">Amount</p>
          <h2 className="font-bold">{standardAmountFormat(props.amount)}</h2>
        </div>
        <div>
          <p className="text-sm">Reason for Sending Money:</p>
          <h2 className="font-bold">{props.reason}</h2>
        </div>
      </div>
    </div>
  );
}

function SendMoney(props) {
  const [disabled, setDisabled] = React.useState(true);
  const [amount, setAmount] = React.useState('');
  const [reason, setReason] = React.useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const dispatch = useDispatch();
  const youngster = JSON.parse(sessionStorage.getItem('youngster'));
  const history = useNavigate();

  React.useEffect(() => {
    if (reason !== '' && amount > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [amount, reason]);

  const goBack = () => {
    history(-1);
  };

  const setReasonValue = e => {
    setReason(e.target.value.trim());
  };
  const setAmountValue = e => {
    setAmount(e);
  };

  const sendMoney = () => {
    if (youngster === null) {
      Swal.fire({
        icon: 'warning',
        title: 'Please select a youngster',
        text: 'You need to select a youngster',
      });
    } else {
      onOpen();
    }
  };

  const confirmTransaction = () => {
    onClose();
    setDisabled(true)
    setShowModal(true);
    // setIsPinOpen(true)
  };

  const sendMoneytoKid = () => {
    if (pin === '') {
      return;
    } else {
      setLoading(true);
      setDisabled(true)
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL + '/send-money-kid',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
          ContentType: 'application/json',
        },
        data: {
          childId: youngster._id,
          amount: amount,
          reason: reason,
          pin: pin,
          firstName: youngster.firstName,
          email: youngster.email,
          sendertag: props.user.minietag,
          relationship: youngster.relationship
            ? youngster.relationship
            : 'parent',
        },
      })
        .then(async () => {
          dispatch(fetchUser('adult')).then(() => {
            setLoading(false);
            setDisabled(false)
            setShowModal(false);
            Swal.fire({
              icon: 'success',
              title: 'Transfer initiated successfully',
              text:
                '₦ ' +
                amount +
                ' has been sent to your youngster: ' +
                youngster.firstName,
              confirmButtonColor: '#0066f5',
            }).then(result => {
              if (result.isConfirmed) {
                window.location.href = '/wallet/adult';
              }
            });
          });
        })
        .catch(error => {
          console.log(error);
          setShowModal(false);
          setLoading(false);
          setDisabled(false)
          if (
            error.response.data.data.message ===
            'Insufficient Balance. Kindly top-up your Miniewallet and try again'
          ) {
            Swal.fire({
              icon: 'warning',
              title: 'Insufficient Funds',
              text: error.response.data.data.message,
              confirmButtonText: 'Fund Wallet',
              confirmButtonColor: '#0066f5',
            }).then(result => {
              if (result.isConfirmed) {
                // setIsPinOpen(true)
                setShowModal(true);
                history('/fund-wallet/adult');
              }
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Something went wrong',
              text: error.response.data.data.message,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#0066f5',
            }).then(result => {
              if (result.isConfirmed) {
                // setIsPinOpen(true)
                setShowModal(true);
              }
            });
          }
          setLoading(false);
          setDisabled(false)
        });
    }
  };

  return (
    <div id={props.id} className="dashboardCenter walletScreensContainer">
      <EditProfileHeader
        goBack={goBack}
        title={'Send money to ' + youngster.firstName}
      />

      <div className="formContainer">
        <YoungsterAvatarHeading youngster={youngster} />
        <div>
          <label>How much are you sending?</label>
          <AmountInput
            setAmount={e => setAmountValue(e)}
            focusBorderColor={'#0066f5'}
            placeholder={'Enter amount'}
          />
        </div>

        <div className="my-3">
          <label>Why do you want to send this money?</label>
          <Input
            onChange={e => setReasonValue(e)}
            size="lg"
            focusBorderColor="#0066f5 "
            placeholder={'Enter reason for sending money'}
            fontSize={"15px"}
          />
        </div>

        <div className="mt-6">
          <Button
            onClick={sendMoney}
            padding={'28px 32px'}
            width={'100%'}
            disabled={disabled}
            rounded={'full'}
            colorScheme={'#0066f5 '}
            background={'#0066f5 '}
          >
            Continue
          </Button>
        </div>
      </div>

      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-medium text-xl">Confirm Transaction</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ConfirmDetails
              youngster={youngster}
              amount={amount}
              reason={reason}
            />
          </ModalBody>

          <ModalFooter
            margin={'0px 0px 20px 0px'}
            className="confirmDetailsFlexButtons"
          >
            <Button
              color={'#54595E'}
              rounded={'full'}
              background={'#F3F3F4'}
              colorScheme="#F3F3F4"
              onClick={onClose}
            >
              <ArrowBackIcon className="mr-3" /> Cancel
            </Button>
            <Button
              rounded={'full'}
              className="confirmButton"
              background={'#0066f5 '}
              colorScheme="#0066f5 "
              onClick={confirmTransaction}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <EnterTransactionPinModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        confirm={() => sendMoneytoKid()}
        loading={isLoading}
        setLoading={e => setLoading(e)}
        setPin={e => setPin(e)}
        disabled={disabled}
        setDisabled={e => setDisabled(e)}
        bg={'#7A58EF'}
        pinBg={'#F2EEFD'}
        color={'#7A58EF'}
        class={'transaction'}
      />
    </div>
  );
}

export default SendMoney;
