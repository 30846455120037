import React from 'react'
import {Tabs,TabList,Tab,TabPanels,TabPanel} from "@chakra-ui/react"
import EarnItHeader from "../../../components/CreateTask/EarnitHeader"
import EarnItTask from '../../../components/EarnIt/EarnItTask'


function Tasks(props) {

  const goToOverview = ()=>{
    const tasks = document.getElementById("tasks")
    const earnit = document.getElementById("earnitOverview")
    earnit.style.display = "block"
    tasks.style.display="none"
  }

  const editTask = (e) =>{
    const tasks = document.getElementById("tasks")
    const taskinformation = document.getElementById("taskinfo")
    tasks.style.display ="none"
    taskinformation.style.display = "block"
    props.selectTask(e)
   }


  return (
    <div id={props.id} className="dashboardCenter hiddenForm">
      <div className="mx-3 my-4">
        <EarnItHeader goBack={goToOverview} title={"Task History"}/>
      </div>
        <Tabs variant="unstyled" colorScheme="blue">
              <TabList background={"#FBFBFB"} borderRadius={"8px"}>
                <Tab
                  padding={'10px 0px'}
                  width={'50%'}
                  _selected={{ color: '#06AEDA', bg: '#E6F7FB' }}
                  borderRadius={"8px"}
                >
                All
                </Tab>
                <Tab
                  padding={'10px 0px'}
                  width={'50%'}
                  _selected={{ color: '#06AEDA', bg: '#E6F7FB' }}
                  borderRadius={"8px"}
                >
                  Pending
                </Tab>
               <Tab
                  padding={'10px 0px'}
                  width={'50%'}
                  _selected={{ color: '#06AEDA', bg: '#E6F7FB' }}
                  borderRadius={"8px"}
                >
                 Ongoing
                </Tab>

                <Tab
                  padding={'10px 0px'}
                  width={'50%'}
                  _selected={{ color: '#06AEDA', bg: '#E6F7FB' }}
                  borderRadius={"8px"}
                >
                 Completed 
                </Tab>

                {window.innerWidth > 600 &&<Tab
                  padding={'10px 0px'}
                  width={'50%'}
                  _selected={{ color: '#06AEDA', bg: '#E6F7FB' }}
                  borderRadius={"8px"}
                >
                 Disapproved
                </Tab>}
              </TabList>
              <TabPanels>
                <TabPanel>
                <div>
         {props.tasks && props.tasks.slice().reverse().map((task,i)=>(
          <EarnItTask editTask={()=>editTask(task)} key={i} task={task}/>
         ))
         }
        </div>
                  </TabPanel>
                <TabPanel>
                {props.tasks && props.tasks.slice().reverse().filter((task)=>task.status==="pending").map((task,i)=>(
          <EarnItTask editTask={()=>editTask(task)} key={i} task={task}/>
         ))
         }
                </TabPanel>

                <TabPanel>
                {props.tasks && props.tasks.slice().reverse().filter((task)=>task.status==="ongoing").map((task,i)=>(
          <EarnItTask editTask={()=>editTask(task)} key={i} task={task}/>
         ))
         }
                </TabPanel>

                <TabPanel>
                {props.tasks && props.tasks.slice().reverse().filter((task)=>task.status==="completed" || task.status==="approved").map((task,i)=>(
          <EarnItTask editTask={()=>editTask(task)} key={i} task={task}/>
         ))
         }
                </TabPanel>

                <TabPanel>
                {props.tasks && props.tasks.slice().reverse().filter((task)=>task.status==="disapproved").map((task,i)=>(
          <EarnItTask editTask={()=>editTask(task)} key={i} task={task}/>
         ))
         }
                </TabPanel>
                </TabPanels>
                </Tabs>

    </div>
  )
}

export default Tasks