import React from 'react'
import {Button} from "@chakra-ui/react"
import bankaccount from "../../Icons/nobank.svg"


function NoBankAccount(props) {
  return (
    <div className="notificationsCard">
    <div>
      <div className="my-4 flex justify-center">
        <img src={bankaccount} alt="notificationBell" />
      </div>
      <p className="my-3">Nothing to see here, you have not added any withdrawal accounts.</p>
      <Button fontSize={"14px"} size={"lg"} width={"80%"} onClick={props.onOpen} rounded={"full"} background="#0073E5" color="white" variant="#0073E5">
          Add bank Account
          </Button>
    </div>
    </div>
  )
}

export default NoBankAccount