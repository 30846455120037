import React, { useState } from 'react';
import { Avatar } from '@chakra-ui/react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';
import { toast } from 'react-toastify';
import { storage } from '../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';

function YoungsterAvatar(props) {
  const [image, setImage] = useState(props.user.profileImage);
  // const [loading,setLoading] = useState(false)
  const dispatch = useDispatch();
  const accountType = window.location.pathname.split("/")[2]

  const [avatarArray] = useState([
    {
      id: 1,
      image:
        'https://drive.google.com/uc?export=view&id=1hzjYiFQ2mVC23Nc8p9idHOjW4xZIGGpq',
    },
    {
      id: 2,
      image:
        'https://drive.google.com/uc?export=view&id=1ppG0s5svhO6CiFvWBJuZuUk9HsmWFsdB',
    },
    {
      id: 3,
      image:
        'https://drive.google.com/uc?export=view&id=17k6NlCUGVRYxZe7Nosp8mY6NRiyVYjqb',
    },
    {
      id: 4,
      image:
        'https://drive.google.com/uc?export=view&id=196Siivq5gNCXm-PaDRP9-QWXmKNlg-ys',
    },

    {
      id: 5,
      image:
        'https://drive.google.com/uc?export=view&id=1HZzZ1KgOUyikBwOOqpvIS8Cp3VAM5T4p',
    },
    {
      id: 6,
      image:
        'https://drive.google.com/uc?export=view&id=1-Pz4GVQtI_8WBofcDgLXn7HsVt_kZoev',
    },
    {
      id: 7,
      image:
        'https://drive.google.com/uc?export=view&id=1b3pKhoksVX0YwzYGKkAVHt71AW3V7fNC',
    },
    {
      id: 8,
      image:
        'https://drive.google.com/uc?export=view&id=1okhJUfLZ8wdXSSPp7pfzWwCdf0qs34qF',
    },
    {
      id: 9,
      image:
        'https://drive.google.com/uc?export=view&id=1oYqeIT_WmzuSv-oKRWTmU2-GsGJnHpcD',
    },
    {
      id: 10,
      image:
        'https://drive.google.com/uc?export=view&id=1CVYk45-72MrYoTxNLn8nOzT9mIUmrW8I',
    },
    {
      id: 11,
      image:
        'https://drive.google.com/uc?export=view&id=1AV0V87Y_A19u0uRLBAz9ZG-aZBLuDQZw',
    },
    {
      id: 12,
      image:
        'https://drive.google.com/uc?export=view&id=1lBJTmEPCKDfI4UirbewC4LELSxTyfLgI',
    },
    {
      id: 13,
      image:
        'https://drive.google.com/uc?export=view&id=1PL4SzkF8fLjQ4oJUirzMfJ7V7ty25vWG',
    },
  ]);

  function imageModal() {
    let input = document.getElementById('imageInput');
    input.click();
  }

  function uploadImage(event) {
    uploadFile(event.target.files[0]);
    var input = event.target;
    if (input.files) {
      var reader = new FileReader();
      reader.onload = e => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  const uploadFile = (image) => {
    toast.loading('Uploading Image. Please wait...');
    // Create the file metadata
    /** @type {any} */
    const storageRef = ref(storage, props.user.email === undefined?props.user.phone:props.user.email);
    const uploadTask = uploadBytesResumable(
      storageRef,
      image
    );

    uploadTask.on(
      'state_changed',
      snapshot => {
        console.log(snapshot);
      },
      error => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          toast.dismiss()
          updateProfile(downloadURL)
        });
      }
    );
  };

  async function chooseAvatar(image) {
    setImage(image);
    await updateProfile(image);
  }

  const updateProfile = image => {
    const id = toast.loading('Updating profile. Please wait...');
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/edit-profile/'+accountType,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        profileImage: image,
      },
    })
      .then(response => {
        console.log(response)
        dispatch(fetchUser(accountType))
        .then(()=>{
          toast.update(id, {
            render: 'Profile Updated Successfully',
            type: 'success',
            isLoading: false,
            autoClose:3000
          });
          
          Swal.fire({
            icon: 'success',
            title: 'Profile Updated Successfully',
            text: response.data.data.message,
            confirmButtonColor: '#005FEB',
            confirmButtonText: 'Done',
          }).then(result => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        })
        
      })
      .catch(error => {
        toast.update(id, {
          render: 'Profile not Updated',
          type: 'error',
          isLoading: false,
          autoClose:3000
        });
        Swal.fire({
          icon: 'error',
          title: 'Profile Image not Updated',
          text: 'Something went wrong. Perhaps the uploaded image is too large!',
          confirmButtonColor: '#005FEB',
          confirmButtonText: 'Try again',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      });
  };

  return (
    <div className="my-6">
      <div className="flex justify-center">
        <div className="relative">
          <Avatar
            size={'2xl'}
            cursor={'pointer'}
            rounded={'full'}
            onClick={imageModal}
            src={image}
          />
        </div>
      </div>

      <input
        onChange={uploadImage}
        id="imageInput"
        type="file"
        hidden
        accept="image/*"
      />

      <p className="text-center my-3">
        Click to change profile picture or choose from our custom avatars below
      </p>

      <div className="scrollAvatar my-6">
        {avatarArray.map((image, i) => (
          <div key={i} className="pr-3 flexAvatar">
            <Avatar
              size={'lg'}
              src={image.image}
              onClick={() => chooseAvatar(image.image)}
            />
          </div>
        ))}
      </div>

      <div></div>
    </div>
  );
}

export default YoungsterAvatar;
