import React,{useState} from 'react';
import Modal from './Modal';
import {Button,Input,Avatar} from "@chakra-ui/react"
import AmountInput from '../Misc/Forms/AmountInput';
import exchange from '../../Icons/exchange.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import "./Modal.css"

function RequestMoneyModal(props) {
  const [amount,setAmount] = useState("")
  const [reason,setReason] = useState("")
  const [amountError, setAmountError] = useState('');
  const [btnloading, setBtnLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  React.useEffect(()=>{
    if (amount <= 0 || reason === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  },[amount,reason])


  const sendMoneyRequest = () => {
    if (amount > 0) {
      setBtnLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/request-money',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
          ContentType: 'application/json',
        },
        data: {
          amount: amount,
          reason: reason,
          recipientName: props.recipient.firstName,
          recipientEmail: props.recipient.email,
          recipientId: props.recipient._id,
          name: props.user.firstName + ' ' + props.user.lastName,
          profileImage: props.user.profileImage,
          youngsterEmail: props.user.email,
        },
      })
        .then(response => {
          setBtnLoading(false);
          setDisabled(true)
          props.closeModal()
          Swal.fire({
            icon: 'success',
            title: 'Request sent!',
            text: response.data.data.message,
          });
        })
        .catch(() => {
          setBtnLoading(false);
          props.closeModal()
          Swal.fire({
            icon: 'error',
            title: 'Request Not sent!',
            text: 'Money request was not sent. Kindly try again',
          });
        });
    } else {
      setAmountError('Please enter an amount');
    }
  };

  return (
    <Modal isVisible={props.showModal} onClose={() => props.closeModal()}>
            <div className="my-8 mx-3">
            <div className="mt-7">
              <div className="requestGrid">
                <div className="flex">
                  <Avatar src={props.user.profileImage} />
                  <div className="ml-2">
                    <h2 className="font-bold">
                      {props.user.firstName}
                    </h2>
                    <span>@{props.user.minietag}</span>
                  </div>
                </div>

                <div className="flex">
                  <Button
                    colorScheme={'#F2EEFD'}
                    background={'#F2EEFD'}
                    rounded={'full'}
                    className="exchangeButton"
                  >
                    <img src={exchange} alt="exchange" />
                  </Button>
                </div>

                <div className="flex">
                  <Avatar src={props.recipient.profileImage} />
                  <div className="ml-2">
                    <h2 className="font-bold">
                      {props.recipient.firstName}
                    </h2>
                    <span>@{props.recipient.minietag}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-3 mt-6 space-y-4">
              <div>
                <label>Enter amount</label>
                <AmountInput
                  focusBorderColor={'#7A58EF'}
                  setAmount={e => setAmount(e)}
                  placeholder={'Enter an amount'}
                />
                <span className="text-red-400">{amountError}</span>
              </div>

              <div>
                <label>Reason for Request</label>
                <Input
                  onChange={e => setReason(e.target.value)}
                  focusBorderColor={'#7A58EF'}
                />
              </div>
              <Button
                isLoading={btnloading}
                isDisabled={disabled}
                onClick={() => sendMoneyRequest()}
                background={'#7A58EF'}
                colorScheme={'#7A58EF'}
                textColor={'white'}
                className="confirmButton"
                rounded={'full'}
                width={'100%'}
              >
                Request Money
              </Button>
            </div>
            </div>
          </Modal>

  )
}

export default RequestMoneyModal