import React from 'react';
import './Kyc.css';
import { useNavigate } from 'react-router';
import KYCHeader from './KYCHeader';
import Setting from './Setting';

function Kyc(props) {
  const history = useNavigate();
  const kyc = props.kycs

  const goBack = () => {
    history(-1);
  };
  

  const kycSettings = [
    { title: 'Bank Verification (BVN)', link: 'bvn',status:kyc.length === 0 || kyc.filter((kyc)=>kyc.type==="bvn").length === 0? "":kyc.filter((kyc)=>kyc.type==="bvn")[0].status },
    { title: 'Means of ID', link: 'id',status:kyc.length === 0 || kyc.filter((kyc)=>kyc.type==="means-of-id").length === 0?"":kyc.filter((kyc)=>kyc.type==="means-of-id")[0].status },
    { title: 'Passport Photograph', link: 'passport',status:kyc.length === 0 || kyc.filter((kyc)=>kyc.type==="passport").length === 0?"":kyc.filter((kyc)=>kyc.type==="passport")[0].status},
  ];

  return (
    <div id={props.id} className="dashboardCenter pb-12">
      {/* page heading */}
      <KYCHeader title="KYC Verification" goBack={goBack} />

      <div className="detailTitle text-center my-9">
        To ensure you have a smooth transaction experience on MinieMoney, we
        need to verify your identity by filling and uploading the required
        documents below.
      </div>

      {/* additional settings */}
      <div>
        {kycSettings.map((setting, i) => (
          <Setting user={props.user} setting={setting} key={i} loading={props.kycloading} />
        ))}
      </div>
    </div>
  );
}

export default Kyc;
