import React from 'react';

function PaymentFrequency({ frequency, weekDay, monthDay, allowanceData }) {
  return (
    <div>
   
      {allowanceData && !frequency && (
        <div>
          NOTE: Allowance payment will be made 09:00AM{' '}
         
         <strong> {allowanceData.frequency === 'Daily'
            ? 'each day'
            : allowanceData.frequency === 'Weekly'
            ? allowanceData.weekDay
              ? ' every ' + allowanceData.weekDay
              : 'each day'
            : allowanceData.frequency === 'Monthly'
            ? allowanceData.monthDay
              ? ' every ' +allowanceData.monthDay+' day of the month'
              : 'each day'
            : ''}
            </strong>
        </div>
      )}
      {frequency && (
        <div>
          NOTE: Allowance payment will be made 09:00AM{' '}
          <strong>{frequency === 'Daily'
            ? 'each day'
            : frequency === 'Weekly'
            ? weekDay
              ? ' every ' + weekDay
              : 'each day'
            : frequency === 'Monthly'
            ? monthDay
              ? ' every ' +monthDay+" day of the month"
              : 'each day'
            : ''}
            </strong>
        </div>
      )}
    </div>
  );
}

export default PaymentFrequency;
