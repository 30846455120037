import React from 'react';
import './MinieWallet.css';
import Notification from '../../Icons/walletNotification.svg';
import { useNavigate } from 'react-router';
import WalletCard from './WalletCard';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import sendWallet from '../../Icons/wallet-send.svg';
import walletAdd from '../../Icons/wallet-add-white.svg';
import Transactions from './Transactions';
import TransactionList from './TransactionList';
import Swal from 'sweetalert2';
import NoKyc from '../../Icons/noKYC.svg';
import virtualAccount from '../../Icons/virtualaccount.svg';
import copy from '../../Icons/copy.svg';
import { toast } from 'react-toastify';
import withdraw from '../../Icons/withdrawalIcon.svg';
import TransactionLoading from './TransactionLoading';
import transactionsData from '../../data/Transactions.json';

function BankModal(props) {
  const history = useNavigate();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.user.accountNumber);
    toast.success('Account Number Copied to clipboard', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // props.onClose()
  };
  return (
    <div>
      {props.user.accountNumber === '' && (
        <div className="text-center">
          <img className="mx-auto" src={NoKyc} alt="" />
          <p className="my-4">
            Nothing to see here, we will display your MinieMoney account number
            when you verify your KYC.
          </p>
          <div className="my-4">
            <Button
              background={'#7A58EF'}
              colorScheme={'#7A58EF'}
              rounded={'full'}
              className="confirmButton"
              width={'full'}
              onClick={() => history('/account/adult/kyc')}
            >
              Verify KYC
            </Button>
          </div>
        </div>
      )}

      {props.user.accountNumber !== '' && (
        <div>
          <img className="mx-auto" src={virtualAccount} alt="" />

          <p className="text-center my-4">
            Send money to the account number below and your MinieMoney wallet
            will be funded immediately.
          </p>
          <hr />
          <div className="my-4 flex justify-between">
            <div>
              <span>{props.user.bank}</span>
              <h2 className="font-bold">
                {props.user.accountNumber} - {props.user.accountName}
              </h2>
            </div>
            <div>
              <Button
                onClick={copyToClipboard}
                color={'#7A58EF'}
                rounded={'full'}
                colorScheme="#F2EEFD"
                background={'#F2EEFD'}
              >
                <img src={copy} alt="copy" className="mr-2" /> Copy
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function MinieWallet(props) {
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const gotonotifications = () => {
    history('/notifications/' + accountType);
  };

  const sendMoney = () => {
    if (props.user.minietag === '' || props.user.minietag === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Request Money Not Available 😢',
        text: 'You cannot use this feature until you have added your Minietag',
        confirmButtonColor: '#0066f5',
        confirmButtonText: 'Set Minietag',
      }).then(result => {
        if (result.isConfirmed) {
          history(`/account/${props.user.accountType}/edit-profile`);
        }
      });
      return;
    }

    if (accountType === 'youngster' && props.parents.length === 0) {
      const overview = document.getElementById('overview');
      const transfer = document.getElementById('transfer');
      overview.style.display = 'none';
      transfer.style.display = 'block';
    }

    if (accountType === 'youngster' && !props.user.canTransfer) {
      Swal.fire({
        icon: 'error',
        title: 'Transfer Money Disabled',
        text: 'Your parents have disabled your ability to transfer money. Kindly ask them to reactivate this feature for you',
      });
      return;
    }
    const overview = document.getElementById('overview');
    const transfer = document.getElementById('transfer');
    overview.style.display = 'none';
    transfer.style.display = 'block';
  };

  const fundWallet = () => {
    const overview = document.getElementById('overview');
    const fundwallet = document.getElementById('fundwallet');
    overview.style.display = 'none';
    fundwallet.style.display = 'block';
  };

  const goToHistory = () => {
    window.scrollTo(0, 0);
    const overview = document.getElementById('overview');
    const fundwallet = document.getElementById('fundwallet');
    const walletHistory = document.getElementById('history');
    overview.style.display = 'none';
    fundwallet.style.display = 'none';
    walletHistory.style.display = 'block';
  };

  const requestMoney = () => {
    if (props.user.minietag === '' || props.user.minietag === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Request Money Not Available 😢',
        text: 'You cannot use this feature until you have added your Minietag',
        confirmButtonColor: '#0066f5',
        confirmButtonText: 'Set Minietag',
      }).then(result => {
        if (result.isConfirmed) {
          history(`/account/${props.user.accountType}/edit-profile`);
        }
      });
      return;
    }

    if (accountType === 'youngster' && props.parents.length === 0) {
      history('/request-money/youngster');
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Request Money Not Available',
      //   text: 'You cannot use this feature until you invite your parents to join MinieMoney',

      // });
      return;
    }

    if (props.user.canRequest) {
      history('/request-money/youngster');
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Request Money Disabled',
        text: 'Your parents have disabled your ability to request money. Kindly ask them to reactivate this feature for you',
        confirmButtonColor: '#0066f5',
      });
    }
  };

  const withdrawMoney = () => {
    document.getElementById('overview').style.display = 'none';
    document.getElementById('sendmoneybank').style.display = 'block';
  };

  return (
    <div id={props.id} className="dashboardCenter mb-9 pb-9">
      {/* the user info */}
      <div className="flex justify-between w-full">
        <div className="flex">
          <h2 className="font-bold text-2xl">Minie Wallet</h2>
        </div>
        <div className="relative cursor-pointer" onClick={gotonotifications}>
          {props.user.notifications.filter(
            notification => notification.status === 'recent'
          ).length > 0 && (
            <div className="notificationsBadge">
              {
                props.user.notifications.filter(
                  notification => notification.status === 'recent'
                ).length
              }
            </div>
          )}
          <img src={Notification} alt="notification" />
        </div>
      </div>

      <WalletCard user={props.user} />

      {props.accountType === 'adult' && (
        <div
          onClick={() => onOpen()}
          className="cursor-pointer text-center my-6 viewAccount"
        >
          <p>
            View your account number <ArrowForwardIcon />
          </p>
        </div>
      )}

      {/* {props.accountType === 'youngster' && (
        <div
          onClick={() => history("/wallet/"+accountType+"/account-number")}
          className="cursor-pointer text-center my-6 viewAccount"
        >
          <p>
            View your account number <ArrowForwardIcon />
          </p>
        </div>
      )} */}
      <div className="flexWalletButtons my-6">
        <div className="flex sendButtons">
          <Button
            className="walletButton mr-3"
            rounded={'full'}
            borderColor="#7A58EF"
            variant="outline"
            colorScheme={'white'}
            onClick={() => sendMoney()}
            color={'#7A58EF'}
            width={'full'}
          >
            <img src={sendWallet} className="mr-2" alt="send-wallet" />
            Send Money
          </Button>

          {props.accountType === 'adult' && (
            <Button
              className="walletButton mr-3"
              rounded={'full'}
              borderColor="#7A58EF"
              variant="outline"
              colorScheme={'white'}
              onClick={withdrawMoney}
              color={'#7A58EF'}
              width={'full'}
            >
              <img src={withdraw} className="mr-2" alt="send-wallet" /> Withdraw
            </Button>
          )}
        </div>

        <div>
          {accountType === 'adult' ? (
            <Button
              className="walletButton"
              rounded={'full'}
              background="#7A58EF"
              color="white"
              colorScheme={'#7A58EF'}
              onClick={fundWallet}
              width={'full'}
            >
              <img src={walletAdd} className="mr-2" alt="add-wallet" /> Fund
              Wallet
            </Button>
          ) : (
            <Button
              className="walletButton"
              rounded={'full'}
              background="#7A58EF"
              color="white"
              colorScheme={'#7A58EF'}
              onClick={() => requestMoney()}
            >
              <img src={walletAdd} className="mr-2" alt="add-wallet" />
              Fund Wallet
            </Button>
          )}
        </div>
      </div>

      {!props.transactionsLoading &&
        props.transactions &&
        props.transactions.length === 0 && <Transactions />}

      {props.transactionsLoading &&
        transactionsData.map((transaction, i) => (
          <TransactionLoading key={i} />
        ))}

      {!props.transactionsLoading &&
        props.transactions &&
        props.transactions.length !== 0 && (
          <div className="recentTransactions">
            <div className="flex justify-between">
              <h2 className="recentTransactionTitle">Recent Transactions</h2>{' '}
              <div onClick={goToHistory} className="walletLinkButton">
                Transactions <ArrowForwardIcon />
              </div>
            </div>
            {props.transactions.slice(0, 5).map((transaction, i) => (
              <TransactionList
                id={props.id}
                transaction={transaction}
                key={i}
              />
            ))}
          </div>
        )}

      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-medium text-xl">Account Number</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BankModal user={props.user} onClose={() => onClose()} />
          </ModalBody>

          <ModalFooter
            margin={'0px 0px 20px 0px'}
            className="confirmDetailsFlexButtons"
          ></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default MinieWallet;
