import { Button } from '@chakra-ui/button';
import { Select } from '@chakra-ui/select';
import React, { Fragment, useState } from 'react';
import back from '../../Icons/backButton.svg';
import Modal from '../Modal/Modal';
import success from '../../Icons/successIcon.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';
import closeButtonGreen from '../../Icons/closeButtonGreen.svg';
import AmountInput from '../Misc/Forms/AmountInput';
import { standardAmountFormat } from '../../utils/amountUtil';

function InstantSave(props) {
  const Back = () => {
    props.Back();
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const [disabled, setDisabled] = useState(true);
  const [showModal2, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  const accountType = window.location.pathname.split('/')[2];
  const history = useNavigate();
  const dispatch = useDispatch();

  const showSaving = () => {
    window.location.reload();
  };
  const setAmountValue = e => {
    setAmount(e);
    if (amount > 0 && amount !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const save = () => {
    //fire function to save
    setError('');
    if (amount < 200 || amount === '') {
      setError('The least amount you can save instantly is ₦ 200');
      return;
    }

    if (amount > props.user.walletBalance) {
      Swal.fire({
        icon: 'error',
        title: 'Insufficient funds 😢',
        text: 'Please topup your MinieWallet and try again',
        confirmButtonColor: '#669f2a',
      }).then(result => {
        if (result.isConfirmed) {
          history('/request-money/youngster');
        }
      });
      return;
    }

    setLoading(true);
    setDisabled(true)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/instant-kolosave',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
      data: {
        amount: amount,
      },
    })
      .then(() => {
        dispatch(fetchUser(accountType)).then(() => {
          setLoading(false);
          setDisabled(false)
          setShowModal(true);
        });
      })
      .catch(error => {
        setLoading(false);
        setDisabled(false)
        Swal.fire({
          icon: 'error',
          title: "Something's Wrong 😢",
          text: error.response.data.data.message,
          confirmButtonColor: '#669f2a',
        });
      });
  };

  return (
    <Fragment>
      <div id="instantsave" className="dashboardCenter hiddenForm">
        <div onClick={Back} className="cursor-pointer pb-8">
          <img src={back} alt="" />

          <h2 className="walletScreenTitle text-center">Instant Save</h2>
        </div>
        <div className="pt-4">
          <label>Select Money Source</label>
          <Select fontSize={"16px"}  size={"lg"} focusBorderColor="#669F2A" color={'#151618'}>
            <option
              defaultValue={'Where would you like to take money from?'}
              hidden
              disabled
              value=""
            >
              Where would you like to take money from?
            </option>
            <option defaultValue="miniewallet">
              MinieWallet ({standardAmountFormat(props.user.walletBalance)})
            </option>
          </Select>
        </div>

        <div className="my-3">
          <label>Enter Amount To Save</label>
          <AmountInput
            focusBorderColor={'#669F2A'}
            placeholder={'Enter the amount you want to save'}
            color={'#151618'}
            setAmount={e => setAmountValue(e)}
          />

          <span className="text-red-500">{error}</span>
        </div>

        <div className="mt-6">
          <Button
            onClick={save}
            padding={'28px 32px'}
            width={'100%'}
            rounded={'full'}
            colorScheme={'#669F2A '}
            background={'#669F2A '}
            isLoading={loading}
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      </div>
      <Modal
        closeButtonGreen={closeButtonGreen}
        isVisible={showModal2}
        onClose={() => closeModal()}
      >
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            Your instant save is successful!
          </h5>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            You have successfully saved{' '}
            <span style={{ color: '#669f2a' }}>
              <strong>₦ {amount}</strong>
            </span>{' '}
            to your KoloSave. Happy saving!
          </p>
          <button
            onClick={showSaving}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2"
          >
            <span className="font-semibold text-base">Done</span>
          </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default InstantSave;
