import React,{useState,useEffect,useCallback} from 'react';
import './DashboardRight.css';
import { Button } from '@chakra-ui/react';
import Telegram from '../../../Icons/telegram.png';
import DiscordArrow from '../../../Icons/discordArrow.svg';
import {FaDiscord} from "react-icons/fa"
import {descriptionUtil} from "../../../utils/descriptionUtil"

function DashboardRight(props) {
  const [slide,setSlide] = useState(0)
  const announcements = JSON.parse(sessionStorage.getItem("announcements"))




  const firstCircle = useCallback(() => {
    setSlide(0)
    const circle1 = document.getElementById('circle1');
    const circle2 = document.getElementById('circle2');
    const circle3 = document.getElementById('circle3');
    circle1.classList.add('circleActive');
    circle2.classList.remove('circleActive');
    circle3.classList.remove('circleActive');
  }, []);


  const secondCircle = useCallback(() => {
    setSlide(1)
    const circle1 = document.getElementById('circle1');
    const circle2 = document.getElementById('circle2');
    const circle3 = document.getElementById('circle3');
    circle1.classList.remove('circleActive');
    circle2.classList.add('circleActive');
    circle3.classList.remove('circleActive');
  }, []);


  const thirdCircle = useCallback(() => {
    setSlide(2)
    const circle1 = document.getElementById('circle1');
    const circle2 = document.getElementById('circle2');
    const circle3 = document.getElementById('circle3');
    circle3.classList.add('circleActive');
    circle1.classList.remove('circleActive');
    circle2.classList.remove('circleActive');
  }, []);


  useEffect(()=>{
    if(announcements && announcements.announcements.length >= 3){
      const interval =  setInterval(()=>{
        if(slide === 0){
            secondCircle()
            return
        }
        if(slide === 1){
          thirdCircle()
          return
        }
        if(slide === 2){
          firstCircle()
          return
        }

      },4500)

      return () => {
        clearInterval(interval);
      };
    }
  },[announcements,slide,setSlide,firstCircle,secondCircle,thirdCircle])
 


  const jointelegram = () => {
    window.open('https://t.me/miniemoney', '_blank');
  };

  const joindiscord = () =>{
    window.open('https://discord.gg/UbTb3yXxyJ', '_blank');
  }

  const goToLink = (link) =>{
    window.open(link, '_blank');
  }

  return (
    <div className="dashboardRight">
      <div className="quoteCard">
        <div className={announcements && announcements.announcements[slide].type}>{announcements && announcements.announcements[slide].type}</div>
        <h2>{announcements && announcements.announcements[slide].title}</h2>
        <p className="announcementDescription">{descriptionUtil(announcements && announcements.announcements[slide].description)}</p>
        {announcements && <button onClick={()=>goToLink(announcements && announcements.announcements[slide].link)} className="eventButton">Get started</button>}
      </div>
      <div className="carsouselCircle">
        <div
          id="circle1"
          onClick={firstCircle}
          className="circle circleActive"
        ></div>
        <div id="circle2" onClick={secondCircle} className="circle"></div>
        <div id="circle3" onClick={thirdCircle} className="circle"></div>
      </div>
      <div className="joinCommunity">
        <h2>Join the Community</h2>
        <div>
          <p>
            MinieMoney’s vibrant community drives the success of the platform.
            Join the community on {props.accountType === 'adult'?'Telegram':'Discord'} to stay up to date on the latest
            happenings.
          </p>
        </div>
        {props.accountType === "adult" && <Button
          onClick={jointelegram}
          width={'100%'}
          className="discordButton space-x-2"
          rounded={'full'}
          backgroundColor={'#E6F1FC'}
          color={'#0073E5'}
        >
          <img width={'25px'} src={Telegram} alt="discord" />
          <span>Join our Telegram channel </span>
          <img src={DiscordArrow} alt="discordArrow" />
        </Button>}

        {props.accountType === "youngster" && <Button
          onClick={joindiscord}
          width={'100%'}
          className="discordButton space-x-2"
          rounded={'full'}
          backgroundColor={'#E6F1FC'}
          color={'#0073E5'}
        >
          <FaDiscord />
          <span>Join our Discord channel </span>
          <img src={DiscordArrow} alt="discordArrow" />
        </Button>}

        <div></div>
      </div>
    </div>
  );
}

export default DashboardRight;
