import React from 'react';

export const Home = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.51666 2.36713L3.025 5.86713C2.275 6.45046 1.66666 7.69213 1.66666 8.63379V14.8088C1.66666 16.7421 3.24166 18.3255 5.175 18.3255H14.825C16.7583 18.3255 18.3333 16.7421 18.3333 14.8171V8.75046C18.3333 7.74213 17.6583 6.45046 16.8333 5.87546L11.6833 2.26713C10.5167 1.45046 8.64166 1.49213 7.51666 2.36713Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.9912V12.4912"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HomeActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.51666 2.36713L3.025 5.86713C2.275 6.45046 1.66666 7.69213 1.66666 8.63379V14.8088C1.66666 16.7421 3.24166 18.3255 5.175 18.3255H14.825C16.7583 18.3255 18.3333 16.7421 18.3333 14.8171V8.75046C18.3333 7.74213 17.6583 6.45046 16.8333 5.87546L11.6833 2.26713C10.5167 1.45046 8.64166 1.49213 7.51666 2.36713Z"
      stroke="#0073E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.9912V12.4912"
      stroke="#0073E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EarnIt = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9167 15.8168H6.08333C5.73333 15.8168 5.34166 15.5418 5.225 15.2085L1.775 5.55846C1.28333 4.17513 1.85833 3.75013 3.04166 4.60013L6.29166 6.92513C6.83333 7.30013 7.45 7.10846 7.68333 6.50013L9.15 2.5918C9.61666 1.3418 10.3917 1.3418 10.8583 2.5918L12.325 6.50013C12.5583 7.10846 13.175 7.30013 13.7083 6.92513L16.7583 4.75013C18.0583 3.8168 18.6833 4.2918 18.15 5.80013L14.7833 15.2251C14.6583 15.5418 14.2667 15.8168 13.9167 15.8168Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41666 18.333H14.5833"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91666 11.667H12.0833"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EarnItActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9167 15.8168H6.08333C5.73333 15.8168 5.34166 15.5418 5.225 15.2085L1.775 5.55846C1.28333 4.17513 1.85833 3.75013 3.04166 4.60013L6.29166 6.92513C6.83333 7.30013 7.45 7.10846 7.68333 6.50013L9.15 2.5918C9.61666 1.3418 10.3917 1.3418 10.8583 2.5918L12.325 6.50013C12.5583 7.10846 13.175 7.30013 13.7083 6.92513L16.7583 4.75013C18.0583 3.8168 18.6833 4.2918 18.15 5.80013L14.7833 15.2251C14.6583 15.5418 14.2667 15.8168 13.9167 15.8168Z"
      stroke="#06AEDA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41666 18.334H14.5833"
      stroke="#06AEDA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91666 11.666H12.0833"
      stroke="#06AEDA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinieWallet = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8333 9.29199H5.83334"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66666 9.29121V5.44121C1.66666 3.74121 3.04167 2.36621 4.74167 2.36621H9.425C11.125 2.36621 12.5 3.42454 12.5 5.12454"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5666 10.1667C14.15 10.5667 13.95 11.1833 14.1167 11.8166C14.325 12.5916 15.0917 13.0833 15.8917 13.0833H16.6667V14.2917C16.6667 16.1333 15.175 17.625 13.3333 17.625H5C3.15833 17.625 1.66666 16.1333 1.66666 14.2917V8.45833C1.66666 6.61667 3.15833 5.125 5 5.125H13.3333C15.1667 5.125 16.6667 6.625 16.6667 8.45833V9.66663H15.7666C15.3 9.66663 14.875 9.84999 14.5666 10.1667Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3334 10.517V12.2337C18.3334 12.7003 17.95 13.0837 17.475 13.0837H15.8667C14.9667 13.0837 14.1417 12.4254 14.0667 11.5254C14.0167 11.0004 14.2167 10.5087 14.5667 10.167C14.875 9.85036 15.3 9.66699 15.7667 9.66699H17.475C17.95 9.66699 18.3334 10.0503 18.3334 10.517Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinieWalletActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8334 9.29199H5.83337"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66663 9.29121V5.44121C1.66663 3.74121 3.04163 2.36621 4.74163 2.36621H9.42496C11.125 2.36621 12.5 3.42454 12.5 5.12454"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5666 10.1667C14.1499 10.5667 13.95 11.1833 14.1166 11.8166C14.325 12.5916 15.0916 13.0833 15.8916 13.0833H16.6666V14.2917C16.6666 16.1333 15.175 17.625 13.3333 17.625H4.99996C3.15829 17.625 1.66663 16.1333 1.66663 14.2917V8.45833C1.66663 6.61667 3.15829 5.125 4.99996 5.125H13.3333C15.1666 5.125 16.6666 6.625 16.6666 8.45833V9.66663H15.7666C15.2999 9.66663 14.8749 9.84999 14.5666 10.1667Z"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 10.517V12.2337C18.3333 12.7003 17.95 13.0837 17.475 13.0837H15.8667C14.9667 13.0837 14.1417 12.4254 14.0667 11.5254C14.0167 11.0004 14.2167 10.5087 14.5667 10.167C14.875 9.85036 15.3 9.66699 15.7667 9.66699H17.475C17.95 9.66699 18.3333 10.0503 18.3333 10.517Z"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinieSave = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.425 9.26658C17.425 13.3416 14.4667 17.1582 10.425 18.2749C10.15 18.3499 9.84999 18.3499 9.57499 18.2749C5.53333 17.1582 2.575 13.3416 2.575 9.26658V5.60824C2.575 4.92491 3.09168 4.14991 3.73334 3.89158L8.37499 1.9916C9.41666 1.5666 10.5917 1.5666 11.6333 1.9916L16.275 3.89158C16.9083 4.14991 17.4333 4.92491 17.4333 5.60824L17.425 9.26658Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.4163C10.9205 10.4163 11.6667 9.67015 11.6667 8.74967C11.6667 7.8292 10.9205 7.08301 10 7.08301C9.07953 7.08301 8.33334 7.8292 8.33334 8.74967C8.33334 9.67015 9.07953 10.4163 10 10.4163Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.416V12.916"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinieSaveActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.425 9.26756C17.425 13.3426 14.4667 17.1592 10.425 18.2759C10.15 18.3509 9.84999 18.3509 9.57499 18.2759C5.53333 17.1592 2.575 13.3426 2.575 9.26756V5.60922C2.575 4.92588 3.09168 4.15088 3.73334 3.89255L8.37499 1.99258C9.41666 1.56758 10.5917 1.56758 11.6333 1.99258L16.275 3.89255C16.9083 4.15088 17.4333 4.92588 17.4333 5.60922L17.425 9.26756Z"
      stroke="#D138F0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.4173C10.9205 10.4173 11.6667 9.67113 11.6667 8.75065C11.6667 7.83018 10.9205 7.08398 10 7.08398C9.07953 7.08398 8.33334 7.83018 8.33334 8.75065C8.33334 9.67113 9.07953 10.4173 10 10.4173Z"
      stroke="#D138F0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.418V12.918"
      stroke="#D138F0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const User = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 10.0003C12.3012 10.0003 14.1667 8.13485 14.1667 5.83366C14.1667 3.53247 12.3012 1.66699 10 1.66699C7.69885 1.66699 5.83337 3.53247 5.83337 5.83366C5.83337 8.13485 7.69885 10.0003 10 10.0003Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1583 18.3333C17.1583 15.1083 13.95 12.5 10 12.5C6.05001 12.5 2.84167 15.1083 2.84167 18.3333"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 9.99935C12.3012 9.99935 14.1667 8.13387 14.1667 5.83268C14.1667 3.5315 12.3012 1.66602 10 1.66602C7.69882 1.66602 5.83334 3.5315 5.83334 5.83268C5.83334 8.13387 7.69882 9.99935 10 9.99935Z"
      stroke="#0073E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1583 18.3333C17.1583 15.1083 13.95 12.5 10 12.5C6.05 12.5 2.84167 15.1083 2.84167 18.3333"
      stroke="#0073E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Youngster = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9984 5.96602C14.9484 5.95769 14.8901 5.95769 14.8401 5.96602C13.6901 5.92435 12.7734 4.98268 12.7734 3.81602C12.7734 2.62435 13.7318 1.66602 14.9234 1.66602C16.1151 1.66602 17.0734 2.63268 17.0734 3.81602C17.0651 4.98268 16.1484 5.92435 14.9984 5.96602Z"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1383 12.0328C15.28 12.2245 16.5383 12.0245 17.4216 11.4328C18.5966 10.6495 18.5966 9.36615 17.4216 8.58282C16.53 7.99115 15.2549 7.79115 14.1133 7.99115"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.97344 5.96602C5.02344 5.95769 5.08177 5.95769 5.13177 5.96602C6.28177 5.92435 7.19844 4.98268 7.19844 3.81602C7.19844 2.62435 6.24011 1.66602 5.04844 1.66602C3.85677 1.66602 2.89844 2.63268 2.89844 3.81602C2.90677 4.98268 3.82344 5.92435 4.97344 5.96602Z"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83255 12.0328C4.69088 12.2245 3.43255 12.0245 2.54922 11.4328C1.37422 10.6495 1.37422 9.36615 2.54922 8.58282C3.44089 7.99115 4.71588 7.79115 5.85755 7.99115"
      stroke="#54595E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0023 12.1926C9.95234 12.1842 9.89401 12.1842 9.84401 12.1926C8.69401 12.1509 7.77734 11.2092 7.77734 10.0426C7.77734 8.85091 8.73568 7.89258 9.92734 7.89258C11.119 7.89258 12.0773 8.85925 12.0773 10.0426C12.069 11.2092 11.1523 12.1592 10.0023 12.1926Z"
      stroke="#54595E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.57656 14.8168C6.40156 15.6001 6.40156 16.8835 7.57656 17.6668C8.9099 18.5585 11.0932 18.5585 12.4266 17.6668C13.6016 16.8835 13.6016 15.6001 12.4266 14.8168C11.1016 13.9335 8.9099 13.9335 7.57656 14.8168Z"
      stroke="#54595E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const YoungsterActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6083 6.47435C14.55 6.46602 14.4917 6.46602 14.4333 6.47435C13.1417 6.43268 12.1167 5.37435 12.1167 4.07435C12.1167 2.74935 13.1917 1.66602 14.525 1.66602C15.85 1.66602 16.9333 2.74102 16.9333 4.07435C16.925 5.37435 15.9 6.43268 14.6083 6.47435Z"
      fill="#005FEB"
    />
    <path
      d="M17.325 12.2503C16.3917 12.8753 15.0833 13.1087 13.875 12.9503C14.1917 12.267 14.3583 11.5087 14.3667 10.7087C14.3667 9.87534 14.1833 9.08367 13.8333 8.392C15.0667 8.22534 16.375 8.45867 17.3167 9.08367C18.6333 9.95034 18.6333 11.3753 17.325 12.2503Z"
      fill="#005FEB"
    />
    <path
      d="M5.36665 6.47435C5.42499 6.46602 5.48332 6.46602 5.54165 6.47435C6.83332 6.43268 7.85832 5.37435 7.85832 4.07435C7.85832 2.74102 6.78332 1.66602 5.44999 1.66602C4.12499 1.66602 3.04999 2.74102 3.04999 4.07435C3.04999 5.37435 4.07499 6.43268 5.36665 6.47435Z"
      fill="#005FEB"
    />
    <path
      d="M5.45834 10.7088C5.45834 11.5172 5.63334 12.2838 5.95001 12.9755C4.77501 13.1005 3.55001 12.8505 2.65001 12.2588C1.33334 11.3838 1.33334 9.95883 2.65001 9.08383C3.54167 8.48383 4.80001 8.24216 5.98334 8.37549C5.64167 9.07549 5.45834 9.86716 5.45834 10.7088Z"
      fill="#005FEB"
    />
    <path
      d="M10.1 13.225C10.0333 13.2167 9.95833 13.2167 9.88333 13.225C8.35 13.175 7.125 11.9167 7.125 10.3667C7.13333 8.78333 8.40833 7.5 10 7.5C11.5833 7.5 12.8667 8.78333 12.8667 10.3667C12.8583 11.9167 11.6417 13.175 10.1 13.225Z"
      fill="#005FEB"
    />
    <path
      d="M7.39166 14.9492C6.13332 15.7909 6.13332 17.1742 7.39166 18.0076C8.82499 18.9659 11.175 18.9659 12.6083 18.0076C13.8667 17.1659 13.8667 15.7826 12.6083 14.9492C11.1833 13.9909 8.83332 13.9909 7.39166 14.9492Z"
      fill="#005FEB"
    />
  </svg>
);

export const MinieQuiz = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91666 15.0003V5.83366C2.91666 2.50033 3.75 1.66699 7.08333 1.66699H12.9167C16.25 1.66699 17.0833 2.50033 17.0833 5.83366V14.167C17.0833 14.2837 17.0833 14.4003 17.075 14.517"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.29166 12.5H17.0833V15.4167C17.0833 17.025 15.775 18.3333 14.1667 18.3333H5.83333C4.225 18.3333 2.91666 17.025 2.91666 15.4167V14.875C2.91666 13.5667 3.98333 12.5 5.29166 12.5Z"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66666 5.83301H13.3333"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66666 8.75H10.8333"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const MinieQuizActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91797 15.0003V5.83366C2.91797 2.50033 3.7513 1.66699 7.08464 1.66699H12.918C16.2513 1.66699 17.0846 2.50033 17.0846 5.83366V14.167C17.0846 14.2837 17.0846 14.4003 17.0763 14.517"
      stroke="#15B79E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.29297 12.5H17.0846V15.4167C17.0846 17.025 15.7763 18.3333 14.168 18.3333H5.83464C4.2263 18.3333 2.91797 17.025 2.91797 15.4167V14.875C2.91797 13.5667 3.98464 12.5 5.29297 12.5Z"
      stroke="#15B79E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66797 5.83301H13.3346"
      stroke="#15B79E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66797 8.75H10.8346"
      stroke="#15B79E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
