import React from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import YoungsterCenter from '../../../components/Account/YoungsterSingleCenter';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import './Account.css';
import { useSelector } from 'react-redux';
import YoungsterTransaction from '../../../components/Account/YoungsterTransactions';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchYoungsters } from '../../../store/fetchYoungster';
// import YoungsterAccountSetting from '../../../components/Account/YoungsterAccountSetting';
import YoungsterSetting from '../../../components/Account/YoungsterSetting';
import Control from '../../../Icons/send-money-youngster.svg';
import YoungsterSettingHeader from "../../../components/Account/YoungsterSettingHeader"

function Youngster() {
  const user = useSelector(state => state.user.value);
  const youngster = JSON.parse(sessionStorage.getItem('youngster'));
  const dispatch = useDispatch();

  const controlSettings = [
    {
      title: 'Grant access to transfer and send money',
      type: 'control',
      image: Control,
    },
    { title: 'Grant access to request money', type: 'control', image: Control },
    {
      title: 'Grant access to account',
      type: 'control',
      image: Control,
    },
  ];

  const fetchYoungsterDetails = React.useCallback(() => {
    const id = toast.loading('Fetching youngster data...');
    axios({
      method: 'GET',
      url:
      process.env.REACT_APP_API_URL+'/fetch-youngster/' +
        youngster.email,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
    })
      .then(() => {
        toast.update(id, {
          render: 'Youngster data fetched',
          type: 'success',
          isLoading: false,
          autoClose:3000
        });
      })
      .catch(error => {
        toast.dismiss();
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: error.response.data.data.message,
        });
      });
  }, [youngster]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchYoungsters());
  }, [fetchYoungsterDetails, dispatch]);

  const goBack = () => {
    document.getElementById('youngsterOverview').style.display = 'block';
    document.getElementById('youngsterSettings').style.display = 'none';
  };


  return (
    <div>
      <div className="dashboardPage">
       
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <div id="youngsterOverview">
              <YoungsterCenter id={'youngster-single'} user={user} />
              <YoungsterTransaction
                id={'youngster-transactions'}
                transactions={youngster.transactions.slice().reverse()}
              />
            </div>
            <div id="youngsterSettings" className="hiddenForm dashboardCenter">
              {/* <YoungsterAccountSetting youngster={youngster} /> */}
              <div>
                <div>
                  <YoungsterSettingHeader
                    title={'Control & Monitoring'}
                    goBack={goBack}
                  />
                  {controlSettings.map((setting, i) => (
                    <YoungsterSetting
                      youngsterData={youngster}
                      user={user}
                      setting={setting}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="hideOnMobile">
            <DashboardRight />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default Youngster;
