import React from 'react';
import danger from "../../../Icons/danger.svg"

function PhotoInstructions() {
  return (
    <div className="photoInstructionContainer">

        <div className="text-red-500 flex"><img src={danger} alt="danger" className="mr-2"/> Photos Not Allowed</div>
        <ul>
         <li> Use of Filters</li> 
         <li>Use of Accessories like sunglasses, head gear, face cap, etc</li>
         <li>Snapping another photo </li>
        </ul>
    </div>
  );
}

export default PhotoInstructions;
