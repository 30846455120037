import React, { useState, useEffect } from 'react';
import success from '../../../Icons/successIcon.svg';
import PhoneInput from '../../Misc/Forms/PhoneInput';
import TransactionPin from '../../Misc/TransactionPin';
import PrimaryButton from '../../Misc/Buttons/PrimaryButton';
import TertiaryButton from '../../Misc/Buttons/TertiaryButton';
import CustomModal from '../../Modal/Modal';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import ResendOtp from '../../Misc/Counter/ResendOtp';
import { Button } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../store/userLogin';

function ChangePhoneModal(props) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setBtnLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [error] = useState('');
  const [pin, setPin] = useState('');
  const [sessionId, setSessionId] = useState('');
  const accountType = window.location.pathname.split('/')[2];
  const [countDown, setCountDown] = useState('');
  const [otpLoading, setOtpLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pin.length === 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [pin]);

  const addPhoneNumber = () => {
    setBtnLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/verify-phone-auth',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
      },
      data: {
        phone: phone,
      },
    })
      .then(response => {
        toast.success('Otp sent successfully');
        setSessionId(response.data.data.sessionId);
        setBtnLoading(false);
        setDisabled(true)
        document.getElementById('firststep').style.display = 'none';
        document.getElementById('secondStep').style.display = 'block';
      })
      .catch(error => {
        setBtnLoading(false);
        setDisabled(true)
        let message =
          error.response.data.data.message ===
          'Request failed with status code 503'
            ? 'Our SMS OTP provider is currently unavailable. Please try later'
            : error.response.data.data.message;

        Swal.fire({
          icon: 'warning',
          title: "Something's wrong 🤔",
          text: message,
          confirmButtonColor: '#0073E5',
          confirmButtonText: 'Ok',
        });
      });
  };
  const enterOTP = e => {
    setPin(e);
  };

  const closeModal = () => {
    props.setShowmodal(false);
  };

  const updatePhone = () => {
    //confirm otp and update phone number
    setBtnLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/change-phone',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
      data: {
        otp: pin,
        sessionId: sessionId,
        accountType: accountType,
      },
    })
      .then(() => {
        setBtnLoading(false);
        document.getElementById('firststep').style.display = 'none';
        document.getElementById('secondStep').style.display = 'none';
        document.getElementById('thirdStep').style.display = 'block';
      })
      .catch(error => {
        setBtnLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 🤔",
          text: error.response.data.data.message,
          confirmButtonColor: '#0073E5',
        });
      });
  };

  const showChangeNumber = () => {
    //reload and refresh data in state
    setBtnLoading(true);
    dispatch(fetchUser(accountType)).then(() => {
      setBtnLoading(false);
      window.location.reload();
    });
  };

  function resendOtp() {
    setOtpLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/resend-phone-otp-auth',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
      data: {
        sessionId: sessionId,
        phone: phone,
      },
    })
      .then(response => {
        setOtpLoading(false);
        setSessionId(response.data.data.sessionId);
        toast.success('Otp resent successfully');
      })
      .catch(() => {
        setOtpLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Invalid session',
          text: 'Your session is invalid or expired. Kindly start from the previous step',
          confirmButtonColor: '#0073E5',
        });
      });
  }

  const back = () => {
    document.getElementById('thirdStep').style.display = 'none';
    document.getElementById('firststep').style.display = 'block';
    document.getElementById('secondStep').style.display = 'none';
  };

  return (
    <CustomModal isVisible={props.showModal} onClose={() => closeModal()}>
      <div className="p-4 py-6" id="firststep">
        <form>
          <h2 className="font-medium text-xl text-center mb-4">Phone Number</h2>
          <label>What is your phone number?</label>
          <div className="mt-4"> </div>
          <PhoneInput
            setPhone={e => setPhone(e)}
            setDisabled={e => setDisabled(e)}
            size={"lg"}
          />

          <p className="text-red-500">{error}</p>
          <div className="my-8"> </div>
          <PrimaryButton
            title={'Verify phone number'}
            disabled={disabled}
            onClick={() => addPhoneNumber()}
            loading={loading}
          />
        </form>
      </div>

      <div id="secondStep" className="hiddenForm my-6">
        <h2 className="text-xl text-center font-bold">Verification Code</h2>

        <p className="px-6 py-4 text-center">
          Kindly enter the verification code sent to your phone number{' '}
          <span className="brandcolor font-semibold">{phone}</span>
        </p>

        <TransactionPin class={'auth'} setPin={e => enterOTP(e)} />

        {countDown !== 0 && (
          <div className="text-center my-5">
            Resend code in <ResendOtp countDown={e => setCountDown(e)} />
          </div>
        )}

        {countDown === 0 && (
          <div className="text-center my-5">
            Didn’t receive a code?{' '}
            <Button
              isLoading={otpLoading}
              onClick={resendOtp}
              ml={'10px'}
              rounded={'full'}
              size="sm"
              color={'#0073E5'}
              variant={'unstyled'}
              fontWeight={'bold'}
            >
              Resend code
            </Button>{' '}
          </div>
        )}

        <div className="flex w-full items-center gap-3">
          <TertiaryButton
            preIcon={ArrowBackIcon}
            title={'Back'}
            onClick={back}
            width={'250px'}
          />

          <PrimaryButton
            postIcon={ArrowForwardIcon}
            title={'Confirm'}
            disabled={disabled}
            onClick={updatePhone}
            loading={loading}
          />
        </div>
      </div>
      <div id="thirdStep" className="hiddenForm">
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h3 className="text-2xl font-semibold max-w-[456px] text-center mt-3">
            Phone Number Updated
          </h3>
          <p className="text-base font-normal max-w-[456px] text-center my-4 sm:mb-4">
            You have successfully updated your phone number.
          </p>
          <PrimaryButton
            onClick={() => showChangeNumber()}
            title={'Done'}
            loading={loading}
          ></PrimaryButton>
        </div>
      </div>
    </CustomModal>
  );
}

export default ChangePhoneModal;
