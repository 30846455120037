import React from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import AccountCenter from '../../../components/Account/Account';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchKYCs } from '../../../store/fetchKyc';

function Account() {
  const user = useSelector(state => state.user.value);
  const history = useNavigate()
  const accountType = window.location.pathname.split('/')[2];
  const dispatch = useDispatch()

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  React.useEffect(()=>{
    dispatch(fetchKYCs())
  },[dispatch])


  //logout user with invalid session
  React.useEffect(() => {
    if (
      sessionStorage.getItem('minieToken') == null || 
      sessionStorage.getItem('userData') == null
    ) {
      console.log(sessionStorage.getItem("minieToken"))
      history('/login/');
    }
  }, [history])

  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.showWidget();
        }
      };
    }, 1000);
  }, []);

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <AccountCenter user={user} />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default Account;
