import React, { useState } from 'react';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Sidebar from '../../components/SideBar/SideBar';
import DashboardRight from '../../components/Dashboard/DashboardRight/DashboardRight';
import { useSelector } from 'react-redux';
import SetPassword from '../../components/SetPassword/SetPassword';

function ChangePassword() {
  const user = useSelector(state => state.user.value);
  const accountType = window.location.pathname.split('/')[2];
  
  const [pin, setPin] = useState('');

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <SetPassword
              user={user}
              id={'setup-pin'}
              title={'Change Password'}
              pin={pin}
              setPin={setPin}
              accountType={accountType}
            />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default ChangePassword;
