import React, { useState } from 'react';
import './EmailVerificationForm.css';
import { Input } from '@chakra-ui/react';
import Steppers from '../Misc/Steppers/Steppers';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import MinieTagForm from '../Misc/Forms/MinieTagForm';
import PasswordInput from '../Misc/Forms/PasswordInput';
import { checkPassword } from '../Misc/Forms/FormChecks/CheckPassword';
import { checkEmail } from '../Misc/Forms/FormChecks/CheckEmail';
import EmailInput from '../Misc/Forms/EmailInput';

function NameForm(props) {
  const [isLoading] = useState(false);
  // const history = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [password, setPassword] = useState('');
  const [minietagError, setMinietagError] = useState(true);
  const [error, setError] = useState('');

  function setDetails() {
    // this function checks if all inputs (firstName, lastName, minietag and password) has been entered, but also has a special ternary condition to check if
    // email was entered properly in situations where the user is signing up with their phone number

    if (
      firstName !== '' &&
      lastName !== '' &&
      !minietagError &&
      checkPassword(password) &&
      (props.phone ? checkEmail(email) : true)
    ) {
      props.setFirstName(firstName);
      props.setLastName(lastName);
      props.setUsername(username);
      props.setPassword(password);
      props.setEmail(email);
      props.nextStep(3);
    } else {
      setError('Please enter all your details correctly');
    }
  }

  const goBack = () => {
    props.nextStep(1);
  };

  React.useEffect(() => {
    setDisabled(false);
  }, []);

  return (
    <div id={props.id} className="emailVerificationCard hiddenForm">
      <div className="relative">
        <Steppers step={props.step} />
      </div>
      <h2 className="mt-7 leading-6">Enter your full details</h2>
      <p>Tell us a little bit more about you.</p>
      <label>First Name</label>
      <Input
        onChange={e => setFirstName(e.target.value)}
        placeholder="Enter your first name"
        size="lg"
      />

      <div className="my-5">
        <label>Last Name</label>
        <Input
          onChange={e => setLastName(e.target.value)}
          placeholder="Enter your last name"
          size="lg"
        />
      </div>

      {props.phone && (
        <div className="my-3">
          <EmailInput
            size={'lg'}
            label={'Email address'}
            placeholder={'Enter your email address'}
            setEmail={e => setEmail(e)}
            setDisabled={(e)=>setDisabled(e)}
          />
        </div>
      )}

      <MinieTagForm
        setUsername={e => setUsername(e)}
        setMinietagError={e => setMinietagError(e)}
      />

      <div className="my-4">
        <PasswordInput
          label={'Password'}
          setPassword={setPassword}
          placeholder={'Enter your password'}
          password={password}
        />
      </div>

      <div className="my-3 text-red-400">{error}</div>

      <PrimaryButton
        loading={isLoading}
        onClick={e => setDetails(e)}
        title={'Continue'}
        disabled={disabled}
      />

      <div className="text-center my-3">
        <button className={'brandcolor font-semibold'} onClick={goBack}>
          Back to previous
        </button>
      </div>
    </div>
  );
}

export default NameForm;
