import React from 'react';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';
import kidsAccount from '../../../Icons/kidsAccount.svg';
import MinieMoneyUser from '../../../Icons/MinieMoneyUser.svg';
import BankAccount from '../../../Icons/BankAccount.svg';
import MinieSavingsAccount from '../../../Icons/MinieSavingsAccount.svg';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { fetchParents } from '../../../store/fetchParents';

function Transfer(props) {
  const dispatch = useDispatch();
  const accountType = window.location.pathname.split('/')[2];
  const [accounts] = React.useState([
    {
      id: 'sendmoneyfamily',
      image: kidsAccount,
      title:
        props.accountType === 'adult'
          ? 'Send to Kid’s Account'
          : "Send to Parent's Account",
      description:
        props.accountType === 'adult'
          ? 'Send money to one of your kids'
          : 'Send money to your parents',
      accountType: accountType,
    },
    {
      id: 'sendmoneyuser',
      image: MinieMoneyUser,
      title: 'Send to MinieMoney User',
      description: 'Send money to a friend on MinieMoney',
      accountType: accountType,
    },
    {
      id: 'sendmoneybank',
      image: BankAccount,
      title: 'Withdraw to Bank Account',
      description: 'Withdraw money to your bank account',
      accountType: 'adult',
    },
    {
      id: 'sendmoneysavings',
      image: MinieSavingsAccount,
      title: 'Send to Minie Savings',
      description: 'Send money to one of your savings',
      accountType: accountType,
    },
  ]);
  const gotoOverview = () => {
    const overview = document.getElementById('overview');
    const transfer = document.getElementById('transfer');
    overview.style.display = 'block';
    transfer.style.display = 'none';
  };

  const sendTo = e => {
    props.setId(e);
    const transfer = document.getElementById('transfer');
    const sendtokid = document.getElementById(e);
    sendtokid.style.display = 'block';
    transfer.style.display = 'none';
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchParents());
  }, [dispatch]);

  return (
    <div
      id={props.id}
      className="dashboardCenter hiddenForm walletScreensContainer"
    >
      <WalletScreenHeader goBack={gotoOverview} title={props.title} />
      <div className="mt-6">
        {accounts.map(
          (account, i) =>
            account.accountType === accountType && (
              <div onClick={() => sendTo(account.id)} key={i}>
                <div className="transferCards">
                  <div className="transferCardDetails">
                    <div className="mr-2">
                      <img src={account.image} alt="kidsAccount" />
                    </div>
                    <div>
                      <h2>{account.title}</h2>
                      <p>{account.description}</p>
                    </div>
                  </div>
                  {account.id !== 'sendmoneysavings' && (
                    <div className="text-right">
                      <ArrowForwardIcon />
                    </div>
                  )}
                  {account.id === 'sendmoneysavings' && (
                    <div>
                      <Button
                        size="xs"
                        color={'#7A58EF'}
                        background={'#F2EEFD'}
                        padding={'10px'}
                        rounded={'full'}
                      >
                        COMING SOON
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default Transfer;
