import React from 'react';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';
import virtualAccount from '../../../Icons/virtualaccount.svg';
import BankDetails from '../../../components/SterlingBank/BankDetails';
import { useNavigate } from 'react-router';

function AccountDetails(props) {
  const accountType = window.location.pathname.split("/")[2]
  const history = useNavigate()

  const goToOVerview = () => {
    history("/wallet/"+accountType)
  };


  return (
    <div id={props.id} className="dashboardCenter walletScreensContainer">
      <WalletScreenHeader goBack={goToOVerview} title="Account Details" />{' '}
      <div className="space-y-4 py-4">
        <div className="flex justify-center">
          <img src={virtualAccount} alt="" />
        </div>
        <p className="text-center virtualAccountDescription">
          Fund your MinieMoney wallet by sending money to the account number
          below.
        </p>

        <div>
            <BankDetails title={"Bank Name"} value={"Sterling Bank"}/>
            <BankDetails title={"Account Name"} value={props.user.accountName}/>
            <BankDetails title={"Account Number"} value={props.user.accountNumber}/>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
