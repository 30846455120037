import React, { useState } from 'react';
import { Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import PasswordInput from '../Misc/Forms/PasswordInput';
function YoungsterPin(props) {
  const [showConfirm, setShowConfirm] = useState(false);
  const handleClickConfirm = () => setShowConfirm(!showConfirm);
  const [miniepin, setMiniePin] = useState('');
  const [confirmMiniepin, setConfirmMiniePin] = useState('');
  const [pinError, setPinError] = useState('');

  const nextStep = () => {
    if (miniepin !== confirmMiniepin) {
      setPinError('Your Password and Confirm Password do not match');
      return;
    }

    if (miniepin.length < 8) {
      setPinError('Your Password needs to be more than 8 characters long');
      return;
    } else {
      props.setPin(miniepin);
      window.scrollTo(0, 0);
      props.nextStep(3);
    }
  };

  return (
    <div id={props.id} className="hiddenForm">
      <h2 className="aboutYoungster">Set your youngster's login details</h2>
      <p>
        Create a Password for your youngster, It is a secured Password he/she will
        use to gain access to thier MinieMoney account and to authorize app
        transactions.
      </p>
      <div className="flexForm mt-6 space-x-2">
        <div>
        <PasswordInput
          label={'Password'}
          setPassword={setMiniePin}
          placeholder={'Enter Password'}
          password={miniepin}
        />
        </div>
        <form className="w-full">
          <label>Confirm Password</label>
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={showConfirm ? 'text' : 'password'}
              placeholder="Confirm Password"
              onChange={e => setConfirmMiniePin(e.target.value)}
            />
            <InputRightElement>
              <Button
                background={'white'}
                h="1.75rem"
                size="sm"
                onClick={handleClickConfirm}
                variant={'unstyled'}
              >
                {showConfirm ? (
                  <FiEye size={'18px'} />
                ) : (
                  <FiEyeOff size={'18px'} />
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
        </form>
      </div>

      <div className="text-red-400">{pinError}</div>

      <div className="my-9">
        <Button
          rounded={'full'}
          background={'#005FEB'}
          variant={'#005FEB'}
          color={'white'}
          size="lg"
          id="nextButton"
          width={'100%'}
          p={9}
          onClick={nextStep}
          _hover={{background:"#0066f5"}}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

export default YoungsterPin;
