import React, { useState, useEffect } from 'react';
import WalletScreenHeader from '../../components/MinieWallet/WalletScreenHeader';
import { AtSignIcon, CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Button,
  InputGroup,
  InputLeftElement,
  Avatar,
  InputRightElement,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import shareLink from '../../Icons/shareMinieLink.svg';
import fundWallet from '../../Icons/fundWallet.svg';
import requestMoney from '../../Icons/requestMoney.svg';
import './RequestMoney.css';
import copy from '../../Icons/copy.svg';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import axios from 'axios';
import slugify from 'slugify';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';
import FundOption from './FundOption';
import FundWalletModal from '../Modal/FundWalletModal';

function RequestMoney(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useNavigate();
  const [btnloading, setBtnLoading] = useState(false);
  const [minieTagOpen, setMinieTagOpen] = useState(false);
  const [minietag, setMinieTag] = useState('');
  const [minieAlert, setMinieAlert] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const gotoOverview = () => {
    history(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      'https://miniemoney.com/@' + props.user.minietag
    );
    toast.success('DashLink Copied to clipboard', {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showMinieTag = () => {
    if (props.user.minietag === '') {
      Swal.fire({
        icon: 'warning',
        title: 'MinieTAG not set!',
        text: 'Kindly navigate to the edit profile page to set your MinieTAG',
        confirmButtonText: 'Set MinieTAG',
      }).then(result => {
        if (result.isConfirmed) {
          setMinieTagOpen(true);
        }
      });
    } else {
      onOpen();
    }
  };

  const showRequestMoney = () => {
    document.getElementById('send-money-request').style.display = 'block';
    document.getElementById('request-money').style.display = 'none';
  };

  const showPaystack = () =>{
    setShowModal(true)
  }

  const shareMinieLink = () => {
    navigator.share({
      title:
        'Send money to ' +
        props.user.firstName +
        ' ' +
        props.user.lastName +
        ' on MinieMoney',
      url: 'https://miniemoney.com/@' + props.user.minietag,
    });
  };

  const MinieTagClose = () => {
    setMinieTagOpen(true);
  };

  function setName() {
    setBtnLoading(true);
    if (usernameError === 'This MinieTAG is available' && minietag !== '') {
      updateProfile(username);
    } else {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL + '/check-minietag',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          minietag: username,
          accountType: 'youngster',
        },
      }).then(response => {
        setError('');
        setMinieAlert(true);
        setBtnLoading(false);
        setUsernameError(response.data.data.message);
      });
    }
  }

  function updateMinieTag(e) {
    setMinieTag(e.target.value);
    const slug = slugify(e.target.value, {
      replacement: '-',
      remove: /[$*_+~.()'"!\-:@]/g,
      lower: true,
    });
    setUsername(slug);
  }

  const updateProfile = e => {
    setBtnLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/edit-profile/youngster',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        minietag: e,
      },
    })
      .then(response => {
        setBtnLoading(false);
        dispatch(fetchUser('youngster'));

        setMinieTagOpen(false);
        Swal.fire({
          icon: 'success',
          title: 'Profile Updated Successfully',
          text: response.data.data.message,
          confirmButtonColor: '#005FEB',
          confirmButtonText: 'Done',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch(error => {
        setBtnLoading(false);
        onClose();
        Swal.fire({
          icon: 'error',
          title: 'Profile not Updated',
          text: error.response.data.data.message,
          confirmButtonColor: '#005FEB',
          confirmButtonText: 'Try again',
        });
      });
  };

  return (
    <div id={props.id} className="dashboardCenter walletScreensContainer">
      <WalletScreenHeader goBack={gotoOverview} title={props.title} />
      <div className="mt-6">
        <p>You can fund your wallet with these options: </p>

        <FundOption
          onClick={showPaystack}
          title={'Fund with Paystack'}
          subtitle={
            'Fund your wallet via Paystack using debit card, bank transfer, or USSD code'
          }
          icon={fundWallet}
          animation={"animate__animated animate__fadeInUp animate__faster"}
        />

        <FundOption
          onClick={showMinieTag}
          title={'Share Your MinieLink®'}
          subtitle={'Receive money directly with your personalized MinieLink'}
          icon={shareLink}
          animation={"animate__animated animate__fadeInUp animate__fast"}
        />

        <FundOption
          onClick={showRequestMoney}
          title={'Send Money Request'}
          subtitle={'Receive money from friends/family on MinieMoney'}
          icon={requestMoney}
          animation={"animate__animated animate__fadeInUp"}
        />
      </div>

      <FundWalletModal
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        user={props.user}
      />

      <Modal
        size={'xl'}
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="my-2 titleHeader">
            Fund your wallet with your MinieLink®.
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex justify-center">
              <div>
                <div className="flex">
                  <Avatar src={props.user.profileImage} />
                  <div className="ml-2">
                    <h2 className="font-bold">
                      {props.user.firstName} {props.user.lastName}
                    </h2>
                    <span>@{props.user.minietag}</span>
                  </div>
                </div>

                <div className="minieLink my-5">
                  <a
                    className="minielink"
                    target="_blank"
                    rel="noreferrer"
                    href={'https://miniemoney.com/@' + props.user.minietag}
                  >
                    miniemoney.com/@{props.user.minietag}{' '}
                  </a>
                  <Button
                    onClick={copyToClipboard}
                    color={'#7A58EF'}
                    rounded={'full'}
                    colorScheme="#F2EEFD"
                    background={'#F2EEFD'}
                  >
                    <img src={copy} alt="copy" className="mr-2" /> Copy
                  </Button>
                </div>
              </div>
            </div>
            <Button
              onClick={() => shareMinieLink()}
              background={'#7A58EF'}
              colorScheme={'#7A58EF'}
              textColor={'white'}
              className="confirmButton"
              rounded={'full'}
              width={'100%'}
            >
              Share my MinieLink®
            </Button>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={'xl'}
        blockScrollOnMount={false}
        isOpen={minieTagOpen}
        onClose={MinieTagClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Your MinieTAG</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <label>Kindly enter your MinieTAG</label>
              <InputGroup>
                <InputLeftElement
                  mt="1"
                  pointerEvents="none"
                  children={<AtSignIcon color="gray.300" />}
                />
                <Input
                  type="text"
                  className={
                    usernameError === 'This MinieTAG is available'
                      ? 'successForm'
                      : 'failureForm'
                  }
                  size="lg"
                  placeholder="Enter your Minietag"
                  onKeyUp={e => updateMinieTag(e)}
                />
                {usernameError === 'This MinieTAG is available' ? (
                  <InputRightElement
                    mt="1"
                    children={<CheckCircleIcon color="green.500" />}
                  />
                ) : (
                  <InputRightElement
                    mt="1"
                    children={<CloseIcon color="red.500" />}
                  />
                )}
              </InputGroup>

              {minieAlert && (
                <div
                  className={
                    usernameError === 'This MinieTAG is available'
                      ? 'text-green-400'
                      : 'text-red-400'
                  }
                >
                  {usernameError}.{' '}
                  {usernameError === 'This MinieTAG is available' &&
                    username !== minietag &&
                    `Your Minietag will be ${username}`}
                </div>
              )}
              <p className="text-red-400">{error}</p>
            </form>
            <div className="mt-7">
              <Button
                rounded={'full'}
                width={'full'}
                background={'#0066f5'}
                colorScheme="#0066f5"
                className="confirmButton mb-7"
                onClick={setName}
                isLoading={btnloading}
              >
                Set MinieTAG
              </Button>
            </div>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default RequestMoney;
