import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Badge, Spinner } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import axios from 'axios';

function Setting(props) {
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];

  const goToLink = e => {
    if (e === 'passport') {
      history('/account/adult/passport');
    }
    if (e === 'id' && (props.user.phone === '' || props.user.gender === '')) {
      Swal.fire({
        icon: 'info',
        title: 'Complete your profile',
        text: 'You need to complete your profile before you can commence Means of Identity verification',
        confirmButtonColor: '#0066f5',
        confirmButtonText: 'Complete profile',
      }).then(result => {
        if (result.isConfirmed) {
          history('/account/' + accountType + '/edit-profile');
        }
      });
      return;
    }
    const overview = document.getElementById('overview');
    const bvn = document.getElementById(e);
    overview.style.display = 'none';
    bvn.style.display = 'block';
  };

  const redoKyc = e => {
    if (e === 'rejected' || e==='invalid') {
      Swal.fire({
        icon: 'info',
        title: 'Would you like to redo your KYC?',
        text: 'Tap the button below to reset the status of this KYC and try again',
        confirmButtonColor: '#0066f5',
        confirmButtonText: 'Redo KYC',
      }).then(result => {
        if (result.isConfirmed) {
          console.log(props.setting.link);
          axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + '/reset-kyc',
            headers: {
              Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
              ContentType: 'application/json',
              Accept: '*/*',
            },
            data: {
              type: 'means-of-id',
            },
          })
            .then(() => {
              const overview = document.getElementById('overview');
              const bvn = document.getElementById("id");
              overview.style.display = 'none';
              bvn.style.display = 'block';
            })
            .catch((error) => {
              Swal.fire({
                icon:"error",
                title:"Something went wrong 🤔",
                text:error.response.data.data.message,
                confirmButtonColor: '#0066f5',
                confirmButtonText: 'Retry',
              })
            });
        }
      });
    }
  };

  return (
    <div
      onClick={() => redoKyc(props.setting.status)}
      className="cursor-pointer transactionList flex py-5 justify-between"
    >
      <div className="flex">
        <div className="ml-3">
          <h2 className="detailTitle mt-1 font-medium ">
            {props.setting.title}
          </h2>
        </div>
      </div>
      <div className="brandcolor">
        {props.loading && <Spinner />}
        {!props.loading && props.setting.status !== null && (
          <Badge
            onClick={() => redoKyc(props.setting.status)}
            className={props.setting.status}
          >
            {props.setting.status}
          </Badge>
        )}
        {!props.loading &&
          (props.setting.status === undefined ||
            props.setting.status === '') && (
            <span onClick={() => goToLink(props.setting.link)}>
              {props.setting.title} <ArrowForwardIcon />
            </span>
          )}
      </div>
    </div>
  );
}

export default Setting;
