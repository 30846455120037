import React from 'react';
import './MinieQuiz.css';
import Notification from '../../Icons/QuizNotification.svg';
import { useNavigate } from 'react-router';
import MinieQuizCard from './MinieQuizCard';
import QuizIcon from '../../Icons/QuizIcon.svg';
import Game from '../../Icons/Game1.svg';
import Finguru from '../../Icons/FinGuru.svg';
import FinExpert from '../../Icons/FinExpert.svg';
import Fin from '../../Icons/Fin.svg';
import FinMaster from '../../Icons/FinMaster.svg';
import FinGreen from '../../Icons/FinGreen.svg';
function MinieQuiz(props) {
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const gotonotifications = () => {
    history('/notifications/' + accountType);
  };

  return (
    <div className="dashboardCenter">
      {/* the user info */}
      <div className="flex justify-between w-full">
        <div className="flex">
          <h2 className="font-bold text-2xl">Minie Quiz</h2>
        </div>
        <div className="relative cursor-pointer" onClick={gotonotifications}>
          {props.user.notifications.filter((notification)=>notification.status ==="recent").length > 0 && (
            <div className="notificationsBadge">
              {props.user.notifications.filter((notification)=>notification.status ==="recent").length}
            </div>
          )}
          <img src={Notification} alt="notification" />
        </div>
      </div>

      <MinieQuizCard />

      <div className="flexCard justify-between">
        <div className="Quiz w-[286px]">
          <div className="flexCard">
            <img src={QuizIcon} alt="quiz card" />
            <div className="ml-4">
              <p>MinieLevel</p>
              <h2>1</h2>
            </div>
          </div>
          <div className="comingSoon">Coming soon</div>
        </div>
        <div className="Quiz w-[286px]">
          <div className="flexCard">
            <img src={QuizIcon} alt="quiz card" />
            <div className="ml-4">
              <p>MiniePoints</p>
              <h2>200</h2>
            </div>
          </div>
          <div className="comingSoon">Coming soon</div>
        </div>
      </div>

      <h2 className="font-bold text-2xl mt-4">Statistics</h2>
      <div className="flexCard justify-between">
        <div className="Quiz w-[286px] ">
          <div className="flexCard ">
            <img src={QuizIcon} alt="quiz card" />
            <div className="ml-4">
              <p>MinieLevel</p>
              <h2>1</h2>
            </div>
          </div>
          <div className="comingSoon">Coming soon</div>
        </div>
        <div className="Quiz w-[286px]">
          <div className="flexCard">
            <img src={QuizIcon} alt="quiz card" />
            <div className="ml-4">
              <p>MiniePoints</p>
              <h2>200</h2>
            </div>
          </div>
          <div className="comingSoon">Coming soon</div>
        </div>
      </div>
      <h2 className="font-bold text-2xl mt-4">Minie Levels</h2>
      <div>
        <div className="kycList mt-8 mb-8">
          <div className="kycType">
            <div>
              <img className="GameIcon" src={Game} alt="user-verification" />
            </div>
            <div className="kycText">
              <h2 className="text-xl font-bold">Fin-guru</h2>
              <p>Score 100% in this test</p>
              <img src={Finguru} alt="user-verification" />
              <p>50 youngsters participated in this test</p>
            </div>
          </div>
          <div className="comingSoon">Coming soon</div>
        </div>
        <div className="kycList mt-8 mb-8">
          <div className="kycType">
            <div>
              <img
                className="GameIcon"
                src={FinExpert}
                alt="user-verification"
              />
            </div>
            <div className="kycText">
              <h2 className="text-xl font-bold">Fin-Xpert</h2>
              <p>Score 100% in this test</p>
              <img src={FinGreen} alt="user-verification" />
              <p>100 youngsters participated in this test</p>
            </div>
          </div>

          <div className="comingSoon">Coming soon</div>
        </div>
        <div className="kycList mt-8 mb-8">
          <div className="kycType">
            <div>
              <img
                className="GameIcon"
                src={FinMaster}
                alt="user-verification"
              />
            </div>
            <div className="kycText">
              <h2 className="text-xl font-bold">Fin-Master</h2>
              <p>Score 100% in this test</p>
              <img src={Fin} alt="user-verification" />
              <p>50 youngsters participated in this test</p>
            </div>
          </div>

          <div className="comingSoon">Coming soon</div>
        </div>
      </div>
    </div>
  );
}

export default MinieQuiz;