import React from 'react'
import "./KYCBanner.css"
import BannerTick from "../../../Icons/banner-tick.svg"
import {Button} from "@chakra-ui/react"
import { useNavigate } from 'react-router'


function KYCBanner() {
  const history = useNavigate()

  const goTokYC = () =>{
    history("/account/adult/kyc")
  }


  return (
    <div data-tut="reactour__kyc" className="bannerBackground">
    <div className="bannerBackground mx-auto flex py-4 px-4 justify-between ">
        <div className="bannerFlex">
        <img src={BannerTick} alt="bannerTick"/>
        <p className="w-full brandColor pl-4 text-md mt-2">Update your KYC - Provide your BVN and Means of ID to fully set up your MinieMoney account</p>
        </div>
        <Button onClick={goTokYC} rounded={"full"} variant={"#005FEB"} background={"#005FEB"} color="white">Update KYC</Button>
    </div>
    </div>
  )
}

export default KYCBanner