import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import Paystack from '../../pages/Adult/MinieWallet/Paystack';
import AmountInput from '../Misc/Forms/AmountInput';
import { toast } from 'react-toastify';
import closeButtonPurple from '../../Icons/closePurpleButton.svg';

function FundWalletModal({ showModal, closeModal, user }) {
  const [amount, setAmount] = useState('');
  const [disabled, setDisabled] = useState(true);

  const setAmountValue = e => {
    setAmount(e);
    if (amount > 0) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (amount > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [amount]);

  const setLoading = () => {
    toast.loading('Confirming payment, please wait...', {
      position: toast.POSITION.TOP_RIGHT,
    });

    setTimeout(() => {
      toast.dismiss();
    }, 3000);
  };

  return (
    <Modal
      isVisible={showModal}
      onClose={() => closeModal()}
      closeButtonGreen={closeButtonPurple}
    >
      <div className="my-6 mx-3">
        <h2 className="my-3 text-xl font-medium">Fund wallet with Paystack</h2>
        <label>Enter amount</label>
        <AmountInput
          focusBorderColor="#7A58EF"
          placeholder={'Enter the amount'}
          value={amount}
          setAmount={e => setAmountValue(e)}
        />

        <div className="mt-8">
          <Paystack
            onOpen={() => closeModal()}
            onClose={() => closeModal()}
            amount={amount}
            user={user}
            disabled={disabled}
            email={user.email}
            setLoading={() => setLoading()}
          />
        </div>
      </div>
    </Modal>
  );
}

export default FundWalletModal;
