import React, { useState } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Swal from 'sweetalert2';
import Steppers from '../Misc/Steppers/Steppers';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import ConfirmOtpModal from '../Modal/ConfirmOtpModal';
import PhoneInput from '../Misc/Forms/PhoneInput';

function PhoneVerificationForm(props) {
  const [phone, setPhone] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [error, setError] = useState('');
  const [payload, setPayload] = useState({});

  const goBack = () => {
    history(-1);
  };

  React.useEffect(() => {
    if (phone.length === 11) {
      setDisabled(false);
      setError('');
    } else if (phone.length < 11 || phone.length > 11) {
      setDisabled(true);
    }
  }, [phone]);

  function verifyPhone() {
    setLoading(true);
    if (phone !== '') {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/verify-phone',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          phone: phone,
          accountType: accountType,
        },
      })
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            setShowModal(true);
            setSessionId(response.data.data.sessionId);
            setPayload({
              phone: phone,
              sessionId: response.data.data.sessionId,
            });
          } else if (response.status === 201) {
            setLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Phone number already in database',
              text: response.data.data.message,
              confirmButtonColor: '#0073E5',
            }).then(result => {
              if (result.isConfirmed) {
                history('/login/');
              }
            });
          }
        })
        .catch(error => {
          setLoading(false);
          let message =
            error.response.data.data.message ===
            'Request failed with status code 503'
              ? 'Our SMS OTP provider is currently unavailable. Please try signing up with email'
              : error.response.data.data.message;

          Swal.fire({
            icon: 'warning',
            title: 'Something\'s wrong 🤔',
            text: message,
            confirmButtonColor: '#0073E5',
            confirmButtonText:" Use Email instead"
          }).then(() => {
            history('/email-signup/' + accountType);
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Please enter your phone number',
        confirmButtonColor: '#0073E5',
      });
    }
  }

  //check if otp is correct
  function checkOtp() {
    if (otp !== '') {
      setLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/check-otp',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          otp: otp,
          sessionId: sessionId,
        },
      })
        .then(response => {
          props.showToast(response.data.data.message);
          props.setProcessId(response.data.data.processId);
          setLoading(false);
          setShowModal(false);
          props.nextStep(2, payload);
          // props.nextStep(3)
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Wrong OTP',
            text: 'The OTP you entered is wrong/invalid',
            confirmButtonColor: '#0073E5',
          });
        });
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Please enter your OTP',
        confirmButtonColor: '#0073E5',
      });
    }
  }

  const showToast = e => {
    props.showToast(e);
  };

  return (
    <div className="emailVerificationCard" id={props.id}>
      <div className="relative">
        <Steppers step={0} />
      </div>

      <h2 className="mt-7">Let's start here</h2>
      <p>Enter your phone number to get a special code.</p>
      <label>Phone Number</label>
      <div>
        <PhoneInput
          size={"lg"}
          setPhone={e => setPhone(e)}
          setDisabled={e => setDisabled(e)}
        />
        <span className="text-red-500">{error}</span>
      </div>

      <div
        onClick={() => history('/email-signup/' + accountType)}
        className="my-3 text-blue-600 cursor-pointer"
      >
        Use Email address instead <ArrowForwardIcon />{' '}
      </div>

      <PrimaryButton
        loading={isLoading}
        onClick={verifyPhone}
        title={'Get Started'}
        disabled={disabled}
      />

      <div className="text-center my-3">
        <button className={'brandcolor font-semibold'} onClick={goBack}>
          Back to previous
        </button>
      </div>

      <ConfirmOtpModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        confirm={() => checkOtp()}
        loading={isLoading}
        setLoading={e => setLoading(e)}
        setPin={e => setOtp(e)}
        disabled={() => setDisabled(false)}
        bg={'#005FEB'}
        pinBg={'#C6DBFB'}
        color={'#005FEB'}
        closeButton={'blue'}
        userDetail={phone}
        sessionId={sessionId}
        setSessionId={e => setSessionId(e)}
        showToast={e => showToast(e)}
        link={'resend-phone-otp'}
        dataType={'phone'}
      />
    </div>
  );
}

export default PhoneVerificationForm;
