import React, { useState } from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import './Earnit.css';
import { useDispatch, useSelector } from 'react-redux';
import EarnItCenter from '../../../components/EarnIt/EarnIt';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import { fetchTasks } from '../../../store/fetchTasks';
import Tasks from './Tasks';
import TaskInformation from './TaskInformation';
import { useNavigate } from 'react-router';

function EarniT() {
  document.title = "EarnIT | MinieMoney"
  const user = useSelector(state => state.user.value);
  const tasks = useSelector(state => state.task.value);
  const loading = useSelector(state => state.task.loading);
  const accountType = window.location.pathname.split('/')[2];
  const history = useNavigate();
  const [rating,setRating] = useState(0)

  const [task, setTask] = useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      };
    }, 500);
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (
      sessionStorage.getItem('minieToken') == null &&
      sessionStorage.getItem('userData') == null
    ) {
      history('/login/');
      return;
    }
    dispatch(fetchTasks());
  }, [history, accountType, user, dispatch]);

  const selectTask = e => {
    setTask(e);
  };

  const setRatingValue = e =>{
    setRating(e)
  }

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <EarnItCenter
              accountType={accountType}
              loading={!loading}
              selectTask={e => selectTask(e)}
              id="earnitOverview"
              user={user}
              tasks={tasks}
            />
            <Tasks id="tasks" selectTask={e => selectTask(e)} tasks={tasks} />
            <TaskInformation
              user={user}
              selectTask={e => selectTask(e)}
              id={'taskinfo'}
              task={task}
              rating={rating}
              setRatingValue= {e => setRatingValue(e)}
            />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <div>
        <BottomMenu />
      </div>
    </div>
  );
}

export default EarniT;
