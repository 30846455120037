import React,{useState} from 'react'
import { Input, Textarea, Button} from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import YoungsterAvatarHeading from '../../Account/YoungsterAvatarHeading'

function TaskDetails(props) {
    const [title,setTitle] = useState("")
    const [reward,setReward] = useState("")
    const [description,setDescription] = useState("")
    const [titleError,setTitleError] = useState("")
    const [rewardError,setRewardError] = useState("")
    


    const gotoDuration = () =>{
        if(title !== "" && reward > 0 && props.youngster !=="" && props.youngster !== undefined){
        const payload = {
            youngster:props.youngster,
            title,
            reward,
            description
        }
        props.gotoDuration(payload)
        const taskdetails = document.getElementById("taskdetails")
        const taskduration = document.getElementById("taskduration")
        const createTask = document.getElementById("createTaskHeading")
        taskdetails.style.display ="none"
        taskduration.style.display = "block"
        createTask.style.display = "none"
        }
        else{
         
           title ===""? setTitleError("Please enter task name"):setTitleError("")
           reward <= 0 ? setRewardError("Please enter reward. Reward cannot be negative or zero"):setRewardError("")
        }
    }
 

  
  return (
    <div id={props.id} className="space-y-4">

      <YoungsterAvatarHeading youngster={props.youngster}/>
       
        <div>
        <Input onChange={(e)=>setTitle(e.target.value)} focusBorderColor='#06AEDA' placeholder="Task Name"></Input>
        <span className="text-sm text-red-600">{titleError}</span>
        </div>
        <div>
        <Input type="number" onChange={(e)=>setReward(e.target.value)} focusBorderColor='#06AEDA' placeholder="Set Reward (₦)"></Input>
        <span className="text-sm text-red-600">{rewardError}</span>
        </div>
        <Textarea onChange={(e)=>setDescription(e.target.value)} focusBorderColor='#06AEDA' placeholder="Write task description (Optional)"/>

        <Button onClick={gotoDuration} className="confirmButton" rounded="full" width="full" backgroundColor={"#06AEDA"} colorScheme={"#06AEDA"}>Next <ArrowForwardIcon className="ml-3"/></Button>
    </div>
  )
}

export default TaskDetails