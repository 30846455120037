import React from 'react';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

function AmountInput(props) {
  const [amount, setAmount] = React.useState("");

 
  const setAmountValue = event => {
    const newValue = event.target.value.replace(/[^\d]/g, '');
    props.setAmount(newValue)
    setAmount(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  };

  const handleKeyPress = event => {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode === 43 ||
      charCode === 45 ||
      charCode === 46 ||
      charCode < 48 ||
      charCode > 57
    ) {
      event.preventDefault();
    }

    if (event.key === ' ' || !/[\d]/.test(event.key)) {
        event.preventDefault();
      }
  };

  return (
    <div className="relative">
        <InputGroup size={"lg"}>
        <InputLeftElement className="prefix">₦</InputLeftElement>
        <Input
          type={'text'}
          inputMode={'numeric'}
          onChange={e => setAmountValue(e)}
          value={amount}
          placeholder={props.placeholder}
          color={props.color}
          focusBorderColor={props.focusBorderColor}
          onKeyPress={handleKeyPress}
          className={'amountInput'}
          onPaste={(e) => e.preventDefault()}
          fontSize={"15px"}
        ></Input>
        </InputGroup>
    </div>
  );
}

export default AmountInput;
