import React from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

function FundOption({ onClick, title, subtitle, icon, animation }) {
  return (
    <div
      onClick={onClick}
      className={
        'cursor-pointer shareLinkButton flex justify-between ' + animation
      }
    >
      <div className="flex">
        <img src={icon} alt="shareLink" />
        <div className="ml-4">
          <h2 className="shareLink">{title}</h2>
          <span className="shareLinkDescription">{subtitle}</span>
        </div>
      </div>
      <div className="mt-3">
        <ArrowForwardIcon />
      </div>
    </div>
  );
}

export default FundOption;
