import React, { useState } from 'react';
import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import { AtSignIcon, CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/react';
import slugify from 'slugify';
import axios from 'axios';
import InfoTip from '../../Dashboard/DashboardCenter/InfoTip';

function MinieTagForm(props) {
  const [isLoading, setLoading] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [username, setUsername] = useState('');
  const [minieAlert, setMinieAlert] = useState(false);
  const specialCharacters = /[@#$%[{\]}^:;.,<>~`?'"!+=&*()_\-\\|/]/;
  const numberCharacters = /^\d|\s$/

  const formatMinietag = (e) =>{
    if (specialCharacters.test(e.key) || numberCharacters.test(e.key)) {
      e.preventDefault();
    }
  }

  const checkMinieTag = (e) =>{
    setLoading(true)
    const slug = slugify(e.target.value, {
      replacement: '-',
      remove: /[$*_+~.()'"!\-:@]/g,
      lower: true,
    })
    setUsername(slug)
    props.setUsername(slug);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/check-minietag',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          minietag: slug,
        },
      }).then(response => {
        props.setMinietagError(response.data.data.error)
        setMinieAlert(true);
        setLoading(false);
        setUsernameError(response.data.data.message);
      })
  }

  return (
    <div>
      <label>
        MinieTAG{' '}
        <InfoTip
          title={'Choose a MinieTAG®'}
          message={
            'Your MinieTAG is your special name on MinieMoney. You need it to receive money on the app so write down something cool.'
          }
        />
      </label>
      <InputGroup>
        <InputLeftElement
          mt="1"
          pointerEvents="none"
          children={<AtSignIcon color="gray.300" />}
        />
        <Input
          type="text"
          className={
            usernameError === 'This MinieTAG is available'
              ? 'successForm'
              : username !== '' ? 'failureForm':''
          }
          size="lg"
          placeholder="Enter your Minietag"
          onKeyUp={checkMinieTag}
          onKeyPress={formatMinietag}
        />
        {isLoading && (
          <InputRightElement>
            <Spinner />
          </InputRightElement>
        )}

        {!isLoading && usernameError === 'This MinieTAG is available' && (
          <InputRightElement
            mt="1"
            children={<CheckCircleIcon color="green.500" />}
          />
        )}

        {!isLoading &&
          username !== '' &&
          usernameError !== 'This MinieTAG is available' && (
            <InputRightElement
              mt="1"
              children={<CloseIcon color="red.500" />}
            />
          )}
      </InputGroup>

      {minieAlert && (
        <div
          className={
            usernameError === 'This MinieTAG is available'
              ? 'text-green-400'
              : 'text-red-400'
          }
        >
          {usernameError === 'This MinieTAG is available' ? usernameError+ `. Your Minietag will be ${username}`: usernameError}
        </div>
      )}
    </div>
  );
}

export default MinieTagForm;
