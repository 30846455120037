import React, { useState } from 'react';
import './CreateYoungster.css';
import YoungersterDetails from './YoungsterDetails';
import YoungsterPin from './YoungsterPin';
import { IconButton, Progress } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import YoungsterAvatar from './YoungsterAvatar';
import { useNavigate } from 'react-router';
import CompleteYoungster from './CompleteYoungster';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { fetchUser } from '../../store/userLogin';
import { useDispatch } from 'react-redux';
import InviteCoparent from "./InviteCoparent"


function CreateYoungster(props) {
  const [loading, setLoading] = useState(false);
  const [isSkipLoading, setSkipLoading] = useState(false);
  const [progress, setProgress] = React.useState(30);
  const [payload, setPayload] = React.useState({});
  const [image, saveImage] = React.useState('');
  const [step,setStep] = React.useState("")
  const [youngsterId,setYoungsterId] = React.useState("")
  const dispatch = useDispatch();

  const createAccount = () => {
    setSkipLoading(true);
    setLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/create-youngster',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email.trim(),
        phone: payload.phone.trim(),
        nickname: payload.nickname,
        profileImage: payload.image,
        dob: payload.dob,
        gender: payload.gender,
        relationship: payload.relationship?payload.relationship:"parent",

      },
    })
      .then(async response => {
        if (response.status === 202) {
          setSkipLoading(false);
          setLoading(false);
          Swal.fire({
            icon: 'info',
            title: 'Wait A Minute...',
            text: response.data.data.message,
            showCancelButton: true,
            cancelButtonText: 'Close',
            confirmButtonColor: '#56c0f2',
            confirmButtonText: 'Request Permission',
          }).then(result => {
            if (result.isConfirmed) {
              const id = toast.loading('Sending request. Please wait...');
              axios({
                method: 'POST',
                url: process.env.REACT_APP_API_URL+'/request-youngster',
                headers: {
                  Authorization:
                    'Bearer ' + sessionStorage.getItem('minieToken'),
                  ContentType: 'application/json',
                },
                data: {
                  email: payload.email,
                  youngsterName: payload.firstName,
                  name: props.user.firstName + ' ' + props.user.lastName,
                  profileImage: props.user.profileImage,
                  youngsterId: response.data.youngster._id,
                  senderEmail: props.user.email,
                  relationship:payload.relationship?payload.relationship:"parent",
                  nickname:payload.nickname
                },
              })
                .then(() => {
                  toast.update(id, {
                    render: 'Request sent successfully!',
                    type: 'success',
                    isLoading: false,
                    autoClose:3000
                  });

                  Swal.fire({
                    icon: 'success',
                    title: 'Request to add youngster sent successfully!',
                    text: 'We would notify you if there are any updates to your request',
                    confirmButtonColor: '#56c0f2',
                  }).then(result => {
                    if (result.isConfirmed) {
                      history('/dashboard/adult');
                    }
                  });
                })
                .catch(error => {
                  Swal.fire({
                    icon:"error",
                    title:"Request Not Sent",
                    text:error.response.data.data.message
                  })
                });
            }

           
          });
        } else {
          setSkipLoading(false);
          setLoading(false);
          dispatch(fetchUser('adult'));
          Swal.fire({
            icon: 'success',
            title: 'Account created successfully',
            text: response.data.data.message,
            confirmButtonColor: '#005FEB',
          }).then(result => {
            setYoungsterId(response.data.data.id)
            if (result.isConfirmed) {
              nextStep(3);
            } else {
              nextStep(3);
            }
          });
        }
      })
      .catch(error => {
        setSkipLoading(false);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: error.error,
          confirmButtonColor: '#005FEB',
        });
      });
  };

  const setImage = e => {
    payload.image = e;
    saveImage(e);
  };

const prevStep = (e) =>{
  window.scrollTo(0, 0);
    document.getElementById('youngsterForm').style.display= e === 1 ? 'block' : 'none';
    // document.getElementById('youngsterPin').style.display= e === 2 ? 'block' : 'none';
    document.getElementById('youngsterAvatar').style.display= e === 2 ? 'block' : 'none';
    document.getElementById('inviteCoparent').style.display = e === 3 ? 'block':'none'
    document.getElementById('completeYoungster').style.display= e === 4 ? 'block' : 'none';
    
}
  const nextStep = e => {
    setStep(e)
    window.scrollTo(0, 0);
    const youngsterDetails = document.getElementById('youngsterForm');
    // const youngsterPin = document.getElementById('youngsterPin');
    const youngsterAvatar = document.getElementById('youngsterAvatar');
    const InviteCoparent = document.getElementById('inviteCoparent')
    const completeYoungster = document.getElementById('completeYoungster');
    

    if (e === 2) {
      setProgress(60);
    }

    if (e === 3) {
      setProgress(100);
    }

    youngsterDetails.style.display = e === 1 ? 'block' : 'none';
    // youngsterPin.style.display = e === 2 ? 'block' : 'none';
    youngsterAvatar.style.display = e === 2 ? 'block' : 'none';
    InviteCoparent.style.display = e === 3 ? 'block':'none'
    completeYoungster.style.display = e === 4 ? 'block' : 'none';
  };

  const setPin = e => {
    payload.password = e;
  };

  const history = useNavigate();

  const goBack = async () =>{
    console.log(step)
    if(step > 1){
      await setStep(step-1)
      prevStep(step-1)
      return
    }
    history(-1)
  }

  return (
    <div className="dashboardCenter">
      <IconButton
        background={'#E6EFFD'}
        color={'#0073E5'}
        rounded={'full'}
        icon={<ArrowBackIcon />}
        onClick={() => goBack()}
      />
      <h2 className="youngsterTitle">Create Youngster’s Account</h2>
      <div className="mt-7 mb-4">
        <Progress value={progress} rounded={'full'} colorScheme="blue" />
        <div className="text-right">{progress}%</div>

        <div>
          <YoungersterDetails
            id={'youngsterForm'}
            nextStep={e => nextStep(e)}
            setPayload={e => setPayload(e)}
            user ={props.user}
          />
          <YoungsterPin
            id={'youngsterPin'}
            nextStep={e => nextStep(e)}
            setPin={e => setPin(e)}
          />
          <YoungsterAvatar
            id={'youngsterAvatar'}
            isSkipLoading={isSkipLoading}
            progress={100}
            createAccount={e => createAccount(e)}
            nextStep={e => nextStep(e)}
            setImage={e => setImage(e)}
            loading={loading}
            email={payload.email}
          />
        </div>
        <InviteCoparent youngsterId={youngsterId} nextStep={e => nextStep(e)} id={'inviteCoparent'} />
        <CompleteYoungster id={'completeYoungster'} image={image} />
        
      </div>
    </div>
  );
}

export default CreateYoungster;
