import React from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import "./MinieQuiz.css"
import { useSelector } from 'react-redux'
import MinieQuiz from '../../../components/MinieQuiz/MinieQuiz';

function Quiz() {
  const user = useSelector((state) => state.user.value)
  const accountType = window.location.pathname.split('/')[2];


  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      };
    }, 1000);
  }, []);

  React.useEffect(()=>{
   window.scrollTo(0,0)
  },[])

  return (
    <div>  
      <div className="dashboardPage">
        
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
        <MinieQuiz user={user} />
        </div>

        <div className="hideOnMobile">
        <DashboardRight accountType={accountType}/>
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default Quiz