import React, { useState } from 'react';
import Modal from './Modal';
import closePurpleButton from '../../Icons/closePurpleButton.svg';
import TransactionPin from '../Misc/TransactionPin';
import { Button } from '@chakra-ui/react';
import ResendOtp from '../Misc/Counter/ResendOtp';
import axios from 'axios';
import Swal from 'sweetalert2';

function ConfirmOtpModal(props) {
  const [disabled, setDisabled] = useState(true);
  const [otpLoading, setOtpLoading] = useState(false);
  const [countDown, setCountDown] = useState('');

  function resendOtp() {
    console.log(props)
    console.log(props.userDetail.replace("0","234"))
    setOtpLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/'+props.link,
      headers: {
        ContentType: 'application/json',
        Accept: '/',
      },
      data: {
        [props.dataType]:props.userDetail,
        sessionId: props.sessionId,
      },
    })
      .then(response => {
        console.log(response)
        setOtpLoading(false);
        props.setSessionId(response.data.data.sessionId);
        props.showToast(response.data.data.message)
      })
      .catch((error) => {
        console.log(error)
        setOtpLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Invalid session',
          text: 'Your session is invalid or expired. Kindly start from the previous step',
          confirmButtonColor: '#0073E5',
        });
      });
  }

  const setPin = e => {
    if (e.length === 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    props.setPin(e);
  };

  return (
    <Modal
      closeButtonGreen={props.closeButton === 'blue' ? '' : closePurpleButton}
      isVisible={props.showModal}
      onClose={() => props.closeModal()}
    >
      <div className="p-6">
        <h2 className="text-2xl font-semibold max-w-[456px] sm:mt-3">
          Check for the code
        </h2>

        <p>
          Enter the code that was sent to{' '}
          <span className="font-semibold brandcolor">{props.userDetail}</span>
        </p>

        <TransactionPin
          bg={props.pinBg}
          color={props.color}
          setPin={e => setPin(e)}
          class={props.class}
        />

        {countDown !== 0 && (
          <div className="text-center">
            Resend code in <ResendOtp countDown={e => setCountDown(e)} />
          </div>
        )}

        {countDown === 0 && (
          <div className="text-center my-3">
            Didn’t receive a code?{' '}
            <Button
              isLoading={otpLoading}
              onClick={resendOtp}
              ml={'10px'}
              rounded={'full'}
              size="sm"
              color={'#0073E5'}
              variant={'unstyled'}
              fontWeight={'bold'}
            >
              Resend code
            </Button>{' '}
          </div>
        )}

        <Button
          onClick={() => props.confirm()}
          background={props.bg}
          color={'white'}
          width={'full'}
          rounded={'full'}
          className="confirmButton my-4"
          disabled={disabled}
          isLoading={props.loading}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmOtpModal;

// props available
// closeModal: to close the Modal
// confirm: to fire the function
// loading: to load the button within the Modal
// setLoading: to set the button within the modal to load
// setPin: to set the value of the otp
// disabled: to set the button within the modal to disabled
// bg: background color of the
// pinBg: to set the background color of the pin input
// color: to set the color of the input
