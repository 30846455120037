import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  id:""
}

export const fetchTransaction = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransaction: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTransaction } = fetchTransaction.actions

export default fetchTransaction.reducer