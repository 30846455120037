import React from 'react';
import { Avatar,Button } from '@chakra-ui/react';
import './EmailVerificationForm.css';
// import { useNavigate } from 'react-router';
// import axios from 'axios';
// import Swal from 'sweetalert2';
import Confetti from 'react-confetti';




function ImageForm(props) {
  const accountType = window.location.pathname.split("/")[2]

    const gotodashboard = () => {
      const userData = JSON.parse(sessionStorage.getItem("userData"))
      userData.profileImage = props.image
      localStorage.setItem("isTour",false)
      sessionStorage.setItem("userData",JSON.stringify(userData))
      window.location.href="/dashboard/"+accountType
    }
 

  return (
    <div id={props.id} className="hiddenForm">
      <Confetti />
     <div className="flex justify-center">
    <div className="relative">
      <Avatar className="avatarSize" cursor={"pointer"} rounded={"full"}  src={props.image}/>
    </div>
      </div>

      <div className="text-center">
      <h2 className="my-3 text-center">Welcome, {props.name}</h2>
      <div>You're all set and ready to start your financial journey.</div>
      </div>

      


    
      <div className="my-6 flex justify-center">
       
        <Button
          rounded={'full'}
          className="journeyButton"
          color={'white'}
          size="lg"
          id="nextButton"
          onClick={gotodashboard}
          loadingText="Checking"
        >
          Let the journey begin
        </Button>
      </div>
    </div>
  );
}

export default ImageForm;
