import React from 'react';
import { PaystackConsumer } from 'react-paystack';



const handleClose = () => {
  alert('Payment not completed')
}



  function Paystack(props) {
    // const [loading,setLoading] = useState(false)



    const config = {
      reference: (new Date()).getTime().toString(),
      email: props.paymentData.email !==''? props.paymentData.email: "hello@miniemoney.com",
      amount: props.paymentData.amount*100*1.014,
      publicKey: 'pk_live_75b2fa1bf9ff039679aea7bc981c032121a4db0a',
  };
      const componentProps = {
          ...config,
          text: 'Paystack Button Implementation',
          onSuccess: (reference) => handleSuccess(reference),
          onClose: handleClose
      };
      


  const handleSuccess = (reference) => {
    props.handlePayment(reference)
  };





  
    return (
        <form>
        <PaystackConsumer {...componentProps} >
          {({initializePayment}) => 
          <div  className="sendMoneyBtn" onClick={() => initializePayment(handleSuccess, handleClose)}><span>Continue to send money</span></div>}
        </PaystackConsumer>
        </form>
    );
  }
  
  export default Paystack;