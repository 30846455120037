import React,{useState} from 'react';
import { PaystackConsumer } from 'react-paystack';
import { Button } from '@chakra-ui/react';
import "./MinieWallet.css"
import { fetchUser } from '../../../store/userLogin';
import {useDispatch} from "react-redux"
import Swal from 'sweetalert2';
// import { useNavigate } from 'react-router';




  function Paystack(props) {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    // const history = useNavigate()


    const handleClose = () => {
      props.onOpen()
      alert('Payment not completed')
    }

    const config = {
      reference: (new Date()).getTime().toString(),
      email: props.email !==''? props.email: "hello@miniemoney.com",
      amount: (props.amount*100*1.015).toFixed(2),
      publicKey: process.env.REACT_APP_PAYSTACK_KEY,
      metadata:{
        custom_fields:[
            {
            "display_name":props.user.minietag,
            "variable_name":props.user.accountType,
            "value":"Bearer "+sessionStorage.getItem("minieToken")
            }
        ]
    },
  };
      const componentProps = {
          ...config,
          text: 'Paystack Button Implementation',
          onSuccess: (reference) => handleSuccess(reference),
          onClose: handleClose
      };


  const handleSuccess = () => {
    props.onClose()
    props.setLoading()
    setTimeout(()=>{
      dispatch(fetchUser(props.user.accountType)).then(()=>{
        Swal.fire({
          icon:"success",
          title:"Wallet funded successfully!",
          text:"Thank you for funding your wallet"
        }).then((result)=>{
          // setLoading(false)
          if(result.isConfirmed){
            window.location.href="/wallet/"+props.user.accountType
          }
        })
      })
      .catch((error)=>{
        console.log(error)
        alert("Something went wrong")
        setLoading(false)
      })
    },2000)
  
  };

  const receivePayment = (initializePayment) =>{
    props.onClose()
    setLoading(true)
    initializePayment(handleSuccess, handleClose)
  }
  
    return (
        <form>
        <PaystackConsumer {...componentProps} >
          {({initializePayment}) => 
          <Button isLoading={loading} disabled={props.disabled} className="confirmButton" background={"#7A58EF"} colorScheme='#7A58EF' rounded={"full"} width="100%"  onClick={() => receivePayment(initializePayment)}><span>Fund Wallet</span></Button>}
        </PaystackConsumer>
        </form>
    ); 
  }
  
  export default Paystack;