export const formatNumber = event => {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode === 43 ||
      charCode === 45 ||
      charCode === 46 ||
      charCode < 48 ||
      charCode > 57
    ) {
      event.preventDefault();
    }

    if (event.target.value.length === 11) {
      event.preventDefault()
    }

    if (event.key === ' ' || !/[\d]/.test(event.key)) {
        event.preventDefault();
      }

    
  };