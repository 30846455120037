import React, { useState } from 'react';
import { Avatar } from '@chakra-ui/react';
import './EmailVerificationForm.css';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';
import { storage } from '../../firebase';
import Steppers from '../Misc/Steppers/Steppers';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import { FiPlus } from 'react-icons/fi';

function ProfileImageForm(props) {
  const [image, setImage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const createAccount = () => {
    props.setImage(image);
    props.createAccount();
  };

  const [avatarArray] = useState([
    {
      id: 1,
      image:
        'https://drive.google.com/uc?export=view&id=1hzjYiFQ2mVC23Nc8p9idHOjW4xZIGGpq',
    },
    {
      id: 2,
      image:
        'https://drive.google.com/uc?export=view&id=1ppG0s5svhO6CiFvWBJuZuUk9HsmWFsdB',
    },
    {
      id: 3,
      image:
        'https://drive.google.com/uc?export=view&id=17k6NlCUGVRYxZe7Nosp8mY6NRiyVYjqb',
    },
    {
      id: 4,
      image:
        'https://drive.google.com/uc?export=view&id=196Siivq5gNCXm-PaDRP9-QWXmKNlg-ys',
    },

    {
      id: 5,
      image:
        'https://drive.google.com/uc?export=view&id=1HZzZ1KgOUyikBwOOqpvIS8Cp3VAM5T4p',
    },
    {
      id: 6,
      image:
        'https://drive.google.com/uc?export=view&id=1-Pz4GVQtI_8WBofcDgLXn7HsVt_kZoev',
    },
    {
      id: 7,
      image:
        'https://drive.google.com/uc?export=view&id=1b3pKhoksVX0YwzYGKkAVHt71AW3V7fNC',
    },
    {
      id: 8,
      image:
        'https://drive.google.com/uc?export=view&id=1okhJUfLZ8wdXSSPp7pfzWwCdf0qs34qF',
    },
    {
      id: 9,
      image:
        'https://drive.google.com/uc?export=view&id=1oYqeIT_WmzuSv-oKRWTmU2-GsGJnHpcD',
    },
    {
      id: 10,
      image:
        'https://drive.google.com/uc?export=view&id=1CVYk45-72MrYoTxNLn8nOzT9mIUmrW8I',
    },
    {
      id: 11,
      image:
        'https://drive.google.com/uc?export=view&id=1AV0V87Y_A19u0uRLBAz9ZG-aZBLuDQZw',
    },
    {
      id: 12,
      image:
        'https://drive.google.com/uc?export=view&id=1lBJTmEPCKDfI4UirbewC4LELSxTyfLgI',
    },
    {
      id: 13,
      image:
        'https://drive.google.com/uc?export=view&id=1PL4SzkF8fLjQ4oJUirzMfJ7V7ty25vWG',
    },
  ]);

  function imageModal() {
    let input = document.getElementById('imageInput');
    input.click();
  }

  function uploadImage(event) {
    uploadFile(event.target.files[0]);
    var input = event.target;
    if (input.files) {
      var reader = new FileReader();
      reader.onload = e => {
        setImage(e.target.result);
      };
      //   setUploadImage(event.target.files);
      reader.readAsDataURL(input.files[0]);
    }
  }

  const uploadFile = image => {
    setDisabled(true);
    const id = toast.loading('Uploading Image. Please wait...');
    // Create the file metadata
    /** @type {any} */
    const storageRef = ref(
      storage,
      props.email === undefined ? props.phone : props.email
    );
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      snapshot => {
        console.log(snapshot);
      },
      error => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          toast.update(id, {
            render: 'Image uploaded successfully',
            type: 'success',
            isLoading: false,
            autoClose: 3000
          });
          props.setImage(downloadURL);
          setDisabled(false);
        });
      }
    );
  };

  function chooseAvatar(image) {
    setImage(image);
    props.setImage(image);
  }

  function saveImage() {
    props.setImage(image);
    props.createAccount(image);
  }

  return (
    <div id={props.id} className="emailVerificationCard hiddenForm">
      <div className="relative">
        <Steppers step={props.step} />
      </div>
      <h2 className="mt-7">Choose a profile picture</h2>
      <p>
      Make your profile beautiful by selecting an avatar or adding your picture.
      </p>

      <div className="flex justify-center">
        <div className="relative">
          <Avatar
            className="avatarSize"
            cursor={'pointer'}
            rounded={'full'}
            onClick={imageModal}
            src={image}
          />

          <div onClick={imageModal} className="uploadImageIcon">
          <FiPlus
            cursor={'pointer'}
            color={'#D1D1D6'}
            boxsize={'40px'}
          />
          </div>
        </div>
      </div>

      <input
        onChange={uploadImage}
        id="imageInput"
        type="file"
        hidden
        accept="image/*"
      />

      <div className="scrollAvatar my-6">
        {avatarArray.map((image, i) => (
          <div key={i} className="pr-3 flexAvatar">
            <Avatar
              size={'xl'}
              src={image.image}
              onClick={() => chooseAvatar(image.image)}
            />
          </div>
        ))}
      </div>

      <PrimaryButton
        loading={props.isLoading}
        onClick={() => saveImage()}
        title={'Get Started'}
        disabled={disabled}
      />

      <div className="text-center my-3">
        <button className={'brandcolor font-semibold'} onClick={createAccount}>
          Not now
        </button>
      </div>
    </div>
  );
}

export default ProfileImageForm;
