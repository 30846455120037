import React from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import { useSelector } from 'react-redux'
import FundWalletCenter from '../../../components/FundWallet/FundWallet';
import { useNavigate } from 'react-router';


function FundWallet() {
  document.title = "Fund Wallet | MinieMoney"
  const user = useSelector((state) => state.user.value)
  const accountType = window.location.pathname.split('/')[2];
  const history = useNavigate()


  React.useEffect(()=>{
   window.scrollTo(0,0)
   document.getElementById('fundwallet').style.display = "block"
  },[])


  React.useEffect(()=>{
    window.scrollTo(0,0)
    if(sessionStorage.getItem("minieToken")==null && sessionStorage.getItem("userData") == null ){
     history("/login/")
    }
   },[history,accountType,user])




  return (
    <div>  
      <div className="dashboardPage">
        
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
        <FundWalletCenter user={user} id={"fundwallet"} title={"Fund Wallet"}/>  
        </div>

        <div className="hideOnMobile">
        <DashboardRight accountType={accountType}/>
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default FundWallet