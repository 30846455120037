import React from 'react'
import WalletIcon from "../../Icons/minieWalletIcon.svg"
import eyeIcon from "../../Icons/eye-black.svg"
import { amountFormat } from '../../utils/amountUtil'


function WalletCard(props) {
  const [showBalance,setShowBalance] = React.useState(localStorage.getItem("showBalance") !==null?JSON.parse(localStorage.getItem("showBalance")):true)
  
  const setShowBalanceValue = (e) =>{
    localStorage.setItem("showBalance",e)
    setShowBalance(e)
  }
  
  return (
    <div className="walletCard">
        <div className="flexCard">
        <img src={WalletIcon} alt=""/>
        <div className="minieSavingsTitle">
        <p className="mt-2">Minie Wallet Balance</p>
        <h2>{amountFormat(props.user.walletBalance,showBalance)}  </h2>
        </div>
        </div>
        <img className="eyeIcon" onClick={()=>setShowBalanceValue(!showBalance)} src={eyeIcon} alt=""/> 
    
    </div>
  )
}

export default WalletCard