import React,{useState} from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import "./CreateTask.css"
import { useSelector } from 'react-redux';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import TaskDetails from "./TaskDetails"
import TaskDuration from './TaskDuration'
import { Progress } from '@chakra-ui/react';
import EarnitHeader from '../../../components/CreateTask/EarnitHeader';
import {useNavigate} from "react-router-dom"
import Swal from 'sweetalert2';




function CreateTask() {
  const user = useSelector(state => state.user.value);
  const [progress,setProgress] = useState(60)
  const [id,setId] = useState("taskdetails")
  const history = useNavigate()
  const [taskDetails,setTaskDetails] = useState("")
  const [loading,setLoading] = useState(false) 
  const accountType = window.location.pathname.split("/")[2]
  const gotoDuration = (e) =>{
    setTaskDetails(e)
  }

  const createTask = (e) => {
    console.log(e)
    console.log(taskDetails)
    setLoading(true)
  }

  const gotoOverview = () =>{
    if(id === "taskdetails"){
        history(-1)
    }
    else{
    setId("taskdetails")
    const taskdetails = document.getElementById("taskdetails")
    const taskduration = document.getElementById("taskduration")
    taskdetails.style.display ="block"
    taskduration.style.display = "none"
    }
    
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if(user.youngsters.length === 0){
      Swal.fire({
        icon:"info",
        title:"EarnIt not yet available",
        text:"You can't use EarnIt yet because you haven't added a youngster to your account.",
        confirmButtonText:"Add Youngster",
        confirmButtonColor:"#06AEDA",
      }).then((result)=>{
        if(result.isConfirmed){
          history("/create-youngster/adult")
        }
      })
    }
  }, [user,history]);

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div className="dashboardCenter">

          <EarnitHeader goBack={gotoOverview} title={"Create Task"} />
          <div className="my-9">
        <Progress colorScheme="cyan" rounded={"full"} value={progress}/>
        <div className="text-right">
            {progress}%
        </div>
        <TaskDetails setId={(e)=>setId(e)}  setProgress={(e)=>setProgress(e)} gotoDuration={(e)=>gotoDuration(e)} user={user} id={"taskdetails"} />
        <TaskDuration loading={loading} taskDetails={taskDetails} createTask={(e)=>createTask(e)} setProgress={(e)=>setProgress(e)} user={user} id={"taskduration"} />
        </div>
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType}/>
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default CreateTask;