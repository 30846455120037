import React from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import EditProfileCenter from '../../../components/EditProfile/EditProfile';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import "./EditProfile.css"
import { useSelector } from 'react-redux'
import {useNavigate} from "react-router"
import Swal from 'sweetalert2';

function EditProfile() {
  document.title = "Profile | MinieMoney"
  const user = useSelector((state) => state.user.value)
  const accountType = window.location.pathname.split('/')[2];
  const history = useNavigate()


  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      };
    }, 700);
  }, []);

  React.useEffect(()=>{
    window.scrollTo(0,0)
    if(sessionStorage.getItem("minieToken")==null && sessionStorage.getItem("userData") == null ){
     history("/login/")
    }
 
    if(accountType === 'youngster' && !user.accountStatus){
     Swal.fire({
       icon:"error",
       title:"Your account is restricted",
       text:"Your parents has restricted your account, hence there is a limit to the transactions you can perform. Kindly ask them to reactivate this feature for you",
       confirmButtonColor:"#0066f5"
     })
    }
   
   },[history,accountType,user])

  return (
    <div>  
      <div className="dashboardPage">
        
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
        <EditProfileCenter user={user} accountType={accountType} />
        </div>

        <div className="hideOnMobile">
        <DashboardRight accountType={accountType}/>
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default EditProfile