import {
  Avatar,
  Circle,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React from 'react';
import back from '../../Icons/backButton.svg';
import Topsavers from '../../Image/Topsavers.png';
import gem1 from '../../Image/GemGreen.svg';
import gem2 from '../../Image/GemBlue.svg';
import gem3 from '../../Image/GemPurple.svg';
import background from '../../Image/Green2.svg';
import background2 from '../../Image/Purple2.svg';
import background1 from '../../Image/Container1.svg';
import participant2 from '../../Image/Avatar2.png';
import { numberth } from '../../utils/numberthUtil';
// import UpTick from './UpTick';
// import DownTick from './DownTick';
// import { useSelector } from 'react-redux';

function TopSavers(props) {
  let rankedParticipants = [...props.participants];
  rankedParticipants = rankedParticipants.sort(
    (a, b) => b.koloBalance - a.koloBalance
  );
  const otherParticipants = [...rankedParticipants.slice(3)];
  const otherDailyParticipants = [
    ...(props.leaderboard && props.leaderboard.daily.slice(3)),
  ];
  const otherWeeklyParticipants = [
    ...(props.leaderboard && props.leaderboard.weekly.slice(3)),
  ];
  const otherMonthlyParticipants = [
    ...(props.leaderboard && props.leaderboard.monthly.slice(3)),
  ];

  // console.log(props.participants)

  //get transactions for daily/total for daily participants

  const Back = () => {
    props.Back();
  };

  return (
    <div id="top-savers" className="dashboardCenter hiddenForm">
      <div onClick={Back} className="cursor-pointer pb-4">
        <img src={back} alt="" />

        <h2 className="walletScreenTitle text-center">Top Savers</h2>
      </div>
      <div className="kycList my-8">
        <div className="flex justify-between items-center">
          <div>
            <img
              className="max-w-[100%]"
              src={Topsavers}
              alt="user-verification"
            />
          </div>

          <div className="kycText">
            <p className="text-sm font-medium pl-4 md:pl-0">
              See the overall top savers in the current KoloSave savings
              challenge
            </p>
          </div>
        </div>
      </div>

      {props.leaderboard && props.leaderboard.monthly.length !== 0 && (
        <Tabs variant="unstyled" colorScheme="blue">
          <TabList background={'#FBFBFB'} padding={'8px'} borderRadius={'8px'}>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#669F2A', bg: '#F0F5EA' }}
              borderRadius={'8px'}
            >
              Overall
            </Tab>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#669F2A', bg: '#F0F5EA' }}
              borderRadius={'8px'}
            >
              Daily
            </Tab>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#669F2A', bg: '#F0F5EA' }}
              borderRadius={'8px'}
            >
              Weekly
            </Tab>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#669F2A', bg: '#F0F5EA' }}
              borderRadius={'8px'}
            >
              Monthly
            </Tab>
          </TabList>
          <TabPanels>
            {/* Overall participants */}
            <TabPanel>
              <div className="sm:hidden flex flex-row items-center gap-2 justify-center my-8">
                <LeaderBoard
                  width="82px"
                  height="182px"
                  img1={
                    props.participants.length >= 2 &&
                    rankedParticipants[1] &&
                    rankedParticipants[1].kolosave.anonymous === false
                      ? rankedParticipants[1].profileImage
                      : participant2
                  }
                  title={
                    props.participants &&
                    rankedParticipants[1] &&
                    rankedParticipants[1].kolosave.anonymous === false
                      ? rankedParticipants[1].firstName +
                        ' ' +
                        rankedParticipants[1].lastName
                      : 'Private Participant'
                  }
                  text={
                    props.participants &&
                    rankedParticipants[1] &&
                    rankedParticipants[1].kolosave.anonymous === false
                      ? '@' + rankedParticipants[1].minietag
                      : 'This participant is private'
                  }
                  textColor={'#107569'}
                  paraColor={'#0E9384'}
                  bgImage={background}
                  img2={gem1}
                  rank="2"
                  circleColor="#0E9384"
                  circleSize={'26px'}
                />
                <LeaderBoard
                  width="102px"
                  height="239px"
                  img1={
                    props.participants &&
                    rankedParticipants[0] &&
                    rankedParticipants[0].kolosave.anonymous === false
                      ? rankedParticipants[0].profileImage
                      : participant2
                  }
                  title={
                    props.participants &&
                    rankedParticipants[0] &&
                    rankedParticipants[0].kolosave.anonymous === false
                      ? rankedParticipants[0].firstName +
                        ' ' +
                        rankedParticipants[0].lastName
                      : 'Private Participant'
                  }
                  text={
                    props.participants &&
                    rankedParticipants[0] &&
                    rankedParticipants[0].kolosave.anonymous === false
                      ? '@' + rankedParticipants[0].minietag
                      : 'This participant is private'
                  }
                  textColor={'#004EC2'}
                  paraColor={'#005FEB'}
                  img2={gem2}
                  rank="1"
                  bgImage={background1}
                  circleColor={'#005FEB'}
                  circleSize={'30px'}
                  className="floating"
                />
                <LeaderBoard
                  width="78px"
                  height="170px"
                  img1={
                    props.participants &&
                    rankedParticipants[2] &&
                    rankedParticipants[2].kolosave.anonymous === false
                      ? rankedParticipants[2].profileImage
                      : participant2
                  }
                  title={
                    props.participants &&
                    rankedParticipants[2] &&
                    rankedParticipants[2].kolosave.anonymous === false
                      ? rankedParticipants[2].firstName +
                        ' ' +
                        rankedParticipants[2].lastName
                      : 'Private Participant'
                  }
                  text={
                    props.participants &&
                    rankedParticipants[2] &&
                    rankedParticipants[2].kolosave.anonymous === false
                      ? '@' + rankedParticipants[2].minietag
                      : 'This participant is private'
                  }
                  textColor={'#6927DA'}
                  paraColor={'#7839EE'}
                  img2={gem3}
                  rank="3"
                  bgImage={background2}
                  circleColor={'#7839EE'}
                  circleSize={'24px'}
                  className="floating"
                />
              </div>
              <div className="hidden sm:flex flex-row items-center justify-between my-8">
                <LeaderBoard
                  width="180px"
                  height="179px"
                  img1={
                    props.participants.length >= 2 &&
                    rankedParticipants[1] &&
                    rankedParticipants[1].kolosave.anonymous === false
                      ? rankedParticipants[1].profileImage
                      : participant2
                  }
                  title={
                    props.participants &&
                    rankedParticipants[1] &&
                    rankedParticipants[1].kolosave.anonymous === false
                      ? rankedParticipants[1].firstName +
                        ' ' +
                        rankedParticipants[1].lastName
                      : 'Private Participant'
                  }
                  text={
                    props.participants &&
                    rankedParticipants[1] &&
                    rankedParticipants[1].kolosave.anonymous === false
                      ? '@' + rankedParticipants[1].minietag
                      : 'This participant is private'
                  }
                  textColor={'#107569'}
                  paraColor={'#0E9384'}
                  bgImage={background}
                  img2={gem1}
                  rank="2"
                  circleColor="#0E9384"
                  circleSize={'26px'}
                />
                <LeaderBoard
                  width="180px"
                  height="199px"
                  img1={
                    props.participants &&
                    rankedParticipants[0] &&
                    rankedParticipants[0].kolosave.anonymous === false
                      ? rankedParticipants[0].profileImage
                      : participant2
                  }
                  title={
                    props.participants &&
                    rankedParticipants[0] &&
                    rankedParticipants[0].kolosave.anonymous === false
                      ? rankedParticipants[0].firstName +
                        ' ' +
                        rankedParticipants[0].lastName
                      : 'Private Participant'
                  }
                  text={
                    props.participants &&
                    rankedParticipants[0] &&
                    rankedParticipants[0].kolosave.anonymous === false
                      ? '@' + rankedParticipants[0].minietag
                      : 'This participant is private'
                  }
                  textColor={'#004EC2'}
                  paraColor={'#005FEB'}
                  img2={gem2}
                  rank="1"
                  bgImage={background1}
                  circleColor={'#005FEB'}
                  circleSize={'30px'}
                  className="floating"
                />
                <LeaderBoard
                  width="180px"
                  height="170px"
                  img1={
                    props.participants &&
                    rankedParticipants[2] &&
                    rankedParticipants[2].kolosave.anonymous === false
                      ? rankedParticipants[2].profileImage
                      : participant2
                  }
                  title={
                    props.participants &&
                    rankedParticipants[2] &&
                    rankedParticipants[2].kolosave.anonymous === false
                      ? rankedParticipants[2].firstName +
                        ' ' +
                        rankedParticipants[2].lastName
                      : 'Private Participant'
                  }
                  text={
                    props.participants &&
                    rankedParticipants[2] &&
                    rankedParticipants[2].kolosave.anonymous === false
                      ? '@' + rankedParticipants[2].minietag
                      : 'This participant is private'
                  }
                  textColor={'#6927DA'}
                  paraColor={'#7839EE'}
                  img2={gem3}
                  rank="3"
                  bgImage={background2}
                  circleColor={'#7839EE'}
                  circleSize={'24px'}
                  className="floating"
                />
              </div>
              <p className="text-center text-[#18181B] font-semibold text-[14px] -mt-3">
                Hurray!{' '}
                <span className="font-bold">
                  {props.participants &&
                  rankedParticipants[0] &&
                  rankedParticipants[0].kolosave.anonymous === false
                    ? rankedParticipants[0].firstName +
                      ' ' +
                      rankedParticipants[0].lastName
                    : 'Private Participant'}
                </span>{' '}
                is our overall top saver. Congratulations 🎉
              </p>
              <Position />
              {/* <UpTick />
            <DownTick /> */}
              {otherParticipants.map((participant, i) => (
                <div
                  key={i}
                  className="cursor-pointer flex transactionList justify-between bg-[#FBFBFB] kycList my-3"
                >
                  <div className="flex">
                    <Avatar
                      size={'md'}
                      src={
                        participant.kolosave.anonymous === false
                          ? participant.profileImage
                          : participant
                      }
                      alt="participant"
                    />
                    <div className="ml-3">
                      <h3 className="mt-1 font-medium">
                        {participant.kolosave.anonymous === false
                          ? participant.firstName + ' ' + participant.lastName
                          : 'Private Participant'}
                      </h3>
                      <p className="text-[#54595E] font-normal text-xs">
                        {participant.kolosave.anonymous === false
                          ? '@' + participant.minietag
                          : 'This participant is private'}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="text-[#18181B] font-bold">{i + 4}{numberth(i+4)}</p>
                  </div>
                </div>
              ))}
            </TabPanel>

            {/* Daily participants */}

            <TabPanel>
              <div className="sm:hidden flex flex-row items-center gap-2 justify-center my-8">
                <LeaderBoard
                  width="82px"
                  height="182px"
                  bgImage={background}
                  img1={
                    props.participants.length >= 2 &&
                    props.leaderboard.daily &&
                    props.leaderboard &&
                    props.leaderboard.daily[1].kolosave.anonymous === false
                      ? props.leaderboard.daily[1].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 2 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[1].kolosave.anonymous === false
                      ? props.leaderboard.daily[1].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 2 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[1].kolosave.anonymous === false
                      ? '@' + props.leaderboard.daily[1].minietag
                      : 'This participant is private'
                  }
                  textColor={'#107569'}
                  paraColor={'#0E9384'}
                  img2={gem1}
                  rank="2"
                  circleColor="#0E9384"
                  circleSize={'26px'}
                />
                <LeaderBoard
                  width="102px"
                  height="239px"
                  bgImage={background1}
                  img1={
                    props.leaderboard &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[0].kolosave.anonymous === false
                      ? props.leaderboard.daily[0].profileImage
                      : participant2
                  }
                  title={
                    props.leaderboard &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[0].kolosave.anonymous === false
                      ? props.leaderboard.daily[0].name
                      : 'Private Participant'
                  }
                  text={
                    props.leaderboard &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[0].kolosave.anonymous === false
                      ? '@' + props.leaderboard.daily[0].minietag
                      : 'This participant is private'
                  }
                  textColor={'#004EC2'}
                  paraColor={'#005FEB'}
                  img2={gem2}
                  rank="1"
                  circleColor={'#005FEB'}
                  circleSize={'30px'}
                  className="floating"
                />
                <LeaderBoard
                  width="78px"
                  height="170px"
                  bgImage={background2}
                  img1={
                    props.participants.length >= 3 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[2].kolosave.anonymous === false
                      ? props.leaderboard.daily[2].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 3 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[2].kolosave.anonymous === false
                      ? props.leaderboard.daily[2].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 3 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[2].kolosave.anonymous === false
                      ? '@' + props.leaderboard.daily[2].minietag
                      : '@anonymous'
                  }
                  textColor={'#6927DA'}
                  paraColor={'#7839EE'}
                  img2={gem3}
                  rank="3"
                  circleColor={'#7839EE'}
                  circleSize={'24px'}
                  className="floating"
                />
              </div>
              <div className="hidden sm:flex flex-row items-center justify-between my-8">
                <LeaderBoard
                  width="180px"
                  height="179px"
                  bgImage={background}
                  img1={
                    props.participants.length >= 2 &&
                    props.leaderboard.daily &&
                    props.leaderboard &&
                    props.leaderboard.daily[1].kolosave.anonymous === false
                      ? props.leaderboard.daily[1].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 2 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[1].kolosave.anonymous === false
                      ? props.leaderboard.daily[1].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 2 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[1].kolosave.anonymous === false
                      ? '@' + props.leaderboard.daily[1].minietag
                      : 'This participant is private'
                  }
                  textColor={'#107569'}
                  paraColor={'#0E9384'}
                  img2={gem1}
                  rank="2"
                  circleColor="#0E9384"
                  circleSize={'26px'}
                />
                <LeaderBoard
                  width="180px"
                  height="199px"
                  bgImage={background1}
                  img1={
                    props.leaderboard &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[0].kolosave.anonymous === false
                      ? props.leaderboard.daily[0].profileImage
                      : participant2
                  }
                  title={
                    props.leaderboard &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[0].kolosave.anonymous === false
                      ? props.leaderboard.daily[0].name
                      : 'Private Participant'
                  }
                  text={
                    props.leaderboard &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[0].kolosave.anonymous === false
                      ? '@' + props.leaderboard.daily[0].minietag
                      : 'This participant is private'
                  }
                  textColor={'#004EC2'}
                  paraColor={'#005FEB'}
                  img2={gem2}
                  rank="1"
                  circleColor={'#005FEB'}
                  circleSize={'30px'}
                  className="floating"
                />
                <LeaderBoard
                  width="180px"
                  height="170px"
                  bgImage={background2}
                  img1={
                    props.participants.length >= 3 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[2].kolosave.anonymous === false
                      ? props.leaderboard.daily[2].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 3 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[2].kolosave.anonymous === false
                      ? props.leaderboard.daily[2].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 3 &&
                    props.leaderboard.daily &&
                    props.leaderboard.daily[2].kolosave.anonymous === false
                      ? '@' + props.leaderboard.daily[2].minietag
                      : '@anonymous'
                  }
                  textColor={'#6927DA'}
                  paraColor={'#7839EE'}
                  img2={gem3}
                  rank="3"
                  circleColor={'#7839EE'}
                  circleSize={'24px'}
                  className="floating"
                />
              </div>
              <p className="text-center text-[#18181B] font-semibold text-[14px] -mt-3">
                Hurray!{' '}
                <span className="font-bold">
                  {props.participants.length >= 2 &&
                  props.leaderboard.daily &&
                  props.leaderboard.daily[0].kolosave.anonymous === false
                    ? props.leaderboard.daily[0].name
                    : 'Private Participant'}
                </span>{' '}
                is our overall top saver. Congratulations 🎉
              </p>
              <Position />
              {props.leaderboard.daily &&
                otherDailyParticipants.map((participant, i) => (
                  <div
                    key={i}
                    className="cursor-pointer transactionList flex justify-between bg-[#FBFBFB] kycList my-3"
                  >
                    <div className="flex">
                      <Avatar
                        size={'md'}
                        src={
                          participant &&
                          participant.kolosave.anonymous === false
                            ? participant.profileImage
                            : participant
                        }
                        alt="participant"
                      />
                      <div className="ml-3">
                        <h3 className="mt-1 font-medium">
                          {participant &&
                          participant.kolosave.anonymous === false
                            ? participant.name
                            : 'Private Participant'}
                        </h3>
                        <p className="text-[#54595E] font-normal text-xs">
                          {participant &&
                          participant.kolosave.anonymous === false
                            ? '@' + participant.minietag
                            : 'This participant is private'}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-[#18181B] font-bold">{i + 4}{numberth(i+4)}</p>
                    </div>
                  </div>
                ))}
            </TabPanel>

            {/* Weekly participants */}

            <TabPanel>
              <div className="sm:hidden flex flex-row items-center gap-2 justify-center my-8">
                <LeaderBoard
                  width="82px"
                  height="182px"
                  bgImage={background}
                  img1={
                    props.participants.length >= 2 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[1].kolosave.anonymous === false
                      ? props.leaderboard.weekly[1].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 2 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[1].kolosave.anonymous === false
                      ? props.leaderboard.weekly[1].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 2 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[1].kolosave.anonymous === false
                      ? '@' + props.leaderboard.weekly[1].minietag
                      : 'This participant is private'
                  }
                  textColor={'#107569'}
                  paraColor={'#0E9384'}
                  img2={gem1}
                  rank="2"
                  circleColor="#0E9384"
                  circleSize={'26px'}
                />
                <LeaderBoard
                  width="102px"
                  height="239px"
                  bgImage={background1}
                  img1={
                    props.leaderboard &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[0].kolosave.anonymous === false
                      ? props.leaderboard.weekly[0].profileImage
                      : participant2
                  }
                  title={
                    props.leaderboard &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[0].kolosave.anonymous === false
                      ? props.leaderboard.weekly[0].name
                      : 'Private Participant'
                  }
                  text={
                    props.leaderboard &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[0].kolosave.anonymous === false
                      ? '@' + props.leaderboard.weekly[0].minietag
                      : 'This participant is private'
                  }
                  textColor={'#004EC2'}
                  paraColor={'#005FEB'}
                  img2={gem2}
                  rank="1"
                  circleColor={'#005FEB'}
                  circleSize={'30px'}
                  className="floating"
                />
                <LeaderBoard
                  width="78px"
                  height="170px"
                  bgImage={background2}
                  img1={
                    props.participants.length >= 3 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[2].kolosave.anonymous === false
                      ? props.leaderboard.weekly[2].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 3 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[2].kolosave.anonymous === false
                      ? props.leaderboard.weekly[2].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 3 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[2].kolosave.anonymous === false
                      ? '@' + props.leaderboard.weekly[2].minietag
                      : 'This participant is private'
                  }
                  textColor={'#6927DA'}
                  paraColor={'#7839EE'}
                  img2={gem3}
                  rank="3"
                  circleColor={'#7839EE'}
                  circleSize={'24px'}
                  className="floating"
                />
              </div>
              <div className="hidden sm:flex flex-row items-center justify-between my-8">
                <LeaderBoard
                  width="180px"
                  height="179px"
                  bgImage={background}
                  img1={
                    props.participants.length >= 2 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[1].kolosave.anonymous === false
                      ? props.leaderboard.weekly[1].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 2 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[1].kolosave.anonymous === false
                      ? props.leaderboard.weekly[1].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 2 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[1].kolosave.anonymous === false
                      ? '@' + props.leaderboard.weekly[1].minietag
                      : 'This participant is private'
                  }
                  textColor={'#107569'}
                  paraColor={'#0E9384'}
                  img2={gem1}
                  rank="2"
                  circleColor="#0E9384"
                  circleSize={'26px'}
                />
                <LeaderBoard
                  width="180px"
                  height="199px"
                  bgImage={background1}
                  img1={
                    props.leaderboard &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[0].kolosave.anonymous === false
                      ? props.leaderboard.weekly[0].profileImage
                      : participant2
                  }
                  title={
                    props.leaderboard &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[0].kolosave.anonymous === false
                      ? props.leaderboard.weekly[0].name
                      : 'Private Participant'
                  }
                  text={
                    props.leaderboard &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[0].kolosave.anonymous === false
                      ? '@' + props.leaderboard.weekly[0].minietag
                      : 'This participant is private'
                  }
                  textColor={'#004EC2'}
                  paraColor={'#005FEB'}
                  img2={gem2}
                  rank="1"
                  circleColor={'#005FEB'}
                  circleSize={'30px'}
                  className="floating"
                />
                <LeaderBoard
                  width="180px"
                  height="170px"
                  bgImage={background2}
                  img1={
                    props.participants.length >= 3 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[2].kolosave.anonymous === false
                      ? props.leaderboard.weekly[2].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 3 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[2].kolosave.anonymous === false
                      ? props.leaderboard.weekly[2].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 3 &&
                    props.leaderboard.weekly &&
                    props.leaderboard.weekly[2].kolosave.anonymous === false
                      ? '@' + props.leaderboard.weekly[2].minietag
                      : 'This participant is private'
                  }
                  textColor={'#6927DA'}
                  paraColor={'#7839EE'}
                  img2={gem3}
                  rank="3"
                  circleColor={'#7839EE'}
                  circleSize={'24px'}
                  className="floating"
                />
              </div>
              <p className="text-center text-[#18181B] font-semibold text-[14px] -mt-3">
                Hurray!{' '}
                <span className="font-bold">
                  {props.participants.length >= 2 &&
                  props.leaderboard.weekly &&
                  props.leaderboard.weekly[0].kolosave.anonymous === false
                    ? props.leaderboard.weekly[0].name
                    : 'Private Participant'}
                </span>{' '}
                is our overall top saver. Congratulations 🎉
              </p>
              <Position />
              {props.leaderboard.weekly &&
                otherWeeklyParticipants.map((participant, i) => (
                  <div
                    key={i}
                    className="cursor-pointer transactionList flex kycList my-3 justify-between"
                  >
                    <div className="flex">
                      <Avatar
                        size={'md'}
                        src={
                          participant &&
                          participant.kolosave.anonymous === false
                            ? participant.profileImage
                            : participant
                        }
                        alt="participant"
                      />
                      <div className="ml-3">
                        <h3 className="mt-1 font-medium">
                          {participant &&
                          participant.kolosave.anonymous === false
                            ? participant.name
                            : 'Private Participant'}
                        </h3>
                        <p className="text-[#54595E] font-normal text-xs">
                          {participant &&
                          participant.kolosave.anonymous === false
                            ? '@' + participant.minietag
                            : 'This participant is private'}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-[#18181B] font-bold">{i + 4}{numberth(i+4)}</p>
                    </div>
                  </div>
                ))}
            </TabPanel>

            {/* Monthly */}

            <TabPanel>
              <div className="sm:hidden flex flex-row items-center gap-2 justify-center my-8">
                <LeaderBoard
                  width="82px"
                  height="182px"
                  bgImage={background}
                  img1={
                    props.participants.length >= 2 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[1].kolosave.anonymous === false
                      ? props.leaderboard.monthly[1].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 2 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[1].kolosave.anonymous === false
                      ? props.leaderboard.monthly[1].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 2 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[1].kolosave.anonymous === false
                      ? '@' + props.leaderboard.monthly[1].minietag
                      : 'This participant is private'
                  }
                  textColor={'#107569'}
                  paraColor={'#0E9384'}
                  img2={gem1}
                  rank="2"
                  circleColor="#0E9384"
                  circleSize={'26px'}
                />
                <LeaderBoard
                  width="102px"
                  height="239px"
                  bgImage={background1}
                  img1={
                    props.leaderboard &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[0].kolosave.anonymous === false
                      ? props.leaderboard.monthly[0].profileImage
                      : participant2
                  }
                  title={
                    props.leaderboard &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[0].kolosave.anonymous === false
                      ? props.leaderboard.monthly[0].name
                      : 'Private Participant'
                  }
                  text={
                    props.leaderboard &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[0].kolosave.anonymous === false
                      ? '@' + props.leaderboard.monthly[0].minietag
                      : 'This participant is private'
                  }
                  textColor={'#004EC2'}
                  paraColor={'#005FEB'}
                  img2={gem2}
                  rank="1"
                  circleColor={'#005FEB'}
                  circleSize={'30px'}
                  className="floating"
                />
                <LeaderBoard
                  width="78px"
                  height="170px"
                  bgImage={background2}
                  img1={
                    props.participants.length >= 3 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[2].kolosave.anonymous === false
                      ? props.leaderboard.monthly[2].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 3 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[2].kolosave.anonymous === false
                      ? props.leaderboard.monthly[2].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 3 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[2].kolosave.anonymous === false
                      ? '@' + props.leaderboard.monthly[2].minietag
                      : 'This participant is private'
                  }
                  textColor={'#6927DA'}
                  paraColor={'#7839EE'}
                  img2={gem3}
                  rank="3"
                  circleColor={'#7839EE'}
                  circleSize={'24px'}
                  className="floating"
                />
              </div>
              <div className="hidden sm:flex flex-row items-center justify-between mt-4 mb-8">
                <LeaderBoard
                  width="180px"
                  height="179px"
                  bgImage={background}
                  img1={
                    props.participants.length >= 2 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[1].kolosave.anonymous === false
                      ? props.leaderboard.monthly[1].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 2 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[1].kolosave.anonymous === false
                      ? props.leaderboard.monthly[1].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 2 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[1].kolosave.anonymous === false
                      ? '@' + props.leaderboard.monthly[1].minietag
                      : 'This participant is private'
                  }
                  textColor={'#107569'}
                  paraColor={'#0E9384'}
                  img2={gem1}
                  rank="2"
                  circleColor="#0E9384"
                  circleSize={'26px'}
                />
                <LeaderBoard
                  width="180px"
                  height="199px"
                  bgImage={background1}
                  img1={
                    props.leaderboard &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[0].kolosave.anonymous === false
                      ? props.leaderboard.monthly[0].profileImage
                      : participant2
                  }
                  title={
                    props.leaderboard &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[0].kolosave.anonymous === false
                      ? props.leaderboard.monthly[0].name
                      : 'Private Participant'
                  }
                  text={
                    props.leaderboard &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[0].kolosave.anonymous === false
                      ? '@' + props.leaderboard.monthly[0].minietag
                      : 'This participant is private'
                  }
                  textColor={'#004EC2'}
                  paraColor={'#005FEB'}
                  img2={gem2}
                  rank="1"
                  circleColor={'#005FEB'}
                  circleSize={'30px'}
                  className="floating"
                />
                <LeaderBoard
                  width="180px"
                  height="170px"
                  bgImage={background2}
                  img1={
                    props.participants.length >= 3 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[2].kolosave.anonymous === false
                      ? props.leaderboard.monthly[2].profileImage
                      : participant2
                  }
                  title={
                    props.participants.length >= 3 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[2].kolosave.anonymous === false
                      ? props.leaderboard.monthly[2].name
                      : 'Private Participant'
                  }
                  text={
                    props.participants.length >= 3 &&
                    props.leaderboard.monthly &&
                    props.leaderboard.monthly[2].kolosave.anonymous === false
                      ? '@' + props.leaderboard.monthly[2].minietag
                      : 'This participant is private'
                  }
                  textColor={'#6927DA'}
                  paraColor={'#7839EE'}
                  img2={gem3}
                  rank="3"
                  circleColor={'#7839EE'}
                  circleSize={'24px'}
                  className="floating"
                />
              </div>
              <p className="text-center text-[#18181B] font-semibold text-[14px] -mt-3">
                Hurray!{' '}
                <span className="font-bold">
                  {props.participants.length >= 2 &&
                  props.leaderboard.monthly &&
                  props.leaderboard.monthly[0].kolosave.anonymous === false
                    ? props.leaderboard.monthly[0].name
                    : 'Private Participant'}
                </span>{' '}
                is our overall top saver. Congratulations 🎉
              </p>
              <Position />
              {props.leaderboard.monthly &&
                otherMonthlyParticipants.map((participant, i) => (
                  <div
                    key={i}
                    className="cursor-pointer transactionList flex kycList my-3 justify-between"
                  >
                    <div className="flex">
                      <Avatar
                        size={'md'}
                        src={
                          participant &&
                          participant.kolosave.anonymous === false
                            ? participant.profileImage
                            : participant
                        }
                        alt="participant"
                      />
                      <div className="ml-3">
                        <h3 className="mt-1 font-medium">
                          {participant &&
                          participant.kolosave.anonymous === false
                            ? participant.name
                            : 'Private Participant'}
                        </h3>
                        <p className="text-[#54595E] font-normal text-xs">
                          {participant &&
                          participant.kolosave.anonymous === false
                            ? '@' + participant.minietag
                            : 'This participant is private'}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-[#18181B] font-bold">{i + 4}{numberth(i+4)}</p>
                    </div>
                  </div>
                ))}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </div>
  );
}

export default TopSavers;

export const LeaderBoard = ({
  width,
  height,
  bgImage,
  img1,
  title,
  text,
  img2,
  rank,
  textColor,
  paraColor,
  circleColor,
  circleSize,
}) => {
  return (
    <div className="floating flex items-center py-3 justify-center">
      <div
        className={`w-[${width}] h-[${height}]
     px-2 bg-[${bgImage}] flex flex-col items-center text-[${textColor}]`}
        style={{
          minWidth:"90px",
          width: width,
          height: height,
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          borderWidth: '1.5px',
          borderColor: textColor,
        }}
      >
        <Avatar
          src={img1}
          alt="leader"
          className="flex items-center -mt-[49px] sm:-mt[50px] justify-center"
          size={'lg'}
        />
        <div className="flex items-center justify-center flex-col py-2">
          <h3
            className={`mt-[12px] font-medium text-[${textColor}] text-center text-[14px] sm:text-[16px]`}
            style={{ color: textColor }}
          >
            {title}
          </h3>
          <p
            className={`text-[${paraColor}] font-normal text-xs text-center `}
            style={{ color: paraColor }}
          >
            {text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center my-2">
          <img src={img2} alt="gem" />
          <Circle
            size={circleSize}
            bg={circleColor}
            color="white"
            className="font-semibold text-xl -mt-4 border-[#fff] border circlePosition"
          >
            {rank}
          </Circle>
        </div>
      </div>
    </div>
  );
};

export const Position = () => {
  return (
    <div
      className="flex flex-row justify-between
 items-center font-semibold text-[16px] sm:text-[20px] text-[#18181B] mt-3"
    >
      <h4>Savers Name</h4>
      <h4>Position</h4>
    </div>
  );
};
