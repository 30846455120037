import React, { useState } from 'react';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Sidebar from '../../components/SideBar/SideBar';
import DashboardRight from '../../components/Dashboard/DashboardRight/DashboardRight';
import { useSelector } from 'react-redux';
import SetUpPin from '../../components/SetPin/SetUpPin';
import ConfirmPin from '../../components/SetPin/ConfirmPin';

function ChangePin() {
  const user = useSelector(state => state.user.value);
  const accountType = window.location.pathname.split('/')[2];

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('setup-pin').style.display = 'block';
    // document.getElementById('comfirm-pin').style.display = 'hidden';
  }, []);
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <SetUpPin
              user={user}
              id={'setup-pin'}
              title={'Change Transaction PIN'}
              pin={pin}
              setPin={setPin}
            />
            <ConfirmPin
              user={user}
              id={'confirm-pin'}
              title={'Confirm Transaction PIN'}
              confirmPin={confirmPin}
              setConfirmPin={setConfirmPin}
              pin={pin}
              bg={'#005FEB'}
              pinBg={'#C6DBFB'}
              color={'#005FEB'}
              class={"auth"}
            />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default ChangePin;
