import React,{useState} from 'react'
import { Input, Textarea, Button,Avatar} from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import {useSelector} from "react-redux"

function TaskDetails(props) {
    const [title,setTitle] = useState("")
    const [reward,setReward] = useState("")
    const [description,setDescription] = useState("")
    const [titleError,setTitleError] = useState("")
    const [rewardError,setRewardError] = useState("")
    const [youngster,setYoungster] = useState("")
    const [youngsterError,setYoungsterError] = useState("")
    const youngsters = useSelector(state => state.user.value.youngsters);


    const gotoDuration = () =>{
        if(title !== "" && reward > 0 && youngster !=="" && youngster !== undefined){
        props.setProgress(100)
        const payload = {
            youngster,
            title,
            reward,
            description
        }
        props.setId("taskduration")
        props.gotoDuration(payload)
        window.scrollTo(0, 0)
      
       
        const taskdetails = document.getElementById("taskdetails")
        const taskduration = document.getElementById("taskduration")
        taskdetails.style.display ="none"
        taskduration.style.display = "block"
        }
        else{
           youngster ===""? setYoungsterError("Please select a youngster"):setYoungsterError("")
           title ===""? setTitleError("Please enter task name"):setTitleError("")
           reward <= 0 ? setRewardError("Please enter reward. Reward cannot be negative or zero"):setRewardError("")
        }

    }

    const selectYoungster = (youngster,i) =>{
        let selectedCard = document.getElementById(i)
        for(let i = 0;i<youngsters.length;i++){
          let selectedCards = document.getElementById(i)
          if(selectedCards.classList.contains("selectedTaskCard")){
            selectedCards.classList.remove("selectedTaskCard")
          }
        }
    
        selectedCard.classList.add("selectedTaskCard")
        setYoungster(youngster)
      }

      React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
 

  
  return (
    <div id={props.id} className="space-y-4 pb-9">
        <div>
        <div className="mb-4">
        <h2 className="text-lg font-medium">Task Details</h2>
        <p>Create a new task for your youngster.</p>
        </div>

        <h2 className="text-md font-medium">Select Youngster</h2>
        <div className="youngsterCards">
        {props.user.youngsters.map((youngster, i) => (
          <div id={i} onClick={()=>selectYoungster(youngster,i)} className="youngsterTaskCard" key={i}>
            <div>
              <Avatar src={youngster.profileImage} alt="youngsterImage" />
            </div>
            <div className="youngsterName">
              <h2>{youngster.firstName} {(parseInt(youngster.firstName.length)+parseInt(youngster.lastName.length)) > 11?youngster.lastName.slice(0,2)+"...":youngster.lastName}</h2>
              <p>{youngster.email}</p>
            </div>
          </div>
        ))}
      </div>
      <p className="text-sm text-red-600">{youngsterError}</p>
      </div>
        <div>
        <Input onChange={(e)=>setTitle(e.target.value)} focusBorderColor='#06AEDA' placeholder="Task Name"></Input>
        <span className="text-sm text-red-600">{titleError}</span>
        </div>
        <div>
        <Input type="number" onChange={(e)=>setReward(e.target.value)} focusBorderColor='#06AEDA' placeholder="Set Reward (₦)"></Input>
        <span className="text-sm text-red-600">{rewardError}</span>
        </div>
        <Textarea onChange={(e)=>setDescription(e.target.value)} focusBorderColor='#06AEDA' placeholder="Write task description (Optional)"/>

        <Button onClick={gotoDuration} className="confirmButton" rounded="full" width="full" backgroundColor={"#06AEDA"} colorScheme={"#06AEDA"}>Next <ArrowForwardIcon className="ml-3"/></Button>
    </div>
  )
}

export default TaskDetails