import React, { Fragment, useState } from 'react';
import eyeIcon from '../../Image/eye1.png';
import eyeIcon2 from '../../Image/eye2.png';
import Modal from '../Modal/Modal';
import kolo from '../../Image/kolo.png';
import modal from '../../Image/not.png';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import moment from 'moment';
// import trend from '../../Image/trend.png';
import closeButtonGreen from "../../Icons/closeButtonGreen.svg"
import { amountFormat, standardAmountFormat } from '../../utils/amountUtil';
function KoloCard(props) {
  const [showModal, setShowModal] = useState(false);
  const [showBalance, setShowBalance] = useState(false)
  const closeModal = () => {
    setShowModal(false);
  };
  const RevealDate = () => {
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('review').style.display = 'block';
    document.getElementById('koloSaveStatement').style.display = 'none';
    closeModal();
  };

  const viewKoloBalance = () =>{
  // get Month and day value of todays
   const today = moment(Date.now()).format("LL").slice(0,-6)
   if (props.revealDates.revealDates.includes(today) || today.includes('December')){
    setShowBalance(!showBalance)
    return
   }
    setShowModal(true)
  }



  return (
    <Fragment>
      <div className="koloCard flex-col">
        <div className="flex justify-between ">
          <div className="flexCard">
            <img src={kolo} alt="" />
            <div className="minieSavingsTitle">
              <p className="mt-2 text-xs sm:text-base font-semibold">
                KoloSave Balance
              </p>
              <h2 className="mt-2 text-sm sm:text-base font-semibold">
                 {amountFormat(props.user.koloBalance,showBalance)}
              </h2>
            </div>
          </div>
          <button
            onClick={viewKoloBalance}
            className="
        flex text-[#669F2A] gap-2 h-[48px] py-[2px] hover:border-[2px] hover:border-[#48711E] px-2 sm:py-2 sm:px-3 w-[158px] sm:w-[178px] justify-center items-center rounded-[24px] bg-white"
          > 
          {!showBalance && 
            <img className="" src={eyeIcon} alt="" />}
            {showBalance && 
            <img className="" src={eyeIcon2} alt="" />}
            <span className="text-xs sm:text-sm font-semibold">
              {showBalance && 'Hide your money'}
              {!showBalance && 'See your money'}
              
            </span>
          </button>
        </div>
        <div className="flex text-[#54595E] font-semibold -mb-6 gap-2 h-[48px] py-[2px] px-2 sm:py-2 sm:px-3 items-center justify-center">
          {/* <img className="" src={trend} alt="" /> */}
          <span className="text-xs sm:text-sm">
          KoloSave interest - 6% P/A
          </span>
          {props.user.koloInterest !== undefined && <span className="text-sm rounded-xl font-bold text-[#48711E] bg-[#DAE8CC] py-1 px-2 text-center">
          
          {standardAmountFormat(props.user.koloInterest)}
          </span>}
        </div>
      </div>
      <Modal closeButtonGreen={closeButtonGreen} isVisible={showModal} onClose={() => closeModal()}>
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={modal}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            Shhh! It's A Secret.
          </h5>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            You are currently not allowed to see your KoloSave balance. Click
            the button below to see the next reveal date.
          </p>
          <button
            onClick={() => RevealDate()}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2 hover:bg-[#48711E]"
          >
            <span className="font-semibold text-base">
              Check balance reveal dates
            </span>
            <ArrowForwardIcon />
          </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default KoloCard;
