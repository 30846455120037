import { toast } from "react-toastify";


export const getBase64FromUrl = async (url) => {
  try{
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data)
        return base64data
      }
    })
  }

  catch(error){
    toast.error("You need to upload a picture of yourself for your badge to show properly")
  }
  }