import React from 'react';
import './Account.css';
import Notification from '../../Icons/notifications.svg';
import { useNavigate } from 'react-router';
import {Avatar} from "@chakra-ui/react"
import Setting from './Setting';
import editProfile from "../../Icons/editProfile.svg"
import kyc from "../../Icons/kyc.svg"
import youngsterAccount from "../../Icons/youngsters.svg"
import allowNotifications from "../../Icons/allowNotifications.svg"
import changePin from "../../Icons/changePin.svg"
// import twoFA from "../../Icons/2FA.svg"
import contactUs from "../../Icons/contactUs.svg"
import termsOfUse from "../../Icons/termsOfUse.svg"
import privacyPolicy from "../../Icons/privacyPolicy.svg"
import website from "../../Icons/visitWebsite.svg"
import community from "../../Icons/community.svg"
import blog from "../../Icons/blog.svg"
import instagram from "../../Icons/instagram.svg"
import twitter from "../../Icons/twitter.svg"
import withdrawal from "../../Icons/withdraw.svg"


function AccountCenter(props) {
  document.title ="Account | MinieMoney"
  const history = useNavigate();
  const gotonotifications = () => {
    history('/notifications/'+accountType);
  };
  const accountType = window.location.pathname.split("/")[2]

  const accountSettings=[
    {title:"Edit Profile",image:editProfile,link:"/account/"+accountType+"/edit-profile",type:accountType},
    {title:"KYC verification",image:kyc,link:"/account/"+accountType+"/kyc", type:"adult"},
    {title:accountType==='adult'?"Youngster Account":"Parent's Account",image:youngsterAccount,link:"/account/"+accountType+"/"+(accountType==='adult'?'youngster':'parents'),type:accountType},
    {title:"Withdrawal Accounts",image:withdrawal,link:"/account/"+accountType+"/withdrawal", type:"adult"},
    {title:"Allow Notifications",image:allowNotifications,link:"",type:accountType},
  ]

  const securitySettings=[
    {title:"Change Password",image:changePin,type:"change-password"},
    {title:"Change Transaction Pin",image:changePin,type:"change-pin"},
    // {title:"Two Factor Authentication",image:twoFA,type:"two-fa"},
  ]

  const aboutSettings=[
    {title:"Contact us",type:"contact-us",image:contactUs,link:"mailto:support@miniemoney.com"},
    {title:"Terms of Use",image:termsOfUse,link:"https://www.miniemoney.com/AMLPolicy"},
    {title:"Privacy Policy",image:privacyPolicy,link:"https://www.miniemoney.com/privacy"},
    {title:"Visit our website",image:website,link:"https://miniemoney.com"},
    {title:"Join our community",image:community,link:"https://t.me/miniemoney"},
    {title:"Read Our Blog",image:blog,link:"https://medium.com/@myminiemoney"},
    {title:"Logout",image:community,link:"logout"},
  ]

  const goToTwitter = () =>{
    window.open("https://twitter.com/myminiemoney","_blank")
  }

  const goToInstagram = () =>{
    window.open("https://www.instagram.com/myminiemoney/","_blank")
  }
  
  return (
    <div className="dashboardCenter">
      {/* page heading */}
      <div className="flex justify-between w-full">
        <div className="flex">
          <h2 className="font-bold text-2xl">Account</h2>
        </div>
        <div className="relative cursor-pointer" onClick={gotonotifications}>
        {props.user && props.user.notifications.filter((notification)=>notification.status ==="recent").length > 0 && <div className="notificationsBadge">{props.user.notifications.filter((notification)=>notification.status ==="recent").length}</div>}
          <img src={Notification} alt="notification" />
        </div>
      </div>

      {/* profileinfo */}
      <div className="my-4 flex justify-center">
        <div className='text-center'>
        <Avatar size={"2xl"} src={props.user && props.user.profileImage} alt=""/>
        <h3 className="font-bold text-lg">{props.user && props.user.firstName} {props.user && props.user.lastName} </h3>
        <p>@{props.user && props.user.minietag}</p>
        </div>
      </div>


     {/* account settings */}
      <div>
        <div className="settingsTitle">Account</div>
        {props.user && accountSettings.map((setting,i)=>(
          setting.type === accountType &&
            <Setting accountType={accountType} user={props.user} setting={setting} key={i}/>
        ))}
      </div>

      {/* security settings */}
      <div>
        <div className="settingsTitle">Security</div>
        {props.user && securitySettings.map((setting,i)=>(
            <Setting accountType={accountType} user={props.user} setting={setting} key={i}/>
        ))}
      </div>


          {/* about settings */}
          <div>
        <div className="settingsTitle">About MinieMoney</div>
        {props.user && aboutSettings.map((setting,i)=>(
            <Setting accountType={accountType} user={props.user} setting={setting} key={i}/>
        ))}
      </div>


      <div className="flex justify-center my-10">
        <img onClick={goToTwitter} className="cursor-pointer mr-4" src={twitter} alt="twitter"/>
        <img onClick={goToInstagram} className="cursor-pointer" src={instagram} alt="instagram" />
      </div>

      
      
    </div>
  );
}

export default AccountCenter;
