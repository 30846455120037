import React from 'react';
import { Button } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

function BankAccount(props) {
  return (
    <div>
      <div>
        <p className="text-start my-3 pr-9">
          Your money will be sent to any of these accounts when you initiate a
          withdrawal. You can add maximum of 3 withdrawal accounts.
        </p>

        {props.banks &&
          props.banks.map((bank, i) => (
            <div key={i}>
              <div className="accountCard">
                <div className="text-start">
                  <p className="text-xs">{bank.bankName}</p>
                  <h2 style={{fontSize:"15px"}}>{bank.accountName}</h2>
                </div>

                <Button
                  color={'#EF4444'}
                  rounded={'full'}
                  background={'#FEF2F2'}
                  onClick={() => props.deleteAccount(bank)}
                >
                  <CloseIcon size={'xs'} className="mr-3" /> Remove
                </Button>
              </div>
              <hr />
            </div>
          ))}

        {props.banks.length < 3 && (
          <div className="my-7">
            <Button
              fontSize={'14px'}
              size={'lg'}
              width={'full'}
              onClick={props.onOpen}
              rounded={'full'}
              background="#0073E5"
              color="white"
              variant="solid"
              className="confirmButton"
            >
              Add bank Account
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default BankAccount;
