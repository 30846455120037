import React from 'react'
import { ArrowBackIcon,AddIcon} from '@chakra-ui/icons';
import {  Button,IconButton } from '@chakra-ui/react'



function ParentHeader(props) {
  return (
        <div>
        <IconButton
          onClick={()=>props.goBack()}
          className="walletAccountArrow"
          rounded={'full'}
          icon={<ArrowBackIcon />}
        />
        <h2 className="walletScreenTitle text-center">
       {props.title}
        </h2>

        {props.title !=='Invite Parent' && <div id="settingsBtn" onClick={()=>props.inviteParents()} className="-mt-8 float-right cursor-pointer">
         
 <div className="mt-2 text-right">
    <Button
      rounded={'full'}
      color="#0073E5"
      variant="link"
    >
      <AddIcon className="mr-2" />Add 
    </Button>
  </div>
          </div>}
   
    </div>
  )
}

export default ParentHeader