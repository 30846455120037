import React from 'react';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import Bank from '../../../Icons/bank.svg';
import Card from '../../../Icons/card.svg';
import Paystack from './Paystack';
import NoKyc from '../../../Icons/noKYC.svg';
import virtualAccount from '../../../Icons/virtualaccount.svg';
import copy from '../../../Icons/copy.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import AmountInput from '../../../components/Misc/Forms/AmountInput';

function BankModal(props) {
  const history = useNavigate();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.user.accountNumber);
    toast.success('Account number copied to clipboard', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // props.onClose()
  };
  return (
    <div>
      {props.user.accountNumber === '' && (
        <div className="text-center">
          <img className="mx-auto" src={NoKyc} alt="" />
          <p className="my-4">
            Nothing to see here, we will display your MinieMoney account number
            when you verify your KYC.
          </p>
          <div className="my-4">
            <Button
              background={'#7A58EF'}
              colorScheme={'#7A58EF'}
              rounded={'full'}
              className="confirmButton"
              width={'full'}
              onClick={() => history('/account/adult/kyc')}
            >
              Verify KYC
            </Button>
          </div>
        </div>
      )}

      {props.user.accountNumber !== '' && (
        <div>
          <img className="mx-auto" src={virtualAccount} alt="" />

          <p className="text-center my-4">
            Send money to the account number below and your MinieMoney wallet
            will be funded immediately.
          </p>
          <hr />
          <div className="my-4 flex justify-between">
            <div>
              <span>{props.user.bank}</span>
              <h2 className="font-bold">
                {props.user.accountNumber} - {props.user.accountName}
              </h2>
            </div>
            <div>
              <Button
                onClick={copyToClipboard}
                color={'#7A58EF'}
                rounded={'full'}
                colorScheme="#F2EEFD"
                background={'#F2EEFD'}
              >
                <img src={copy} alt="copy" className="mr-2" /> Copy
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function CardModal(props) {
  const [amount, setAmount] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);

  const setAmountValue = e => {
    setAmount(e);
    if (amount > 0) {
      setDisabled(false);
    }
  };

  return (
    <div>
      <label>Enter amount</label>
      <AmountInput 
      focusBorderColor='#7A58EF' 
      placeholder={"Enter the amount"}
      setAmount={e => setAmountValue(e)}
      />
      <div className="mt-8">
        <Paystack
          onOpen={() => props.onOpen()}
          onClose={() => props.onClose()}
          setLoading={()=> props.setLoading()}
          amount={amount}
          user={props.user}
          disabled={disabled}
          email={props.user.email}
        />
      </div>
    </div>
  );
}

function FundWallet(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fundingType] = React.useState([
    {
      id: 'bank',
      image: Bank,
      title: 'Bank Transfer',
      description: 'Fee - Free',
    },
    { id: 'card', image: Card, title: 'Debit Card', description: 'Fee - 1.5%' },
  ]);
  const [modalType, setModalType] = React.useState('');

  const gotoOverview = () => {
    const overview = document.getElementById('overview');
    const fundwallet = document.getElementById('fundwallet');
    overview.style.display = 'block';
    fundwallet.style.display = 'none';
  };
  const makePayment = e => {
    setModalType(e);
    onOpen();
  };

  const setLoading = () => {
    toast.loading('Confirming payment, please wait...', {
      position: toast.POSITION.TOP_RIGHT,
    });

    setTimeout(() => {
      toast.dismiss();
    }, 3000);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      id={props.id}
      className="dashboardCenter hiddenForm walletScreensContainer"
    >
      <WalletScreenHeader goBack={gotoOverview} title={props.title} />
      <div className="mt-6">
        {fundingType.map((account, i) => (
          <div onClick={() => makePayment(account.id)} key={i}>
            <div className="transferCards">
              <div className="transferCardDetails">
                <div className="mr-2">
                  <img src={account.image} alt="kidsAccount" />
                </div>
                <div>
                  <h2>{account.title}</h2>
                  <p>{account.description}</p>
                </div>
              </div>
              <div className="text-right">
                <ArrowForwardIcon />
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-medium text-xl">
              {modalType === 'bank' ? 'Bank Transfer' : 'Debit Card'}
            </h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalType === 'bank' ? (
              <BankModal user={props.user} onClose={() => onClose()} />
            ) : (
              <CardModal
                onOpen={() => onOpen()}
                setLoading={() => setLoading()}
                onClose={() => onClose()}
                user={props.user}
              />
            )}
          </ModalBody>

          <ModalFooter
            margin={'0px 0px 20px 0px'}
            className="confirmDetailsFlexButtons"
          ></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default FundWallet;
