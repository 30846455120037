import { Avatar } from '@chakra-ui/react';
import React from 'react';
import './MinieWallet.css';
import { dateFormatter } from '../../utils/dateUtil';
import { useDispatch } from 'react-redux';
import { setTransaction } from '../../store/fetchTransaction';

function TransactionList(props) {
  const dispatch = useDispatch();
  const accountType = window.location.pathname.split('/')[2];

  const showSummary = transaction => {
    window.scrollTo(0,0)
    dispatch(setTransaction(transaction));
    const summary = document.getElementById('transactions-summary');
    const overview = document.getElementById('overview');
    const history = document.getElementById('history');
    overview.style.display = 'none';
    summary.style.display = 'block';
    history.style.display = 'none';
  };

  return (
    <div>
      <div
        className="transactionList flex py-5 justify-between cursor-pointer"
        onClick={() => showSummary(props.transaction)}
      >
        <div className="flex">
          <Avatar src={props.transaction.image} />
          <div className="ml-3">
            <h3 className="font-medium">
              {props.transaction.title && props.transaction.title.length > 20
                ? props.transaction.title.slice(0, 24) + '...'
                : props.transaction.title}
            </h3>
            <span className="text-xs">
              {dateFormatter(props.transaction.date)}
            </span>
          </div>
        </div>
        <div>
          <span
            className={
                props.transaction.type ===
                'allowance' ? accountType : props.transaction.type
            }
          >
            ₦{' '}
            {parseFloat(
              parseFloat(props.transaction.amount).toFixed(2)
            ).toLocaleString('en')}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TransactionList;
