import axios from 'axios'
import React from 'react'
import NavMenu from './NavMenu'
import PaymentComponent from './PaymentComponent'
import "./PaymentLink.css"
import ErrorComponent from "./ErrorComponent"
import ConfirmationPage from './ConfirmationPage'
import Swal from 'sweetalert2'
import { toast } from "react-toastify"


function PaymentLink() {
const [user,setUser] = React.useState({})
const [isUser,setIsUser] = React.useState(true)
const [error,setError] = React.useState("")
const [paymentData,setPaymentData] = React.useState({})
const [loading,setLoading] = React.useState(false)

React.useEffect(()=>{
    axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/payment/"+window.location.pathname.split('/')[1]
    }).then((response)=>{
        setIsUser(true)
        setUser(response.data.data)
    }).catch((error)=>{
        setIsUser(false)
        setError(error.response.data.data)
    })
},[])

const setData = (payload) =>{
    setPaymentData(payload)
}

const handlePayment = (reference) =>{
    setLoading(true)
    const id = toast.loading("Completing transaction, please wait...")
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/payment-link",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json"
      },
      data:{
        amount:parseFloat(paymentData.amount),
        minietag:(window.location.pathname.split('/')[1]).substring(1),
        id:user.id,
        firstName:user.firstName,
        senderName:paymentData.name,
        email:user.email,
        sendertag:paymentData.email,
        reason:paymentData.reason
      }
    }).then((response)=>{
      setLoading(false)
      toast.update(id, {
        render: 'Transaction completed successfully',
        type: 'success',
        isLoading: false,
        autoClose:3000
      });
      Swal.fire({
        icon:"success",
        title:"Wallet funded successfully!",
        text:response.data.data.message,
        confirmButtonColor:"#0066f5"
      }).then(()=>{
        window.location.reload()
      })
    })
    .catch(()=>{
      setLoading(false)
      alert("Something went wrong")
      toast.update(id, {
        render: 'Transaction not completed',
        type: 'error',
        isLoading: false,
        autoClose:3000
      });
     
    })
}

  return (
    <div>
        <NavMenu />
        {isUser && <PaymentComponent loading={loading} setData={(payload)=>setData(payload)} id={"firstpage"} user={user} minietag={window.location.pathname.split('/')[1]}/>}
        <ConfirmationPage handlePayment={(reference)=>handlePayment(reference)} paymentData={paymentData} user={user} id={"secondpage"} minietag={window.location.pathname.split('/')[1]}/>
        {!isUser && <ErrorComponent error={error}/> }
    </div>
  )
}

export default PaymentLink