const SwalError = (message) => {
    return {
        icon:"error",
        title:"Something's wrong 🤔",
        text:message,
        confirmButtonColor:"#0066f5"
      }
}



export default SwalError