export const Home = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5165 2.36713L3.02484 5.86713C2.27484 6.45046 1.6665 7.69213 1.6665 8.63379V14.8088C1.6665 16.7421 3.2415 18.3255 5.17484 18.3255H14.8248C16.7582 18.3255 18.3332 16.7421 18.3332 14.8171V8.75046C18.3332 7.74213 17.6582 6.45046 16.8332 5.87546L11.6832 2.26713C10.5165 1.45046 8.6415 1.49213 7.5165 2.36713Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.9912V12.4912"
      stroke="#0e0e0f"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HomeActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.51666 2.36713L3.025 5.86713C2.275 6.45046 1.66666 7.69213 1.66666 8.63379V14.8088C1.66666 16.7421 3.24166 18.3255 5.175 18.3255H14.825C16.7583 18.3255 18.3333 16.7421 18.3333 14.8171V8.75046C18.3333 7.74213 17.6583 6.45046 16.8333 5.87546L11.6833 2.26713C10.5167 1.45046 8.64166 1.49213 7.51666 2.36713Z"
      stroke="#0073E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.9912V12.4912"
      stroke="#0073E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinieWallet = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8335 9.29199H5.8335"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6665 9.29121V5.44121C1.6665 3.74121 3.04151 2.36621 4.74151 2.36621H9.42484C11.1248 2.36621 12.4998 3.42454 12.4998 5.12454"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5665 10.1667C14.1498 10.5667 13.9498 11.1833 14.1165 11.8166C14.3248 12.5916 15.0915 13.0833 15.8915 13.0833H16.6665V14.2917C16.6665 16.1333 15.1748 17.625 13.3332 17.625H4.99984C3.15817 17.625 1.6665 16.1333 1.6665 14.2917V8.45833C1.6665 6.61667 3.15817 5.125 4.99984 5.125H13.3332C15.1665 5.125 16.6665 6.625 16.6665 8.45833V9.66663H15.7665C15.2998 9.66663 14.8748 9.84999 14.5665 10.1667Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 10.517V12.2337C18.3333 12.7003 17.95 13.0837 17.475 13.0837H15.8667C14.9667 13.0837 14.1417 12.4254 14.0667 11.5254C14.0167 11.0004 14.2167 10.5087 14.5667 10.167C14.875 9.85036 15.3 9.66699 15.7667 9.66699H17.475C17.95 9.66699 18.3333 10.0503 18.3333 10.517Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinieWalletActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8334 9.29199H5.83337"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66663 9.29121V5.44121C1.66663 3.74121 3.04163 2.36621 4.74163 2.36621H9.42496C11.125 2.36621 12.5 3.42454 12.5 5.12454"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5666 10.1667C14.1499 10.5667 13.95 11.1833 14.1166 11.8166C14.325 12.5916 15.0916 13.0833 15.8916 13.0833H16.6666V14.2917C16.6666 16.1333 15.175 17.625 13.3333 17.625H4.99996C3.15829 17.625 1.66663 16.1333 1.66663 14.2917V8.45833C1.66663 6.61667 3.15829 5.125 4.99996 5.125H13.3333C15.1666 5.125 16.6666 6.625 16.6666 8.45833V9.66663H15.7666C15.2999 9.66663 14.8749 9.84999 14.5666 10.1667Z"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 10.517V12.2337C18.3333 12.7003 17.95 13.0837 17.475 13.0837H15.8667C14.9667 13.0837 14.1417 12.4254 14.0667 11.5254C14.0167 11.0004 14.2167 10.5087 14.5667 10.167C14.875 9.85036 15.3 9.66699 15.7667 9.66699H17.475C17.95 9.66699 18.3333 10.0503 18.3333 10.517Z"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EarnIt = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9168 15.8168H6.0835C5.7335 15.8168 5.34183 15.5418 5.22517 15.2085L1.77517 5.55846C1.2835 4.17513 1.8585 3.75013 3.04183 4.60013L6.29183 6.92513C6.8335 7.30013 7.45017 7.10846 7.6835 6.50013L9.15017 2.5918C9.61683 1.3418 10.3918 1.3418 10.8585 2.5918L12.3252 6.50013C12.5585 7.10846 13.1752 7.30013 13.7085 6.92513L16.7585 4.75013C18.0585 3.8168 18.6835 4.2918 18.1502 5.80013L14.7835 15.2251C14.6585 15.5418 14.2668 15.8168 13.9168 15.8168Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.4165 18.333H14.5832"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9165 11.667H12.0832"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EarnItActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9167 15.8168H6.08333C5.73333 15.8168 5.34166 15.5418 5.225 15.2085L1.775 5.55846C1.28333 4.17513 1.85833 3.75013 3.04166 4.60013L6.29166 6.92513C6.83333 7.30013 7.45 7.10846 7.68333 6.50013L9.15 2.5918C9.61666 1.3418 10.3917 1.3418 10.8583 2.5918L12.325 6.50013C12.5583 7.10846 13.175 7.30013 13.7083 6.92513L16.7583 4.75013C18.0583 3.8168 18.6833 4.2918 18.15 5.80013L14.7833 15.2251C14.6583 15.5418 14.2667 15.8168 13.9167 15.8168Z"
      stroke="#06AEDA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41666 18.334H14.5833"
      stroke="#06AEDA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91666 11.666H12.0833"
      stroke="#06AEDA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinieQuiz = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91666 15.0003V5.83366C2.91666 2.50033 3.75 1.66699 7.08333 1.66699H12.9167C16.25 1.66699 17.0833 2.50033 17.0833 5.83366V14.167C17.0833 14.2837 17.0833 14.4003 17.075 14.517"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.29166 12.5H17.0833V15.4167C17.0833 17.025 15.775 18.3333 14.1667 18.3333H5.83333C4.225 18.3333 2.91666 17.025 2.91666 15.4167V14.875C2.91666 13.5667 3.98333 12.5 5.29166 12.5Z"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66666 5.83301H13.3333"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66666 8.75H10.8333"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const MinieQuizActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91797 15.0003V5.83366C2.91797 2.50033 3.7513 1.66699 7.08464 1.66699H12.918C16.2513 1.66699 17.0846 2.50033 17.0846 5.83366V14.167C17.0846 14.2837 17.0846 14.4003 17.0763 14.517"
      stroke="#15B79E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.29297 12.5H17.0846V15.4167C17.0846 17.025 15.7763 18.3333 14.168 18.3333H5.83464C4.2263 18.3333 2.91797 17.025 2.91797 15.4167V14.875C2.91797 13.5667 3.98464 12.5 5.29297 12.5Z"
      stroke="#15B79E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66797 5.83301H13.3346"
      stroke="#15B79E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66797 8.75H10.8346"
      stroke="#15B79E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Savings = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4252 9.26658C17.4252 13.3416 14.4669 17.1582 10.4252 18.2749C10.1502 18.3499 9.85019 18.3499 9.57519 18.2749C5.53352 17.1582 2.5752 13.3416 2.5752 9.26658V5.60824C2.5752 4.92491 3.09187 4.14991 3.73354 3.89158L8.37519 1.9916C9.41685 1.5666 10.5919 1.5666 11.6335 1.9916L16.2752 3.89158C16.9085 4.14991 17.4335 4.92491 17.4335 5.60824L17.4252 9.26658Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0002 10.4163C10.9206 10.4163 11.6668 9.67015 11.6668 8.74967C11.6668 7.8292 10.9206 7.08301 10.0002 7.08301C9.07969 7.08301 8.3335 7.8292 8.3335 8.74967C8.3335 9.67015 9.07969 10.4163 10.0002 10.4163Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.416V12.916"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SavingsActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.425 9.26756C17.425 13.3426 14.4667 17.1592 10.425 18.2759C10.15 18.3509 9.84999 18.3509 9.57499 18.2759C5.53333 17.1592 2.575 13.3426 2.575 9.26756V5.60922C2.575 4.92588 3.09168 4.15088 3.73334 3.89255L8.37499 1.99258C9.41666 1.56758 10.5917 1.56758 11.6333 1.99258L16.275 3.89255C16.9083 4.15088 17.4333 4.92588 17.4333 5.60922L17.425 9.26756Z"
      stroke="#D138F0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.4173C10.9205 10.4173 11.6667 9.67113 11.6667 8.75065C11.6667 7.83018 10.9205 7.08398 10 7.08398C9.07953 7.08398 8.33334 7.83018 8.33334 8.75065C8.33334 9.67113 9.07953 10.4173 10 10.4173Z"
      stroke="#D138F0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.418V12.918"
      stroke="#D138F0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Youngster = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9984 5.96602C14.9484 5.95769 14.8901 5.95769 14.8401 5.96602C13.6901 5.92435 12.7734 4.98268 12.7734 3.81602C12.7734 2.62435 13.7318 1.66602 14.9234 1.66602C16.1151 1.66602 17.0734 2.63268 17.0734 3.81602C17.0651 4.98268 16.1484 5.92435 14.9984 5.96602Z"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1383 12.0328C15.28 12.2245 16.5383 12.0245 17.4216 11.4328C18.5966 10.6495 18.5966 9.36615 17.4216 8.58282C16.53 7.99115 15.2549 7.79115 14.1133 7.99115"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.97344 5.96602C5.02344 5.95769 5.08177 5.95769 5.13177 5.96602C6.28177 5.92435 7.19844 4.98268 7.19844 3.81602C7.19844 2.62435 6.24011 1.66602 5.04844 1.66602C3.85677 1.66602 2.89844 2.63268 2.89844 3.81602C2.90677 4.98268 3.82344 5.92435 4.97344 5.96602Z"
      stroke="#151618"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83255 12.0328C4.69088 12.2245 3.43255 12.0245 2.54922 11.4328C1.37422 10.6495 1.37422 9.36615 2.54922 8.58282C3.44089 7.99115 4.71588 7.79115 5.85755 7.99115"
      stroke="#54595E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0023 12.1926C9.95234 12.1842 9.89401 12.1842 9.84401 12.1926C8.69401 12.1509 7.77734 11.2092 7.77734 10.0426C7.77734 8.85091 8.73568 7.89258 9.92734 7.89258C11.119 7.89258 12.0773 8.85925 12.0773 10.0426C12.069 11.2092 11.1523 12.1592 10.0023 12.1926Z"
      stroke="#54595E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.57656 14.8168C6.40156 15.6001 6.40156 16.8835 7.57656 17.6668C8.9099 18.5585 11.0932 18.5585 12.4266 17.6668C13.6016 16.8835 13.6016 15.6001 12.4266 14.8168C11.1016 13.9335 8.9099 13.9335 7.57656 14.8168Z"
      stroke="#54595E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const YoungsterActive = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6083 6.47435C14.55 6.46602 14.4917 6.46602 14.4333 6.47435C13.1417 6.43268 12.1167 5.37435 12.1167 4.07435C12.1167 2.74935 13.1917 1.66602 14.525 1.66602C15.85 1.66602 16.9333 2.74102 16.9333 4.07435C16.925 5.37435 15.9 6.43268 14.6083 6.47435Z" fill="#005FEB"/>
<path d="M17.325 12.2503C16.3917 12.8753 15.0833 13.1087 13.875 12.9503C14.1917 12.267 14.3583 11.5087 14.3667 10.7087C14.3667 9.87534 14.1833 9.08367 13.8333 8.392C15.0667 8.22534 16.375 8.45867 17.3167 9.08367C18.6333 9.95034 18.6333 11.3753 17.325 12.2503Z" fill="#005FEB"/>
<path d="M5.36665 6.47435C5.42499 6.46602 5.48332 6.46602 5.54165 6.47435C6.83332 6.43268 7.85832 5.37435 7.85832 4.07435C7.85832 2.74102 6.78332 1.66602 5.44999 1.66602C4.12499 1.66602 3.04999 2.74102 3.04999 4.07435C3.04999 5.37435 4.07499 6.43268 5.36665 6.47435Z" fill="#005FEB"/>
<path d="M5.45834 10.7088C5.45834 11.5172 5.63334 12.2838 5.95001 12.9755C4.77501 13.1005 3.55001 12.8505 2.65001 12.2588C1.33334 11.3838 1.33334 9.95883 2.65001 9.08383C3.54167 8.48383 4.80001 8.24216 5.98334 8.37549C5.64167 9.07549 5.45834 9.86716 5.45834 10.7088Z" fill="#005FEB"/>
<path d="M10.1 13.225C10.0333 13.2167 9.95833 13.2167 9.88333 13.225C8.35 13.175 7.125 11.9167 7.125 10.3667C7.13333 8.78333 8.40833 7.5 10 7.5C11.5833 7.5 12.8667 8.78333 12.8667 10.3667C12.8583 11.9167 11.6417 13.175 10.1 13.225Z" fill="#005FEB"/>
<path d="M7.39166 14.9492C6.13332 15.7909 6.13332 17.1742 7.39166 18.0076C8.82499 18.9659 11.175 18.9659 12.6083 18.0076C13.8667 17.1659 13.8667 15.7826 12.6083 14.9492C11.1833 13.9909 8.83332 13.9909 7.39166 14.9492Z" fill="#005FEB"/>
</svg>
  
);

export const Account = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0002 10.0003C12.3013 10.0003 14.1668 8.13485 14.1668 5.83366C14.1668 3.53247 12.3013 1.66699 10.0002 1.66699C7.69898 1.66699 5.8335 3.53247 5.8335 5.83366C5.8335 8.13485 7.69898 10.0003 10.0002 10.0003Z"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1585 18.3333C17.1585 15.1083 13.9501 12.5 10.0001 12.5C6.05013 12.5 2.8418 15.1083 2.8418 18.3333"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AccountActive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 9.99935C12.3012 9.99935 14.1667 8.13387 14.1667 5.83268C14.1667 3.5315 12.3012 1.66602 10 1.66602C7.69882 1.66602 5.83334 3.5315 5.83334 5.83268C5.83334 8.13387 7.69882 9.99935 10 9.99935Z"
      stroke="#0073E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1583 18.3333C17.1583 15.1083 13.95 12.5 10 12.5C6.05 12.5 2.84167 15.1083 2.84167 18.3333"
      stroke="#0073E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Logout = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4165 6.3002C7.67484 3.3002 9.2165 2.0752 12.5915 2.0752H12.6998C16.4248 2.0752 17.9165 3.56686 17.9165 7.29186V12.7252C17.9165 16.4502 16.4248 17.9419 12.6998 17.9419H12.5915C9.2415 17.9419 7.69984 16.7335 7.42484 13.7835"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4999 10H3.0166"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.87516 7.20801L2.0835 9.99967L4.87516 12.7913"
      stroke="#0E0E0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LeftArrow = () => {
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.9752 15.6829C7.81686 15.6829 7.65853 15.6246 7.53353 15.4996L2.4752 10.4413C2.23353 10.1996 2.23353 9.79961 2.4752 9.55794L7.53353 4.49961C7.7752 4.25794 8.1752 4.25794 8.41686 4.49961C8.65853 4.74128 8.65853 5.14128 8.41686 5.38294L3.8002 9.99961L8.41686 14.6163C8.65853 14.8579 8.65853 15.2579 8.41686 15.4996C8.3002 15.6246 8.13353 15.6829 7.9752 15.6829Z"
      fill="#383A3B"
    />
    <path
      d="M17.0831 10.625H3.05811C2.71644 10.625 2.43311 10.3417 2.43311 10C2.43311 9.65833 2.71644 9.375 3.05811 9.375H17.0831C17.4248 9.375 17.7081 9.65833 17.7081 10C17.7081 10.3417 17.4248 10.625 17.0831 10.625Z"
      fill="#383A3B"
    />
  </svg>;
};
export const DiscordAvatar = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.59139 6.71582C6.13539 6.71582 5.77539 7.11582 5.77539 7.60382C5.77539 8.09182 6.14339 8.49182 6.59139 8.49182C7.04739 8.49182 7.40739 8.09182 7.40739 7.60382C7.41539 7.11582 7.04739 6.71582 6.59139 6.71582ZM9.51139 6.71582C9.05539 6.71582 8.69539 7.11582 8.69539 7.60382C8.69539 8.09182 9.06339 8.49182 9.51139 8.49182C9.96739 8.49182 10.3274 8.09182 10.3274 7.60382C10.3274 7.11582 9.96739 6.71582 9.51139 6.71582Z"
      fill="#0073E5"
    />
    <path
      d="M13.4 0.00390625H2.68004C1.77604 0.00390625 1.04004 0.739906 1.04004 1.65191V12.4679C1.04004 13.3799 1.77604 14.1159 2.68004 14.1159H11.752L11.328 12.6359L12.352 13.5879L13.32 14.4839L15.04 16.0039V1.65191C15.04 0.739906 14.304 0.00390625 13.4 0.00390625ZM10.312 10.4519C10.312 10.4519 10.024 10.1079 9.78404 9.80391C10.832 9.50791 11.232 8.85191 11.232 8.85191C10.904 9.06791 10.592 9.21991 10.312 9.32391C9.91204 9.49191 9.52804 9.60391 9.15204 9.66791C8.38404 9.81191 7.68004 9.77191 7.08004 9.65991C6.62404 9.57191 6.23204 9.44391 5.90404 9.31591C5.72004 9.24391 5.52004 9.15591 5.32004 9.04391C5.29604 9.02791 5.27204 9.01991 5.24804 9.00391C5.23204 8.99591 5.22404 8.98791 5.21604 8.97991C5.07204 8.89991 4.99204 8.84391 4.99204 8.84391C4.99204 8.84391 5.37604 9.48391 6.39204 9.78791C6.15204 10.0919 5.85604 10.4519 5.85604 10.4519C4.08804 10.3959 3.41604 9.23591 3.41604 9.23591C3.41604 6.65991 4.56804 4.57191 4.56804 4.57191C5.72004 3.70791 6.81604 3.73191 6.81604 3.73191L6.89604 3.82791C5.45604 4.24391 4.79204 4.87591 4.79204 4.87591C4.79204 4.87591 4.96804 4.77991 5.26404 4.64391C6.12004 4.26791 6.80004 4.16391 7.08004 4.13991C7.12804 4.13191 7.16804 4.12391 7.21604 4.12391C7.70404 4.05991 8.25604 4.04391 8.83204 4.10791C9.59204 4.19591 10.408 4.41991 11.24 4.87591C11.24 4.87591 10.608 4.27591 9.24804 3.85991L9.36004 3.73191C9.36004 3.73191 10.456 3.70791 11.608 4.57191C11.608 4.57191 12.76 6.65991 12.76 9.23591C12.76 9.23591 12.08 10.3959 10.312 10.4519Z"
      fill="#0073E5"
    />
  </svg>
);
export const GoToArrow = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1653 4.6709H7.94533C7.67199 4.6709 7.44533 4.89757 7.44533 5.1709C7.44533 5.44423 7.67199 5.6709 7.94533 5.6709H9.95866L4.81199 10.8176C4.61866 11.0109 4.61866 11.3309 4.81199 11.5242C4.91199 11.6242 5.03866 11.6709 5.16533 11.6709C5.29199 11.6709 5.41866 11.6242 5.51866 11.5242L10.6653 6.37757V8.3909C10.6653 8.66423 10.892 8.8909 11.1653 8.8909C11.4387 8.8909 11.6653 8.66423 11.6653 8.3909V5.1709C11.6653 4.89757 11.4387 4.6709 11.1653 4.6709Z"
      fill="#0073E5"
    />
  </svg>
);

export const StarIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2091 1.89294C12.7728 0.158081 15.2272 0.158082 15.7909 1.89294L17.8049 8.09152C18.057 8.86737 18.78 9.39266 19.5958 9.39266H26.1134C27.9375 9.39266 28.6959 11.7269 27.2202 12.7991L21.9474 16.63C21.2874 17.1095 21.0112 17.9595 21.2633 18.7353L23.2773 24.9339C23.841 26.6688 21.8554 28.1114 20.3796 27.0392L15.1068 23.2083C14.4468 22.7288 13.5532 22.7288 12.8932 23.2083L7.62036 27.0392C6.14459 28.1114 4.15897 26.6688 4.72266 24.9339L6.7367 18.7353C6.98879 17.9595 6.71263 17.1095 6.05265 16.63L0.779825 12.7991C-0.695937 11.7269 0.0625048 9.39266 1.88665 9.39266H8.40421C9.21999 9.39266 9.943 8.86737 10.1951 8.09152L12.2091 1.89294Z"
      fill="#151618"
    />
  </svg>
);

export const Exchange = () => {
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0195 6.96684L14.4995 4.48682L12.0195 2.00684"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 4.4873H14.5"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.97998 9.0332L2.5 11.5132L4.97998 13.9932"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 11.5137H2.5"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>;
};

export const Share = () => {
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.332 9.16634L18.1654 2.33301"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.832 5.66699V1.66699H14.832"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.66797 1.66699H8.0013C3.83464 1.66699 2.16797 3.33366 2.16797 7.50033V12.5003C2.16797 16.667 3.83464 18.3337 8.0013 18.3337H13.0013C17.168 18.3337 18.8346 16.667 18.8346 12.5003V10.8337"
      stroke="#7A58EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>;
};
