import React, { useState } from 'react';
import Logo from '../../../Image/Logo.png';
import { useNavigate } from 'react-router';
import './Login.css';
import AnimatedPage from '../../Animation/AnimatedPage';
import slugify from 'slugify';
import {
  Input,
  Button,
  InputRightElement,
  InputGroup,
  FormControl,
  InputLeftElement,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { AtSignIcon, CloseIcon, CheckCircleIcon } from '@chakra-ui/icons';
import moment from 'moment';

function Invite() {
  document.title = 'Invite | MinieMoney';
  const history = useNavigate();
  const [show, setShow] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [accountType, setAccountType] = useState('adult');
  const [minietag, setMinieTag] = useState('');
  const [minieAlert, setMinieAlert] = useState(false);
  const [pin, setPin] = useState('');
  const [name, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [pinError,setPinError] = useState("")
  const [id,setId] = useState("")


  React.useEffect(() => {
    let id = new URLSearchParams(window.location.search).get('id');
    setId(id)
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL+'/fetch-invite?id=' + id,
      headers: {
        ContentType: 'application/json',
        Accept: '*/*',
      },
    }).then(response => {
      setFullName(response.data.data.name);
      setEmail(response.data.data.email);
      setPhone(response.data.data.phone);
    });
  }, []);

  function updateMinieTag(e) {
    setName(e.target.value);
    setMinieTag(e.target.value);
    const slug = slugify(e.target.value, {
      replacement: '-',
      remove: /[$*_+~.()'"!\-:@]/g,
      lower: true,
    });
    setUsername(slug);
  }

  function setName(e) {
    setLoading(true);
    if (minietag !== '') {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/check-minietag',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          minietag: e.toLowerCase(),
          accountType: 'adult',
        },
      }).then(response => {
        setMinieAlert(true);
        setLoading(false);
        setUsernameError(response.data.data.message);
      });
    }
  }

  const setMiniePin = e => {
    setPin(e);
  };

  const createUser = () =>{
    axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/signup/' + accountType + '?id=none',
        data: {
          firstName: name.split(' ')[0],
          lastName: name.split(' ')[1],
          email: email,
          phone: '',
          nickname: '',
          youngsters: [],
          minietag: username,
          profileImage: '',
          balance: 0,
          walletBalance: 0,
          savingsBalance: 0,
          bvn: '',
          accountType: accountType,
          password: pin,
          notifications: [],
          transactions: [],
          friends: [],
          securityQuestion: '',
          securityAnswer: '',
          dob: '',
          gender: '',
          maritalStatus: '',
          kyc: [],
          accountNumber: '',
          accountName: '',
          bank: '',
          dateCreated: moment(Date.now()).format('LLL'),
          lastLogin: moment(Date.now()).format('LLL'),
          status: 'unverified',
          canTransfer: true,
          canRequest: true,
          accountStatus: true,
        },
      })
        .then(response => {
          sessionStorage.setItem('minieToken', response.data.token);
          sessionStorage.setItem(
            'userData',
            JSON.stringify({
              firstName: name.split(' ')[0],
              lastName: name.split(' ')[1],
              email: email,
              phone: '',
              nickname: '',
              youngsters: [],
              minietag: username,
              profileImage: '',
              balance: 0,
              walletBalance: 0,
              savingsBalance: 0,
              accountType: accountType,
              notifications: [],
              friends: [],
              transactions: [],
              kyc: [],
              dob: '',
              gender: '',
              maritalStatus: '',
              accountNumber: '',
              accountName: '',
              bank: '',
              status: 'unverified',
              canTransfer: true,
              canRequest: true,
              accountStatus: true,
            })
          );
          setLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Account created Successfully',
            text: 'You can now proceed to login to your account',
            confirmButtonColor: '#0073E5',
          }).then(result => {
            if (result) {
              window.location.href = '/dashboard/' + accountType;
            }
          });
        })
        .catch(error => {
          alert(error);
        });
  }

  const checkEmailExist = () =>{
    axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/event-verify-email',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          email: email,
          id:id,
          accountType: accountType,
        },
      })
        .then(response => {
          if (response.status === 200) {
           createUser()
          } else if (response.status === 201) {
            setLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Email already in database',
              text: response.data.data.message,
              confirmButtonColor: '#0073E5',
            }).then(result => {
              if (result.isConfirmed) {
                history('/login/');
              }
            });
          }
        })
  }

  const createAccount = async () => {
    //check if you're not passing any empty content
    if(username ==='' || accountType ==='' || pin === ''){
        return
    }
    
    if(/[a-zA-Z]/.test(pin)){
        setPinError("Your MiniePIN should only contain numbers")
        setLoading(false)
        return
      }
  
      if(pin === "123456" || pin === "654321"){
        setPinError("Your MiniePIN is weak, kindly use a stronger one")
        setLoading(false)
        return
      }
  
      
      if(pin.length < 6 || pin.length > 6){
          setPinError("Your MiniePIN needs to be 6 digits")
          setLoading(false)
          return
      }

    
    if (usernameError === 'This MinieTAG is available') {
     //check if user email exist in database
      console.log(accountType, username, pin);
      setLoading(true);
      await checkEmailExist()
     
    }
  };

  return (
    <div className="bgContainer">
      <AnimatedPage>
        <div>
          <div className="flex justify-center my-4">
            <img
              className="cursor-pointer appLogo"
              onClick={() => history('/')}
              src={Logo}
              alt="miniemoneylogo"
            />
          </div>

          <div className="formWithImage">
            {/* <div className="bg-white showLarge">
            <ImageCarousel id={'imageCarousel'} />
          </div> */}

            <div className="formCard">
              <h3 className="mt-3 inviteTitle">
                Complete Your Registration 🚀
              </h3>
              <span>The only family savings app you'll ever need</span>
              <FormControl className="inviteForm">
                <div>
                  <label>Full Name</label>
                  <Input
                    size="lg"
                    placeholder="Full Name"
                    readOnly={true}
                    defaultValue={name}
                    backgroundColor={'#edf2f7'}
                  />
                </div>
                <div className="mt-3">
                  <label>Email</label>
                  <Input
                    size="lg"
                    placeholder="Email"
                    readOnly={true}
                    defaultValue={email}
                    backgroundColor={'#edf2f7'}
                  />
                </div>

                <div className="mt-3">
                  <label>Phone Number</label>
                  <Input
                    size="lg"
                    placeholder="Full Name"
                    readOnly={true}
                    defaultValue={phone}
                    backgroundColor={'#edf2f7'}
                    inputMode={"numeric"}
                  />
                </div>

                <div className="mt-3">
                  <label>Choose Account type</label>
                  <Select
                    onChange={e => setAccountType(e.target.value)}
                    defaultValue={'adult'}
                  >
                    <option value={'adult'}>Adult</option>
                    <option value={'youngster'}>Youngster</option>
                  </Select>
                </div>

                <div className="mt-3">
                  <label>Choose a MinieTAG®</label>
                  <InputGroup>
                    <InputLeftElement
                      mt="1"
                      pointerEvents="none"
                      children={<AtSignIcon color="gray.300" />}
                    />
                    <Input
                      type="text"
                      className={
                        usernameError === 'This MinieTAG is available'
                          ? 'successForm'
                          : 'failureForm'
                      }
                      size="lg"
                      placeholder="Enter your Minietag"
                      onKeyUp={e => updateMinieTag(e)}
                    />
                    {usernameError === 'This MinieTAG is available' ? (
                      <InputRightElement
                        mt="1"
                        children={<CheckCircleIcon color="green.500" />}
                      />
                    ) : (
                      <InputRightElement
                        mt="1"
                        children={
                          usernameError !== '' && <CloseIcon color="red.500" />
                        }
                      />
                    )}
                  </InputGroup>

                  {minieAlert && (
                    <div
                      className={
                        usernameError === 'This MinieTAG is available'
                          ? 'text-green-400'
                          : 'text-red-400'
                      }
                    >
                      {usernameError}.{' '}
                      {usernameError === 'This MinieTAG is available' &&
                        username !== minietag &&
                        `Your Minietag will be ${username}`}
                    </div>
                  )}
                </div>

                <div className="my-4">
                  <label>MiniePIN</label>

                  <InputGroup size="lg">
                    <Input
                      type={show ? 'text' : 'password'}
                      placeholder="Enter password"
                      onChange={e => setMiniePin(e.target.value)}
                      onSubmit={createAccount}
                    />

                    <InputRightElement>
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={handleClick}
                        variant={'unstyled'}
                      >
                        {show ? (
                          <FiEyeOff size={'20px'} />
                        ) : (
                          <FiEye size={'20px'} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <span className="text-red-400">{pinError}</span>
                </div>

               

                <div className="my-5">
                  <Button
                    rounded={'full'}
                    size="lg"
                    onClick={e => createAccount(e)}
                    textColor={'white'}
                    backgroundColor="#0073E5"
                    colorScheme={'#0073E5'}
                    width={'100%'}
                    className="loginButton"
                    isLoading={loading}
                  >
                    Create Account
                  </Button>
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      </AnimatedPage>

      <div>
        <ul className="conditionsList">
          <li>Contact</li>
          <li className="conditions">Terms and Conditions</li>
          <li className="conditions">Privacy Policy</li>
        </ul>
      </div>

      <div className="copyrightText text-center my-3">
        <p>&copy; 2022 MinieMoney</p>
      </div>
    </div>
  );
}

export default Invite;
