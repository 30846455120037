import React from 'react'
import friends from '../../Icons/friends.svg';
import {
    Button,
  } from '@chakra-ui/react'
import { useNavigate } from 'react-router';




function EarnItCard(props) {
    const history = useNavigate()
  return (
    <div className="notificationsCard">
    <div>
      <div className="my-4 flex justify-center">
        <img src={friends} alt="notificationBell" />
      </div>
      <p>{props.details}</p>
      <Button
        className="addFriendsButton"
        rounded={'full'}
        background="#06AEDA"
        color="white"
        colorScheme={"#06AEDA"}
        onClick={()=>history(props.link)}
      >
       {props.buttonText}
      </Button>
    </div>

  </div>
  )
}

export default EarnItCard