import React,{useState} from 'react'
import TaskDetails from "./TaskDetails"
import TaskDuration from './TaskDuration'
import EditProfileHeader from "../../EditProfile/EditProfileHeader"
import {useNavigate} from "react-router"

function CreateTask(props) {
  const [taskDetails,setTaskDetails] = useState("")
  const [loading,setLoading] = useState(false) 
  const youngster = JSON.parse(sessionStorage.getItem("youngster"))
  const history = useNavigate()

  const gotoDuration = (e) =>{
    setTaskDetails(e)
  }

  const createTask = (e) => {
    setLoading(true)
  }

  const goBack = () =>{
    history(-1)
  }


  return (
    <div className="dashboardCenter walletScreensContainer">
      <EditProfileHeader id={"createTaskHeading"} title={"Assign task to "+youngster.firstName} goBack={goBack} />
        <TaskDetails youngster={youngster} gotoDuration={(e)=>gotoDuration(e)} user={props.user} id={"taskdetails"} />
        <TaskDuration youngster={youngster} loading={loading} taskDetails={taskDetails} createTask={(e)=>createTask(e)} user={props.user} id={"taskduration"} />
    </div>
  )
}

export default CreateTask