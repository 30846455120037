import React from 'react';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Sidebar from '../../components/SideBar/SideBar';
import DashboardRight from '../../components/Dashboard/DashboardRight/DashboardRight';
import './MinieSavings.css';
import Kolosave from '../../components/KoloSave/Kolosave';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLeaderboards } from '../../store/fetchLeaderBoards';
import { fetchKolosaveParticipants } from '../../store/fetchKolosaveParticipants';
import { fetchKolosaveRevealDates } from '../../store/fetchRevealDates';
import { useNavigate } from 'react-router';
import { fetchTransactions } from '../../store/fetchTransactions';

function KoloSave() {
  document.title = 'Kolosave | MinieMoney';
  const user = useSelector(state => state.user.value);
  const participants = useSelector(state => state.koloparticipants.value);
  const leaderboard = useSelector(state => state.leaderboard.value);
  const revealDates = useSelector(state => state.revealDates.value);
  const accountType = window.location.pathname.split('/')[2];
  const transactions = useSelector(state => state.transactions.value)
  const dispatch = useDispatch();
  const history = useNavigate()

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchLeaderboards());
    dispatch(fetchKolosaveRevealDates());
    dispatch(fetchKolosaveParticipants());
  }, [dispatch]);

  //logout user with invalid session
  React.useEffect(() => {
    if (
      sessionStorage.getItem('minieToken') == null || 
      sessionStorage.getItem('userData') == null
    ) {
      console.log(sessionStorage.getItem("minieToken"))
      history('/login/');
    }
  }, [history])

    //fetch all transactions
    React.useEffect(()=>{
      dispatch((fetchTransactions(accountType)))
    },[dispatch,accountType])

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          {user && <div>
            <Kolosave
              user={user}
              participants={participants}
              leaderboard={leaderboard}
              revealDates={revealDates}
              transactions={transactions}
            />
          </div>}

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default KoloSave;
