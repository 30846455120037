//function to upload base64 to firebase
import { storage } from '../firebase';
import { ref, getDownloadURL, uploadString } from 'firebase/storage';
import {toast} from "react-toastify"

export const uploadAndShareBase64 = (base64, minietag,shareType) => {
  const storageRef = ref(storage, minietag+"_badge.png");
  uploadString(storageRef, base64, 'data_url').then(snapshot => {
    console.log('Uploaded a data_url string!');
    // Get the download URL of the uploaded file
    getDownloadURL(snapshot.ref).then((downloadURL)=>{
        console.log(downloadURL)
        window.open(shareType+downloadURL)
        toast.dismiss()
        // return downloadURL
    })
 
  });
};



//NOTE:
// to enable CORS in any bucket, visit the google cloud console and enter: gsutil cors set cors.json <bucket name>