import { Avatar, Select, Textarea,Button,Input } from '@chakra-ui/react'
import axios from 'axios'
import React,{useState} from 'react'
import Swal from 'sweetalert2'
import EarnitHeader from '../../../components/CreateTask/EarnitHeader'
import YoungsterRating from './YoungsterRating'
import YoungsterStaticRating from './YoungsterStaticRating'
import {fetchTasks} from "../../../store/fetchTasks"
import {useDispatch} from "react-redux"
import { toast } from 'react-toastify'
import { fetchUser } from '../../../store/userLogin'
import Confetti from "react-confetti"

function TaskInformation(props) {
    // React.useEffect(()=>{
    //     window.scrollTo(0,0)
    // })

  const [title,setTitle] = React.useState("")
  const [reward,setReward] = React.useState("")
  const [startDate,setStartDate] = React.useState("")
  const [startTime,setStartTime] = React.useState("")
  const [endDate,setEndDate] = useState("")
  const [endTime,setEndTime] = useState("")
  const [frequency,setFrequency] = useState("")
  const [status,setStatus] =  useState("")
  const [description,setDescription] =  useState("")
  const [rating,setRating] = useState(0)
  const [isLoading,setLoading] = useState(false)
  const dispatch = useDispatch()
  const accountType = window.location.pathname.split("/")[2]


  
    const gotoOverview = () =>{
    const tasks = document.getElementById("tasks")
    const taskinformation = document.getElementById("taskinfo")
    const earnitoverview = document.getElementById("earnitOverview")
    tasks.style.display = "none"
    taskinformation.style.display="none"
    earnitoverview.style.display="block"
    props.selectTask("")
  }

  const editTask = () =>{
    setLoading(true)
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/edit-task",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json"
      },
      data:{
        id:props.task._id,
        kidId:props.task.kidId,
        parentId:props.task.parentId,
        title:title !== "" ? title : props.task.title,
        reward:reward !== "" ? reward : props.task.reward,
        description: description !== "" ? description : props.task.description,
        startDate: startDate !== "" || startTime !==""  ? startDate+"T"+startTime : props.task.startDate,
        endDate: endDate !== "" || endTime !=="" ? endDate+"T"+endTime : props.task.endDate,
        frequency:frequency !== "" ? frequency : props.task.frequency,
        kidEmail:props.task.kidEmail,
        rating:rating  !== 0 ? rating : props.task.rating,
        kidName:props.task.kidName,
        relationship:props.task.relationship?props.task.relationship:"parent",
        parentName:props.task.parentName,
        status:status  !== "" ? status : props.task.status,
        profileImage:props.task.profileImage,
        kidLastName:props.task.kidLastName
      }
    }).then((response)=>{
      dispatch(fetchTasks()).then(()=>{
        setLoading(false)
        Swal.fire({
          icon:"success",
          title:"Task Updated Successfully",
          text:response.data.data.message,
          confirmButtonColor:"#06AEDA"
        }).then((result)=>{
          if(result.isConfirmed){
           gotoOverview()
          }
        })
      })
  
    }).catch((error)=>{
      Swal.fire({
        icon:"error",
        title:"Task not Edited",
        text:error.response.data.data.message,
        confirmButtonColor:"#06AEDA"
      })
      setLoading(false)
    })

  }



  const disapproveTask = () =>{
    Swal.fire({
      icon:"question",
      title:"Disapprove "+props.task.title,
      text:"Are you sure want to you want to disapprove this task?. Your child will not receive any reward for performing this task",
      confirmButtonColor:"#06AEDA",
      confirmButtonText:"Yes",
      cancelButtonColor:"black",
      cancelButtonText:"Nope",
      showCancelButton:true,
    }).then((result)=>{
      if(result.isConfirmed){
      const id = toast.loading("Deleting task, please wait...")
      axios({
        method:"POST",
        url:process.env.REACT_APP_API_URL+"/disapprove-task",
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
          ContentType:"application/json"
        },
        data:{
          id:props.task._id,
          task:props.task
        }
      }).then((response)=>{
        dispatch(fetchUser(accountType)).then(()=>{
           toast.update(id,{
            type:"success",
            render:"Task disapproved successfully",
            isLoading:false,
            autoClose:3000
           })
          Swal.fire({
            icon:"success",
            title:"Task Disapproved",
            text:response.data.data.message,
            confirmButtonColor:"#06AEDA",
          }).then((result)=>{
            if(result.isConfirmed){
              window.location.reload()
            }
          })
        })
       
      }).catch((error)=>{
        toast.update(id,{
          type:"error",
          render:"Task not disapproved",
          isLoading:false,
          autoClose:3000
         })
        Swal.fire({
          icon:"error",
          title:"Something went wrong",
          text:error.response.data.data.message,
          confirmButtonColor:"#56c0f2"
        })
      })
    }
    })
  }



  const deleteTask = () =>{
    Swal.fire({
      icon:"question",
      title:"Delete "+props.task.title,
      text:"Are you sure want to you want to delete this task?. All information about this task would be deleted.",
      confirmButtonColor:"#06AEDA",
      confirmButtonText:"Yes",
      cancelButtonColor:"black",
      cancelButtonText:"Nope",
      showCancelButton:true,
    }).then((result)=>{
      if(result.isConfirmed){
      const id = toast.loading("Deleting task, please wait...")
      axios({
        method:"POST",
        url:process.env.REACT_APP_API_URL+"/delete-task",
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
          ContentType:"application/json"
        },
        data:{
          id:props.task._id,
          task:props.task
        }
      }).then((response)=>{
        dispatch(fetchUser(accountType)).then(()=>{
           toast.update(id,{
            type:"success",
            render:"Task deleted successfully",
            isLoading:false,
            autoClose:3000
           })
          Swal.fire({
            icon:"success",
            title:"Task Deleted",
            text:response.data.data.message,
            confirmButtonColor:"#06AEDA",
          }).then((result)=>{
            if(result.isConfirmed){
              window.location.reload()
            }
          })
        })
       
      }).catch((error)=>{
        Swal.fire({
          icon:"error",
          title:"Something went wrong",
          text:error.response.data.data.message,
          confirmButtonColor:"#56c0f2"
        })
      })
    }
    })
  }

  const setStatusValue = (status) => {
    setStatus(status)
    const id = toast.loading('Updating task, please wait');
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/edit-task",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json"
      },
      data:{
        id:props.task._id,
        kidId:props.task.kidId,
        parentId:props.task.parentId,
        title:title !== "" ? title : props.task.title,
        reward:reward !== "" ? reward : props.task.reward,
        description: description !== "" ? description : props.task.description,
        startDate: startDate !== "" || startTime !==""  ? startDate+"T"+startTime : props.task.startDate,
        endDate: endDate !== "" || endTime !=="" ? endDate+"T"+endTime : props.task.endDate,
        frequency:frequency !== "" ? frequency : props.task.frequency,
        kidEmail:props.task.kidEmail,
        rating:rating  !== 0 ? rating : props.task.rating,
        kidName:props.task.kidName,
        relationship:props.task.relationship?props.task.relationship:"parent",
        parentName:props.task.parentName,
        status:status,
        profileImage:props.task.profileImage,
        kidLastName:props.task.kidLastName,
        parentEmail:props.task.parentEmail,
        accountType:accountType
      }
    }).then((response)=>{
    
      toast.update(id,{
        render: 'Task updated successfully!',
        type: 'success',
        isLoading: false,
        autoClose:3000
      })
      setTimeout(()=>toast.dismiss(),2000)
      dispatch(fetchTasks()).then(()=>{
        setLoading(false)
        Swal.fire({
          icon:"success",
          title:"Task Updated Successfully",
          text:response.data.data.message,
          confirmButtonColor:"#06AEDA"
        }).then((result)=>{
          if(result.isConfirmed){
           gotoOverview()
          }
        })
      })
  
    }).catch((error)=>{
      Swal.fire({
        icon:"error",
        title:"Task not Edited",
        text:error.response.data.data.message,
        confirmButtonColor:"#06AEDA"
      })

      toast.update(id,{
        render: 'Task not updated successfully!',
        type: 'error',
        isLoading: false,
        autoClose:3000
      })
      setLoading(false)
    })   
  }


  const setRatingValue = (rating) => {
    setRating(rating)
    props.setRatingValue(rating)
    const id = toast.loading('Updating task, please wait');
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/edit-task",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json"
      },
      data:{
        id:props.task._id,
        kidId:props.task.kidId,
        parentId:props.task.parentId,
        title:title !== "" ? title : props.task.title,
        reward:reward !== "" ? reward : props.task.reward,
        description: description !== "" ? description : props.task.description,
        startDate: startDate !== "" || startTime !==""  ? startDate+"T"+startTime : props.task.startDate,
        endDate: endDate !== "" || endTime !=="" ? endDate+"T"+endTime : props.task.endDate,
        frequency:frequency !== "" ? frequency : props.task.frequency,
        kidEmail:props.task.kidEmail,
        rating:rating  !== 0 ? rating : props.task.rating,
        kidName:props.task.kidName,
        relationship:props.task.relationship?props.task.relationship:"parent",
        parentName:props.task.parentName,
        status:props.task.status,
        profileImage:props.task.profileImage,
        kidLastName:props.task.kidLastName,
        parentEmail:props.task.parentEmail,
        accountType:accountType
      }
    }).then((response)=>{
      toast.update(id,{
        render: 'Task updated successfully!',
        type: 'success',
        isLoading: false,
        autoClose:3000
      })
      setTimeout(()=>toast.dismiss(),2000)
      dispatch(fetchTasks()).then(()=>{
        setLoading(false)
        Swal.fire({
          icon:"success",
          title:"Task Updated Successfully",
          text:response.data.data.message,
          confirmButtonColor:"#06AEDA"
        })
      })
  
    }).catch((error)=>{
      Swal.fire({
        icon:"error",
        title:"Task not Edited",
        text:error.response.data.data.message,
        confirmButtonColor:"#06AEDA"
      })

      toast.update(id,{
        render: 'Task not updated successfully!',
        type: 'error',
        isLoading: false,
        autoClose:3000
      })
      setLoading(false)
    })   
  }


  const handleApproveTask = () =>{
    var approvedTask = { ...props.task}
    approvedTask.rating = props.rating === 0 ? props.task.rating:props.rating
    
   toast.loading('Approving task, please wait');
   axios({
    method:"POST",
    url:process.env.REACT_APP_API_URL+"/approve-task",
    headers:{
      Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
      ContentType:"application/json"
    },
    data:approvedTask
   }).then( async ()=>{
    toast.dismiss()
    await setStatusValue('approved')
    dispatch(fetchUser(accountType)).then(()=>{
      Swal.fire({
        icon:"success",
        title:"Task Approved Successfully!",
        text:"Your task "+props.task.title+" has been approved successfully. The reward has also been credited to your youngster",
        confirmButtonColor:"#00b5d8",
        confirmButtonText:"Ok",
        allowOutsideClick:false
      })
    })
   }).catch((error)=>{
    Swal.fire({
      icon:"error",
      title:"Something went wrong",
      text:error.response.data.data.message,
      confirmButtonColor:"#56c0f2"
    })
   })
  }




  const approveTask = () =>{
    if(rating === 0 && props.task.rating === 0){
      Swal.fire({
        icon:"info",
        title:"Rate the task",
        text:"You need to rate the task before you can approve it",
        confirmButtonColor:"#56c0f2",
        confirmButtonText:"Rate task",
      })
      return
    }
    Swal.fire({
      icon:"question",
      title:"Ready to approve?",
      text:"Are you sure you want to approve this task? The reward for the task would be sent to the child instantly",
      confirmButtonColor:"#56c0f2",
      confirmButtonText:"Approve Task",
      showCancelButton:true,
      cancelButtonText:"Not yet"
    }).then((result)=>{
      if(result.isConfirmed){
        handleApproveTask()
      }
    })
  }

  React.useEffect(()=>{
    if(accountType === 'youngster' && props.task.status === 'approved'){
      Swal.fire({
        icon:"success",
        title:"Task Approved Successfully",
        text:"Congratulations, "+ props.user.firstName +". Your task: "+props.task.title+" has been approved by your "+(props.task.relationship?props.task.relationship:"parent")+". Tap the button below to share your achievement.",
        confirmButtonColor:"#00b5d8",
        allowOutsideClick:false,
        confirmButtonText:"Share Achievement",
        showCancelButton:true
      }).then((result)=>{
        if(result.isConfirmed){
          navigator.share({
            title:"I just got rewarded ₦ "+ props.task.reward*(props.task.rating/5)+" and earned a rating of "+props.task.rating*(100/5) +"% by my parents for completing tasks and chores on MinieMoney. Create a MinieMoney account too to get rewarded for doing chores",
            url:"https://app.miniemoney.com"
          })
        }
      })
    }
  },[accountType,props])




  return (
    <div id={props.id} className="hiddenForm dashboardCenter pb-12">
      {accountType === 'youngster' && props.task.status === 'approved' && <Confetti />}
        <EarnitHeader goBack={gotoOverview} title={"Task Information"} />
        <div className="my-9 flex justify-center">
      <div className="flex">
      <Avatar src={props.task.profileImage} />
      <div className="ml-2">
        {props.task && <h2 className="font-bold">{props.task.kidName} {props.task.kidLastName}</h2>}
        {props.task && <p>{props.task.kidEmail}</p>}
      </div>
      </div>
      </div>

      <div className="taskInformation flex justify-between">
      <div>
        <p>Task Name:</p>
        {props.task && <h2 className="text-md font-bold"><Input disabled={props.task.status === 'pending'?false:true} readOnly={props.user.accountType === 'adult'?false:true} onChange={(e)=>setTitle(e.target.value)} defaultValue={props.task.title} width={"130px"}/></h2>}
      </div>

      <div>
        <p>Task Reward:</p>
        {props.task && <h2 className="text-md font-bold">₦ <Input disabled={props.task.status === 'pending'?false:true} readOnly={props.user.accountType === 'adult'?false:true} onChange={(e)=>setReward(e.target.value)} defaultValue={props.task.reward} width={"70px"}/></h2>}
      </div>
      </div>

      <div className="taskInformation flex justify-between">
      <div>
        <p>Start Date:</p>
        {props.task &&   <h2 className="text-md font-bold"><Input disabled={props.task.status === 'pending'?false:true} readOnly={props.user.accountType === 'adult'?false:true} onChange={(e)=>setStartDate(e.target.value)} type="date" defaultValue={props.task.startDate.split("T")[0]} width={"150px"}/></h2>}
      </div>

      <div>
        <p>Start Time:</p>
        {props.task &&  <h2 className="text-md font-bold"><Input disabled={true} readOnly={props.user.accountType === 'adult'?false:true} onChange={(e)=>setStartTime(e.target.value)} type="time" defaultValue={props.task.startDate.split("T")[1].slice(0,5)} width={"110px"}/></h2>}
      </div>
      </div>

      <div className="taskInformation flex justify-between">
      <div>
        <p>End Date:</p>
        {props.task &&   <h2 className="text-md font-bold"><Input disabled={props.task.status === 'pending'?false:true} readOnly={props.user.accountType === 'adult'?false:true} onChange={(e)=>setEndDate(e.target.value)} type="date" defaultValue={props.task.endDate.split("T")[0]} width={"150px"}/></h2>}
      </div>

      <div>
        <p>End Time:</p>
        {props.task &&  <h2 className="text-md font-bold"><Input disabled={true} readOnly={props.user.accountType === 'adult'?false:true} onChange={(e)=>setEndTime(e.target.value)} type="time" defaultValue={props.task.endDate.split("T")[1].slice(0,5)} width={"110px"}/></h2>}
      </div>
      </div>

      <div className="taskInformation flex justify-between">
      <div>
        <p>Task Frequency:</p>
        {props.task &&
            <Select disabled={props.user.accountType === 'youngster' || props.task.status !== 'pending'?true:false} onChange={(e)=>setFrequency(e.target.value)} defaultValue={props.task.frequency}>
                <option value={"Once"}>Once</option>
                <option value={"Daily"}>Daily</option>
                <option value={"Weekly"}>Weekly</option>
                <option value={"Monthly"}>Monthly</option>
            </Select>
          
        }
      </div>

      <div>
        <p className="text-right">Status</p>
        {props.task.status !=='approved'&& props.task && 
        <Select disabled={accountType ==='adult'} onChange={(e)=>setStatusValue(e.target.value)} defaultValue={props.task.status}>
            <option value={"pending"}>Pending</option>
            <option value={"ongoing"}>Ongoing</option>
            <option value={"completed"}>Completed</option>
        </Select>
        }
         {props.task.status ==='approved'&& <p className='capitalize text-lg text-green-500'>{props.task.status}</p>}
      </div>
      </div>

      <div className="taskInformation">
      <div>
        <p>Task Description:</p>
        {props.task &&
        <Textarea disabled={props.task.status === 'pending'?false:true} readOnly={props.user.accountType === 'adult'?false:true} focusBorderColor={"#06AEDA"} onChange={(e)=>setDescription(e.target.value)} value={props.task.description} width={"full"}/>
        }
        </div>
      </div>

      <div className="mb-6">
      { props.task.status === 'completed' && props.user.accountType === 'adult' && <YoungsterRating setRating={(e)=>setRatingValue(e)} task={props.task}/> }
      { props.task.status === 'approved' && props.user.accountType === 'youngster' && <YoungsterStaticRating task={props.task}/> }
      { props.task.status === 'pending' && props.user.accountType === 'adult' &&  <Button isLoading={isLoading} onClick={editTask} className="confirmButton my-5" colorScheme={"#06AEDA"} rounded={"full"} width={"100%"} background={"#06AEDA"}>Edit Task</Button>}
      { props.task.status === 'completed' && props.user.accountType === 'adult' &&  <Button isLoading={isLoading} onClick={approveTask} className="confirmButton my-5" colorScheme={"#06AEDA"} rounded={"full"} width={"100%"} background={"#06AEDA"}>Approve Task</Button>}
    
      { props.user.accountType === 'adult' && 
     props.task.status === 'pending' && <Button variant={"outline"} onClick={deleteTask} className="confirmButton mb-12" rounded={"full"} color={"#06AEDA"} colorScheme={"#06AEDA"}  width={"100%"} >Delete Task</Button>} 
    
     { props.user.accountType === 'adult' && 
     props.task.status === 'completed' && <Button variant={"outline"} onClick={disapproveTask} className="confirmButton mb-12" rounded={"full"} color={"#06AEDA"} colorScheme={"#06AEDA"}  width={"100%"} >Disapprove Task</Button>} 
    </div>
    </div>
  )
}

export default TaskInformation