import React from 'react'

import QuizIcon from '../../Icons/QuizIcon.svg'

function MinieQuizCard() {
  
  
  return (
    <div className="QuizCard">
        <div className="flexCard">
        <img src={QuizIcon} alt="quiz card"/>
        <div className="ml-4">
        <p>Total Minie Quiz</p>
        <h2>40</h2>
        </div>
        </div>
        
    
    </div>
  )
}

export default MinieQuizCard