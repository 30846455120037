import React from 'react'
import Modal from './Modal'
import { Spinner } from '@chakra-ui/react'

function QuoteModal(props) {
  return (
    <Modal isVisible={props.showModal} onClose={() => props.closeModal()}>
        <div className="p-4 flex justify-between flex-col items-center">
          <h2 className="text-3xl font-semibold max-w-[456px] text-center sm:mt-3">
            {props.title}
          </h2>
          <p className="text-base font-normal max-w-[456px] text-center my-4 sm:mb-4">
            {props.quotes && props.quotes && props.quotes[0].text}
            {!props.quotes && <Spinner color="#0066f5"/>}
          </p>

          <button
            onClick={() => props.closeModal()}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
             bg-[#005FEB] text-[#E6EFFD] rounded-full mt-2 hover:border-[2px]"
          >
            <span className="font-semibold text-base">Close</span>
          </button>

          <span className="footer">Powered by OpenAI (ChatGPT)</span>
        </div>
      </Modal>

  )
}

export default QuoteModal