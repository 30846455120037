import React from 'react';
import back from '../../Icons/backButton.svg';
import participant from '../../Image/participant.png';
import participant2 from '../../Image/Avatar2.png';
import arrow from '../../Image/arrow-down.png';
import arrow1 from '../../Image/arrow-left.png';
import arrow2 from '../../Image/arrow-right.png';
import { Avatar } from '@chakra-ui/react';



function Participants(props) {
  const Back = () => {
    props.Back();
  };


  return (
    <div id="participant" className="dashboardCenter hiddenForm">
      <div onClick={Back} className="cursor-pointer pb-4">
        <img src={back} alt="" />

        <h2 className="walletScreenTitle text-center">Participants</h2>
      </div>
      <div className="kycList my-8">
        <div className="flex justify-between items-center">
          <div>
            <img
              className="max-w-[100%]"
              src={participant}
              alt="user-verification"
            />
          </div>

          <div className="kycText">
            <p className="text-sm font-medium">
              See other people in the KoloSave savings challenge
            </p>
          </div>
        </div>
      </div>

      {
        props.participants && props.participants.map((participant,i)=>(
          <div key={i}>
          <ParticipantList
          img={participant.kolosave.anonymous === false ? participant.profileImage:participant2}
          text={participant.kolosave.anonymous === false? participant.firstName+" "+participant.lastName:"Private Participant"}
          username={participant.kolosave.anonymous === false ?"@"+participant.minietag:"@anonymous"}
        />
        </div>
        ))
      }
    
     
      <div className="kycList my-8 flex-col sm:flex-row">
        <div className="flex flex-1 items-center">
          <p className="text-sm font-normal text-[#6F7174]">
            ( <span>1</span>-<span> {props.participants && props.participants.length} results </span>) of {props.participants && props.participants.length}
          </p>
        </div>
        <div className="flex flex-1 h-[28px]">
          <div className="flex items-center">
            <p className="text-sm font-normal text-[#6F7174]">
              The page you’re on{' '}
            </p>
            <div className="flex items-center gap-1 w-[43px] h-[28px] rounded-md bg-[#FAFAFA] px-[8px] border border-[#E9EBEC] ml-2">
              <span>1</span>
              <img
                src={arrow}
                alt="particpant"
                className="w-[12px] h-[12px] max-w-[100%]"
              />
            </div>
            <div className="w-[1px] h-[28px] bg-[#E9EBEC] ml-4"></div>
            <div className="flex items-center w-[28px] h-[28px] rounded-md bg-[#FAFAFA] px-2 border border-[#E9EBEC] ml-2">
              <img
                src={arrow1}
                alt="particpant"
                className="w-[12px] h-[12px] max-w-[100%]"
              />
            </div>
            <div className="flex items-center w-[28px] h-[28px] rounded-md bg-[#FAFAFA] px-2 border border-[#E9EBEC] ml-2">
              <img
                src={arrow2}
                alt="particpant"
                className="w-[12px] h-[12px] max-w-[100%]"
              />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default Participants;

export const ParticipantList = ({ img, text, username}) => {
  return (
    <div className="cursor-pointer transactionList flex py-5 justify-between">
      <div className="flex">
        <Avatar size={'md'} src={img} alt="participant" />
        <div className="ml-3">
          <h3 className="mt-1 font-medium">{text}</h3>
          <p className="text-[#54595E] font-normal text-xs">{username}</p>
        </div>
      </div>
    </div>
  );
};


