import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userReducer'
import userLogin from './userLogin'
// import fetchTask from './fetchTasks'
import fetchParent from './fetchParents'
import fetchYoungster from './fetchYoungster'
import fetchBanks from "./fetchBanks"
import fetchUserBank from './fetchUserBanks'
import fetchKolosaveParticipant from './fetchKolosaveParticipants'
import fetchKolosaveRevealDate  from './fetchRevealDates'
import fetchLeaderBoard from './fetchLeaderBoards'
import fetchTransaction from './fetchTransaction'
import fetchAdultYoungster from './fetchAdultYoungsters'
import fetchKolosavePosition from './fetchPosition'
import fetchBalance from './fetchBalances'
import fetchAllTransactions  from './fetchTransactions'
import fetchKYC from './fetchKyc'
import fetchQuote from './fetchQuote'
import fetchAnnouncement from './fetchAnnouncement'
import fetchQuiz from './fetchQuiz'




export const store = configureStore({
  reducer: {
    user:userReducer,
    userLogin:userLogin,
    // task:fetchTask,
    parent:fetchParent,
    youngster:fetchYoungster,
    banks:fetchBanks,
    userbanks:fetchUserBank,
    koloparticipants:fetchKolosaveParticipant,
    leaderboard:fetchLeaderBoard,
    revealDates:fetchKolosaveRevealDate,
    transaction:fetchTransaction,
    youngsters:fetchAdultYoungster,
    balances:fetchBalance,
    kolosavePosition:fetchKolosavePosition,
    transactions:fetchAllTransactions,
    kycs:fetchKYC,
    quotes:fetchQuote,
    announcements:fetchAnnouncement,
    quiz:fetchQuiz
  }
 
})