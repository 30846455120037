import React from 'react';
import Logo from '../../Image/Logo.png';
import './CreateAccount.css';
import AnimatedPage from '../../pages/Animation/AnimatedPage';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import { DateInput } from '../Misc/Forms/DateInput';
import Swal from 'sweetalert2';
import { isValidDate } from '../Misc/Forms/FormChecks/checkDate';

function CreateAccountComponent() {
  const [disabled, setDisabled] = React.useState(true);
  const [date, setDate] = React.useState('');
  const history = useNavigate();

  React.useEffect(() => {
    if (isValidDate(date)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [date]);

  const goToSignup = () => {
    sessionStorage.setItem('dob', date);
    if (Date.now() - Date.parse(date) > 630720000000) {
      history('/email-signup/adult');
    } else if (Date.parse(date) > Date.now()) {
      Swal.fire({
        icon: 'info',
        title: 'Are you a time traveller? 🤔',
        text: 'You selected a birthdate that is in the future. Please check',
        confirmButtonColor: '#0066f5',
      });
    } else {
      history('/email-signup/youngster');
    }
  };

  return (
    <div className="bgContainer">
      <div>
        <div className="flex justify-center my-4">
          <img className="cursor-pointer" src={Logo} alt="miniemoneylogo" />
        </div>
        <AnimatedPage>
          <div className="formWithImage">
            <div className="formCard">
              <h2 className="mt-2">Date of birth</h2>
              <p>What is your date of birth?</p>
              <DateInput
                iconClass={'mt-1'}
                size={'lg'}
                setDate={e => setDate(e)}
                date={date}
              />
              <div className="my-6">
                <PrimaryButton
                  title={'Continue'}
                  disabled={disabled}
                  onClick={goToSignup}
                />
              </div>

              <div className="text-center mt-3 mb-12">
                <button
                  onClick={() => history('/login')}
                  className="font-bold brandcolor"
                >
                  Back to login
                </button>
              </div>
            </div>
          </div>
        </AnimatedPage>
      </div>

      <div>
        <ul className="conditionsList">
          <li>Contact</li>
          <li className="conditions">Terms and Conditions</li>
          <li className="conditions">Privacy Policy</li>
        </ul>
      </div>

      <div className="copyrightText text-center my-3">
        <p>&copy; 2022 MinieMoney</p>
      </div>
    </div>
  );
}

export default CreateAccountComponent;
