import React from 'react';
import back from '../../Icons/backButton.svg';
import eye from '../../Image/Eye.png';
import date from '../../Image/date.png';
function KoloReviewDate(props) {
  const Back = () => {
    props.Back();
  };

  return (
    <div id="review" className="dashboardCenter hiddenForm">
      <div onClick={Back} className="cursor-pointer pb-4">
        <img src={back} alt="" />

        <h2 className="walletScreenTitle text-center">Balance Reveal Dates</h2>
      </div>

      <ReviewDate
        img={eye}
        text="You can only get to see your KoloSave savings balance 4 time a
              year, the dates below are the 4 days you will be allowed to see
              your saved balance."
      />

      {props.revealDates &&
        props.revealDates.revealDates &&
        props.revealDates.revealDates.map((value, i) => (
          <ReviewDate key={i} img={date} text={value} />
        ))}
    </div>
  );
}

export default KoloReviewDate;
export const ReviewDate = ({ img, text }) => {
  return (
    <div className="kycList my-8">
      <div className="flex justify-between items-center">
        <div>
          <img className=" max-w-[100%]" src={img} alt="user-verification" />
        </div>

        <div className="kycText">
          <p className="text-sm font-medium">{text}</p>
        </div>
      </div>
    </div>
  );
};
