import React from 'react';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Sidebar from '../../components/SideBar/SideBar';
import DashboardRight from '../../components/Dashboard/DashboardRight/DashboardRight';
import { useDispatch, useSelector } from 'react-redux';
import KahootComponent from '../../components/MinieQuiz/Kahoot';
import { fetchQuizzes } from '../../store/fetchQuiz';

function Kahoot() {
  const user = useSelector(state => state.user.value);
  const quiz = useSelector(state => state.quiz.value)
  const accountType = window.location.pathname.split('/')[2];
  const dispatch = useDispatch()

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchQuizzes())
  }, [dispatch])

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
          <KahootComponent
              user={user}
              title={'Play Kahoot!'}
              accountType={accountType}
              quiz={quiz}
            />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default Kahoot;
