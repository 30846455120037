export function senderFormatter(transaction, user) {
  if (transaction.type === 'kolosave' || transaction.type === 'transfer') {
    return user.firstName + ' ' + user.lastName;
  }
  if (transaction.type === 'deposit') {
    const sentence = transaction.message;
    const words = sentence.split(' ');
    const lastWord = words[words.length - 1];
    return lastWord;
  }

  if (transaction.type === 'withdrawal') {
    return 'MinieWallet';
  }

  if (transaction.type === 'allowance') {
    return transaction.sender;
  }
}

export function beneficiaryFormatter(transaction, user) {
  if (transaction.type === 'deposit') {
    return user.firstName + ' ' + user.lastName;
  }
  if (transaction.type === 'kolosave') {
    return 'Kolo';
  }

  if (transaction.type === 'withdrawal') {
    return user.firstName + ' ' + user.lastName;
  }

  if (transaction.type === 'transfer') {
    const words = transaction.message.split(' ');
    const index = words.indexOf('to');
    const beneficiary = words[index + 1];
    return beneficiary
  }

  if (transaction.type === 'allowance') {
    return transaction.receiver
  }
}

export function referenceFormatter(transaction) {
  if (
    transaction.transactionId !== undefined &&
    transaction.transactionId.length > 20
  ) {
    return transaction.transactionId.slice(0, 15) + '...';
  } else {
    return 'miniemoney_' + transaction.type;
  }
}
