import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
  value: "",
  loading: false,
};


export const fetchUser = createAsyncThunk("user/fetchUser", async (e) => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/user/"+e,
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
            ContentType: "application/json",
        }
      })
      sessionStorage.setItem("userData",JSON.stringify(response.data.data))
      return response.data.data
    } catch (error) {
      if(error.response.status === 401){
        alert("Your session has expired, click ok to logout")
        setTimeout(()=>{
          localStorage.removeItem("_expiredTime")
          sessionStorage.removeItem('minieToken');
          sessionStorage.removeItem('userData');
          window.location.href = '/login/';
        },3000)
      }
    }
  });
  
  export const userLogin = createSlice({
    name: "user",
    initialState,
    extraReducers: {
      [fetchUser.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchUser.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchUser.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default userLogin.reducer;