import React, { useState, useRef } from 'react';
import download from '../../Image/downloadButton.svg';
import whatsapp from '../../Image/whatsappButton.svg';
import fbk from '../../Image/fbkButton.svg';
import twitter from '../../Image/twitterButton.svg';
import ShareBadge from './Share/ShareBadge';
import html2canvas from 'html2canvas';
import { getBase64FromUrl } from '../../utils/imageConverter';
import { uploadAndShareBase64 } from '../../utils/uploadBase64';
import { toast } from 'react-toastify'

export const Socials = ({ head, socials, shareImage }) => {
  return (
    <div
      onClick={shareImage}
      className="flex flex-col justify-between items-center sm:gap-2 cursor-pointer"
    >
      <img src={socials} alt="" />
      <h2 className="font-semibold text-[#151618] text-[12px] sm:text-[18px] hover:border-b-[1px] hover:text-[#669F2A]">
        {head}
      </h2>
    </div>
  );
};

export const SocialMediaShare = ({
  bgImage,
  image,
  image2,
  title,
  userName,
  date,
  rank,
  remarkCol,
}) => {
  const componentRef = useRef(null);
  const [base64, setBase64] = useState('');
  const text = `I was the ${rank} top saver on MinieMoney’s KoloSave as at ${date}`

  const saveImage = async () => {
    const id = toast.loading('Downloading badge. Please wait...');
    const node = componentRef.current;
    const base64 = await getBase64FromUrl(image);
    setBase64(base64);
    setTimeout(() => {
      html2canvas(node)
        .then(function (canvas) {
          const dataURL = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = 'miniemoney_badge.png';
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          toast.update(id, {
            render: 'Badge Downloaded successfully',
            type: 'success',
            isLoading: false,
            autoClose: 3000
          });
          document.body.removeChild(link);
        })
        .catch(function (error) {
          toast.update(id, {
            render: 'Oops, something went wrong!',
            type: 'error',
            isLoading: false,
            autoClose:3000
          });
        });
    }, 500);
  };

  const shareFacebook = async () => {
    const id = toast.loading('Sharing to facebook. Please wait...');
    const node = componentRef.current;
    const base64 = await getBase64FromUrl(image);
    setBase64(base64);
    setTimeout(() => {
      html2canvas(node)
        .then(async function (canvas) {
          const dataURL = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = 'my-image.png';
          link.href = dataURL;
          document.body.appendChild(link);
          await uploadAndShareBase64(link.href,userName,"https://web.facebook.com/sharer/sharer.php?u=")
        })
        .catch(function (error) {
          toast.update(id, {
            render: 'Oops, something went wrong!',
            type: 'error',
            isLoading: false,
            autoClose:3000
          });
        });
    }, 500);

   
  };

  const shareTwitter = async () => {
    const id = toast.loading('Sharing to Twitter. Please wait...');
    const node = componentRef.current;
    const base64 = await getBase64FromUrl(image);
    setBase64(base64);
    setTimeout(() => {
      html2canvas(node)
        .then(async function (canvas) {
          const dataURL = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = 'my-image.png';
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          await uploadAndShareBase64(link.href,userName,`https://twitter.com/intent/tweet?text=${text}&via=myminiemoney&url=`)
        })
        .catch(function (error) {
          toast.update(id, {
            render: 'Oops, something went wrong!',
            type: 'error',
            isLoading: false,
            autoClose:3000
          });
        });
    }, 500);


  };

  const shareWhatsApp = async () => {
    const id = toast.loading('Sharing to WhatsApp. Please wait...');
    const node = componentRef.current;
    const base64 = await getBase64FromUrl(image);
    setBase64(base64);
    setTimeout(() => {
      html2canvas(node)
        .then(async function (canvas) {
          const dataURL = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = 'my-image.png';
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          await uploadAndShareBase64(link.href,userName,`https://api.whatsapp.com/send?text=${text}%0A`)
        })
        .catch(function (error) {
          toast.update(id, {
            render: 'Oops, something went wrong!',
            type: 'error',
            isLoading: false,
            autoClose:3000
          });
        });
    }, 500);
  };

  return (
    <div className="flex flex-col items-center justify-between px-6">
      <h3
        className={`my-3 font-medium text-[#151618] text-center text-[18px] sm:text-[28px]`}
      >
        Share
      </h3>

      <div ref={componentRef}>
        <ShareBadge
          remarkCol={remarkCol}
          title={title}
          bgImage={bgImage}
          image={base64 ? base64 : image}
          userName={userName}
          date={date}
          rank={rank}
          image2={image2}
        />
      </div>

      <div className="flex items-center justify-between w-[240px] sm:w-[440px] py-6">
        <Socials
          shareImage={() => saveImage()}
          socials={download}
          head={'Save image'}
        />
        <Socials
          shareImage={() => shareFacebook()}
          socials={fbk}
          head={'Facebook'}
        />
        <Socials
          shareImage={() => shareTwitter()}
          socials={twitter}
          head={'Twitter'}
        />
        <Socials
          shareImage={() => shareWhatsApp()}
          socials={whatsapp}
          head={'Whatsapp'}
        />
      </div>
    </div>
  );
};
