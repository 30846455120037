import React from 'react';
import { Button } from '@chakra-ui/react';
import bankaccount from '../../../Icons/noAccount.svg';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';
import { useNavigate } from 'react-router';

function NoAccountNumber(props) {
  const accountType = window.location.pathname.split("/")[2]
  const history = useNavigate()

  const goToOverview = () =>{
    history("/wallet/"+accountType)
  }

  const createAccountNumber = () =>{
   history("/wallet/"+accountType+"/create-account-number")
  }


  return (
    <div id={props.id} className="dashboardCenter walletScreensContainer">
      <WalletScreenHeader goBack={goToOverview} title="Account Details" />{' '}
      <div className="my-10 notificationsCard">
        <div>
          <div className="my-4 flex justify-center">
            <img src={bankaccount} alt="notificationBell" />
          </div>
          <p className="my-3">
            We will show your account number when you complete the process of creating a MinieMoney bank account
          </p>
          <Button
            fontSize={'14px'}
            size={'lg'}
            width={'80%'}
            maxWidth={"270px"}
            onClick={createAccountNumber}
            rounded={'full'}
            background="#7A58EF"
            color="white"
            variant="#0073E5"
          >
            Create MinieMoney Bank Account
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NoAccountNumber;
