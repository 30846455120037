import React, { useEffect, useState } from 'react';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { fetchUser } from '../../../store/userLogin';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import EnterTransactionPinModal from '../../../components/Modal/EnterTransactionPinModal';
import AmountInput from '../../../components/Misc/Forms/AmountInput';
import { standardAmountFormat } from '../../../utils/amountUtil';
import { truncateName } from '../../../utils/truncateName';

function ConfirmDetails(props) {
  return (
    <div>
      <div className="confirmYoungsterDetails">
        <Avatar src={props.youngster.profileImage} alt="youngsterImg" />
        <div>
          <h2>
            {props.youngster.firstName} {props.youngster.lastName}
          </h2>
          <p>{props.youngster.email}</p>
        </div>
      </div>
      <hr />
      <div className="my-3 flex justify-between">
        <div>
          <p className="text-sm">Amount</p>
          <h2 className="font-bold">{standardAmountFormat(props.amount)}</h2>
        </div>
        <div>
          <p className="text-sm">Reason for Sending Money:</p>
          <h2 className="font-bold">{props.reason}</h2>
        </div>
      </div>
    </div>
  );
}

function SendMoneyFamily(props) {
  const youngsters = useSelector(state => state.user.value.youngsters);
  const parents = useSelector(state => state.parent.value);
  const [disabled, setDisabled] = React.useState(true);
  const [amount, setAmount] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [youngster, setYoungster] = React.useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoading] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const apiUrl =
    accountType === 'adult' ? '/send-money-kid' : '/send-money-parent';

  useEffect(()=>{
    if(amount > 0 && reason !== ""){
      setDisabled(false)
    }
    else{
      setDisabled(true)
    }
  },[amount,reason])

  const gotoTransfer = () => {
    const sendmoneyfamily = document.getElementById('sendmoneyfamily');
    const transfer = document.getElementById('transfer');
    transfer.style.display = 'block';
    sendmoneyfamily.style.display = 'none';
  };

  const selectYoungter = (youngster, i) => {
    let selectedCard = document.getElementById(i);
    for (let i = 0; i < youngsters.length; i++) {
      let selectedCards = document.getElementById(i);
      if (selectedCards.classList.contains('selectedCard')) {
        selectedCards.classList.remove('selectedCard');
      }
    }

    selectedCard.classList.add('selectedCard');
    setYoungster(youngster);
  };

  const selectParent = (parent, i) => {
    let selectedCard = document.getElementById(i);
    for (let i = 0; i < parents.length; i++) {
      let selectedCards = document.getElementById(i);
      if (selectedCards.classList.contains('selectedCard')) {
        selectedCards.classList.remove('selectedCard');
      }
    }

    selectedCard.classList.add('selectedCard');
    setYoungster(parent);
  };

  const setReasonValue = e => {
    setReason(e.target.value);
  };
  const setAmountValue = e => {
    setAmount(e)
  }

  const sendMoney = () => {
    if (amount < 0 || amount === '') {
      return;
    }
    if (youngster === null) {
      Swal.fire({
        icon: 'info',
        title:
          'Please select a ' + (accountType === 'adult' ? 'kid' : 'parent'),
        text:
          'You need to select a ' +
          (accountType === 'adult' ? 'kid' : 'parent'),
      });
    } else {
      onOpen();
    }
  };

  const confirmTransaction = () => {
    onClose();
    setDisabled(true)
    setShowModal(true);
    // setIsPinOpen(true);
  };

  //depending on the accountType, this function to send money to parent (for youngster accountType) Or send money to youngster (for adult accountType)
  const sendMoneytoFamily = () => {
    if (amount <= 0) {
      return;
    } else {
      setLoading(true);
      setDisabled(true)
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL + apiUrl,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
          ContentType: 'application/json',
        },
        data: {
          childId: youngster._id,
          parentId: youngster._id,
          amount: amount,
          reason: reason,
          pin: pin,
          firstName: youngster.firstName,
          email: youngster.email,
          sendertag: props.user.minietag,
          relationship: youngster.relationship
            ? youngster.relationship
            : 'parent',
        },
      })
        .then(async () => {
          dispatch(fetchUser(accountType)).then(() => {
            setLoading(false);
            setDisabled(false)
            setShowModal(false);
            Swal.fire({
              icon: 'success',
              title: 'Transfer initiated successfully',
              text:
                '₦ ' +
                amount +
                ' has been sent to your ' +
                (accountType === 'adult' ? 'youngster: ' : 'parent: ') +
                youngster.firstName,
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          });
        })
        .catch(error => {
          console.log(error)
          setShowModal(false)
          setLoading(false)
          setDisabled(false)
          if (
            error.response.data.data.message ===
            'Insufficient Balance. Kindly top-up your Miniewallet and try again'
          ) {
            Swal.fire({
              icon: 'warning',
              title: 'Insufficient Funds',
              text: error.response.data.data.message,
              confirmButtonText: 'Fund Wallet',
            }).then(result => {
              if (result.isConfirmed) {
                setShowModal(true);
                history(
                  accountType === 'adult'
                    ? '/fund-wallet/' + accountType
                    : '/request-money/' + accountType
                );
              }
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Something went wrong',
              text: error.response.data.data.message,
              confirmButtonText: 'Ok',
            }).then(result => {
              if (result.isConfirmed) {
                setShowModal(true);
              }
            });
          }
          setLoading(false);
          setDisabled(false)
        });
    }
  };

  return (
    <div
      id={props.id}
      className="dashboardCenter hiddenForm walletScreensContainer"
    >
      <WalletScreenHeader goBack={gotoTransfer} title={props.title} />

      <div className="mt-6">
        <h2 className="text-lg my-3 font-medium">
          Select {accountType === 'adult' ? 'Kid' : 'Parent'}
        </h2>
        <div className="youngsterCards">
          {youngsters &&
            youngsters.map((youngster, i) => (
              <div
                id={i}
                onClick={() => selectYoungter(youngster, i)}
                className="youngsterCard"
                key={i}
              >
                <div>
                  <Avatar src={youngster.profileImage} alt="youngsterImage" />
                </div>
                <div className="youngsterName">
                  <h2>
                    {youngster.firstName} {truncateName(youngster.firstName, youngster.lastName)}
                  </h2>
                  <p>{youngster.email}</p>
                </div>
              </div>
            ))}

          {
            parents && parents.map((parent, i) => (
              <div
                id={i}
                onClick={() => selectParent(parent, i)}
                className="youngsterCard"
                key={i}
              >
                <div>
                  <Avatar src={parent.profileImage} alt="youngsterImage" />
                </div>
                <div className="youngsterName">
                  <h2>
                    {parent.firstName} {parent.lastName}
                  </h2>
                  <p>{parent.email}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="formContainer">
        <div>
          <label>How much are you sending?</label>

          <AmountInput
            placeholder={'Enter amount'}
            focusBorderColor={'#7A58EF'}
            setAmount={e => setAmountValue(e)}
          />
        </div>

        <div className="my-3">
          <label>Why do you want to send this money?</label>
          <Input
            onChange={e => setReasonValue(e)}
            size="lg"
            focusBorderColor="#7A58EF"
            placeholder={'Enter reason for sending money'}
            fontSize={"15px"}
          />
        </div>

        <div className="mt-6">
          <Button
            onClick={sendMoney}
            padding={'28px 32px'}
            width={'100%'}
            disabled={disabled}
            rounded={'full'}
            colorScheme={'#7A58EF'}
            background={'#7A58EF'}
          >
            Continue
          </Button>
        </div>
      </div>

      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-medium text-xl">Confirm Transaction</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ConfirmDetails
              youngster={youngster}
              amount={amount}
              reason={reason}
            />
          </ModalBody>

          <ModalFooter
            margin={'0px 0px 20px 0px'}
            className="confirmDetailsFlexButtons"
          >
            <Button
              color={'#54595E'}
              rounded={'full'}
              background={'#F3F3F4'}
              colorScheme="#F3F3F4"
              onClick={onClose}
            >
              <ArrowBackIcon className="mr-3" /> Cancel
            </Button>
            <Button
              rounded={'full'}
              className="confirmButton"
              background={'#7A58EF'}
              colorScheme="#7A58EF"
              onClick={confirmTransaction}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <EnterTransactionPinModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        confirm={() => sendMoneytoFamily()}
        loading={isLoading}
        setLoading={e => setLoading(e)}
        setPin={e => setPin(e)}
        disabled={disabled}
        setDisabled={(e) => setDisabled(e)}
        bg={'#7A58EF'}
        pinBg={'#F2EEFD'}
        color={'#7A58EF'}
        class={"transaction"}
      />
    </div>
  );
}

export default SendMoneyFamily;
