import React, { Fragment } from 'react';
import back from '../../Icons/backButton.svg';
import auto from '../../Image/AutoSave.png';
import { Button, Select } from '@chakra-ui/react';
import Modal from '../Modal/Modal';
import success from '../../Icons/successIcon.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';
import closeButtonGreen from '../../Icons/closeButtonGreen.svg';
import AmountInput from '../Misc/Forms/AmountInput';
import { standardAmountFormat } from '../../utils/amountUtil';

function AutoSave(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Back = () => {
    props.Back();
  };
  const [showModal2, setShowModal] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [frequency, setFrequency] = React.useState('');
  // const [time,setTime] = React.useState("midday")
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [error, setError] = React.useState('');

  const history = useNavigate();
  const dispatch = useDispatch();

  const setAmountValue = e => {
    setAmount(e)
    if (amount > 0 && amount !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const showSaving = () => {
    window.location.reload();
  };
  const setAutosave = () => {
    if (amount < 200 || amount === '') {
      setError('The least amount you can save instantly is ₦ 200');
      return;
    }

    if (amount > props.user.walletBalance) {
      Swal.fire({
        icon: 'error',
        title: 'Insufficient funds 😢',
        text: 'Kindly top-up your MinieWallet and try again',
        confirmButtonColor: '#669f2a',
      }).then(result => {
        if (result.isConfirmed) {
          history('/request-money/' + props.user.accountType);
        }
      });
      return;
    }

    if (frequency === '') {
      Swal.fire({
        icon: 'error',
        title: 'Select Frequency 😉',
        text: 'Please select your Autosave frequency',
        confirmButtonColor: '#669f2a',
      });
      return;
    }

    let kolosave = { ...props.user.kolosave };
    kolosave.autosave = true;
    setLoading(true);
    setDisabled(true)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/kolo-autosave',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        Accept: '*/*',
        ContentType: 'application/json',
      },
      data: {
        accountType: props.user.accountType,
        amount,
        frequency,
        time: 'midnight',
      },
    })
      .then(() => {
        setShowModal(true);
        setLoading(false);
        setDisabled(false)
        dispatch(fetchUser(props.user.accountType));
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        setDisabled(false)
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 🤔",
          text: 'Please try again later',
          confirmButtonColor: '#669f2a',
        });
      });
  };

  return (
    <Fragment>
      <div id="auto-save" className="dashboardCenter hiddenForm">
        <div onClick={Back} className="cursor-pointer pb-4">
          <img src={back} alt="" />

          <h2 className="walletScreenTitle text-center">Auto Save</h2>
        </div>
        <div className="kycList my-4">
          <div className="flex justify-between items-center gap-2 sm:gap-0">
            <div>
              <img
                className="max-w-[100%]"
                src={auto}
                alt="user-verification"
              />
            </div>

            <div className="kycText">
              <p className="text-sm font-medium">
                Auto Save helps you deposit money automatically into your
                KoloSave without worries or delay.
              </p>
            </div>
          </div>
        </div>
        <div className="pt-4 my-2">
          <label>Select Money Source</label>
          <Select
            size={"lg"}
            focusBorderColor="#669F2A"
            color={'#151618'}
            className="placeholder:text-[#AEB2B7]"
            _placeholder={{ opacity: 1, color: '#AEB2B7' }}
            fontSize={"16px"}
          >
            <option
              defaultValue={'Where would you like to take money from?'}
              hidden
              disabled
              value="Where would you like to take money from?"
            >
              Where would you like to take money from?
            </option>
            <option value="miniewallet">
              MinieWallet (
              {standardAmountFormat(props.user.walletBalance)})
            </option>
          </Select>
        </div>

        <div className="my-4">
          <label>Enter Amount To Save</label>
          <AmountInput
            focusBorderColor={'#669F2A'}
            placeholder={'Enter the amount you want to save'}
            color={'#151618'}
            setAmount={e => setAmountValue(e)}
          />

          <span className="text-red-500">{error}</span>
        </div>
        <div className="my-3">
          <label>Select Savings Frequency</label>
          <Select
            size={"lg"}
            focusBorderColor="#669F2A"
            color={'#151618'}
            className="placeholder:text-[#AEB2B7]"
            placeholder="How often would you like to save?"
            onChange={e => setFrequency(e.target.value)}
            fontSize={"16px"}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Select>
        </div>
        <div className="mt-6">
          <Button
            onClick={setAutosave}
            padding={'28px 32px'}
            width={'100%'}
            rounded={'full'}
            colorScheme={'#669F2A '}
            background={'#669F2A '}
            isLoading={loading}
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      </div>
      <Modal
        isVisible={showModal2}
        onClose={() => closeModal()}
        closeButtonGreen={closeButtonGreen}
      >
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h3 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            Your auto save is successful!
          </h3>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            You have successfully automated your KoloSave savings. Happy saving!
          </p>
          <button
            onClick={showSaving}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2 "
          >
            <span className="font-semibold text-base text-[#FFFFFF]">Done</span>
          </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default AutoSave;
