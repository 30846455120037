import React, { useState } from 'react';
import {
  Progress,
  IconButton,
  Button,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Input } from '@chakra-ui/react';
import PasswordInput from '../Misc/Forms/PasswordInput';
import { checkPassword } from '../Misc/Forms/FormChecks/CheckPassword';


function ResetPasswordForm(props) {
  const [miniepin, setMiniePin] = useState('');
  const [disabled,setDisabled] = useState(false)
  const [confirmMiniepin, setConfirmMiniePin] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [pinError, setPinError] = useState('');
  const handleClickConfirm = () => setShowConfirm(!showConfirm);


  React.useEffect(()=>{
    if (checkPassword(miniepin)){
      setDisabled(false)
    } else{
      setDisabled(true)
    }
  },[miniepin])

  function checkPin() {
    if (miniepin !== confirmMiniepin) {
      setPinError('Your new password and Confirm password do not match');
      setTimeout(() => setPinError(''), 2000);
      return;
    }

    if (miniepin.length < 6) {
      setPinError('Your new passwrod needs to be atleast 8 character');
      setTimeout(() => setPinError(''), 2000);
      return;
    } else {
      props.setPin(miniepin);
      // setLoading(false)
    }
  }

  return (
    <div id={props.id} className="hiddenForm">
      <div className="mb-5">
        <IconButton
          onClick={() => props.nextStep(2)}
          className="backIconButtonStyle"
          rounded={'full'}
          icon={<ArrowBackIcon />}
        />
      </div>
      <div className="relative">
        <Progress colorScheme="blue" rounded={'full'} value={props.progress} />
        <span className="absolute right-0">{props.progress}%</span>
      </div>

      <h2 className="mt-7">Enter your New Password</h2>
      <p>
        Your Password let's you to gain access to your MinieMoney
        account and to authorize your app transactions.
      </p>
      <PasswordInput
        label={'Password'}
        setPassword={setMiniePin}
        placeholder={'Enter your new Password'}
        password={miniepin}
      />
      <div className="my-4">
        <label>Confirm Password</label>
        <InputGroup size="lg">
          <Input
            pr="4.5rem"
            type={showConfirm ? 'text' : 'password'}
            placeholder="Confirm your new Password"
            onChange={e => setConfirmMiniePin(e.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Button
              variant={'unstyled'}
              h="1.75rem"
              size="sm"
              onClick={handleClickConfirm}
            >
              {showConfirm ? (
                <FiEye size={'20px'} />
              ) : (
                <FiEyeOff size={'20px'} />
              )}
            </Button>
          </InputRightElement>
        </InputGroup>
      </div>
      <div className="text-red-400">{pinError}</div>

      <div className="my-6 flex justify-between">
        <Button
          onClick={() => props.nextStep(2)}
          rounded={'full'}
          size="lg"
          color={'#A9ABAD'}
        >
          <ArrowBackIcon className="mr-3" /> Back{' '}
        </Button>
        <Button
          isLoading={props.loading}
          rounded={'full'}
          className="activeButton"
          color={'white'}
          size="lg"
          id="nextButton"
          onClick={checkPin}
          loadingText="Resetting Password"
          background={'#0066f5'}
          variant={'solid'}
          _hover={{background:"#0066f5"}}
          isDisabled={disabled}
        >
          Next <ArrowForwardIcon className="ml-3" />
        </Button>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
