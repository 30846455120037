import React from 'react'
import { Avatar, 
  // Switch 

} from '@chakra-ui/react'

function YoungsterAvatarHeading(props) {
  return (
    <div className="flex justify-between w-full youngsterAvatarHeading">
    <div className="flex">
    <Avatar onClick={()=>alert(window.location.pathname)} src={props.youngster.profileImage} alt="youngsterImage"/>
    <div className="ml-2">
    <h2 className="font-bold">{props.youngster.firstName} {props.youngster.lastName}</h2>
    <p>@{props.youngster.minietag}</p>
    </div>
    </div>

    {/* {window.location.pathname.slice(0,25) ==="/account/adult/youngster/" && <Switch onChange={(e)=>props.showYoungsterBalance(e)} className="text-right" />} */}
    </div>
  )
}

export default YoungsterAvatarHeading