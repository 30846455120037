import React from 'react';
import { IconButton, Progress, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import EmailInput from '../../components/Misc/Forms/EmailInput';

function ForgotPasswordForm(props) {
  const [isLoading, setLoading] = React.useState(false);
  const [emailaddress, setEmailAddress] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [disabled,setDisabled] = React.useState(true)

  const history = useNavigate();

  function goBack() {
    history(-1);
  }

  function setEmail(e) {
    setEmailAddress(e)
  }

  function sendOtp() {
    if (emailaddress.length !== 0) {
      setLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/forgot-password',
        data: {
          email: emailaddress.trim(),
        },
      })
        .then(response => {
          setLoading(false);
          sessionStorage.setItem('accountType', response.data.data.accountType);
          props.setEmail(response.data.data.email);
          props.setSessionId(response.data.data.sessionId);
          if (response.status === 201) {
            Swal.fire({
              icon: 'warning',
              title: 'Email not found',
              text: response.data.data.message,
              confirmButtonColor: '#0073E5',
            });
          } else {
            props.nextStep(2);
            toast.success(response.data.data.message);
          }
        })
        .catch(error => {
          alert(error);
          setLoading(false);
        });
    } else {
      setEmailError('Please enter your email address');
      setTimeout(() => {
        setEmailError('');
      }, 1500);
    }
  }

  return (
    <div className="emailVerificationCard" id={props.id}>
      <div className="mb-4">
        <IconButton
          onClick={goBack}
          className="backIconButtonStyle"
          rounded={'full'}
          icon={<ArrowBackIcon />}
        />
      </div>
      <div className="mb-5 relative">
        <Progress colorScheme="blue" rounded={'full'} value={props.progress} />
        <span className="absolute right-0">{props.progress}%</span>
      </div>

      <h2 className="mt-3">Reset your Password</h2>
      <p>
        Kindly enter your MinieMoney registered email address. We’ll send you a
        verification code to reset your Password.
      </p>

      <form>
        <EmailInput
          label={'Email address'}
          placeholder={'Enter your email address'}
          setEmail={e => setEmail(e)}
          size={'lg'}
          setDisabled={e => setDisabled(e)}
        />
      </form>

      <div className="text-red-400">{emailError}</div>

      <div className="my-6">
      <Button
        isLoading={isLoading}
        isDisabled={disabled}
        rounded={'full'}
        className="confirmButton"
        color={'white'}
        size="lg"
        width={'100%'}
        onClick={sendOtp}
        background={"#0066f5"}
      >
        Send verification code
      </Button>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
