import React from 'react';
import Logo from '../../../Image/Logo.png';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux'
import { fetchUser } from '../../../store/userLogin';
import "./Login.css"
import 'react-phone-input-2/lib/bootstrap.css'
import AnimatedPage from '../../Animation/AnimatedPage';

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Button,
  InputRightElement,
  InputGroup
} from '@chakra-ui/react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { handleEmailInput } from '../../../utils/Forms/formatEmail';


function LoginPage() {
  document.title = "Login | MinieMoney"
  const history = useNavigate();
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [pin, setPin] = React.useState('');
  const [show, setShow] = React.useState('');
  const [loading,setLoading] = React.useState(false)
  const handleClick = () => setShow(!show);
  const dispatch = useDispatch()


  React.useEffect(()=>{
    window.scrollTo(0,0)
    
    if(sessionStorage.getItem("minieToken") !=null && sessionStorage.getItem("userData") != null ){
      const isAccount = JSON.parse(sessionStorage.getItem("userData")).accountType
      window.location.href ="/dashboard/"+isAccount
    }
   
   },[history])

  const login = () => {
    setLoading(true)
    setEmail(localStorage.getItem("emailAddress"))
    if (localStorage.getItem("emailAddress") !== '' && pin !== '') {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/login',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          email: localStorage.getItem("emailAddress")?localStorage.getItem("emailAddress").trim():email.trim(),
          password: pin,
          device:navigator.userAgent
        },
      }).then(response => {
        if(response.status ===200){
        const userType = response.data.data.accountType
        sessionStorage.setItem("minieToken",response.data.data.token)
        dispatch(fetchUser(userType)).then(()=>{
         setLoading(false)
         window.location.href ="/dashboard/"+userType
        })
        
        }
        else{
          setLoading(false)
          Swal.fire({
            icon:"warning",
            title:"Login unsuccessful",
            text:response.data.data.email+" "+response.data.data.password,
            confirmButtonColor: '#0073E5'
        })
        }
      }).catch((error)=>{
        console.log(error)
        setLoading(false)
        if(error.response.status === 403){
          Swal.fire({
            icon:"error",
            title:"Login unsuccessful",
            text:error.response.data.data.message,
            confirmButtonColor: '#0073E5'
        })
        return
        }

        if(error.response.status === 422){
          Swal.fire({
            icon:"error",
            title:"Login unsuccessful",
            text:error.response.data.data.message,
            confirmButtonColor: '#0073E5'
        })
        return
        }

        Swal.fire({
            icon:"error",
            title: 'Network Error 😢',
            text: 'Please check your internet connection and try again',
            confirmButtonColor: '#0073E5'
        })
      })
    }
    else{
        setLoading(false)
        Swal.fire({
            icon:"warning",
            title:"Please enter your details",
            text:"Kindly enter your email and password to login",
            confirmButtonColor:"#0066f5"
        })
    }
  };


  const loginPhone = (e) => {
    e.preventDefault()
    setPhone(localStorage.getItem("phoneNumber"))
    setLoading(true)
    if (localStorage.getItem("phoneNumber") !== '' && pin !== '') {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/login/phone',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          phone: localStorage.getItem("phoneNumber") ? localStorage.getItem("phoneNumber").trim(): phone.trim(),
          password: pin,
          device:navigator.userAgent
        },
      }).then(response => {
        if(response.status ===200){
          const userType = response.data.data.accountType
          sessionStorage.setItem("minieToken",response.data.data.token)
          dispatch(fetchUser(userType)).then(()=>{
           setLoading(false)
           window.location.href ="/dashboard/"+userType
          })
        }
        else{
          setLoading(false)
          Swal.fire({
            icon:"warning",
            title:"Login unsuccessful",
            text:response.data.data.message,
            confirmButtonColor: '#0073E5'
        })
        }
      }).catch(error=>{
        setLoading(false)
        if(error.response.status === 403){
          Swal.fire({
            icon:"error",
            title:"Login unsuccessful",
            text:error.response.data.data.message,
            confirmButtonColor: '#0073E5'
        })
        return
        }

        if(error.response.status === 422){
          Swal.fire({
            icon:"error",
            title:"Login unsuccessful",
            text:error.response.data.data.message,
            confirmButtonColor: '#0073E5'
        })
        return
        }
        
        Swal.fire({
            icon:"error",
            title: 'Network Error 😢',
            text: 'Please check your internet connection and try again',
            confirmButtonColor: '#0073E5'
        })
      })
    }
    else{
        setLoading(false)
        Swal.fire({
            icon:"warning",
            title:"Please enter your details",
            text:"Kindly enter your email and password to login",
            confirmButtonColor:"#0066f5"
        })
    }
  };

  const setEmailValue = (e) =>{
    setEmail(e)
    localStorage.setItem("emailAddress",e)
  }

  const setPhoneValue = (e) =>{
    setPhone(e)
    localStorage.setItem("phoneNumber",e)
  }

  const goBack = () => {
    history(-1);
  };

  return (
    <div className="bgContainer">
      <AnimatedPage>
      <div>
      <IconButton
          onClick={goBack}
          className="backIconButtonStyle mx-4 appText"
          rounded={'full'}
          icon={<ArrowBackIcon width={"17px"} />}
        />
     
        <div className="flex justify-center my-4">
          <img
            className="cursor-pointer appLogo"
            onClick={() => history('/')}
            src={Logo}
            alt="miniemoneylogo"
          />
        </div>
       
        <div className="formWithImage">
         

          <div className="formCard">
            <h2 className="mt-3">Welcome back 🎉</h2>
            <p>Continue your financial journey!</p>
            <Tabs variant="unstyled" colorScheme="blue">
              <TabList>
                <Tab
                  padding={'10px 0px'}
                  width={'50%'}
                  _selected={{ color: '#0062C3', bg: '#E6F1FC' }}
                >
                  Email Address
                </Tab>
                <Tab
                  padding={'10px 0px'}
                  width={'50%'}
                  _selected={{ color: '#0062C3', bg: '#E6F1FC' }}
                >
                  Phone Number
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <form className="positionForm">
                    <label>Email Address</label>
                    <Input
                      size="lg"
                      placeholder="Enter your Email Address"
                      onChange={e => setEmailValue(e.target.value)}
                      defaultValue={localStorage.getItem("emailAddress")}
                      onKeyPress={handleEmailInput}
                    />

                    <div className="my-4">
                      <label>Password</label>
                      
                      <InputGroup size="lg">
                        <Input
                          type={show ? 'text' : 'password'}
                          placeholder="Enter password"
                          onChange={e => setPin(e.target.value)}
                          onSubmit={login}
                        />
                       
                        <InputRightElement>
                          <Button
                            
                            h="1.75rem"
                            size="sm"
                            onClick={handleClick}
                            variant={"unstyled"}
                          >
                            {show ? <FiEyeOff size={"20px"} /> : <FiEye size={"20px"}/>}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                     
                  
                    </div>

                    <div className="brandColor cursor-pointer  w-1/2" onClick={()=>history('/forgot-password/')} >Forgot Password?</div>

                    <div className="my-3">
                      <Button
                        rounded={'full'}
                        size="lg"
                        onClick={(e)=>login(e)}
                        textColor={'white'}
                        backgroundColor="#0073E5"
                        colorScheme={"#0073E5"}
                        width={'100%'}
                        className="loginButton"
                        isLoading={loading}
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                  <div className="text-center">
                    Don't have an account?{' '}
                    <span
                      onClick={() => history('/create-account')}
                      className="ml-2 cursor-pointer font-medium brandColor"
                    >
                      Create an account
                    </span>
                  </div>
                </TabPanel>
                <TabPanel>
                  <form onSubmit={(e)=>loginPhone(e)} className="positionForm">
                    <label>Phone Number</label>
                    <Input inputMode={"numeric"} defaultValue={localStorage.getItem("phoneNumber")} type={"number"} size="lg"  placeholder="E.g 081212345678" onChange={(e)=>setPhoneValue(e.target.value)} />
                    <div className="my-4">
                      <label>Password</label>
                      <InputGroup size="lg">                   
                        <Input
                          type={show ? 'text' : 'password'}
                          placeholder="Enter password"
                          onChange={e => setPin(e.target.value)}
                         
                        />
                        <InputRightElement>
                          <Button
                          
                            h="1.75rem"
                            size="sm"
                            onClick={handleClick}
                            variant={"unstyled"}

                          >
                            {show ? <FiEyeOff size={"20px"}/> : <FiEye size={"20px"}/>}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </div>

                    <div className="brandColor cursor-pointer  w-1/2" onClick={()=>history('/forgot-password/phone/')} >Forgot Password?</div>

                    <div className="my-3">
                      <Button
                        rounded={'full'}
                        size="lg"
                        onClick={(e)=>loginPhone(e)}
                        textColor={'white'}
                        backgroundColor={"#0073E5"}
                        colorScheme={"#0073E5"}
                        width={'100%'}
                        className="loginButton"
                        isLoading={loading}
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                  <div className="text-center">
                    Don't have an account?{' '}
                    <span
                      onClick={() => history('/create-account')}
                      className="ml-2 cursor-pointer font-medium brandColor"
                    >
                      Create an account
                    </span>
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </div>
      </AnimatedPage>

      <div>
        <ul className="conditionsList">
          <li>Contact</li>
          <li className="conditions">Terms and Conditions</li>
          <li className="conditions">Privacy Policy</li>
        </ul>
      </div>

      <div className="copyrightText text-center my-3">
        <p>&copy; 2022 MinieMoney</p>
      </div>
    </div>
  );
}

export default LoginPage;
