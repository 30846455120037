import React from 'react'
import {Avatar} from "@chakra-ui/react"
import moment from 'moment'
import pending from "../../Icons/pending.svg"
import ongoing from "../../Icons/ongoing.svg"
import completed from "../../Icons/completed.svg"
import disapproved from "../../Icons/declined.svg"




function EarnItTask(props) {
  function gotoEdit(){
    props.editTask(props.task)
    const tasks = document.getElementById("tasks")
    const taskinformation = document.getElementById("taskinfo")
    tasks.style.display = "none"
    taskinformation.style.display="block"

  }
  return (
    <div onClick={()=>gotoEdit()} className="cursor-pointer transactionList flex py-5 justify-between">
        <div className="flex">
        <div className="taskAvatar">
        {props.task.status ==='pending' && <Avatar src={pending} />}
        {props.task.status ==='ongoing' && <Avatar src={ongoing} />}
        {(props.task.status ==='completed' || props.task.status ==='approved')  && <Avatar src={completed} />}
        {props.task.status ==='disapproved' && <Avatar src={disapproved} />}
        <Avatar className="statusBadge" size={"xs"} src={props.task.profileImage}/>
        </div>
        <div className="ml-3">
            <h2 className="font-bold">{props.task.title}</h2>
            <span className="text-xs">Frequency: {props.task.frequency}</span>
        </div> 
        </div>
        <div>
            <div className={"m-0 p-0 font-bold text-lg text-right"}>₦ {props.task.reward}</div>
           
            <span className="text-xs">{moment(Date.parse(props.task.endDate.slice(0,19))).calendar()}</span>
        </div>
    </div>
  )
}

export default EarnItTask