import React, {useEffect} from 'react';
import {
  IconButton,
  Progress,
  Button,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Swal from 'sweetalert2';
import PhoneInput from '../Misc/Forms/PhoneInput';
import { toast } from 'react-toastify';

function ForgotPasswordPhoneForm(props) {
  const [isLoading, setLoading] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState('');
  const [disabled,setDisabled] = React.useState(true)
  const history = useNavigate();

  function goBack() {
    history(-1);
  }

  function setPhoneNumber(e) {
    setPhone(e);
  }

  useEffect(()=>{
    if (phone.length === 11) {
      setDisabled(false)
    } else if (phone.length < 11) {
      setDisabled(true)
    }
  },[phone])

  function sendOtp() {
    if (phone.length !== 0) {
      setLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/forgot-password-phone',
        data: {
          phone: phone.trim().toString()
        },
      })
        .then(response => {
          setLoading(false);
          sessionStorage.setItem('accountType', response.data.data.accountType);
          props.setPhone(response.data.data.phone);
          props.setSessionId(response.data.data.sessionId);
          if (response.status === 201) {
            Swal.fire({
              icon: 'warning',
              title: 'Phone number not found',
              text: response.data.data.message,
              confirmButtonColor: '#0073E5',
            });
          } else {
            toast.success("Otp sent successfully")
            props.nextStep(2);
          }
        })
        .catch(error => {
          setLoading(false)

          let message =
            error.response.data.data.message ===
            'Request failed with status code 503'
              ? 'Our SMS OTP provider is currently unavailable. Try using email instead'
              : error.response.data.data.message;

          Swal.fire({
            icon:"warning",
            title:"Something's wrong 🤔",
            text:message,
            confirmButtonColor: '#0073E5',
            confirmButtonText:"Try again"
        })
        });
    } else {
      setPhoneError('Please enter your phone number');
      setTimeout(() => {
        setPhoneError('');
      }, 1500);
    }
  }

  return (
    <div className="emailVerificationCard" id={props.id}>
      <div className="mb-4">
        <IconButton
          onClick={goBack}
          className="backIconButtonStyle"
          rounded={'full'}
          icon={<ArrowBackIcon />}
        />
      </div>
      <div className="mb-5 relative">
        <Progress colorScheme="blue" rounded={'full'} value={props.progress} />
        <span className="absolute right-0">{props.progress}%</span>
      </div>

      <h2 className="mt-3">Reset your Password</h2>
      <p>
        Kindly enter your MinieMoney registered phone number. We’ll send you a
        verification code to reset your password.
      </p>

      <form>
        <label>Phone Number</label>
        <PhoneInput
          setPhone={e => setPhoneNumber(e)}
          setDisabled={e => setDisabled(e)}
          size={"lg"}
        />
      </form>

      <div className="text-red-400">{phoneError}</div>
      <div className="my-6">
      <Button
        isLoading={isLoading}
        rounded={'full'}
        className="confirmButton"
        color={'white'}
        size="lg"
        width={'100%'}
        onClick={sendOtp}
        isDisabled={disabled}
        background="#0066f5"
        variant={"solid"}
        margin={"16px 0px 0px 0px"}
      >
        Send verification code
      </Button>
      </div>
    </div>
  );
}

export default ForgotPasswordPhoneForm;
