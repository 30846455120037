import React from 'react'
import success from '../../Image/Success.png';
import Modal from "../../components/Modal/Modal"

function SuccessModal(props) {

 const closeSuccess = () =>{
    props.closeSuccess()
 }
  return (
    <Modal isVisible={props.showModal} onClose={() => props.closeModal()}>
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            {props.title}
          </h5>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
          {props.description}
          </p>
          <button onClick={closeSuccess}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2"
          >
            <span className="font-semibold text-base text-[#FFFFFF]">
            {props.btnTitle}
            </span>
          </button>
        </div>
      </Modal>
  )
}

export default SuccessModal