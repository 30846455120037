import { Button } from '@chakra-ui/react';
import React from 'react';
import "./Buttons.css"
import { Icon } from '@chakra-ui/icons';

function PrimaryButton(props) {
  return (
    <Button
      onClick={() => props.onClick()}
      isLoading = {props.loading}
      rounded={"full"}
      width={"full"}
      color={"white"}
      background={"#0066f5"}
      disabled={props.disabled}
      className="confirmButton"
    >
      <div className="flex gap-1 items-center">
      {props.preIcon && <Icon as={props.preIcon}/>}
        <span className="font-semibold text-base">{props.title}</span>
        {props.postIcon && <Icon fontSize="sm" as={props.postIcon}/>}
      </div>
    </Button>
  );
}

export default PrimaryButton;



//props available for primary button
// title: for text on button
// onClick: for firing action
// loading: for loading state
// preIcon: for if button has an icon before text
// postIcon: for if button has an icon after text