import React from 'react';
import Logo from '../../../Image/Logo.png';
import { useNavigate } from 'react-router';
import ForgotPasswordForm from '../../../components/ForgotPassword/ForgotPasswordForm';
import VerifyOtpForm from '../../../components/ForgotPassword/VerifyOtpForm';
import ResetPasswordForm from '../../../components/ForgotPassword/ResetPassword';
import axios from 'axios';
import Swal from 'sweetalert2';



function ForgotPassword() {
  document.title = "Forgot Password | MinieMoney"
  const history = useNavigate();
  const [email,setEmail] = React.useState("")
  const [sessionId,setSessionId] = React.useState("")
  const [processId,setProcessId] = React.useState("")
  const [loading,setLoading] = React.useState("")
  const accountType = sessionStorage.getItem("accountType")


  function nextStep(e){
    let forgotpasswordform = document.getElementById("forgotpassword")
    let verifyotpform = document.getElementById("verifyotp")
    let resetpasswordform = document.getElementById("resetpassword")
   
    forgotpasswordform.style.display = e ===1?"block":"none"
    verifyotpform.style.display = e ===2?"block":"none"
    resetpasswordform.style.display = e === 3 ? "block":"none"
  }

  function setPin(e){
    setLoading(true)
    axios({
        method:"POST",
        url:process.env.REACT_APP_API_URL+"/reset-password/"+accountType,
        data:{
            email:email.trim(),
            password:e,
            processId:processId
        }
    }).then((response)=>{
        setLoading(false)
       if(response.status === 200){
        Swal.fire({
            icon:"success",
            title:"MiniePIN® Reset Successful",
            text:response.data.data.message,
            confirmButtonColor: '#0073E5',
            confirmButtonText:"Proceed to Sign in"
        }).then((result)=>{
            if(result.isConfirmed){
                history("/login/")
            }
        })
      } else if(response.status === 201){
        Swal.fire({
          icon:"warning",
          title:"Password Reset Unsuccessful",
          text:response.data.data.message,
          confirmButtonColor: '#0073E5',
          confirmButtonText:"Try again"
      })
      }
    }).catch((error)=>{
        setLoading(false)
        Swal.fire({
          icon:"warning",
          title:"Password Reset Unsuccessful",
          text:error.response.data.data.message,
          confirmButtonColor: '#0073E5',
          confirmButtonText:"Try again"
      })

    })


  
  }



  return (
    <div className="bgContainer">
      <div>
        <div className="flex justify-center my-2">
          <img
            className="cursor-pointer appLogo"
            onClick={() => history('/')}
            src={Logo}
            alt="miniemoneylogo"
          />
        </div>
        <div className="formWithImage">
          <div className="formCard">
           <ForgotPasswordForm id={"forgotpassword"} progress={20} setSessionId={(e)=>setSessionId(e)} setEmail={(e)=>setEmail(e)} nextStep={(e)=>nextStep(e)}/>
           <VerifyOtpForm id={"verifyotp"} setProcessId={(e)=>setProcessId(e)} nextStep={(e)=>nextStep(e)} email={email}  setSessionId={(e)=>setSessionId(e)} sessionId={sessionId} progress={50}/>
           <ResetPasswordForm id={"resetpassword"} nextStep={(e)=>nextStep(e)} loading={loading} setPin={(e)=>setPin(e)} progress={80}/>
          </div>
        </div>
      </div>

      <div>
        <ul className="conditionsList">
          <li>Contact</li>
          <li className="conditions">Terms and Conditions</li>
          <li className="conditions">Privacy Policy</li>
        </ul>
      </div>

      <div className="copyrightText text-center my-3">
        <p>&copy; 2022 MinieMoney</p>
      </div>
    </div>
  );
}

export default ForgotPassword;
