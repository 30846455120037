import React, { Fragment } from 'react';
import './EditProfile.css';
import { useNavigate } from 'react-router';
import Setting from './Setting';
import EditProfileHeader from './EditProfileHeader';
import AvatarSelect from './AvatarSelect';
import ChangeNumber from './ChangeNumber';

function AccountCenter(props) {
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const back = () => {
    history('/account/' + accountType);
  };
  const personalSettings = [
    { title: 'First Name', data: props.user.firstName },
    { title: 'Last Name', data: props.user.lastName },
    {
      title: 'MinieTAG',
      data: props.user.minietag,
      type: 'minietag',
      link: 'Set your MinieTAG',
    },
    {
      title: 'Date of Birth',
      link: 'Enter your Date of Birth',
      type: 'dob',
      data: props.user.dob,
    },
    {
      title: 'Gender',
      link: 'Select your gender',
      type: 'gender',
      data: props.user.gender,
    },
  ];

  const contactSettings = [
    {
      title: 'Phone Number',
      link: 'Enter your phone number',
      type: 'phone',
      value: props.user.phone,
      data: props.user.phone,
    },
    {
      title: 'Email address',
      link: props.user.email,
      type: 'email',
      value: props.user.email,
      data: props.user.email,
    },
  ];

  const additionalSettings = [
    {
      title: 'What does your youngster call you?',
      link: 'Enter name',
      type: 'nickname',
      data: props.user.nickname,
    },
    {
      title: 'Marital Status',
      link: 'Select your marital status',
      type: 'maritalStatus',
      data: props.user.maritalStatus,
    },
  ];

  const goBack = () => {
    history(-1);
  };

  return (
    <Fragment>
      <div className="dashboardCenter pb-12" id="edit">
        {/* page heading */}
        <EditProfileHeader
          color={'walletAccountArrow'}
          title="My Profile"
          goBack={goBack}
        />

        {/* profileinfo */}
        <AvatarSelect user={props.user} />

        {/* account settings */}
        <div>
          <div className="settingsTitle">Personal Information</div>
          {personalSettings.map((setting, i) => (
            <Setting user={props.user} setting={setting} key={i} />
          ))}
        </div>

        {/* contact information */}
        <div>
          <div className="settingsTitle">Contact Information</div>
          {contactSettings.map((setting, i) => (
            <Setting setting={setting} key={i} />
          ))}
        </div>

        {/* additional settings */}
        {props.accountType === 'adult' && (
          <div>
            <div className="settingsTitle">Additional Information</div>
            {additionalSettings.map((setting, i) => (
              <Setting user={props.user} setting={setting} key={i} />
            ))}
          </div>
        )}

        <div className="detailTitle text-center mt-3 mb-9">
          Do you want to make any changes on your account information? Send us a
          message at{' '}
          <span className="brandcolor font-semibold">hello@miniemoney.com</span>
        </div>
      </div>
      <ChangeNumber user={props.user} Back={back} />
    </Fragment>
  );
}

export default AccountCenter;
