import React,{useEffect} from 'react';
import { Avatar,Button } from '@chakra-ui/react';
// import { useNavigate } from 'react-router';
// import axios from 'axios';
// import Swal from 'sweetalert2';
import Confetti from 'react-confetti'

function CompleteYoungster(props) {


    const gotodashboard = () => {
      window.location.href="/dashboard/adult"
    }

   useEffect(()=>{
        window.scrollTo(-10,0)
    })
 

  return (
    <div id={props.id} className="hiddenForm">
      <Confetti/>
     <div className="flex justify-center">
    <div className="relative">
      <Avatar className="avatarSize" cursor={"pointer"} rounded={"full"}  src={props.image}/>
    </div>
      </div>

      <div className="text-center">
      <h2 className=" text-2xl font-bold my-3 text-center">Youngster’s Account created</h2>
      <div className="w-3/4 m-auto">You have made the biggest decision for the financial future of your youngster.</div>
      </div>

      


    
      <div className="my-6 flex justify-center">
       
        <Button
          rounded={'full'}
          className="journeyButton"
          color={'white'}
          size="lg"
          id="nextButton"
          onClick={gotodashboard}
          loadingText="Checking"
          _hover={{background:"#0066f5"}}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default CompleteYoungster;
