import React , { useState } from 'react';
import {
  Progress,
  IconButton,
  Button,
  InputGroup, InputLeftAddon,Input
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon, ChevronDownIcon } from '@chakra-ui/icons';
import './EmailVerificationForm.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import flag from "../../Icons/Flag.svg"

function InviteParent(props) {
  const userType = window.location.pathname.split('/')[2];
  const [parentName,setParentName] = useState("")
  const [parentEmail,setParentEmail] = useState("")
  const [parentNumber,setParentNumber] = useState("")
  const [coparentName,setCoparentName] = useState("")
  const [coparentEmail,setCoparentEmail] = useState("")
  const [coparentNumber,setCoparentNumber] = useState("")
  const [error,setError] = useState("")
  const [loading,setLoading] = useState(false)
  const userData = JSON.parse(sessionStorage.getItem("userData"))
  const emailRegex = /.+@.+\..+/
  const createAccount = () =>{
    props.nextStep(8)
  }

  const setPhoneNumber = (e) =>{
    setParentNumber(e)
    setError("Please enter a valid phone number")
    if(e.length === 11){
      setError("")
    }
  }

  const setcoPhoneNumber = (e) =>{
    setCoparentNumber(e)
    setError("Please enter a valid phone number")
    if(e.length === 11){
      setError("")
    }
  }


  const setParentEmailValue = (e) =>{
    setParentEmail(e.target.value)
    setError("Please enter a valid email")
    if(e.target.value.match(emailRegex)){
      setError("")
    }
  }

  const setCoparentEmailValue = (e) =>{
    setCoparentEmail(e)
  }

  const inviteParent = () =>{
    if(userType === 'youngster'){
      if(parentName === '' || parentNumber ===''){
        setError("You need to enter the details of atleast one parent")
        return
      }

      if(error !==''){
        return
      }
    setLoading(true)
    const parents = [
      {firstName:parentName,email:parentEmail,phone:parentNumber},
      {firstName:coparentName,email:coparentEmail,phone:coparentNumber}
    ]
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/youngster-invite-parent",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json",
        Accept:"*/*"
      },
      data:{
        parents:parents,
        youngsterName:userData.firstName
      }
    }).then(()=>{
      setLoading(false)
      Swal.fire({
        icon:"success",
        title:"Invite sent successfully!",
        text:"An invite has been sent to your parents to join MinieMoney",
        confirmButtonColor:"#2b5fd0"
      }).then((result) =>{
        if(result.isConfirmed){
          props.nextStep(8)
        }
      })
    }).catch((error)=>{
      console.log(error.message)
      Swal.fire({
        icon:"error",
        title:"Invite not sent!",
        text:"We could not send an invite to your parents. Try again later",
        confirmButtonColor:"#2b5fd0"
      })
      setLoading(false)
    })
  }
  else{
    if(coparentName === '' || coparentEmail === ''){
      setError("You need to enter your coparent details")
      return
  }
    setLoading(true)
    const coparent = {
      firstName:coparentName,
      email:coparentEmail,
      phone:coparentNumber
    }
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/adult-invite-coparent",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json",
        Accept:"*/*"
      },
      data:{
        coparent:coparent,
        parentName:userData.firstName
      }
    }).then(()=>{
      setLoading(false)
      setLoading(false)
      Swal.fire({
        icon:"success",
        title:"Invite sent successfully!",
        text:"An invite has been sent to your coparent to join MinieMoney",
        confirmButtonColor:"#2b5fd0"
      }).then((result) =>{
        if(result.isConfirmed){
          props.nextStep(8)
        }
      })
    }).catch((error)=>{
      console.log(error.message)
      Swal.fire({
        icon:"error",
        title:"Invite not sent!",
        text:"We could not send an invite to your parents. Try again later",
        confirmButtonColor:"#2b5fd0"
      })
      setLoading(false)
    })
  }

  }





  return (
    <div className="hiddenForm" id={props.id}>
      <div className="mb-5 flex justify-between">
        <IconButton
          onClick={() => props.nextStep(6)}
          className="backIconButtonStyle"
          rounded={'full'}
          icon={<ArrowBackIcon />}
        />

        <Button
        //   isDisabled={disabled}
          loadingText="Creating account"
          className={
            props.isSkipLoading ? 'w-1/3 text-blue-600' : 'w-1/5 text-blue-600'
          }
          onClick={createAccount}
          rounded={'full'}
          isLoading={props.isSkipLoading}
        >
          Skip
        </Button>
      </div>
      <div className="relative">
        <Progress colorScheme="blue" rounded={'full'} value={props.progress} />
        <span className="absolute right-0">{props.progress}%</span>
      </div>

      <h2 className="mt-7">Invite your {userType==='adult'?'co-parent':'parents'}</h2>
      <p>
        You can invite your {userType==='adult'?'co-parent':'parents'} to have access to your {userType==='adult'?'youngster\'s':''} MinieMoney account
      </p>

      <span className="text-red-500">{error}</span>
   {userType === 'youngster' && <div>
      <h3 className="parentTitle">Parent</h3>
      <div>
      <div>
      <label>First Name</label>
      <Input onChange={(e)=>setParentName(e.target.value)} placeholder="First Name"/>
      </div>

      <div className="my-3">
        <label>Email address</label>
        <Input onChange={(e)=>setParentEmailValue(e)} placeholder="Email address"/>
      </div>

      <div>
      <label>Phone Number</label>
        <InputGroup>
        <InputLeftAddon background={"white"}>
        <img src={flag} alt="flag"/>
        <span className="ml-2">234</span>
        <ChevronDownIcon/>
        </InputLeftAddon>
        <Input inputMode={"numeric"} placeholder={"e.g 08012345678"} prefix='234' type={"number"} onChange={(e)=>setPhoneNumber(e.target.value)}></Input>
        </InputGroup>
      </div>

      </div>
      </div>}

      <h3 className="parentTitle">Co-parent</h3>
      <div>
      <div>
      <label>First Name</label>
      <Input onChange={(e)=>setCoparentName(e.target.value)} placeholder="First Name"/>
      </div>

      <div className="my-3">
        <label>Email address</label>
        <Input onChange={(e)=>setCoparentEmailValue(e.target.value)} placeholder="Email address"/>
      </div>


      <div>
      <label>Phone Number</label>
        <InputGroup>
        <InputLeftAddon background={"white"}>
        <img src={flag} alt="flag"/>
        <span className="ml-2">234</span>
        <ChevronDownIcon/>
        </InputLeftAddon>
        <Input inputMode={"numeric"} placeholder={"e.g 08012345678"} prefix='234' type={"number"} onChange={(e)=>setcoPhoneNumber(e.target.value)}></Input>
        </InputGroup>
      </div>


      </div>

     

      <div className="my-6 flex justify-between">
        <Button
          onClick={() => props.nextStep(6)}
          rounded={'full'}
          size="lg"
          color={'#A9ABAD'}
        >
          <ArrowBackIcon className="mr-3" /> Back{' '}
        </Button>
        <Button
         onClick={inviteParent}
          rounded={'full'}
          className="activeButton"
          color={'white'}
          size="lg"
          isLoading={loading}
        >
          Next <ArrowForwardIcon className="ml-3" />
        </Button>
      </div>
    </div>
  );
}

export default InviteParent;
