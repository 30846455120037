import React, { useState } from 'react';
import TransactionPin from '../Misc/TransactionPin';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';
import TertiaryButton from '../Misc/Buttons/TertiaryButton';
import ResendOtp from '../Misc/Counter/ResendOtp';
import Modal from './Modal';
import { Button } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

function PhoneOtpModal(props) {
  const [countDown, setCountDown] = useState('');
  const [otpLoading, setOtpLoading] = useState(false);

  const back = () => {
    props.closeModal();
  };

  const enterOTP = e => {
    props.setPin(e);
  };

  const resendOtp = e => {
    console.log(e);
    setOtpLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/resend-phone-otp-auth',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        sessionId: props.sessionId,
        phone: props.phone,
      },
    })
      .then(response => {
        toast.success(response.data.data.message);
        setOtpLoading(false);
        console.log(response);
        props.setSessionId(response.data.data.sessionId);
      })
      .catch(error => {
        setOtpLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 🤔",
          text: error.response.data.data.message,
          confirmButtonColor: '#0066f5',
        });
      });
  };

  return (
    <Modal isVisible={props.showModal} onClose={() => props.closeModal()}>
      <div className="my-6">
        <h2 className="text-2xl text-center font-bold">
          Confirm Your Phone Number
        </h2>

        <p className="px-6 py-4 text-center">
          Kindly enter the verification code sent to your phone number{' '}
          <span className="brandcolor font-semibold">{props.phone}</span>
        </p>

        <TransactionPin class={'auth'} setPin={e => enterOTP(e)} />

        {countDown !== 0 && (
          <div className="text-center my-5">
            Didn’t receive a code?{' '}
            <ResendOtp countDown={e => setCountDown(e)} />
          </div>
        )}

        {countDown === 0 && (
          <div className="text-center my-5">
            Didn’t receive a code?{' '}
            <Button
              isLoading={otpLoading}
              onClick={resendOtp}
              ml={'10px'}
              rounded={'full'}
              size="sm"
              color={'#0073E5'}
              variant={'unstyled'}
              fontWeight={'bold'}
            >
              Resend code
            </Button>{' '}
          </div>
        )}

        <div className="flex w-full space-x-3 p-5">
          <TertiaryButton
            preIcon={ArrowBackIcon}
            title={'Back'}
            onClick={back}
            width={'250px'}
          />

          <PrimaryButton
            postIcon={ArrowForwardIcon}
            title={'Confirm'}
            disabled={props.disabled}
            onClick={props.createAccountNumber}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
}

export default PhoneOtpModal;

// you need to pass the following props
//loading, disabled, phone, sessionId, createAccountNumber (function), showModal(function), closeModal (function), setSessionId(function)
