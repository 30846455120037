import React from 'react';
import CreateAccountComponent from '../../../components/CreateAccount/CreateAccountComponent';


function CreateAccount() {
  document.title="Create Account | MinieMoney"
  return (
      <div>
        <CreateAccountComponent />
      </div>
  
  );
}

export default CreateAccount;
