import React from 'react'
import Modal from './Modal'
import success from '../../Image/Success.png';
import { ArrowForwardIcon } from '@chakra-ui/icons';

function SuccessModal(props) {
  return (
    <Modal isVisible={props.showModal} onClose={() => props.closeModal()}>
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h2 className="text-2xl font-semibold max-w-[456px] text-center mt-3">
            {props.title}
          </h2>
          <p className="text-base font-normal max-w-[456px] text-center my-4">
            {props.description}
          </p>

          <button
            onClick={() => props.next()}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
             bg-[#005FEB] text-[#E6EFFD] rounded-full mt-2 hover:border-[2px]"
          >
            <span className="font-semibold text-base">{props.btnTitle?props.btnTitle:"Done"}</span>
            {props.btnTitle && <ArrowForwardIcon />}
          </button>
        </div>
      </Modal>

  )
}

export default SuccessModal