import React, { useState } from 'react';
import {
  Avatar,
  CloseButton,
  Input,
  Button,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import AnimatedPage from '../Animation/AnimatedPage';
import './App.css';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';

function AppLogin() {
  const history = useNavigate();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const accountType = localStorage.getItem('accountType');
  const dispatch = useDispatch();

  const goToApp = () => history('/app');

  const goToLogin = () =>
    history('/login/');

  const goToForgotPassword = () =>
    history('/forgot-password/' + localStorage.getItem('accountType'));

  const login = () => {
    alert(localStorage.getItem("emailAddress"))
    setLoading(true);
    if (localStorage.getItem('emailAddress') !== '' && pin !== '') {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/login/',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          email: localStorage.getItem('emailAddress'),
          password: pin,
        },
      })
        .then(response => {
          if (response.status === 200) {
            // history("/dashboard/"+accountType)
            sessionStorage.setItem('minieToken', response.data.token);
            dispatch(fetchUser(accountType)).then(() => {
              setLoading(false);
              window.location.href = '/dashboard/' + accountType;
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Login unsuccessful',
              text:
                response.data.errors.email +
                ' ' +
                response.data.errors.password,
              confirmButtonColor: '#0073E5',
            });
          }
        })
        .catch(error => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Login unsuccessful',
            text: 'Your email/password is wrong. Kindly recheck it',
            confirmButtonColor: '#0073E5',
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'warning',
        title: 'Please enter your details',
        text: 'Kindly enter your email and password to login',
        confirmButtonColor: '#0066f5',
      });
    }
  };

  const loginPhone = () => {
    setLoading(true);
    if (localStorage.getItem('phoneNumber') !== '' && pin !== '') {
      const userType = window.location.pathname.split('/')[2];
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/login/phone/' + userType,
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          phone: localStorage.getItem('phoneNumber'),
          password: pin,
        },
      })
        .then(response => {
          if (response.status === 200) {
            // history("/dashboard/"+accountType)
            sessionStorage.setItem('minieToken', response.data.token);
            dispatch(fetchUser(accountType)).then(() => {
              setLoading(false);
              window.location.href = '/dashboard/' + accountType;
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Login unsuccessful',
              text:
                response.data.errors.phone +
                ' ' +
                response.data.errors.password,
              confirmButtonColor: '#0073E5',
            });
          }
        })
        .catch(error => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Login unsuccessful',
            text: 'Your email/password is wrong. Kindly recheck it',
            confirmButtonColor: '#0073E5',
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'warning',
        title: 'Please enter your details',
        text: 'Kindly enter your email and password to login',
        confirmButtonColor: '#0066f5',
      });
    }
  };
  return (
    <AnimatedPage>
      <div className="appLoginContainer">
        <CloseButton
          margin={'0px 10px 40px 10px'}
          size={'20px'}
          onClick={goToApp}
        ></CloseButton>
        <div className="flex justify-between">
          <div className="appLoginForm">
            <h2>Toheeb</h2>
            <p>Securely login to MinieMoney</p>
          </div>

          <Avatar />
        </div>

        <div className="my-4">
          <label>MiniePIN</label>

          <InputGroup size="lg">
            <Input
              type={show ? 'text' : 'password'}
              placeholder="Enter password"
              onChange={e => setPin(e.target.value)}
            />

            <InputRightElement>
              <Button
                background={'white'}
                h="1.75rem"
                size="sm"
                onClick={handleClick}
                variant={'unstyled'}
              >
                {show ? <FiEyeOff size={'20px'} /> : <FiEye size={'20px'} />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </div>

        <div>
          <Button
            isLoading={loading}
            onClick={
              ()=>localStorage.getItem('emailAddress') !== ''
                ? login()
                : loginPhone()
            }
            className={'confirmButton'}
            background={'#0080ff '}
            variant={'solid'}
            rounded={'full'}
            color={'white'}
            width={'full'}
          >
            Login
          </Button>
        </div>

        <div
          onClick={() => goToLogin()}
          className="my-9 cursor-pointer text-center"
        >
          <p className="text-blue-500 font-bold">No, I am not Toheeb!</p>
        </div>

        <div
          onClick={() => goToForgotPassword()}
          className="my-9 cursor-pointer text-center"
        >
          <p className="font-bold">Forgot MiniePIN?</p>
        </div>
      </div>
    </AnimatedPage>
  );
}

export default AppLogin;
