import React from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import { useSelector } from 'react-redux'
import CreateTask from "../../../components/EarnIt/CreateTask/CreateTask"

function AssignTask() {
  const user = useSelector((state) => state.user.value)

  React.useEffect(()=>{
   window.scrollTo(0,0)
  },[])

  return (
    <div>  
      <div className="dashboardPage">
        
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
        <CreateTask id={"overview"} user={user} />
        </div>

        <div className="hideOnMobile">
        <DashboardRight />
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default AssignTask