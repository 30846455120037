import React from 'react';
import './EarnIt.css';
// import GroupUsers from '../../Icons/GroupUsers.svg'
import EarnitHeader from './EarnitHeader';
import EarnItCard from './EarnItCard';
import EarnItTask from './EarnItTask';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Skeleton } from '@chakra-ui/react';

function EarnItCenter(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const gotoHistory = () => {
    window.scrollTo(0, 0);
    const tasks = document.getElementById('tasks');
    const earnit = document.getElementById('earnitOverview');
    earnit.style.display = 'none';
    tasks.style.display = 'block';
  };

  const editTask = e => {
    const taskdetails = document.getElementById('tasks');
    const taskinformation = document.getElementById('taskinfo');
    const earnitoverview = document.getElementById('earnitOverview');
    taskdetails.style.display = 'none';
    taskinformation.style.display = 'block';
    earnitoverview.style.display = 'none';
    props.selectTask(e);
  };

  return (
    <div id={props.id} className="dashboardCenter pb-9">
      <EarnitHeader
        accountType={props.accountType}
        tasks={props.tasks}
        user={props.user}
      />
      <h2 className="text-xl font-bold mb-2">EarnIT History</h2>
      {props.tasks.length === 0 && props.loading && (
        <EarnItCard
          link={props.accountType === 'adult' ? '/create-task/adult' : '/account/youngster/parents'}
          buttonText={
            props.accountType === 'adult'
              ? 'Create Tasks for kids'
              : 'Invite your parent'
          }
          details={
            props.accountType === 'adult'
              ? 'Teach your kids about money and discipline by assigning tasks for them to complete and earn.'
              : 'Invite your parent to assign you tasks and start earning based on tasks completed.'
          }
          user={props.user}
        />
      )}
      {props.tasks.length > 0 && (
        <div className="pb-12">
          <div className="text-right">
            <div onClick={gotoHistory} className="taskHistory">
              Task History <ArrowForwardIcon className="ml-2" />
            </div>
          </div>
          {props.tasks
            .slice(0, 5)
            .map((task, i) => (
              <EarnItTask editTask={() => editTask(task)} key={i} task={task} />
            ))}
        </div>
      )}

      <Skeleton
        height="60px"
        m={'10px 0px'}
        borderRadius={'10px'}
        isLoaded={props.loading}
      ></Skeleton>

      <Skeleton
        height="60px"
        m={'10px 0px'}
        borderRadius={'10px'}
        isLoaded={props.loading}
      ></Skeleton>

      <Skeleton
        height="60px"
        m={'10px 0px'}
        borderRadius={'10px'}
        isLoaded={props.loading}
      ></Skeleton>
    </div>
  );
}

export default EarnItCenter;
