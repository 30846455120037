import { ArrowForwardIcon } from '@chakra-ui/icons';
import React, { useState } from 'react';
import './EditProfile.css';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Input,
  Radio,
  RadioGroup,
  Flex,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchUser } from '../../store/userLogin';
import { useDispatch } from 'react-redux';
import ChangePhoneModal from '../Account/ChangePhone.js/ChangePhoneModal';
import MinieTagForm from '../Misc/Forms/MinieTagForm';

function Setting(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [nickname, setNickName] = useState('');
  const [maritalStatus, setMarital] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phone] = useState('');
  const [loading, setBtnLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const accountType = window.location.pathname.split('/')[2];
  const [username, setUsername] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const showPhoneOTP = () => {
    setShowModal(true);
  };

  const updateProfile = async () => {
    const payload = {
      nickname: nickname,
      maritalStatus: maritalStatus,
      dob: dob,
      gender: gender,
      email: email,
      phone: phone,
      minietag: username,
    };
    const type = props.setting.type;
    if (
      nickname !== '' ||
      maritalStatus !== '' ||
      dob !== '' ||
      gender !== '' ||
      email !== '' ||
      phone !== '' ||
      username !== ''
    ) {
      setError('');

      const reqPayload = {
        [type]: payload[type],
      };

      setBtnLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL + '/edit-profile/' + accountType,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
          ContentType: 'application/json',
        },
        data: reqPayload,
      })
        .then(response => {
          dispatch(fetchUser(accountType)).then(() => {
            setBtnLoading(false);
            onClose();
            Swal.fire({
              icon: 'success',
              title: 'Profile Updated Successfully',
              text: response.data.data.message,
              confirmButtonColor: '#005FEB',
              confirmButtonText: 'Done',
            }).then(result => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          });
        })
        .catch(error => {
          setBtnLoading(false);
          onClose();
          Swal.fire({
            icon: 'error',
            title: 'Profile not Updated',
            text: error.response.data.data.message,
            confirmButtonColor: '#005FEB',
            confirmButtonText: 'Try again',
          });
        });
    } else {
      setError('Please enter your ' + props.setting.type);
    }
  };

  return (
    <div className="cursor-pointer transactionList flex py-2 justify-between">
      <div className="flex">
        <div className="ml-3">
          <h2 className="detailTitle mt-1 font-medium">
            {props.setting.title}
          </h2>
        </div>
      </div>
      <div>
        {props.setting.data !== '' && props.setting.data}
        {(props.setting.data === '' ||
          props.setting.data === undefined ||
          props.setting.data === null) &&
          props.setting.type !== 'phone' && (
            <span onClick={() => onOpen()} className="brandcolor text-sm">
              {props.setting.link} {props.setting.link && <ArrowForwardIcon />}
            </span>
          )}
        {(props.setting.data === '' ||
          props.setting.data === undefined ||
          props.setting.data === null) &&
          props.setting.type === 'phone' && (
            <span onClick={() => showPhoneOTP()} className="brandcolor text-sm">
              {props.setting.link} {props.setting.link && <ArrowForwardIcon />}
            </span>
          )}
      </div>

      <Modal
        blockScrollOnMount={false}
        size={'xl'}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-medium text-xl">
              Edit{' '}
              {props.setting.title === 'What does your youngster call you?'
                ? 'Nickname'
                : props.setting.title}
            </h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div id="firstStep">
              {props.setting.type === 'dob' && (
                <form>
                  <label>Kindly enter your Date of birth </label>
                  <Input
                    onInput={() => setDisabled(false)}
                    onChange={e => setDob(e.target.value)}
                    type="date"
                  />
                </form>
              )}

              {props.setting.type === 'gender' && (
                <form>
                  <p className="my-4">Which are you? </p>
                  <RadioGroup
                    onInput={() => setDisabled(false)}
                    onChange={e => setGender(e)}
                  >
                    <Flex justifyContent={'space-between'} direction="row">
                      <Radio value="Male">Male</Radio>
                      <Radio value="Female">Female</Radio>
                      <Radio value="Undisclosed">Prefer not to say</Radio>
                    </Flex>
                  </RadioGroup>
                </form>
              )}

              {props.setting.type === 'nickname' && (
                <form>
                  <label>Kindly enter your nickname</label>
                  <Input
                    onInput={() => setDisabled(false)}
                    onChange={e => setNickName(e.target.value)}
                  />
                  <p className="text-red-400">{error}</p>
                </form>
              )}

              {props.setting.type === 'minietag' && (
                <form>
                  <label>Kindly enter your MinieTAG</label>
                  <MinieTagForm
                    setUsername={e => setUsername(e)}
                    setMinietagError={e => setDisabled(e)}
                  />
                </form>
              )}

              {props.setting.type === 'maritalStatus' && (
                <form>
                  <label>Kindly enter your marital status</label>
                  <Select
                    defaultValue={'Select Status'}
                    onChange={e => setMarital(e.target.value)}
                    onInput={() => setDisabled(false)}
                  >
                    <option value="" disabled selected>
                      Choose Marital status
                    </option>
                    <option value={'single'}>Single</option>
                    <option value={'relationship'}>In a relationship</option>
                    <option value={'divorced'}>Divorced</option>
                    <option value={'married'}>Married</option>
                    <option value={'married'}>Widow</option>
                  </Select>
                  <p className="text-red-500">{error}</p>
                </form>
              )}

              {props.setting.type === 'email' && (
                <form>
                  <label>Kindly enter your email address</label>
                  <Input
                    onChange={e => setEmail(e.target.value)}
                    defaultValue={props.setting.value}
                  />
                  <p className="text-red-500">{error}</p>
                </form>
              )}

              <div className="my-6">
                <Button
                  rounded={'full'}
                  width={'full'}
                  background={'#0066f5'}
                  colorScheme="#0066f5"
                  className="confirmButton"
                  onClick={() => updateProfile()}
                  isLoading={loading}
                  disabled={disabled}
                >
                  Update
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ChangePhoneModal
        type="phone"
        showModal={showModal}
        setShowmodal={e => setShowModal(e)}
      />
    </div>
  );
}

export default Setting;
