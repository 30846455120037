import React, { useState } from 'react';
import KYCHeader from '../KYC/KYCHeader';
import { Button } from '@chakra-ui/react';
import Instructions from './Instructions';
import PhoneInput from '../../components/Misc/Forms/PhoneInput';
import PhoneOtpModal from '../../components/Modal/PhoneOtpModal';
import axios from 'axios';
import Swal from 'sweetalert2';
import SuccessModal from '../../components/Modal/SuccessModal';
import { useNavigate } from 'react-router';
import { fetchUser } from '../../store/userLogin';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

function CreateAccountNumber(props) {
  const accountType = window.location.pathname.split('/')[2];
  const [phone, setPhone] = useState(props.user.phone);
  const [isDisabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setTimeout(()=>{
      if (phone.length === 11) {
        setDisabled(false);
      }
    },100)

    if(phone.length !== 11) {
      setDisabled(true);
    }
  }, [phone]);

  React.useEffect(() => {
    if (otp.length === 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [otp]);

  const handleDisabled = () => {
    setDisabled(false);
  };

  const goToOVerview = () => {
    history('/wallet/' + accountType + '/account-number');
  };

  const verifyPhone = () => {
    setLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_ADMIN_URL + '/verify-phone-sterling',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
      },
      data: {
        phone: phone ? phone : props.user.phone,
        accountType,
      },
    })
      .then(response => {
        setLoading(false);
        console.log(response);
        setSessionId(response.data.data.sessionId);
        setShowModal(true);
        setDisabled(true);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 😢",
          text: error.response.data.data.message,
          confirmButtonColor: '#0066f5',
        });
      });
  };

  const createAccountNumber = () => {
    //call the create account number function
    setLoading(true);
    console.log(sessionId);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_ADMIN_URL + '/create-wallet-tier1',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        otp: otp,
        sessionId: sessionId,
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        mobile: phone,
        dob: props.user.dob,
        gender: props.user.gender,
      },
    })
      .then(() => {
        setLoading(false);
        setShowModal(false);
        setShowSuccess(true);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 🤔",
          text: error.response.data.data.message,
          confirmButtonColor: '#0066f5',
        });
      });
  };

  const viewAccount = () => {
    const id = toast.loading('Fetching bank account details...');
    dispatch(fetchUser(accountType)).then(() => {
      toast.update(id, {
        type: 'success',
        autoClose: 2000,
        render: 'Account details fetched successfully',
      });
      window.location.href = '/wallet/' + accountType + '/account-number';
    });
  };

  return (
    <div id={props.id} className="dashboardCenter walletScreensContainer">
      <KYCHeader goBack={goToOVerview} title="MinieMoney Bank Account" />
      <div className="my-6">
        <label>Phone Number</label>
        <PhoneInput
          size={'lg'}
          setPhone={e => setPhone(e)}
          setDisabled={e => handleDisabled(e)}
          defaultValue={props.user.phone}
        />
      </div>

      <Instructions />

      <Button
        onClick={verifyPhone}
        className="confirmButton"
        isDisabled={isDisabled}
        width={'full'}
        colorScheme={'#0066f5'}
        background={'#0066f5'}
        rounded={'full'}
        isLoading={loading}
      >
        Confirm
      </Button>

      <PhoneOtpModal
        showModal={showModal}
        phone={phone}
        closeModal={() => setShowModal(false)}
        createAccountNumber={() => createAccountNumber()}
        loading={loading}
        disabled={isDisabled}
        setPin={e => setOtp(e)}
        sessionId={sessionId}
        setSessionId={e => setSessionId(e)}
      />

      <SuccessModal
        showModal={showSuccess}
        closeModal={() => setShowSuccess(false)}
        title={'MinieMoney Bank Account Created'}
        description={
          'Your MinieMoney bank account has been successfully created and assigned to you. Check your account details by clicking on the button below.'
        }
        btnTitle={'Check account details'}
        next={() => viewAccount()}
      />
    </div>
  );
}

export default CreateAccountNumber;
