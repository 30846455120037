import React from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import KycCenter from '../../../components/KYC/Kyc';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import './Kyc.css';
import { useSelector } from 'react-redux';
import Bvn from './Bvn';
import Id from './Id';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchKYCs } from '../../../store/fetchKyc';

function KYC() {
  document.title = 'KYC | MinieMoney';
  const user = useSelector(state => state.user.value);
  const accountType = window.location.pathname.split('/')[2];
  const kycs = useSelector(state => state.kycs.value);
  const kycloading = useSelector(state => state.kycs.loading);
  const history = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (
      sessionStorage.getItem('minieToken') == null &&
      sessionStorage.getItem('userData') == null
    ) {
      history('/login/');
    }
  }, [history, accountType, user]);

  React.useEffect(() => {
    dispatch(fetchKYCs());
  }, [dispatch]);

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <KycCenter
              id={'overview'}
              user={user}
              kycs={kycs}
              kycloading={kycloading}
            />
            <Bvn id={'bvn'} user={user} />
            <Id id={'id'} user={user} />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default KYC;
