import React from 'react';
import './Account.css';
import YoungsterAccountSingle from './YoungsterAccountSingle';
import YoungsterHeader from './YoungsterHeader';
import { unslugify } from 'unslugify';
import { useNavigate } from 'react-router';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {Button} from "@chakra-ui/react"
import TransactionList from '../MinieWallet/TransactionList';

function AccountCenter(props) {
  const history = useNavigate();
  const youngsterName = window.location.pathname.split('/')[3]
  const youngster = JSON.parse(sessionStorage.getItem("youngster"))
  

  const goBack = () => {
    history(-1);
  };

  const goToYoungsterTransaction = () =>{
    window.scrollTo(0, 0)
   document.getElementById("youngster-single").style.display = "none"
   document.getElementById("youngster-transactions").style.display = "block"
  }

  return (
    <div id={props.id} className="dashboardCenter">
      <YoungsterHeader
        title={unslugify(youngsterName)}
        goBack={goBack}
      />

   
            <div>
              <YoungsterAccountSingle youngster={youngster} />
            </div>
         


     {/* youngster transactions settings */}
     <div>
        <div className="mt-9 flex justify-between">
        <div className="font-bold text-xl">Recent Transactions</div>
        <div>
        <Button onClick={()=>goToYoungsterTransaction()}  variant={"link"} colorScheme="#0066f5" color="#0066f5">See all transactions <ArrowForwardIcon/></Button>
        </div>
        </div>
        {youngster.transactions.slice().reverse().slice(0,5).map((transaction,i)=>(
          <div key={i}>
            <TransactionList transaction={transaction} />
          </div>
        ))}
      </div>
      
    </div>
  );
}

export default AccountCenter;
