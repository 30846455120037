import React,{useEffect} from 'react';
import TransactionPin from '../../../components/Misc/TransactionPin';
import { useState } from 'react';
import PrimaryButton from '../../Misc/Buttons/PrimaryButton';
import TertiaryButton from '../../Misc/Buttons/TertiaryButton';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

function OtpModal(props) {
  const [disabled, setDisabled] = useState(true);

  const enterOtp = e => {
    props.setOtp(e);
  };

  useEffect(()=>{
    if(props.otp.length === 4){
      setDisabled(false)
    } 
    else{
      setDisabled(true)
    }
  },[props])

  return (
    <div id={props.id} className="hiddenForm">
    <div className="p-4 flex justify-center">
      <div>
        <h1 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
          Confirm Your Email Address
        </h1>
        <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
          Kindly enter the one-time code sent to your email address: 
          <span className="brandcolor"> {props.email}</span>
        </p>

        <TransactionPin 
              class={"auth"} setPin={e => enterOtp(e)} />

        <div className="text-red-500 text-center">{props.error}</div>

        <p className="text-center my-5">
          Didn't receive a code?{' '}
          <Button
            rounded={'full'}
            color={'#005FEB'}
            colorScheme={'#E6EFFD'}
            background={'#E6EFFD'}
            onClick={() => props.resendOtp()}
          >
            {' '}
            Resend{' '}
          </Button>
        </p>

        <div className="flex space-x-4">
          <TertiaryButton
            onClick={() => props.closeModal()}
            preIcon={ArrowBackIcon}
            title={'Back'}
          />

          <PrimaryButton
            postIcon={ArrowForwardIcon}
            disabled={disabled}
            title={'Confirm'}
            onClick={() => props.verifyOtp()}
            loading={props.loading}
          />
        </div>
      </div>
    </div>
    </div>
  );
}

export default OtpModal;
