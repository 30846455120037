import React, { Fragment, useState } from 'react';
import TransactionPin from '../Misc/TransactionPin';
import { useNavigate } from 'react-router';
import { Center } from '@chakra-ui/react';
import EditProfileHeader from '../../components/EditProfile/EditProfileHeader';
import PrimaryButton from '../Misc/Buttons/PrimaryButton';

function SetUpPin(props) {
  const [disabled, setDisabled] = useState(true);
  const { pin, setPin } = props;

  const enterPin = e => {
    if (pin.length === 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setPin(e);
  };

  const [error, setError] = useState('');
  const setPinValue = () => {
    if (pin === '') {
      setError('Please enter a Transaction Pin');
      return;
    }
    if (pin.length < 4) {
      setError('Pin needs to be exactly 4 digits');
      return;
    }
    if (pin.length > 4) {
      setError('Pin cannot be more than 4 characters');
      return;
    }

    setError('');
    document.getElementById('setup-pin').style.display = 'none';
    document.getElementById('confirm-pin').style.display = 'block';
  };

  const history = useNavigate();
  const goBack = () => {
    history(-1);
  };
  return (
    <Fragment>
      <div className="dashboardCenter hiddenForm" id={props.id}>
        <EditProfileHeader goBack={goBack} title={props.title} />
        <p className="py-4 text-[#151618] font-semibold text-[16px]">
          Your 4-Digits transaction PIN helps keep your money safe. You will
          also use it to approve transactions and stop others from accessing
          your account.
        </p>
        <div>
          <TransactionPin class={"auth"} setPin={e => enterPin(e)} />
          <div className="text-red-500 text-center">{error}</div>
        </div>
        <Center className="my-3">
          <PrimaryButton
            disabled={disabled}
            onClick={setPinValue}
            title="Continue"
          />
        </Center>
      </div>
    </Fragment>
  );
}

export default SetUpPin;
