import React, { useState } from 'react';
import { Input, Select, Button } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchUser } from './../../store/userLogin';
import { DateInput } from '../Misc/Forms/DateInput';
import { isValidDate } from '../Misc/Forms/FormChecks/checkDate';
import PhoneInput from '../Misc/Forms/PhoneInput';
import EmailInput from "../Misc/Forms/EmailInput"
import { isValidNumber } from '../../utils/phoneUtil';

function YoungersterDetails(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [relationship, setRelationship] = useState('');
  const [nickname, setNickname] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState('');
  const [isInvalid, setInvalid] = useState(false);
  const emailRegex = /.+@.+\..+/;
  const dispatch = useDispatch();

  React.useEffect(()=>{
    if(firstName &&
      lastName &&
      isValidDate(dob) &&
      gender &&
      isValidNumber(phone) &&
      email &&
      relationship &&
      nickname){
        setDisabled(false)
      }

      else{
        setDisabled(true)
      }

  },[firstName,lastName,email,dob,gender,phone,relationship,nickname])

  const checkIfUserExist = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/check-email/' + email,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
          ContentType: 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      alert('Error checking user validity');
    }
  };

  const requestYoungster = payload => {
    const id = toast.loading('Sending request. Please wait...');
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/request-youngster',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        email: payload.email,
        youngsterName: payload.firstName,
        name: props.user.firstName + ' ' + props.user.lastName,
        profileImage: props.user.profileImage,
        youngsterId: payload._id,
        senderEmail: props.user.email,
        relationship: relationship,
        nickname: nickname,
      },
    })
      .then(response => {
        toast.update(id, {
          render: 'Request sent successfully!',
          type: 'success',
          isLoading: false,
          autoClose:3000
        });

        setTimeout(() => toast.dismiss(), 2000);

        Swal.fire({
          icon: 'success',
          title: response.data.data.message,
          text: response.data.data.description,
          confirmButtonColor: '#56c0f2',
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(fetchUser('adult')).then(() => {
              window.location.href = '/dashboard/adult';
            });
          }
        });
      })
      .catch(error => {
        toast.update(id, {
          render: 'Request not sent!',
          type: 'error',
          isLoading: false,
          autoClose:3000
        });
        setTimeout(() => toast.dismiss(), 2000);
        Swal.fire({
          icon: 'error',
          title: 'Request Not Sent',
          text: error.response.data.data.message,
        });
      });
  };

  const nextStep = async () => {
    const time = Date.parse(dob);
    if (time > Date.now()) {
      Swal.fire({
        icon: 'info',
        title: 'Is your youngster a time traveller? 🤔',
        text: "Youngster's Birthdate cannot be in the future",
        confirmButtonColor: '#0066f5',
      });
      return;
    }
    if (dob.slice(0, 4) < 2004) {
      Swal.fire({
        icon: 'info',
        title: "Youngster's age is above 18 🥺",
        text: "Sorry, your youngster's age cannot be above 18 years old",
        confirmButtonColor: '#0066f5',
      });
      return;
    }

    setLoading(true);

    const data = [
      firstName,
      lastName,
      dob,
      gender,
      phone,
      email,
      relationship,
      nickname,
    ];
    const message = [];
    if (
      firstName === '' ||
      lastName === '' ||
      !isValidDate(dob) ||
      gender === '' ||
      phone === '' ||
      email === '' ||
      relationship === '' ||
      nickname === ''
    ) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] === '') {
          message[i] = 'Please fill this field';
          setErrors([...message]);
        }
        if (!isValidDate(dob)) {
          errors[2] = 'Please enter a valid date of birth';
        }
      }
      setDisabled(true);
      setLoading(false);
    } else {
      setErrors([]);
      for (let i = 0; i < data.length; i++) {
        if (data[i] === '') {
          message[i] = '';
          setErrors([...message]);
        }
      }

      if (isInvalid) {
        setLoading(false);
        return;
      }
      sessionStorage.setItem('createYoungster', 'setPin');
      const payLoad = {
        firstName,
        lastName,
        dob,
        gender,
        phone,
        email,
        relationship,
        nickname,
      };

      const userExist = await checkIfUserExist();

      setLoading(false);
      if (userExist.data.youngster) {
        Swal.fire({
          icon: 'info',
          title: 'Wait A Minute...',
          text: userExist.data.message,
          showCancelButton: true,
          cancelButtonText: 'Close',
          confirmButtonColor: '#56c0f2',
          confirmButtonText: 'Request Permission',
        }).then(result => {
          if (result.isConfirmed) {
            requestYoungster(userExist.data.youngster[0]);
          }
        });
        return;
      }

      if (userExist.data.data) {
        Swal.fire({
          icon: 'warning',
          title: 'Email already used',
          text: 'This email has already been used by another account. Please change the email and try again',
          confirmButtonColor: '#0080ff',
          confirmButtonText: 'Try again',
        });
        return;
      }

      window.scrollTo(0, 0);
      props.nextStep(2);
      props.setPayload(payLoad);
    }
  };

  const setDateOfBirth = e => {
    setDob(e);
  };

  const setEmailValue = e => {
    setInvalid(true);
    setEmail(e);
    errors[5] = 'Please enter a valid email';
    if (e.match(emailRegex)) {
      errors[5] = '';
      setInvalid(false);
      return;
    }
  };

  const showTerms = () => {
    window.open('https://www.miniemoney.com/AMLPolicy', '_blank');
  };

  const showPolicy = () => {
    window.open('https://www.miniemoney.com/privacy', '_blank');
  };

  //this is to handle the setDisabled prop
  const handleDisabled = (e) =>{
    console.log(e)
  }

  return (
    <div id={props.id}>
      <div>
        <h2 className="aboutYoungster">Tell us about your Youngster</h2>
        <p>
          We will use this information to create a free account for your
          youngster.
        </p>

        <div className="createYoungsterForm mt-4 space-y-4">
          <div className="flexForm">
            <form className="spaceForm mt-4 w-full">
              <label>First Name</label>
              <Input
                placeholder="Enter your youngster's first name"
                size="md"
                onChange={e => setFirstName(e.target.value)}
              />
              <p className="text-red-500">{errors[0]}</p>
            </form>

            <form className="mt-4 w-full">
              <label>Last Name</label>
              <Input
                placeholder="Enter your youngster's last name"
                size="md"
                onChange={e => setLastName(e.target.value)}
              />
              <p className="text-red-500">{errors[1]}</p>
            </form>
          </div>

          <div className="flexForm">
            <form className="w-full spaceForm">
              <label>Date of Birth</label>
              <DateInput setDate={e => setDateOfBirth(e)} date={dob} />
              <p className="text-red-500">{errors[2]}</p>
            </form>

            <form className="w-full">
              <label>Gender</label>
              <Select
                variant="outline"
                placeholder="Select youngster's gender"
                size="md"
                onChange={e => setGender(e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Select>
              <p className="text-red-500">{errors[3]}</p>
            </form>
          </div>

          <div className="flexForm">
            <form className="spaceForm w-full">
              <label>Youngster's Phone Number</label>
              <PhoneInput
                setPhone={e => setPhone(e)}
                setDisabled={e => handleDisabled(e)}
              />
              <p className="text-red-500">{errors[4]}</p>
            </form>

            <form className="w-full">
              <label>Youngster's Email</label>
              <EmailInput placeholder={"Enter youngster's email"} setEmail={(e)=>setEmailValue(e)} setDisabled={e => handleDisabled(e)}/>
            </form>
          </div>

          <div className="flexForm">
            <form className="w-full spaceForm">
              <label>Relationship with youngster</label>
              <Select
                variant="outline"
                type={'date'}
                placeholder="Select relationship"
                size="md"
                onChange={e => setRelationship(e.target.value)}
              >
                <option value="mother">Mother</option>
                <option value="father">Father</option>
                <option value="guardian">Guardian</option>
              </Select>
              <p className="text-red-500">{errors[6]}</p>
            </form>

            <form className="w-full">
              <label>What does your youngster call you?</label>
              <Input
                onChange={e => setNickname(e.target.value)}
                placeholder="Enter nickname"
                size={'md'}
                type="email"
              />
              <p className="text-red-500">{errors[7]}</p>
            </form>
          </div>
        </div>

        <div className="my-6">
          By clicking “Continue”, you agree to our{' '}
          <span onClick={showTerms} className="brandColor">
            Terms & Conditions
          </span>{' '}
          and{' '}
          <span onClick={showPolicy} className="brandColor">
            Privacy Policy.
          </span>
        </div>

        <Button
          rounded={'full'}
          background={'#005FEB'}
          color={'white'}
          size="md"
          width={'100%'}
          p={9}
          onClick={nextStep}
          isLoading={loading}
          disabled={disabled}
          className={'confirmButton'}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

export default YoungersterDetails;
