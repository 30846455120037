import React, { Fragment } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import instant from '../../Image/instant.png';
import key from '../../Image/Break.png';
import auto from '../../Image/Auto.png';
import lead from '../../Image/Lead.png';
import trend from '../../Image/trend.png';
import people from '../../Image/people.png';
import locked from '../../Image/locked.png';
import annon from '../../Image/annon.png';
import kolosave from '../../Image/kolosave.png';
import eye from '../../Image/Eye.png';
import Modal from '../Modal/Modal';
import modal from '../../Image/modal1.png';
import KoloCard from './KoloCard';
import KolosaveTerms from './KolosaveTerms';
import terms from '../../Image/terms.png';
import back from '../../Icons/backButton.svg';
import KoloReviewDate from './KoloReviewDate';
import InstantSave from './InstantSave';
import BreakKolo from './BreakKolo';
import generate from '../../Image/generate.png';
import lotie from '../../Image/Lock.gif';
import group from '../../Image/Group.png';
import judge from '../../Image/judge.svg';
import KoloSaveStatement from './KoloSaveStatement';
import AutoSave from './AutoSave';
import Participants from './Participants';
import TopSavers from './TopSavers';
import { Switch } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';
import moment from 'moment';
import error from '../../Image/not.png';
import KoloBanner from './KoloBanner';
import closeButtonGreen from '../../Icons/closeButtonGreen.svg';
import { nextKoloSave } from '../../utils/dateUtil';
import { standardAmountFormat } from '../../utils/amountUtil';

function Kolosave(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const accountType = window.location.pathname.split('/')[2];
  const goBack = () => {
    history('/save/' + accountType);
  };
  const Back = () => {
    document.getElementById('kolosave').style.display = 'block';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('breakKolo').style.display = 'none';
    document.getElementById('koloSaveStatement').style.display = 'none';
    document.getElementById('auto-save').style.display = 'none';
    document.getElementById('review').style.display = 'none';
    document.getElementById('participant').style.display = 'none';
    document.getElementById('top-savers').style.display = 'none';
    document.getElementById('instantsave').style.display = 'none';
  };
  const showTerms = () => {
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'block';
    document.getElementById('instantsave').style.display = 'none';
    closeModal();
    setShowModal2(false);
  };
  const breakKolo = () => {
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('instantsave').style.display = 'none';
    document.getElementById('breakKolo').style.display = 'block';
    document.getElementById('koloSaveStatement').style.display = 'none';
    closeModal4();
  };
  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModal3, setShowModal3] = React.useState(false);
  const [showModal4, setShowModal4] = React.useState(false);
  const closeModal = () => {
    setShowModal1(false);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };
  const closeModal3 = () => {
    setShowModal3(false);
  };
  const closeModal4 = () => {
    setShowModal4(false);
  };
  const ShowModal4 = () => {
    setShowModal4(true);
    setShowModal3(false);
  };
  const showKoloStatement = () => {
    const today = moment(Date.now()).format('LL').slice(0, -6);
    if (today === props.revealDates.breakKolo) {
    document.getElementById('koloSaveStatement').style.display = 'block';
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('instantsave').style.display = 'none';
    document.getElementById('breakKolo').style.display = 'none';
    closeModal();
    closeModal4();
      return;
    }
    setShowModal2(true);
    
  };
  const showAutoSave = () => {
    document.getElementById('koloSaveStatement').style.display = 'none';
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('instantsave').style.display = 'none';
    document.getElementById('breakKolo').style.display = 'none';
    document.getElementById('auto-save').style.display = 'block';
    window.scrollTo(0,0)
  };
  const showParticipant = () => {
    document.getElementById('koloSaveStatement').style.display = 'none';
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('instantsave').style.display = 'none';
    document.getElementById('breakKolo').style.display = 'none';
    document.getElementById('auto-save').style.display = 'none';
    document.getElementById('review').style.display = 'none';
    document.getElementById('participant').style.display = 'block';
    window.scrollTo(0,0)
  };
  const showTopsavers = () => {
    document.getElementById('koloSaveStatement').style.display = 'none';
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('instantsave').style.display = 'none';
    document.getElementById('breakKolo').style.display = 'none';
    document.getElementById('auto-save').style.display = 'none';
    document.getElementById('review').style.display = 'none';
    document.getElementById('participant').style.display = 'none';
    document.getElementById('top-savers').style.display = 'block';
    window.scrollTo(0,0)
  };

  const showInstantSave = () => {
    document.getElementById('koloSaveStatement').style.display = 'none';
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('instantsave').style.display = 'block';
    document.getElementById('breakKolo').style.display = 'none';
    document.getElementById('auto-save').style.display = 'none';
    document.getElementById('review').style.display = 'none';
    document.getElementById('participant').style.display = 'none';
    document.getElementById('top-savers').style.display = 'none';
  };

  const RevealDate = () => {
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('review').style.display = 'block';
    document.getElementById('koloSaveStatement').style.display = 'none';
    closeModal();
  };

  const goToInstantSave = () => {
    if (props.user.kolosave !== undefined && props.user.kolosave.isAgreed) {
      showInstantSave();
      return;
    }
    setShowModal1(true);
  };

  const goToAutoSave = () => {
    if (props.user.kolosave !== undefined && props.user.kolosave.isAgreed) {
      showAutoSave();
      return;
    }
    setShowModal1(true);
  };

  const setAutosave = e => {
    const id = toast.loading('Updating settings,please wait...');
    console.log(e.target.checked);
    let reqbody = { ...props.user.kolosave };
    reqbody.autosave = e.target.checked;
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/kolosave-settings',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
      data: reqbody,
    })
      .then(() => {
        dispatch(fetchUser(accountType)).then(() => {
          toast.update(id, {
            render: 'Kolosave Settings Updated Successfully',
            type: 'success',
            isLoading: false,
            autoClose:3000
          });
          Swal.fire({
            icon: 'success',
            title: 'Good Job! 👍🏾',
            text: 'You have successfully updated your Kolosave settings',
            confirmButtonColor: '#669f2a',
          }).then(() => {
            window.location.reload();
          });
          setTimeout(() => toast.dismiss(), 3000);
        });
      })
      .catch(error => {
        toast.dismiss();
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 😢",
          text: error.response.data.data.message,
        });
      });
  };

  const setAnonymous = e => {
    console.log(e.target.checked);
    let reqbody = { ...props.user.kolosave };
    reqbody.anonymous = e.target.checked;
    console.log(reqbody);
    const id = toast.loading('Updating settings,please wait...');
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/kolosave-settings',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        Accept: '*/*',
        ContentType: 'application/json',
      },
      data: reqbody,
    })
      .then(response => {
        console.log(response);
        dispatch(fetchUser(accountType)).then(() => {
          toast.update(id, {
            render: 'Kolosave Settings Updated Successfully',
            type: 'success',
            isLoading: false,
            autoClose:3000
          });
          Swal.fire({
            icon: 'success',
            title: 'Good Job! 👍🏾',
            text: 'You have successfully updated your Kolosave settings',
            confirmButtonColor: '#669f2a',
          }).then(() => {
            window.location.reload();
          });
          setTimeout(() => toast.dismiss(), 3000);
        });
      })
      .catch(error => {
        setTimeout(() => toast.dismiss(), 3000);
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 😢",
          text: error.response.data.data.message,
        });
      });
  };

  const breakMyKolo = () => {
    const today = moment(Date.now()).format('LL').slice(0, -6)
    if (today.includes('December')) {
      setShowModal3(true);
      // change back to 2 for test
      return;
    }
    setShowModal2(true);
    // change back to 3 for test
  };


  return (
    <Fragment>
      <div className="dashboardCenter" id="kolosave">
        <div onClick={goBack} className="cursor-pointer pb-4">
          <img src={back} alt="" />

          <h2 className="walletScreenTitle text-center">KoloSave</h2>
        </div>
        <KoloBanner participants={props.participants} />
        <KoloCard user={props.user} revealDates={props.revealDates} />
        <button
          onClick={goToInstantSave}
          className="flex text-[#FFFFFF] bg-[#669F2A] hover:bg-[#48711E] gap-2 h-[56px] py-[2px] px-2 sm:py-4 sm:px-5 w-full justify-center items-center rounded-[32px] mt-6 mb-12"
        >
          <img className="" src={instant} alt="" />
          <span className="text-[16px] sm:text-[19px]">Instant save</span>
        </button>
        <div className="featureGrid">
          <button
            onClick={breakMyKolo}
            className="flex relative bg-[#FFFFFF] text-[#669F2A] hover:border-[2px] hover:border-[#48711E] gap-2 h-[56px] py-[2px] px-2 sm:py-4 sm:px-5 w-full justify-center items-center border border-[#669F2A] rounded-[32px] mt-5"
          >
            <img className="" src={key} alt="" />
            <span className="text-[16px] sm:text-[19px]">Break my kolo</span>
            <div className="tag flex items-center gap-1 justify-center">
              <img src={locked} alt="" />
              <span>locked</span>
            </div>
          </button>

          <KoloFeatures
            icon={auto}
            text="Auto save"
            click={() => goToAutoSave()}
          />
          <KoloFeatures
            icon={people}
            text="Participants"
            click={() => showParticipant()}
          />
          <KoloFeatures
            icon={lead}
            text="Top savers"
            click={() => showTopsavers()}
          />
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4 mt-8">To-Do</h2>
          {props.user.kolosave && !props.user.kolosave.isAgreed && (
            <div className="cursor-pointer kycList">
              <div className="kycType" onClick={() => showTerms()}>
                <div>
                  <img
                    className="mt-2 w-[40px] h-[40px] max-w-[100%]"
                    src={terms}
                    alt="user-verification"
                  />
                </div>

                <div className="kycText">
                  <h2 className="text-xl font-bold">KoloSave Terms</h2>
                  <p className="text-xs font-normal">
                    Kindly read our KoloSave terms in other to participate and
                    start saving!
                  </p>
                </div>
              </div>

              <div className="arrowIcon" onClick={() => showTerms()}>
                <ArrowForwardIcon />
              </div>
            </div>
          )}

          {/* Generate KoloSave Statement */}
          <div className="cursor-pointer kycList my-2">
            <div className="kycType" onClick={() => showKoloStatement()}>
              <div>
                <img
                  className="mt-2 w-[40px] h-[40px] max-w-[100%]"
                  src={generate}
                  alt="user-verification"
                />
              </div>

              <div className="kycText">
                <h2 className="text-xl font-bold text-[#151618]">
                  Generate KoloSave Statement
                </h2>
                <p className="text-xs font-normal text-[#54595E]">
                  You will get your savings history when you generate your
                  KoloSave Statement
                </p>
              </div>
            </div>

            <div className="arrowIcon" onClick={() => showKoloStatement()}>
              <ArrowForwardIcon />
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold pb-4 mt-8">Settings</h2>

          <div className="cursor-pointer kycList">
            <div className="kycType">
              <div>
                <img
                  className="mt-2 w-[40px] h-[40px] max-w-[100%]"
                  src={kolosave}
                  alt="user-verification"
                />
              </div>

              <div className="kycText">
                <h2 className="text-xl font-bold">Auto Save</h2>
                <p className="text-xs font-normal">
                  {props.user.kolosave && props.user.kolosave.autosave
                    ? 'Your next deposit is on: ' +
                      nextKoloSave(props.user.autokolosave)
                    : ''}
                </p>
              </div>
            </div>

            <Switch
              onChange={e => setAutosave(e)}
              size={'lg'}
              defaultChecked={
                props.user.kolosave === undefined
                  ? false
                  : props.user.kolosave.autosave
              }
              colorScheme={'green'}
            />
          </div>
          <div className="cursor-pointer kycList mt-3">
            <div className="kycType">
              <div>
                <img
                  className="mt-2 w-[40px] h-[40px] max-w-[100%]"
                  src={annon}
                  alt="user-verification"
                />
              </div>

              <div className="kycText ">
                <h2 className="text-xl font-bold">Stay Anonymous</h2>
                <p className="text-xs font-normal">
                  Turn on to make your profile private on the KoloSave
                  leaderboard.
                </p>
              </div>
            </div>

            <Switch
              defaultChecked={
                props.user.kolosave === undefined
                  ? false
                  : props.user.kolosave.anonymous
              }
              onChange={e => setAnonymous(e)}
              size={'lg'}
              colorScheme={'green'}
            />
          </div>
          <div className="cursor-pointer kycList my-3">
            <div className="kycType">
              <div>
                <img
                  className="mt-2 w-[40px] h-[40px] max-w-[100%]"
                  src={eye}
                  alt="user-verification"
                />
              </div>

              <div onClick={RevealDate} className="kycText">
                <h2 className="text-xl font-bold">Balance Reveal Dates</h2>
                <p className="text-xs font-normal">
                  You can only get to see your KoloSave balance 4 time a year.
                </p>
              </div>
            </div>

            <div onClick={RevealDate} className="arrowIcon">
              <ArrowForwardIcon />
            </div>
          </div>
        </div>

        {props.user.kolosave && props.user.kolosave.isAgreed && (
          <div className="cursor-pointer kycList">
            <div className="kycType" onClick={() => showTerms()}>
              <div>
                <img
                  className="mt-2 w-[40px] h-[40px] max-w-[100%]"
                  src={terms}
                  alt="user-verification"
                />
              </div>

              <div className="kycText">
                <h2 className="text-xl font-bold">KoloSave Terms</h2>
                <p className="text-xs font-normal">
                  Kindly read our KoloSave terms in other to participate and
                  start saving!
                </p>
              </div>
            </div>

            <div className="arrowIcon" onClick={() => showTerms()}>
              <ArrowForwardIcon />
            </div>
          </div>
        )}
      </div>
      <Modal
        isVisible={showModal1}
        onClose={() => closeModal()}
        closeButtonGreen={closeButtonGreen}
      >
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={modal}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="mt-3 text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            Learn About KoloSave First
          </h5>
          <p className="my-3 text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            To start saving, learn how KoloSave works and agree to our terms and
            conditions.
          </p>
          <button
            onClick={() => showTerms()}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2 hover:bg-[#48711E] "
          >
            <span className="font-semibold text-base">
              Read and accept the KoloSave terms
            </span>
            <ArrowForwardIcon />
          </button>
        </div>
      </Modal>

      <Modal
        isVisible={showModal2}
        closeButtonGreen={closeButtonGreen}
        onClose={() => closeModal2()}
      >
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={error}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            Kolosave is not mature
          </h5>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            You can't break your kolo or generate your statement because it's
            not yet December 1st. Please read the Kolosave terms again 👇🏾
          </p>
          <button
            onClick={showTerms}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2 hover:bg-[#48711E]"
          >
            <span className="font-semibold text-base">Read Kolosave terms</span>
            <ArrowForwardIcon />
          </button>
        </div>
      </Modal>

      <Modal
        isVisible={showModal3}
        closeButtonGreen={closeButtonGreen}
        onClose={() => closeModal3()}
        bg={'greenBg'}
      >
        <div
          className="p-4 flex justify-between flex-col items-center -m-2"
          style={{ backgroundColor: '#4F7A21', color: '#fff' }}
        >
          <div className="flex flex-col items-center -mt-[96px] sm:-mt-[120px] ">
            <img
              src={group}
              alt="error&Success"
              width="182.62px"
              height="59.68px"
              className="max-w-[100%] -mb-7 -ml-1 sm:w-[185.62px] sm:h-[56.68px] w-[130px] h-[59.68px]"
            />
            <img
              src={lotie}
              alt="error&Success"
              width="200px"
              height="200px"
              className="max-w-[100%] sm:w-[192.62px] sm:h-[200px] w-[120px] h-[120px]"
            />
          </div>
          <div className="">
            <h5 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
              It’s the grand breaking day!
            </h5>
            <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
              Your KoloSave is now unlocked, so you can break your kolo now to
              access all your savings! 💃🏻🕺😁
            </p>

            <button
              onClick={() => ShowModal4()}
              className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#F3F3F4] text-[#669F2A] rounded-full mt-2 hover:bg-[#48711E] hover:text-[#F3F3F4]"
            >
              <span className="font-semibold text-base">Break my kolo</span>
            </button>
            <button
              onClick={() => closeModal3()}
              className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-none text-[#fff] rounded-full mt-2"
            >
              <span className="font-semibold text-base">Not now</span>
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isVisible={showModal4}
        closeButtonGreen={closeButtonGreen}
        onClose={() => closeModal4()}
        bg={'greenBg'}
      >
        <div
          className="flex items-center flex-col -m-2 px-2 py-3"
          style={{ backgroundColor: '#4F7A21', color: '#fff' }}
        >
          <img
            src={judge}
            alt="error&Success"
            width="80px"
            height="80px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            Yassss! You Did it!
          </h5>
          <p className="text-sm sm:text-base font-normal text-[#F3F3F4] max-w-[456px] text-center mt-2 sm:mb-4">
            It’s the time of the year to break your KoloSave.
          </p>

          <p className="text-sm sm:text-base font-normal text-[#F3F3F4] max-w-[456px] text-center mt-2 sm:mb-4">
            Despite all the temptations to break your Kolo, you were able to
            complete this year's Kolo Save challenge.
          </p>

          <p className="text-sm sm:text-base font-normal text-[#F3F3F4] max-w-[456px] text-center mt-2 sm:mb-4">
            You're a champion 👑 for coming this far.
          </p>
          <div className="sm:my-3 my-4 py-2 sm:px-6 flex items-center flex-col rounded-2xl bg-[#ECF3FD] w-full sm:max-w-[456px] mb-4 mt-2">
            <p
              className={`flex text-[12px] sm:text-[14px] text-[#3F3F46] font-semibold text-center`}
            >
              This is the total money you saved this year in your KoloSave.
            </p>
            <h5 className="text-[#ACDC79] font-semibold text-xl sm:text-4xl">
              {standardAmountFormat(props.user.koloBalance)}
            </h5>
            <div className="flex items-center justify-between gap-2 px-2 sm:px-6">
              <img src={trend} alt="" width="24px" height="24px" />{' '}
              <span className="text-[#3F3F46]">
                {' '}
                <b className="text-[#669F2A]">
                  {' '}
                  {standardAmountFormat(props.user.koloInterest)}
                </b>{' '}
                interest earned at 6% per annum{' '}
              </span>
            </div>
          </div>
          <button
            onClick={breakKolo}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-2 text-base
            sm:max-w-[456px] cursor-pointer bg-[#F3F3F4] text-[#151618] rounded-full mt-2 hover:text-[#48711E]"
          >
            <span className="font-semibold text-base">
              Proceed to break my kolo
            </span>
          </button>
          <button
            onClick={showKoloStatement}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-2 sm:px-4 text-base
            sm:max-w-[456px] bg-none cursor-pointer bg-[#151618] text-[#F3F3F4] rounded-full mt-2 hover:text-[#669F2A]"
          >
            <span className="font-semibold text-sm sm:text-base">
              Generate my KoloSave statement
            </span>
          </button>
        </div>
      </Modal>
      <KolosaveTerms user={props.user} Back={Back} />
      <KoloReviewDate
        revealDates={props.revealDates}
        user={props.user}
        Back={Back}
      />
      <InstantSave user={props.user} Back={Back} />
      <BreakKolo user={props.user} Back={Back} />
      <KoloSaveStatement user={props.user} Back={Back} transactions={props.transactions} />
      <AutoSave user={props.user} Back={Back} />
      <Participants
        user={props.user}
        Back={Back}
        participants={props.participants}
      />
      <TopSavers
        user={props.user}
        are
        saving
        with
        Kolo
        Save
        Back={Back}
        leaderboard={props.leaderboard}
        participants={props.participants}
      />
    </Fragment>
  );
}

export default Kolosave;
export const KoloFeatures = ({ icon, text, click }) => {
  return (
    <div>
      <button
        className="flex bg-[#FFFFFF] text-[#669F2A] gap-2 
        h-[56px] py-[2px] px-2 sm:py-4 sm:px-5 w-full justify-center 
        items-center border border-[#669F2A] rounded-[32px] mt-5 hover:border-[2px]  hover:border-[#48711E]"
        onClick={click}
      >
        <img className="" src={icon} alt="" />
        <span className="text-[16px] sm:text-[19px]">{text}</span>
      </button>
    </div>
  );
};
