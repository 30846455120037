import React from 'react';
import { Button } from '@chakra-ui/react';
import users from '../../Icons/users.svg';

function NoParent(props) {
  return (
    <div className="notificationsCard">
      <div>
        <div className="my-4 flex justify-center">
          <img src={users} alt="notificationBell" />
        </div>
        <p className="my-3">{props.description}</p>
        <Button
          fontSize={'13px'}
          padding={'7'}
          width={'xs'}
          onClick={props.inviteParents}
          rounded={'full'}
          background="#0073E5"
          color="white"
          variant={'#0073E5'}
        >
          {props.btnTitle}
        </Button>
      </div>
    </div>
  );
}

export default NoParent;
