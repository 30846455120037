import React, { useRef } from 'react';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';

export const DateInput = props => {
  const datepickerRef = useRef(null);

  React.useEffect(() => {
    const $ = window.jQuery;
    $(datepickerRef.current).datepicker({
      dateFormat: 'yy-mm-dd',
      yearRange: '1950:c',
      onSelect: function (dateText) {
        props.setDate(dateText);
      },
      changeMonth:true,
      changeYear:true
    });
  }, [props]);

  const setDateValue = e => {
    const numericValue = e.replace(/[^\d]/g, '');
  let formattedValue = '';
  if (numericValue.length > 0) {
    formattedValue = numericValue.slice(0, 4);
  }
  if (numericValue.length > 3) {
    formattedValue += '-' + numericValue.slice(4, 6);
  }
  if (numericValue.length > 5) {
    formattedValue += '-' + numericValue.slice(6, 8);
  }
  if (e.length < props.date.length) { // backspace key pressed
    formattedValue = formattedValue.slice(0, -1); // remove the last character
  }
  props.setDate(formattedValue);
  };

  return (
    <div>
      <InputGroup>
        <Input
          placeholder={'yyyy-mm-dd'}
          onChange={e => setDateValue(e.target.value)}
          type="text"
          ref={datepickerRef}
          value={props.date}
          size={props.size}
        />
        <InputRightElement className={props.iconClass}>
          <CalendarIcon />
        </InputRightElement>
      </InputGroup>
      <span className="font-xs helperText">e.g 2005-12-25</span>
    </div>
  );
};



//use setDate prop to update the date in the parent