export function isValidDate(str) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(str)) {
    return false; // Return false if the date string is not in the correct format
  }

  const year = parseInt(str.substring(0, 4));
  const month = parseInt(str.substring(5, 7));
  const day = parseInt(str.substring(8, 10));

  if (month < 1 || month > 12) {
    return false; // Return false if the month is not valid
  }

  if (day < 1 || day > new Date(year, month, 0).getDate()) {
    return false; // Return false if the day is not valid
  }

  return true; // Return true if the date string is valid
}
