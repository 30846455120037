import React from 'react';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';

import {
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
// import Swal from 'sweetalert2';
import { ArrowBackIcon, InfoIcon } from '@chakra-ui/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchUser } from '../../../store/userLogin';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import EnterTransactionPinModal from '../../../components/Modal/EnterTransactionPinModal';
import AmountInput from '../../../components/Misc/Forms/AmountInput';
import { standardAmountFormat } from '../../../utils/amountUtil';

function ConfirmDetails(props) {
  return (
    <div>
      <div className="my-2 confirmDetailsBank">
        <div>
          <p className="text-sm">Bank</p>
          <h2 className="font-bold">{props.bankName}</h2>
        </div>

        <div>
          <p className="text-sm">Amount</p>
          <h2 className="font-bold">
            {standardAmountFormat(props.amount)}{' '}
            <span
              style={{ fontSize: '10px' }}
              className="text-red-500 font-light"
            >
              + ₦ 20 transfer fee
            </span>
          </h2>
        </div>
      </div>
      <hr />

      <div className="my-3 confirmDetailsBank">
        <div>
          <p className="text-sm">Account Number</p>
          <h2 className="font-bold">{props.accountNumber}</h2>
        </div>

        <div>
          <p className="text-sm ">Account Name</p>
          <h2 className="font-bold">{props.accountName}</h2>
        </div>
      </div>

      <hr />
      <div className="mt-3">
        <p className="text-sm">Reason</p>
        <h2 className="font-bold">{props.reason}</h2>
      </div>
    </div>
  );
}

function SendMoneyBank(props) {
  const [disabled, setDisabled] = React.useState(true);
  const [amount, setAmount] = React.useState('');
  const [reason, setReason] = React.useState('');
  //   const [youngster,setYoungster] = React.useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showModal, setShowModal] = React.useState(false);
  const [bank, setBank] = React.useState('');
  const [accountNumber, setAccountNumber] = React.useState('');
  const [accountName, setAccountName] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [pin, setPin] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();

  const gotoTransfer = () => {
    const sendmoneybank = document.getElementById('sendmoneybank');
    const transfer = document.getElementById('transfer');
    transfer.style.display = 'block';
    sendmoneybank.style.display = 'none';
  };


  React.useEffect(()=>{
    if(amount > 0 && reason !== "" && bank !== "" && accountName !=="" && accountNumber !== ""){
      setDisabled(false)
    }
  },[amount,reason,bank,accountName,accountNumber])

  const setBankData = e => {
    const bank = props.userbanks[e.target.value];
    setAccountNumber(bank.accountNumber);
    setAccountName(bank.accountName);
    setBank(bank.bank);
    setBankName(bank.bankName);
  };

  const sendMoney = async (e) => {

    if (amount < 1000) {
      return;
    }

    onOpen()
  };

  const confirmTransaction = () => {
    onClose();
    setDisabled(true)
    setShowModal(true);
  };

  const sendMoneytoBank = () => {
    if (pin === '') {
      return;
    }


    if (amount >= 400000) {
      setShowModal(false);
      Swal.fire({
        icon: 'info',
        title: 'Withdrawal Limit exceeded',
        text: "You can't withdraw more than ₦ 400,000 at once on MinieMoney. Kindly reduce your withdrawal amount",
      });
      return;
    }

    setLoading(true);
    setDisabled(true)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_PAYMENT_URL + '/send-money-bank',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        amount: amount,
        bank: bank,
        pin: pin,
        accountName: accountName,
        accountNumber: accountNumber,
        bankName: bankName,
        reason: reason,
        transferType: bank === '999999' ? 'intra' : 'inter',
      },
    })
      .then(response => {
        dispatch(fetchUser('adult')).then(() => {
          setLoading(false);
          setDisabled(true)
          setShowModal(false);
          Swal.fire({
            icon: 'success',
            title: response.data.data.message,
            text:
              'You have successfully requested withdrawal of ₦ ' +
              amount +
              ' to ' +
              accountName+"("+bankName+")",
          }).then(result => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        });
      })
      .catch(error => {
        console.log(error);
        setShowModal(false);
        setLoading(false);
        setDisabled(false)
        setPin('');
        Swal.fire({
          icon: 'warning',
          title: 'Something went wrong!',
          text: error.response.data
            ? error.response.data.data.message
            : 'Network Error. Please check your internet connection or contact support',
        }).then(result => {
          if (result.isConfirmed) {
            setShowModal(true);
            setDisabled(true)
          }
        });
      });
  };

  return (
    <div
      id={props.id}
      className="dashboardCenter hiddenForm walletScreensContainer"
    >
      <WalletScreenHeader goBack={gotoTransfer} title={props.title} />

      <div className="formContainer">
        <p className="text-xs my-3">
          <InfoIcon color="#7a58ef" /> Bank transfers attract a fee of ₦ 20
        </p>
        <div>
          <label>Select Bank</label>
          <Select size={"lg"} fontSize={"15px"} onChange={e => setBankData(e)}>
            <option defaultValue={'select bank'}> Select Bank</option>
            {props.accountType === 'adult' &&
              props.userbanks &&
              props.userbanks.map((bank, i) => (
                <option id={i} value={i} key={i}>
                  {bank.bankName}
                </option>
              ))}
          </Select>
        </div>

        <div className="my-3">
          <label>Enter Amount</label>
          <AmountInput
            setAmount={e => setAmount(e)}
            focusBorderColor={'#7A58EF'}
            placeholder={'Enter the amount'}
            
          />
          <span className="helpertext">Minimum withdrawal amount is ₦ 1,000</span>
        </div>

        <div>
          <label>Reason for Withdrawal</label>
          <Input
            onChange={e => setReason(e.target.value)}
            size="lg"
            fontSize={"15px"}
            focusBorderColor="#7A58EF"
            placeholder={'Enter your reason for withdrawal'}
          />
        </div>

        <div className="mt-6">
          <Button
            onClick={e => sendMoney(e)}
            padding={'28px 32px'}
            width={'100%'}
            disabled={disabled}
            rounded={'full'}
            colorScheme={'#7A58EF'}
            background={'#7A58EF'}
          >
            Continue
          </Button>
        </div>

        {(props.userbanks === undefined || props.userbanks.length === 0) && (
          <p className="py-6 text-center text-sm">
            Don't have a withdrawal account? Click{' '}
            <span
              onClick={() => history('/account/adult/withdrawal')}
              className="addBankLink"
            >
              here to add one
            </span>
          </p>
        )}
      </div>

      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-medium text-xl">Confirm Transaction</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ConfirmDetails
              accountName={accountName}
              accountNumber={accountNumber}
              bankName={bankName}
              amount={amount}
              reason={reason}
            />
          </ModalBody>

          <ModalFooter
            margin={'0px 0px 20px 0px'}
            className="confirmDetailsFlexButtons"
          >
            <Button
              color={'#54595E'}
              rounded={'full'}
              background={'#F3F3F4'}
              colorScheme="#F3F3F4"
              onClick={onClose}
            >
              <ArrowBackIcon className="mr-3" /> Cancel
            </Button>
            <Button
              rounded={'full'}
              className="confirmButton"
              background={'#7A58EF'}
              colorScheme="#7A58EF"
              onClick={confirmTransaction}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <EnterTransactionPinModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        confirm={() => sendMoneytoBank()}
        loading={isLoading}
        setLoading={e => setLoading(e)}
        setPin={e => setPin(e)}
        disabled={disabled}
        setDisabled={(e) => setDisabled(e)}
        bg={'#7A58EF'}
        pinBg={'#F2EEFD'}
        color={'#7A58EF'}
        class={"transaction"}
      />
    </div>
  );
}

export default SendMoneyBank;
