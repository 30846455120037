import React from 'react';
import './Account.css';
import NoYoungster from '../Dashboard/DashboardCenter/NoYoungster';
import YoungsterAccount from '../Dashboard/DashboardCenter/YoungsterAccount';
import YoungsterHeader from './YoungsterHeader';
import { useNavigate } from 'react-router';
import YoungsterLoading from '../Dashboard/DashboardCenter/YoungsterLoading';

function AccountCenter(props) {
  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };

  return (
    <div className="dashboardCenter">
      <YoungsterHeader title="Youngster’s Accounts" goBack={goBack} />
      <div className="w-full my-6">
        {props.user.youngsters.length === 0 && <NoYoungster />}
      </div>

      {props.accountType === 'adult' &&
        props.youngsterLoading &&
        props.user.youngsters && props.user.youngsters.length !== 0 &&
        props.user.youngsters.map((youngster, i) => (
          <div key={i}>
            <div>
              <YoungsterLoading youngster={youngster} />
            </div>
          </div>
        ))}

      {props.youngsters && props.youngsters.length !== 0 &&
        props.youngsters.map((youngster, i) => (
          <div key={i}>
            <div>
              <YoungsterAccount youngster={youngster} />
            </div>
          </div>
        ))}
    </div>
  );
}

export default AccountCenter;
