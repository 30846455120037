import React from 'react'
import notransactions from "../../Icons/transaction-minus.svg"

function Transactions () {
  return (
    <div className="recentTransactions">
        <h2>Recent Transactions</h2>

        <div className="notificationsCard">
          <div>
            <div className="my-4 flex justify-center">
              <img  src={notransactions} alt="notificationBell"/>
            </div>
            <p>
              Nothing to see here, we will display new notifications here as
              they are available.
            </p>
          </div>
        </div>
      </div>
  )
}

export default Transactions