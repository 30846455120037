import React from 'react';
import { useNavigate } from 'react-router';
import NoBankAccount from './NoBankAccount';
import WithdrawalHeader from './WithdrawalHeader';
import {
  Input,
  Select,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import axios from 'axios';
import BankAccount from './BankAccount';
import './Withdrawal.css';
import { toast } from 'react-toastify';
import { formatNumber } from '../../utils/Forms/formatNumber';

function Withdrawal(props) {
  const [loadingText, setLoadingText] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [accountNumber, setAccountNumber] = React.useState('');
  const [bank, setBank] = React.useState('');
  const [accountName, setAccountName] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [bankButton, setBankButton] = React.useState('Resolve Bank');
  const [bvn, setBvn] = React.useState('');

  const history = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const banks = useSelector(state => state.banks.value.bank);

  const setBankData = e => {
    console.log(bank);
    setBank(e.target.value);
    const bankName = banks.filter(bank => bank.code === e.target.value)[0].name;
    setBankName(bankName);
  };

  const goBack = () => {
    history(-1);
  };

  const setAccountNumberValue = e => {
    const newValue = e.replace(/\D/g, '');
    setAccountNumber(newValue);
    if (accountNumber.length === 9) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  React.useEffect(()=>{
    if (accountNumber.length === 10) {
      setDisabled(false);
    } 
  },[accountNumber])

  const addBankAccount = () => {
    setLoading(true);
    console.log(bvn, bank, bankName, accountName, accountNumber);
    setLoadingText('Adding Bank account');
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/add-bank',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
      },
      data: {
        bank: bank,
        bankName: bankName,
        accountNumber: accountNumber,
        accountName: accountName,
        bvn: bvn,
      },
    })
      .then(response => {
        onClose();
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Bank Account Successfully Added',
          text: response.data.data.message,
          confirmButtonColor: '#005FEB',
        }).then(()=>{
          window.location.reload();
        })
      })
      .catch(error => {
        onClose();
        setAccountName('');
        setAccountNumber('');
        setDisabled(true);
        setBankButton('Resolve Bank');
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Not Your Account',
          text: error.response.data.data.message,
          confirmButtonColor: '#0066f5',
        });
      });
  };

  const resolveAccount = () => {
    setLoading(true);
    setLoadingText('Resolving Bank account');
    axios({
      method: 'POST',
      url: process.env.REACT_APP_PAYMENT_URL+'/resolve-bank',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
      },
      data: {
        accountNumber: accountNumber,
        bankCode: bank,
        transferType: bank === '999999' ? 'intra' : 'inter',
      },
    })
      .then(response => {
        setBvn(response.data.data.bvn);
        setAccountName(response.data.data.name);
        setLoading(false);
        setBankButton('Add Account');
      })
      .catch((error) => {
        console.log(error)
        onClose();
        Swal.fire({
          icon: 'warning',
          title: 'Account Number Invalid',
          text: 'The Account Number you entered is invalid. Kindly check and try again',
          confirmButtonColor: '#0066f5',
        });
        setLoading(false);
      });
  };

  const addAccount = e => {
    e.preventDefault();
    if (accountNumber.length === 10 && accountName === '') {
      resolveAccount();
      return;
    } else {
      addBankAccount();
    }
  };

  const addBank = () => {
    if (props.kycs.length !== 0 && (props.kycs.filter((kyc) => kyc.type ==="bvn").length !== 0 && props.kycs.filter((kyc)=>kyc.type==="bvn")[0].status  === 'verified')) {
      onOpen();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'KYC Required',
        text: 'You need to do your KYC before you can add your withdrawal account',
        confirmButtonColor: '#0073E5',
      }).then(result => {
        if (result.isConfirmed) {
          history('/account/adult/kyc');
        }
      });
    }
  };

  const deleteAccount = e => {
    const id = toast.loading('Deleting account, please wait...');
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/delete-bank',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
      },
      data: e,
    }).then(response => {
      toast.update(id, {
        type: 'success',
        isLoading: false,
        render: response.data.data.message,
        autoClose:3000
      });

      Swal.fire({
        icon: 'success',
        title: 'Bank account deleted',
        text: response.data.data.message,
        confirmButtonColor: '#0066f5',
      }).then(() => {
        window.location.reload();
      });
    });
  };

  return (
    <div id={props.id} className="dashboardCenter pb-12">
      {/* page heading */}
      <WithdrawalHeader title="Withdrawal Accounts" goBack={goBack} />
      {(props.banks === ""  || (props.banks && props.banks.length === 0)) && (
        <div className="detailTitle text-center my-9">
          <NoBankAccount onOpen={addBank} />
        </div>
      )}

      {props.banks && props.banks.length !== 0 && (
        <div className="detailTitle text-center my-9">
          <BankAccount
            onOpen={addBank}
            banks={props.banks}
            deleteAccount={deleteAccount}
          />
        </div>
      )}

      {/* add bank */}

      <Modal
        closeOnOverlayClick={false}
        size={'xl'}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'25px'} fontWeight={'bold'}>
            Add Bank Account
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Select your bank and fill in the required information.</p>

            <div className="formContainer">
              <div>
                <label>Select Bank</label>
                <Select onChange={e => setBankData(e)}>
                  {banks &&
                    banks.map((bank, i) => (
                      <option id={i} value={bank.code} key={i}>
                        {bank.name}
                      </option>
                    ))}
                </Select>
              </div>

              <div className="mt-3">
                <label>Enter Account Number</label>
                <Input
                  onChange={e => setAccountNumberValue(e.target.value)}
                  size="md"
                  focusBorderColor="#0073E5"
                  placeholder={'Enter the account number'}
                  type="number"
                  inputMode={"numeric"}
                  onKeyPress={formatNumber}
                />
                <span className="font-bold">{accountName}</span>
              </div>
            </div>

            <Button
              color={'white'}
              size={'lg'}
              rounded={'full'}
              background={'#0073E5'}
              width={'full'}
              isLoading={isLoading}
              loadingText={loadingText}
              onClick={e => addAccount(e)}
              disabled={disabled}
              variant={'solid'}
              className="confirmButton"
            >
              {bankButton}
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Withdrawal;
