import React from 'react'
import taskadd from '../../Icons/add-task.svg'
import { useNavigate } from 'react-router';
import Notification from '../../Icons/EarnItNotification.svg';
import Crown from '../../Icons/EarnCrown.svg';
import { Button} from '@chakra-ui/react'

function EarnitHeader(props) {
    const history = useNavigate();
    const accountType = window.location.pathname.split("/")[2]
  const gotonotifications = () => {
    history('/notifications/'+accountType)
  }



  return (
    <div>
    <div className="flex justify-between w-full">
      <div>
        <h2 className="font-bold text-xl -mb-1">EarnIT</h2>
      </div>
      <div className="relative cursor-pointer" onClick={gotonotifications}>
      {props.user.notifications.filter((notification)=>notification.status ==="recent").length > 0 && <div className="notificationsBadge">{props.user.notifications.filter((notification)=>notification.status ==="recent").length}</div>}
        <img src={Notification} alt="notification" />
      </div>
    </div>


    {props.accountType === 'adult' && <div className="earnitCard bg-[#E6F7FB] rounded-lg px-5 py-12 my-7">
      <div className="  flex flex-col sm:flex-row flex-wrap gap-3 ">
        <img src={Crown} className=' max-w-[64px] ' alt="crown" />
        <div className='ml-3 flex flex-row gap-4' >
        <div className=" flex flex-col justify-between">
          <span className="headText text-[#54595E]">Total Tasks</span>
          <p className="font-bold text-3xl mt-1">{props.tasks.length}</p>
        </div>
        <div className="  flex flex-col justify-between  ">
          <span className="headText text-[#54595E]">Pending Tasks</span>
          {props.tasks && <p className="font-bold text-3xl mt-1 text-[#151618]-900">{props.tasks.filter((task)=>task.status === "pending").length}</p>}
        </div>
        <div className=" flex flex-col justify-between">
          <span className='headText text-[#54595E]'>Completed Tasks</span>
          {props.tasks && <p className="font-bold text-3xl mt-1">{props.tasks.filter((task)=>task.status === "completed" || task.status === "approved").length}</p>}
        </div>
        </div>
      </div>
    </div>}


    {props.accountType === 'youngster' && <div className="earnitCard bg-[#E6F7FB] rounded-lg px-5 py-12 my-4">
      <div className="  flex flex-col sm:flex-row flex-wrap gap-3 ">
        <img src={Crown} className=' max-w-[64px] ' alt="crown" />
        <div className='ml-3 flex flex-row gap-4' >
        <div className=" flex flex-col justify-between">
          <span className="headText text-[#54595E]">Earned Balance</span>
          <p className="font-bold text-3xl mt-1">₦ {props.user.transactions.filter((transaction)=>transaction.type==='earnit').reduce((a,b)=>(a + b.amount),0)}</p>
        </div>
      
        
        </div>
      </div>
    </div>}

    {props.accountType === 'youngster' && 
    <div className="taskGrid">
    <div className="bg-[#E6F7FB] rounded-lg px-5 py-12 my-3">
      <div className="  flex flex-col sm:flex-row flex-wrap gap-3 ">
        <div className='ml-3 flex flex-row gap-4' >
        <div className=" flex flex-col justify-between">
          <span className="headText text-[#54595E]">Approved</span>
          <p className="font-bold text-3xl mt-1">{props.tasks && props.tasks.filter((task)=>task.status==='approved').length}</p>
        </div>
      
        
        </div>
      </div>
    </div>

<div className="bg-[#E6F7FB] rounded-lg px-5 py-12 my-3">
<div className="  flex flex-col sm:flex-row flex-wrap gap-3 ">
  <div className='ml-3 flex flex-row gap-4' >
  <div className=" flex flex-col justify-between">
    <span className="headText text-[#54595E]">Unapproved</span>
    <p className="font-bold text-3xl mt-1">{props.tasks && props.tasks.filter((task)=>task.status!=='approved').length}</p>
  </div>

  
  </div>
</div>
</div>

<div className="bg-[#E6F7FB] rounded-lg px-5 py-12 my-3">
      <div className="  flex flex-col sm:flex-row flex-wrap gap-3 ">
        <div className='ml-3 flex flex-row gap-4' >
        <div className=" flex flex-col justify-between">
          <span className="headText text-[#54595E]">Total Tasks</span>
          <p className="font-bold text-3xl mt-1">{props.tasks.length}</p>
        </div>
      
        
        </div>
      </div>
    </div>
    </div>
    
    }
    
    {props.accountType === 'adult' && <div className="w-5/6 my-6 mx-auto">
      <Button
        height="48px"
        width="100%"
        border="1px"
        borderColor="[#06AEDA].500"
        variant="outline"
        rounded={'full'}
        padding={'29px 0px'}
        color='#06AEDA'
        onClick={()=>history("/create-task/adult")}
      >
            <span className='flex justify-center'>
                <img className="mr-4" src={taskadd} alt="task-add" /> Create Task
            </span>
      </Button>
    </div>}

    
    </div>
  )
}

export default EarnitHeader