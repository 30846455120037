import React from 'react';

export default function ShareWhite() {
  return (
    <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.334 9.16683L18.1673 2.3335"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.834 5.6665V1.6665H14.834"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.66602 1.6665H7.99935C3.83268 1.6665 2.16602 3.33317 2.16602 7.49984V12.4998C2.16602 16.6665 3.83268 18.3332 7.99935 18.3332H12.9993C17.166 18.3332 18.8327 16.6665 18.8327 12.4998V10.8332"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
  );
}
