import React, { useState } from 'react';
import './App.css';
import appLanding from '../../Image/appLanding.jpg';
import appLanding2 from '../../Image/appLanding2.jpg';
import appLanding3 from '../../Image/appLanding3.jpg';
import { Button, Image } from '@chakra-ui/react';
import adult from '../../Icons/adult.svg';
import youngster from '../../Icons/youngster.svg';
import { useNavigate } from 'react-router';
import appLogo from '../../Icons/appLogo.svg';
import createAccount from '../../Icons/createAccount.svg';

function AppPage() {
  const history = useNavigate();
  const [action, setAction] = React.useState('');
  const [text, setText] = React.useState('');
  const [images] = React.useState([appLanding, appLanding2, appLanding3]);

  const [currentIndex, setCurrentIndex] = useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === images.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex, images]);

  const closeBottomSheet = () => {
    document.getElementById('bottom-sheet').style.height = '0';
    document.getElementById('bottom-sheet').style.visibility = 'hidden';
    document.getElementById('bottom-sheet').style.display = 'none';
  };

  const openBottomSheet = (e, text) => {
    setAction(e);
    setText(text);
    document.getElementById('bottom-sheet').classList.add('displayBottomSheet');
    document.getElementById('bottom-sheet').style.display = 'block';
    document.getElementById('bottom-sheet').style.visibility = 'visible';
  };

  const goToYoungster = () => {
    localStorage.setItem("accountType",'youngster')
    history('/' + action + '/youngster');
  };

  const goToAdult = () => {
    localStorage.setItem("accountType",'adult')
    history('/' + action + '/adult');
  };

  React.useEffect(() => {
    localStorage.setItem("appLogin",true)
  }, []);

  return (
    <main className="appContainer">
      <Image
        onClick={() => closeBottomSheet()}
        id="appImage"
        className="appImage"
        src={images[currentIndex]}
        alt="appLanding"
      ></Image>
      <div className="buttons">
        <div className="buttonContainer space-y-4">
          <div className="flex justify-center mb-12">
            <img src={appLogo} alt="" />
          </div>
          <div>
            <Button
              rounded={'full'}
              className="appButtons"
              width={'full'}
              color={'white'}
              backgroundColor="#0066f5"
              variant={'#0066f5'}
              onClick={() =>
                openBottomSheet(
                  'email-signup',
                  'How would you like to start your finance journey'
                )
              }
            >
              Get Started
            </Button>
          </div>
          <div>
            <Button
              color={'white'}
              className="appButtons"
              rounded={'full'}
              width={'full'}
              variant={'outline'}
              onClick={() =>
                openBottomSheet(
                  'login',
                  'Choose your account type. Jump right back in'
                )
              }
            >
              Login
            </Button>
          </div>
        </div>

        <div id="bottom-sheet" className="bottomSheetContainer">
          <div className="my-3">
            <img width={'50px'} src={createAccount} alt="createAccount" />
          </div>
          <h2>{text}</h2>
          <div className="space-y-4 mt-8">
            <div>
              <Button
                onClick={goToYoungster}
                rounded={'full'}
                width={'full'}
                className="appButtons"
                color={'#0066f5'}
                borderColor={'#0066f5'}
                variant={'outline'}
              >
                {' '}
                <img src={youngster} alt="adult" className="mr-5" /> As a
                Youngster
              </Button>
            </div>

            <div>
              <Button
                onClick={goToAdult}
                rounded={'full'}
                width={'full'}
                className="appButtons"
                color={'#0066f5'}
                borderColor={'#0066f5'}
                variant={'outline'}
              >
                {' '}
                <img src={adult} alt="youngster" className="mr-5" /> As a
                Parent/Guardian
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AppPage;
