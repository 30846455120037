import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  PopoverArrow,
  PopoverHeader,
} from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { NavLink } from 'react-router-dom';

function InfoTip(props) {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <QuestionOutlineIcon color={"gray"}/>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverHeader>{props.title}</PopoverHeader>

        <PopoverBody fontWeight={'medium'}>
          {props.message ? props.message : ""}

         {!props.message && <div>Your MinieTAG is your username, it is your unique identity on
          MinieMoney. You can also receive money with it. Check out{' '}
          <NavLink className={'link'} to="/request-money/youngster">
            Request money
          </NavLink>
          </div>}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default InfoTip;
