import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { toast } from 'react-toastify';
import { FiCheckCircle } from 'react-icons/fi';

function KahootInfo(props) {
  const [btnTitle, setBtnTitle] = useState('Copy Game Pin');
  const [isCopied, setIsCopied] = useState(false);

  const showKahoot = () => {
    document.getElementById(props.id).style.display = 'none';
    document.getElementById('kahoot').style.display = 'block';
  };

  const copyPin = pin => {
    setBtnTitle('Copied');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
      setBtnTitle('Copy Game Pin');
    }, 500);
    navigator.clipboard.writeText(pin);
    toast.success('Game pin copied to clipboard', {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <div id={props.id} className="text-center">
      <p className="max-w-lg mx-auto py-4">
        Win amazing prizes playing our Kahoot Quiz
        game
      </p>

      <div>
        <h2 className="my-1 font-semibold">Instructions</h2>
        <p className="text-sm">
          1) Copy the Game pin below and enter it as your pin when the game starts
        </p>
        <p className="text-sm">
          2) Enter your <strong>MinieTAG</strong> as your <strong>Nickname</strong>{' '}
          when the game starts
        </p>
      </div>

      <div className="mt-5 mb-6">
        <h2 className="text-xl my-1">
          Game Pin:{' '}
          <strong style={{ color: '#7A58EF' }}>{props.quiz.code}</strong>{' '}
        </h2>
        <Button
          onClick={() => copyPin(props.quiz.code)}
          _hover={{ background: '#F2EEFD' }}
          borderRadius={'30px'}
          bgColor={'#F2EEFD'}
          color={'#7A58EF'}
          size={'sm'}
        >
          {isCopied ? (
            <FiCheckCircle className="mr-1" />
          ) : (
            <CopyIcon className="mr-1" />
          )}{' '}
          {btnTitle}
        </Button>
      </div>
      <Button
        padding={'30px 12px'}
        className={'pulsing-button'}
        background={'#7a58ef'}
        color={'white'}
        fontSize={'2xl'}
        rounded={'full'}
        size={'lg'}
        _hover={{ background: '#7a58ef' }}
        onClick={showKahoot}
      >
        Start Game
      </Button>
    </div>
  );
}

export default KahootInfo;
