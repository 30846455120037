import React, { useState } from 'react';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';

import {
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Avatar,
} from '@chakra-ui/react';
// import Swal from 'sweetalert2';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchUser } from '../../../store/userLogin';
import { useDispatch } from 'react-redux';
import EnterTransactionPinModal from '../../../components/Modal/EnterTransactionPinModal';
import AmountInput from '../../../components/Misc/Forms/AmountInput';
import { standardAmountFormat } from '../../../utils/amountUtil';

function ConfirmDetails(props) {
  return (
    <div>
      <div className="confirmYoungsterDetails">
        <div>
          <div className="confirmYoungsterDetails">
            <Avatar src={props.user.profileImage} alt="youngsterImg" />
            <div>
              <h2>
                {props.user.firstName} {props.user.lastName}
              </h2>
              <p>{props.user.email}</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="my-3 flex justify-between">
        <div>
          <p className="text-sm">Amount</p>
          <h2 className="font-bold">{standardAmountFormat(props.amount)}</h2>
        </div>
        <div>
          <p className="text-sm">Reason for Sending Money:</p>
          <h2 className="font-bold">{props.reason}</h2>
        </div>
      </div>
    </div>
  );
}

function SendMoneyUser(props) {
  const [disabled, setDisabled] = React.useState(true);
  const [minietag, setMinietag] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [pin, setPin] = React.useState('');
  const [user, setUser] = React.useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const gotoTransfer = () => {
    const sendmoneyuser = document.getElementById('sendmoneyuser');
    const transfer = document.getElementById('transfer');
    transfer.style.display = 'block';
    sendmoneyuser.style.display = 'none';
  };

  React.useEffect(() => {
    if (pin === '' || pin.length < 4) {
      setDisabled(false);
    }

    if (minietag !== '' && amount > 0 && reason !=='') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [pin,amount,minietag,reason]);

  const setAmountValue = e => {
    setAmount(e)
  }

  const sendMoney = () => {
    if (amount < 0 || amount === '') {
      return;
    }
    setLoading(true);
    setDisabled(true)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/fetch-data-minietag',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        minietag: minietag.toLowerCase().trim(),
      },
    })
      .then(response => {
        setUser(response.data.data);
        onOpen();
        setLoading(false);
        setDisabled(false)
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Invalid transaction',
          text: error.response.data.data.message,
        });
        setLoading(false);
        setDisabled(false)
      });
  };

  const confirmTransaction = () => {
    onClose();
    setShowModal(true);
    setDisabled(true)
  };

  const sendMoneytoUser = () => {
    setLoading(true);
    setDisabled(true)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/send-money-user',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: {
        amount: amount,
        minietag: minietag.toLowerCase().trim(),
        reason: reason,
        pin: pin,
        accountType: props.accountType,
      },
    })
      .then(response => {
        dispatch(fetchUser(props.accountType)).then(() => {
          setShowModal(false);
          setLoading(false);
          setDisabled(false)
          Swal.fire({
            icon: 'success',
            title: response.data.data.message,
            text:
              'You have successfully transferred ₦ ' +
              amount +
              ' to ' +
              minietag,
          }).then(result => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        });
      })
      .catch(error => {
        setLoading(false);
        setDisabled(false)
        setShowModal(false);
        console.log(error);
        Swal.fire({
          icon: 'warning',
          title: 'Something went wrong 🤔',
          text: error.response.data.data.message,
        }).then(result => {
          if (result.isConfirmed) {
            setShowModal(true);
            setDisabled(true)
          }
        });
      });
  };

  return (
    <div
      id={props.id}
      className="dashboardCenter hiddenForm walletScreensContainer"
    >
      <WalletScreenHeader goBack={gotoTransfer} title={props.title} />

      <div className="formContainer">
        <div>
          <label>Receiver's MinieTAG</label>
          <InputGroup size="lg">
            <InputLeftElement>@</InputLeftElement>
            <Input
              onChange={e => setMinietag(e.target.value)}
              focusBorderColor="#7A58EF"
              placeholder={"Enter receiver's MinieTAG"}
              fontSize={"15px"}
            />
          </InputGroup>
        </div>

        <div className="my-3">
          <label>How much are you sending?</label>
          <AmountInput
            placeholder={'Enter the amount'}
            focusBorderColor={'#7A58EF'}
            setAmount={e => setAmountValue(e)}
          />
        </div>

        <div>
          <label>Reason for Transfer</label>
          <Input
            onChange={e => setReason(e.target.value)}
            size="lg"
            focusBorderColor="#7A58EF"
            placeholder={'Enter reason for sending money'}
            fontSize={"15px"}
          />
        </div>

        <div className="mt-6">
          <Button
            isLoading={isLoading}
            onClick={sendMoney}
            padding={'28px 32px'}
            width={'100%'}
            disabled={disabled}
            rounded={'full'}
            colorScheme={'#7A58EF'}
            background={'#7A58EF'}
          >
            Continue
          </Button>
        </div>
      </div>

      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-medium text-xl">Confirm Transaction</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ConfirmDetails user={user} amount={amount} reason={reason} />
          </ModalBody>

          <ModalFooter
            margin={'0px 0px 20px 0px'}
            className="confirmDetailsFlexButtons"
          >
            <Button
              color={'#54595E'}
              rounded={'full'}
              background={'#F3F3F4'}
              colorScheme="#F3F3F4"
              onClick={onClose}
            >
              <ArrowBackIcon className="mr-3" /> Cancel
            </Button>
            <Button
              rounded={'full'}
              className="confirmButton"
              background={'#7A58EF'}
              colorScheme="#7A58EF"
              onClick={confirmTransaction}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <EnterTransactionPinModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        confirm={() => sendMoneytoUser()}
        loading={isLoading}
        setLoading={e => setLoading(e)}
        setPin={e => setPin(e)}
        disabled={disabled}
        setDisabled={(e) => setDisabled(e)}
        bg={'#7A58EF'}
        pinBg={'#F2EEFD'}
        color={'#7A58EF'}
        class={"transaction"}
      />
    </div>
  );
}

export default SendMoneyUser;
