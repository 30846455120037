import React from 'react';
import transaction from '../../Image/transaction.svg';
import { useNavigate } from 'react-router';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';

function TransactionPinModal(props) {
  
    const history = useNavigate();
    const gototransactions = () => {
      history('/account/' + accountType + '/setpin');
    };
    const accountType = window.location.pathname.split('/')[2];
  const { isOpen, onClose } = useDisclosure({ isOpen: props.isOpen });
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={6}>
            <div className="flex justify-between flex-col items-center py-[32px]">
              <img
                src={transaction}
                alt="transaction"
                height="104px"
                width="104px"
              />
              <h5 className="font-bold text-xl text-[#151618] pt-3">
                Set Transaction PIN
              </h5>
              <p className="font-medium text-[#151618] text-sm text-center py-3">
                To ensure the security of your account, you need to set a
                transaction PIN. This PIN will be used to verify transactions
                and protect your account from unauthorized access.
              </p>
              <button
                onClick={()=>gototransactions()}
                className="w-full mt-6 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
             bg-[#005FEB] text-[#E6EFFD] cursor-pointer
             rounded-full border-none"
              >
                <div className="flex gap-1 items-center">
                  <span className="font-semibold text-base">Continue</span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.025 4.94165L17.0834 9.99998L12.025 15.0583"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.91669 10H16.9417"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default TransactionPinModal;
