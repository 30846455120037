import moment from 'moment';

const daily = 86400000;
const weekly = 604800000;
const monthly = 2629746000;

export function dateFormatter(date) {
  if (typeof date === 'string') {
    const dateObj = moment(date);
    // format the moment object to the desired output format
    const formattedDate = dateObj.format('MMMM D, YYYY h:mm A');
    // print the final result
    return formattedDate;
  } else {
    return moment(date).format('LLL');
  }
}

export function nextKoloSave(autokolosave) {
  if(autokolosave){
  const { frequency, lastTransfer } = autokolosave;
  switch (frequency) {
    case 'daily':
      return moment(lastTransfer + daily).format('LL');
    case 'weekly':
      return moment(lastTransfer + weekly).format('LL');
    case 'monthly':
      return moment(lastTransfer + monthly).format('LL');
    default:
      return '';
  }
} else{
  return "Autosave not set"
}
}
