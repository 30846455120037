import {
  FormControl,
  Input,
  HStack,
  InputGroup,
  InputLeftAddon,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React from 'react';
import flag from '../../Icons/Flag.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import SuccessModal from './SuccessModal';
import EmailInput from '../Misc/Forms/EmailInput';
import { checkEmail } from '../Misc/Forms/FormChecks/CheckEmail';

function InviteParent(props) {
  const [btnloading, setBtnLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [relationship, setRelationship] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);


  React.useEffect(()=>{
    if (
      firstName !== '' &&
      lastName !== '' &&
      checkEmail(email) &&
      phoneNumber.length === 11 &&
      relationship !== ''
    ) {
      setDisabled(false);
    }
    else{
      setDisabled(true)
    }
  },[firstName,lastName,email,phoneNumber,relationship])

  const setEmailValue = e => {
    setEmail(e)
  };

  const setPhoneValue = e => {
    setPhoneNumber(e)
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const closeSuccess = () => {
    setShowModal(false);
    window.location.reload();
  };

  const sendInvite = () => {
    setBtnLoading(true);
    const parents = [
      {
        firstName: firstName + ' ' + lastName,
        email: email,
        phone: phoneNumber,
      },
      { firstName: '', email: '', phone: '' },
    ];
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/youngster-invite-parent',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
      data: {
        parents: parents,
        youngsterName: props.user.firstName,
      },
    })
      .then(() => {
        setBtnLoading(false);
        setShowModal(true);
      })
      .catch(error => {
        console.log(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Invite not sent!',
          text: 'We could not send an invite to your parents. Try again later',
          confirmButtonColor: '#2b5fd0',
        });
        setBtnLoading(false);
      });
  };

  return (
    <div id={props.id} className="my-6 hiddenForm">
      <p>Tell us about your parent by filling out the information below:</p>
      <FormControl className="my-3 space-y-3">
        <div>
          <label>First Name</label>
          <Input onChange={e => setFirstName(e.target.value)} />
        </div>

        <div>
          <label>Last Name</label>
          <Input onChange={e => setLastName(e.target.value)} />
        </div>

        <div>
          <label>Select which of your parents you're inviting</label>
          <HStack color="#0E0E0F">
            <div className="radioSpace">
              <input
                onChange={e => setRelationship(e.target.value)}
                type="radio"
                id="email"
                name="signupType"
                value="father"
              />
              <label> Daddy </label>
            </div>
            <input
              onChange={e => setRelationship(e.target.value)}
              type="radio"
              id="email"
              name="signupType"
              value="mother"
            />
            <label> Mummy </label>
          </HStack>
        </div>

        <div>
          <label>Phone Number</label>
          <div>
            <InputGroup>
              <InputLeftAddon background={'white'}>
                <img src={flag} alt="flag" />
                <span className="ml-2">234</span>
                <ChevronDownIcon />
              </InputLeftAddon>
              <Input
                placeholder={'e.g 08012345678'}
                prefix="234"
                type={'number'}
                onChange={e => setPhoneValue(e.target.value)}
                inputMode={'numeric'}
              ></Input>
            </InputGroup>
          </div>
        </div>

        <div>
          <EmailInput
            placeholder={"Enter parent's email address"}
            setEmail={e => setEmailValue(e)}
            label={'Email address'}
            size={"md"}
          />
        </div>

        <Button
          onClick={() => sendInvite()}
          isLoading={btnloading}
          isDisabled={disabled}
          background={'#005FEB'}
          colorScheme={'#005FEB'}
          textColor={'white'}
          className="confirmButton"
          rounded={'full'}
          width={'100%'}
        >
          Invite parent
        </Button>
      </FormControl>

      <SuccessModal
        showModal={showModal}
        closeModal={closeModal}
        title={'Invite successfully Sent To Your Parent'}
        description={
          'You have successfully sent an invite to your parent. Kindly remind them to create an account so that your account can be linked with theirs.'
        }
        btnTitle={'Okay'}
        closeSuccess={closeSuccess}
      />
    </div>
  );
}

export default InviteParent;
