import React from 'react'
import SavingsIcon from "../../Icons/saveIcon.svg"
import eyeIcon from "../../Icons/eye-black.svg"
import { amountFormat, formatZero } from '../../utils/amountUtil'
import { Spinner } from '@chakra-ui/react'

function WalletCard(props) {
  const [showBalance,setShowBalance] = React.useState(localStorage.getItem("showBalance") !==null?JSON.parse(localStorage.getItem("showBalance")):true)
  
  const setShowBalanceValue = (e) =>{
    localStorage.setItem("showBalance",e)
    setShowBalance(e)
  }
  
  return (
    <div className="savingsCard">
        <div className="flexCard">
        <img src={SavingsIcon} alt=""/>
        <div className="minieSavingsTitle">
        <p className="mt-2">Minie Savings Balance</p>
        <h2>
        {props.balances !== undefined && props.balances.savingsBalance ? (
                amountFormat(props.balances.savingsBalance, showBalance)
              ) :props.balances !== undefined && props.balances.savingsBalance === 0 ? (
                formatZero(props.balances.savingsBalance,showBalance)
              ) : (
                <Spinner />
              )}
        </h2>
        </div>
        </div>
        <img className="eyeIcon" onClick={()=>setShowBalanceValue(!showBalance)} src={eyeIcon} alt=""/> 
    
    </div>
  )
}

export default WalletCard