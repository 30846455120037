import React,{useState} from 'react';
import './Kyc.css';
import KYCHeader from '../../../components/KYC/KYCHeader';
import {Input,Button } from '@chakra-ui/react';
import Instructions from './Instructions';
import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchUser } from '../../../store/userLogin';
import {useDispatch} from 'react-redux'
import { formatNumber } from '../../../utils/Forms/formatNumber';

function Bvn(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [bvn, setBvn] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [loading,setLoading] = useState(false)
  const dispatch = useDispatch()


  React.useEffect(()=>{
    if(bvn.length === 11){
      setDisabled(false)
    }
    else{
      setDisabled(true)
    }
  },[bvn])

  const goToOVerview = () => {
    const overview = document.getElementById('overview');
    const bvn = document.getElementById('bvn');
    overview.style.display = 'block';
    bvn.style.display = 'none';
  };

  const verifyBvn = () => {
    setLoading(true)
    axios({
      method:"POST",
      url:process.env.REACT_APP_PAYMENT_URL+"/verify-bvn",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json",
      },
      data:{
        bvn:bvn
      }
    }).then(()=>{
      setLoading(false)
      dispatch(fetchUser('adult'))
      Swal.fire({
        icon:"success",
        title:"BVN Confirmed!",
        text:"You have successfully confirmed your bank verification number (BVN).",
        confirmButtonColor:"#005FEB",
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.reload()
        }
      })
    }).catch((error)=>{
      console.log(error)
      setLoading(false)
      Swal.fire({
        icon:"error",
        title:"Something went wrong! 🤔",
        text:error.response.data ? error.response.data.data.message:"Network error! Please try later" ,
        confirmButtonColor:"#005FEB",
      })
    })
  };

  return (
      <div
        id={props.id}
        className="dashboardCenter hiddenForm walletScreensContainer"
      >
        <KYCHeader goBack={goToOVerview} title="Bank Verification (BVN)" />
        <div className="my-6">
        <label>Kindly enter your BVN</label>
        <Input onKeyPress={formatNumber} inputMode={"numeric"} type="number" onChange={e => setBvn(e.target.value)} />
        </div>

        <Instructions/>

        <Button
        onClick={verifyBvn}
        className="confirmButton"
        isDisabled={isDisabled}
        width={"full"}
        colorScheme={"#0066f5"}
        background={"#0066f5"}
        rounded={"full"}
        isLoading={loading}
      >
        Confirm
      </Button>
      </div>

      
  
  );
}

export default Bvn;
