import React from 'react';
import Modal from './Modal';
import { Avatar } from '@chakra-ui/react';
import Confetti from 'react-confetti';

function SignupImageModal(props) {
  return (
    <Modal isVisible={props.showModal} onClose={() => props.closeModal()}>
       <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          className="sm:top-[19%]"
        >
          <Confetti width={600} className="w-full sm:w-[460px]" />
        </div>
      <div className="p-4 flex justify-between flex-col items-center">
        <Avatar className="signupImage" size={"2xl"} src={props.image} />
        <h2 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
          {props.title}
        </h2>
        <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
          {props.description}
        </p>

        <button
          onClick={() => props.onClick()}
          className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
             bg-[#005FEB] text-[#E6EFFD] rounded-full mt-2 hover:border-[2px] z-40"
        >
          <span className="font-semibold text-base">{props.btnTitle}</span>
        </button>
      </div>
    </Modal>
  );
}

export default SignupImageModal;
