import React, { useState } from 'react';
import { InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

function Password(props) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  function passwordValue(e) {
    props.setPassword(e.target.value);

  }

  return (
    <div>
      <label>{props.label}</label>
      <InputGroup size="md">
        <Input
          pr="4.5rem"
          type={show ? 'text' : 'password'}
          placeholder={props.placeholder}
          onChange={e => passwordValue(e)}
        />
        <InputRightElement>
          <Button
            variant={'unstyled'}
            background={'white'}
            size="sm"
            onClick={handleClick}
          >
            {show ? <FiEye size={'20px'} /> : <FiEyeOff size={'20px'} />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </div>
  );
}

export default Password;
