import React from 'react';
import { ArrowBackIcon, AddIcon } from '@chakra-ui/icons';
import { Button, IconButton } from '@chakra-ui/react';
import SettingsIcon from '../../Icons/settingsIcon.svg';
import { useNavigate } from 'react-router';

function WalletScreenHeader(props) {
  const history = useNavigate();

  const showYoungsterSettings = () => {
    document.getElementById('youngsterOverview').style.display = 'none';
    document.getElementById('youngsterSettings').style.display = 'block';
  };

  return (
    <div>
      <IconButton
        onClick={() => props.goBack()}
        className="walletAccountArrow"
        rounded={'full'}
        icon={<ArrowBackIcon />}
      />
      <h2 className="walletScreenTitle text-center">{props.title}</h2>

      <div
        id="settingsBtn"
        onClick={() => showYoungsterSettings()}
        className="-mt-8 float-right cursor-pointer"
      >
        {(window.location.pathname !== '/account/adult/youngster' &&
          window.location.pathname !== '/youngsters/adult') && (
          <img src={SettingsIcon} alt="settingsIcon" />
        )}

        {(window.location.pathname === '/account/adult/youngster' ||
          window.location.pathname === '/youngsters/adult') && (
          <div className="mt-2 text-right">
            <Button
              onClick={() => history('/create-youngster/adult')}
              rounded={'full'}
              color="#0073E5"
              variant="link"
            >
              <AddIcon className="mr-2" />
              Add
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default WalletScreenHeader;
