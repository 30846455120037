import React from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import Withdrawal from '../../../components/Withdrawal/Withdrawal';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { fetchBanks } from '../../../store/fetchBanks';
import { fetchUserBanks } from '../../../store/fetchUserBanks';
import { fetchKYCs } from '../../../store/fetchKyc';

function WithdrawalPage() {
  document.title = 'Withdrawal | MinieMoney';
  const banks = useSelector(state => state.userbanks.value);
  const user = useSelector(state => state.user.value);
  const accountType = window.location.pathname.split('/')[2];
  const kycs = useSelector(state => state.kycs.value);
  const kycloading = useSelector(state => state.kycs.loading);
  const history = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchKYCs());
    dispatch(fetchBanks());
    dispatch(fetchUserBanks());
    if (
      sessionStorage.getItem('minieToken') == null &&
      sessionStorage.getItem('userData') == null
    ) {
      history('/login/');
    }
  }, [history, accountType, user, dispatch]);

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <Withdrawal
              id={'overview'}
              user={user}
              banks={banks}
              kycs={kycs}
              kycloading={kycloading}
            />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default WithdrawalPage;
