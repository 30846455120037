import React, { useState, Fragment } from 'react';
import back from '../../Icons/backButton.svg';
// import brek from '../../Image/brek.png';
// import { ArrowForwardIcon } from '@chakra-ui/icons';
// import generate from '../../Image/generate.png';
import { Button, Input, Select } from '@chakra-ui/react';
import success from '../../Icons/successIcon.svg';
import Swal from 'sweetalert2';
import Modal from '../Modal/Modal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/userLogin';
import { standardAmountFormat } from '../../utils/amountUtil';

function BreakKolo(props) {
  const [loading,setLoading] = useState(false)
  const [amount,setAmount] = useState(0)
  const [disabled, setDisabled] = React.useState(true);

  const setAmountValue = e => {
    setAmount(e.target.value);
    if (amount > 0 && amount !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const accountType = window.location.pathname.split("/")[2]
  const dispatch = useDispatch()
  window.scrollTo(0,0)
  const Back = () => {
    props.Back();
  };
  const [showModal2, setShowModal] = React.useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  const showKoloStatement = () =>{
    setShowModal(false)
    document.getElementById('koloSaveStatement').style.display = 'block';
    document.getElementById('kolosave').style.display = 'none';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('instantsave').style.display = 'none';
    document.getElementById('breakKolo').style.display = 'none';
  }

  const breakKolo = () =>{
    setLoading(true)
    axios({
      method:"POST",
      url:process.env.REACT_APP_API_URL+"/kolosave-withdraw",
      headers:{
        Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
        ContentType:"application/json",
        Accept:"*/*"
      },
      data:{
        amount:amount
      }
    }).then(()=>{
      dispatch(fetchUser(accountType)).then(()=>{
        setLoading(false)
       setShowModal(true)
      })
    }).catch((error)=>{
      setLoading(false)
      console.log(error)
      Swal.fire({
        icon:"error",
        title:"Something's wrong 😢",
        text:error.response.data.data.message,
        confirmButtonColor:"#669f2a"
      })
    })
  }


  return (
    <Fragment>
      <div id="breakKolo" className="dashboardCenter hiddenForm">
        <div onClick={Back} className="cursor-pointer pb-4">
          <img src={back} alt="" />

          <h2 className="walletScreenTitle text-center">Break My Kolo</h2>
        </div>

        <div className="my-3 flex rounded-lg py-4 px-4 justify-between items-center bg-[#F0F5EA] flex-row ">
          <div>
            <p className="text-md font-normal">
            KoloSave Balance:
            </p>
          </div>
          <div className="text-[#669F2A] font-bold text-lg">{standardAmountFormat(props.user.koloBalance)}</div>
        </div>
        <div className="pt-4 my-4">
          <label>Select Withdrawal Destination</label>
          <Select
            focusBorderColor="#669F2A"
            color={'#151618'}
            _placeholder={'Where should we send your money to?'}
          >
          <option className='opacity-[1] text-gray-400'>Where should we send your money to?</option>
            <option value="option1">MinieWallet ({standardAmountFormat(props.user.walletBalance)})</option>
          </Select>
        </div>

        <div className="my-5">
          <label>Amount To Withdraw</label>
          <Input
            size="md"
            focusBorderColor="#669F2A"
            placeholder={'Enter the amount you want to withdraw'}
            color={'#151618'}
            _placeholder={{ opacity: 1, color: '#AEB2B7' }}
            type={"number"}
            inputMode={"numeric"}
            onChange={e => setAmountValue(e)}
          />
        </div>

        <div className="mt-6">
          <Button
            onClick={breakKolo}
            padding={'28px 32px'}
            width={'100%'}
            rounded={'full'}
            colorScheme={'#669F2A '}
            background={'#669F2A '}
            isLoading={loading}
            disabled={disabled}
          >
          Withdraw money
          </Button>
        </div>
      </div>
      <Modal isVisible={showModal2} onClose={() => closeModal()}>
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center sm:mt-3">
            KoloSave broken
          </h5>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            Congratulations! You have successfully broken your KoloSave, kindly
            participate in next year’s KoloSave to keep being a savings
            champion.
          </p>
          <button onClick={showKoloStatement}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] rounded-full mt-2"
          >
            <span className="font-semibold text-base text-[#FFFFFF]">Generate my KoloSave statement</span>
          </button>
          <button
              onClick={() => closeModal()}
              className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-none text-[#669F2A] rounded-full mt-2"
            >
              <span className="font-semibold text-base">Not now</span>
            </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default BreakKolo;
