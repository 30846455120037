import React from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import SetAllowance from '../../../components/SetAllowance/SetAllowance';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import { useSelector } from 'react-redux';
import axios from 'axios';

const SetAllowancePage = React.memo(() => {
  const user = useSelector(state => state.user.value);
  const accountType = window.location.pathname.split('/')[2];
  const [isLoading, setIsLoading] = React.useState(true);
  const [allowanceData, setAllowanceData] = React.useState('');
  const youngster = JSON.parse(sessionStorage.getItem('youngster'));

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    axios({
      method: 'GET',
      url:
      process.env.REACT_APP_API_URL+'/get-allowance/' +
        JSON.parse(sessionStorage.getItem('userData')).minietag +
        '-' +
        JSON.parse(sessionStorage.getItem('youngster')).minietag,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
    }).then(response => {
      setAllowanceData(response.data.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <SetAllowance
              isLoading={isLoading}
              allowanceData={allowanceData}
              id={'overview'}
              user={user}
              youngster={youngster}
            />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
});
export default SetAllowancePage;
