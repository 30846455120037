import { CopyIcon } from '@chakra-ui/icons';
import React from 'react';
import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FiCheckCircle } from 'react-icons/fi';

function BankDetails({ title, value }) {
  const [btnTitle, setBtnTitle] = React.useState('Copy');
  const [isCopied,setIsCopied] = React.useState(false)

  const copyDetails = value => {
    setBtnTitle('Copied');
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
      setBtnTitle('Copy');
    }, 500);
    navigator.clipboard.writeText(value);
    toast.success(title + ' copied to clipboard', {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <div className="BankDetails flex justify-between">
      <div>
        <span className="title">{title}</span>
        <h2 className="text-xl font-semibold">{value}</h2>
      </div>

      <div>
        <Button
          onClick={() => copyDetails(value)}
          _hover={{ background: '#F2EEFD' }}
          width={'100px'}
          borderRadius={'30px'}
          bgColor={'#F2EEFD'}
          color={'#7A58EF'}
        >
          {isCopied ? <FiCheckCircle className="mr-1"/>: <CopyIcon className="mr-1" />} {btnTitle}
        </Button>
      </div>
    </div>
  );
}

export default BankDetails;
