import React, { useState } from 'react';
import { Progress, IconButton, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import '../../components/EmailSignup/EmailVerificationForm.css';
// import { Input } from '@chakra-ui/react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import TransactionPin from '../Misc/TransactionPin';

function VerifyOtpForm(props) {
  const [otp, setOtp] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [disabled,setDisabled] = useState(true)


  React.useEffect(()=>{
    if(otp.length === 4){
      setDisabled(false)
    } else{
      setDisabled(true)
    }
  },[otp])

  const goBack = () => {
    props.nextStep(1);
  };

  function previousScreen() {
    props.nextStep(1);
  }

  function resendOtp() {
    setOtpLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/resend-email-otp',
      headers: {
        ContentType: 'application/json',
        Accept: '/',
      },
      data: {
        email: props.email.trim(),
        sessionId: props.sessionId,
      },
    })
      .then(response => {
        setOtpLoading(false);
        props.setSessionId(response.data.data.sessionId);
        toast.success('Otp resent successfully');
      })
      .catch(error => {
        setOtpLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Invalid session',
          text: 'Please start again from the previous screen',
          confirmButtonColor: '#0066f5',
        });
      });
  }

  //check if otp is correct
  function checkOtp() {
    if (otp !== '') {
      setLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/check-otp',
        headers: {
          ContentType: 'application/json',
          Accept: '/',
        },
        data: {
          otp: otp,
          sessionId: props.sessionId,
        },
      })
        .then(response => {
          props.setProcessId(response.data.data.processId);
          setLoading(false);
          props.nextStep(3);
          toast.success('Otp verified successfully');
        })
        .catch(error => {
          console.log(error)
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Wrong OTP',
            text: error.response.data.data.message,
            confirmButtonColor: '#0073E5',
          });
        });
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Please enter your OTP',
        confirmButtonColor: '#0073E5',
      });
    }
  }

  return (
    <div id={props.id} className="hiddenForm">
      <div className="mb-6">
        <IconButton
          onClick={goBack}
          className="backIconButtonStyle"
          rounded={'full'}
          icon={<ArrowBackIcon />}
        />
      </div>
      <div className="relative">
        <Progress colorScheme="blue" rounded={'full'} value={props.progress} />
        <span className="absolute right-0">{props.progress}%</span>
      </div>

      <h2 className="mt-7">Enter your verification code</h2>
      <p>
        Kindly enter the verification code sent to your email address:
        <span className="brandColor font-bold"> {props.email}</span>
      </p>
     
      <TransactionPin setPin={e => setOtp(e)} class={'auth'} />
      <div className="text-center my-3">
        Didn’t receive a code?{' '}
        <Button
          isLoading={otpLoading}
          onClick={resendOtp}
          ml={'10px'}
          rounded={'full'}
          size="sm"
          color={'#0073E5'}
        >
          Resend
        </Button>{' '}
      </div>

      <div className="my-6 flex justify-between">
        <Button
          onClick={previousScreen}
          rounded={'full'}
          size="lg"
          color={'#A9ABAD'}
        >
          <ArrowBackIcon className="mr-3" /> Back{' '}
        </Button>
        <Button
          isLoading={isLoading}
          onClick={checkOtp}
          rounded={'full'}
          color={'white'}
          size="lg"
          background={'#0066f5'}
          variant={"solid"}
          minWidth={'150px'}
          _hover={{background:"#0066f5"}}
          isDisabled={disabled}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

export default VerifyOtpForm;
