import React from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import YoungsterCenter from '../../../components/Account/YoungsterCenter';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchAdultYoungsters } from '../../../store/fetchAdultYoungsters';

function Youngster() {
  document.title = 'Youngster | MinieMoney';
  const user = useSelector(state => state.user.value);
  const youngsters = useSelector(state => state.youngsters.value);
  const youngsterLoading = useSelector(state => state.youngsters.loading);
  const accountType = window.location.pathname.split('/')[2];
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (accountType === 'adult') {
      dispatch(fetchAdultYoungsters());
    }
  },[accountType,dispatch]);

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <YoungsterCenter
              user={user}
              youngsters={youngsters}
              youngsterLoading={youngsterLoading}
              accountType={accountType}
            />
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default Youngster;
