import React, { useEffect } from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import CreateYoungsterForm from "../../../components/CreateYoungster/CreateYoungster"
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import "./CreateYoungster.css"
import { useSelector } from 'react-redux'

function CreateYoungster() {
  const user = useSelector((state) => state.user.value)


  useEffect(()=>{
    window.scrollTo(0,0)
  })

  return (
    <div>
      <div className="dashboardPage">
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
        <CreateYoungsterForm user={user}/>
        </div>

        <div className="hideOnMobile">
        <DashboardRight accountType={"adult"}/>
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default CreateYoungster