import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
  value: "",
  loading: false,
};


export const fetchYoungsters = createAsyncThunk("task/fetchYoungsters", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/fetch-youngster/"+JSON.parse(sessionStorage.getItem("youngster")).email,
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
            ContentType: "application/json",
        }
      })

      return response.data.data
    
    } catch (error) {
      if(error.response.status === 401){
        setTimeout(()=>{
          localStorage.removeItem("_expiredTime")
          sessionStorage.removeItem('minieToken');
          sessionStorage.removeItem('userData');
          window.location.href = '/login/';
        },3000)
      }
    }
  });
  
  export const fetchYoungster = createSlice({
    name: "youngster",
    initialState,
    extraReducers: {
      [fetchYoungsters.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchYoungsters.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchYoungsters.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchYoungster.reducer;