import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Switch } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Input,
  Select,
  Spinner
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify"
import axios from 'axios';
import Swal from 'sweetalert2';

function YoungsterSetting(props) {
  const history = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [securityQuestion, setSecurityQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const youngsterData = useSelector(state => state.youngster.value)
  



  const updateControl = (e) => {
      const id = toast.loading("Updating controls...")
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/youngster-control/'+youngsterData.id,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
          ContentType: 'application/json',
        },
        data: e,
      })
        .then(response => {
          toast.update(id,{
            render:"Youngster updated",
            type:"success",
            isLoading:false,
            autoClose:3000
          })
          Swal.fire({
            icon: 'success',
            title: 'Youngster Updated',
            text: response.data.data.message,
            confirmButtonColor: '#005FEB',
            confirmButtonText: 'Done',
          }).then(result => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        })
        .catch(error => {
          toast.dismiss()
          onClose();
          Swal.fire({
            icon: 'error',
            title: 'Profile not Updated',
            text: error.response.data.data.message,
            confirmButtonColor: '#005FEB',
            confirmButtonText: 'Try again',
          });
        });
    } 


  const changeTransfer = (e) =>{

    const reqbody = {
      canTransfer:e.target.checked
    }
    updateControl(reqbody)
  }

  const changeRequest = (e) =>{
    const reqbody = {
      canRequest:e.target.checked
    }
    updateControl(reqbody)
  }

  const changeAccountStatus = (e) =>{
    const reqbody = {
      accountStatus:e.target.checked
    }
    updateControl(reqbody)
  }






  const goToLink = e => {
    if (
      props.setting.type === 'change-pin' ||
      props.setting.type === 'two-fa'
    ) {
      onOpen();
    } else {
      if(props.setting.title === "Read Our Blog" || props.setting.title ===  "Join our community" || props.setting.title === "Visit our website"){
        window.open(e,"_blank")
      }else{
      history(e)
      }
    }
  };

  const updateProfile = () => {
    const firstStep = document.getElementById('firstStep');
    const secondStep = document.getElementById('changePin');
   
    
    console.log(otp, pin, securityQuestion, answer);

    if (props.setting.type === 'change-pin') {
      firstStep.style.display = 'none';
      secondStep.style.display = 'block';
    }
  };

  return (
    <div
      onClick={() => goToLink(props.setting.link)}
      className="cursor-pointer youngsterSetting flex py-3 justify-between"
    >
      
      
      <div className="flex">
        <img alt={props.setting.title} src={props.setting.image} />
        <div className="ml-3">
          <h2 className="text-lg mt-1 font-medium -mb-2 -pb-2">{props.setting.title}</h2>
          <span className="text-xs  m-0 p-0">{props.setting.description}</span>
        </div>
      </div>
      <div>
        {props.setting.type !== 'control' && <ArrowForwardIcon />}
        {props.setting.type === 'control' && props.setting.title==="Grant access to transfer and send money" && (youngsterData?<Switch defaultChecked={youngsterData.canTransfer} onChange={(e)=>changeTransfer(e)}/>:<Spinner/>)}
        {props.setting.type === 'control' && props.setting.title==="Grant access to request money" && (youngsterData?<Switch onChange={(e)=>changeRequest(e)} defaultChecked={youngsterData.canRequest}/>:<Spinner/>)}
        {props.setting.type === 'control' && props.setting.title==="Grant access to account" && (youngsterData?<Switch onChange={(e)=>changeAccountStatus(e)} defaultChecked={youngsterData.accountStatus}/>:<Spinner/>)}
      </div>

      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-medium text-xl">
              {props.setting.title === 'What does your youngster call you?'
                ? 'Nickname'
                : props.setting.title}
            </h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div id="firstStep">
              {props.setting.type === 'two-fa' && (
                <form className="space-y-2">
                  <div>
                    <label>Security Question</label>
                    <Select
                      placeholder={'Select your preferred security question'}
                      onChange={e => setSecurityQuestion(e.target.value)}
                    >
                      <option value={'what-is-the-name-of-your-pet'}>
                        What's the name of your pet?
                      </option>
                      <option value={'relationship'}>In a relationship</option>
                      <option value={'married'}>Married</option>
                    </Select>
                  </div>

                  <div>
                    <label>Answer</label>
                    <Input
                      placeholder={'Enter an answer to your security question'}
                      onChange={e => setAnswer(e.target.value)}
                    />
                  </div>

                  <div>
                    <label>MiniePIN</label>
                    <Input
                      placeholder={'Enter your MiniePin'}
                      onChange={e => setPin(e.target.value)}
                    />
                  </div>

                  <Button
                    rounded={'full'}
                    width={'full'}
                    background={'#0066f5'}
                    colorScheme="#0066f5"
                    className="confirmButton"
                    onClick={updateProfile}
                  >
                    Set Security Question
                  </Button>
                </form>
              )}

              {props.setting.type === 'change-pin' && (
                <div id="secondStep">
                  <label>
                    Enter the OTP sent to your email address: {props.user.email}
                  </label>
                  <Input
                    size={'lg'}
                    onChange={e => setOtp(e.target.value)}
                    className="mt-4"
                    type="number"
                  />

                  <p className="text-center my-5">
                    Didn't receive a code?{' '}
                    <Button
                      rounded={'full'}
                      color={'#005FEB'}
                      colorScheme={'#E6EFFD'}
                      background={'#E6EFFD'}
                    >
                      {' '}
                      Resend{' '}
                    </Button>
                  </p>

                  <Button
                    rounded={'full'}
                    width={'full'}
                    background={'#0066f5'}
                    colorScheme="#0066f5"
                    className="confirmButton mb-7"
                    onClick={updateProfile}
                  >
                    Verify Otp
                  </Button>
                </div>
              )}
            </div>

            <div id="changePin" className="hiddenForm">
              <form className="space-y-4">
                <div>
                <label>Enter Old MiniePIN</label>
                <Input onChange={e => setPin(e.target.value)} />
                </div>

                <div>
                <label>Enter New MiniePIN</label>
                <Input onChange={e => setPin(e.target.value)} />
                </div>

                <div>
                <label>Confirm New MiniePIN</label>
                <Input onChange={e => setPin(e.target.value)} />
                </div>

                <Button
                  rounded={'full'}
                  width={'full'}
                  background={'#0066f5'}
                  colorScheme="#0066f5"
                  className="confirmButton mb-7"
                  onClick={updateProfile}
                >
                </Button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default YoungsterSetting;
