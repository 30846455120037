import React from 'react';
import WalletScreenHeader from '../../components/MinieWallet/WalletScreenHeader';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import TransactionList from '../../components/MinieWallet/TransactionList';


function YoungsterTransactions(props) {
  const goToOverview = () => {
    window.scrollTo(0, 0);
    document.getElementById("youngster-single").style.display = "block"
   document.getElementById("youngster-transactions").style.display = "none"
  };

  return (
    <div id={props.id} className="dashboardCenter hiddenForm pb-8">
      <div className="my-4">
        <div className="my-4">
          <WalletScreenHeader
            goBack={goToOverview}
            title={'Transaction History'}
          />
        </div>

        <Tabs variant="unstyled" colorScheme="blue">
          <TabList background={'#FBFBFB'} padding={'8px'} borderRadius={'8px'}>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#7A58EF', bg: '#F2EEFD' }}
              borderRadius={'8px'}
            >
              All
            </Tab>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#7A58EF', bg: '#F2EEFD' }}
              borderRadius={'8px'}
            >
              Credit
            </Tab>
            <Tab
              padding={'10px 0px'}
              width={'50%'}
              _selected={{ color: '#7A58EF', bg: '#F2EEFD' }}
              borderRadius={'8px'}
            >
              Debit
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div>
                {props.transactions &&
                  props.transactions
                    .map((transaction, i) => (
                      <TransactionList key={i} transaction={transaction} />
                    ))}
              </div>
            </TabPanel>
            <TabPanel>
              {props.transactions &&
                props.transactions
                  .filter(transaction => transaction.type === 'deposit' || transaction.type==='earnit')
                  .map((transaction, i) => (
                    <TransactionList key={i} transaction={transaction} />
                  ))}
            </TabPanel>

            <TabPanel>
              {props.transactions &&
                props.transactions
                  .filter(transaction => transaction.type === 'transfer' || transaction.type==='withdrawal')
                  .map((transaction, i) => (
                    <TransactionList key={i} transaction={transaction} />
                  ))}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
}

export default YoungsterTransactions;
