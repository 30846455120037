import React,{useState,useEffect} from 'react';
import {SearchIcon} from '@chakra-ui/icons';
import exchange from '../../Icons/exchange.svg';
import AmountInput from '../Misc/Forms/AmountInput';
import { Spinner } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Button,
    InputGroup,
    InputLeftElement,
    Avatar
  } from '@chakra-ui/react';
import axios from 'axios';
import Swal from 'sweetalert2';
import WalletScreenHeader from '../MinieWallet/WalletScreenHeader';

function SendMoneyRequest({id,user}) {
  const [requestOpen, setRequestOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [amountError, setAmountError] = useState('');
  const [users, setUsers] = useState([]);
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState('');
  const [btnloading, setBtnLoading] = useState(false);
  const [loading,setLoading] = useState(false)
  const [recipient,setRecipient] = useState("")

  useEffect(()=>{
    if (amount <= 0 || reason === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  },[amount,reason])

  const setAmountValue = e => {
    setAmount(e);
  };

  const sendMoneyRequest = () => {
    if (amount > 0) {
      setBtnLoading(true);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL+'/request-money',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
          ContentType: 'application/json',
        },
        data: {
          amount: amount,
          reason: reason,
          recipientName: recipient.firstName,
          recipientEmail: recipient.email,
          recipientId: recipient._id,
          name: user.firstName + ' ' + user.lastName,
          profileImage: user.profileImage,
          youngsterEmail: user.email,
        },
      })
        .then(response => {
          setBtnLoading(false);
          setRequestOpen(false);
          Swal.fire({
            icon: 'success',
            title: 'Request sent!',
            text: response.data.data.message,
          });
        })
        .catch(() => {
          setBtnLoading(false);
          setRequestOpen(false);
          Swal.fire({
            icon: 'error',
            title: 'Request Not sent!',
            text: 'Money request was not sent. Kindly try again',
          });
        });
    } else {
      setAmountError('Please enter an amount');
    }
  };

  const searchUsers = e => {
    setLoading(true);
    axios({
      method: 'GET',
      url:
        process.env.REACT_APP_API_URL + '/search-user?search=' + e.target.value,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
    })
      .then(response => {
        setUsers(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: error.response.data.error,
        });
      });
  };

  const requestClose = () => {
    setRequestOpen(false);
  };

  const requestMoney = user => {
    setRequestOpen(true);
    setRecipient(user);
  };

  const gotoOverview = () =>{
    document.getElementById("send-money-request").style.display = "none"
    document.getElementById("request-money").style.display = "block"
  }


  return (
    <div id={id} className="dashboardCenter walletScreensContainer hiddenForm">
      <WalletScreenHeader goBack={gotoOverview} title={"Request Money"} />
      <div className="searchUsers">
        <p>Send money request to MinieMoney users</p>
        <InputGroup size={'lg'}>
          <InputLeftElement>
            <SearchIcon />
          </InputLeftElement>
          <Input
            onChange={e => searchUsers(e)}
            focusBorderColor={'#7A58EF'}
            placeholder="Search with MinieTAG or name"
            size={"md"}
          />
        </InputGroup>
      </div>


      <div className="my-3">
            {loading && <Spinner speed={'0.3s'} color="purple.500" />}
          </div>
          {users.map((user, i) => (
            <div className="transferCards" key={i}>
              <div className="transferCardDetails">
                <div className="mr-2">
                  <Avatar
                    size={'md'}
                    src={user.profileImage}
                    alt="kidsAccount"
                  />
                </div>
                <div>
                  <h2>
                    {user.firstName} {user.lastName}
                  </h2>
                  <p>@{user.minietag}</p>
                </div>
              </div>
              <div className="transferCardButtons">
                {/* <Button className="mr-2 spaceButtonsMobile" color={"#7A58EF"} rounded={"full"} colorScheme="#F2EEFD" background={"#F2EEFD"}><FiPlus/>Add Friend</Button> */}
                <Button
                  onClick={() => requestMoney(user)}
                  color={'white'}
                  rounded={'full'}
                  colorScheme="#7A58EF"
                  background={'#7A58EF'}
                >
                  Request Money
                </Button>
              </div>
            </div>
          ))}
      {users.map((user, i) => (
        <div className="transferCards" key={i}>
          <div className="transferCardDetails">
            <div className="mr-2">
              <Avatar size={'md'} src={user.profileImage} alt="kidsAccount" />
            </div>
            <div>
              <h2>
                {user.firstName} {user.lastName}
              </h2>
              <p>@{user.minietag}</p>
            </div>
          </div>
          <div className="transferCardButtons">
            {/* <Button className="mr-2 spaceButtonsMobile" color={"#7A58EF"} rounded={"full"} colorScheme="#F2EEFD" background={"#F2EEFD"}><FiPlus/>Add Friend</Button> */}
            <Button
              onClick={() => requestMoney(user)}
              color={'white'}
              rounded={'full'}
              colorScheme="#7A58EF"
              background={'#7A58EF'}
            >
              Request Money
            </Button>
          </div>
        </div>
      ))}

      <Modal
        size={'xl'}
        blockScrollOnMount={false}
        isOpen={requestOpen}
        onClose={requestClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mt-7">
              <div className="flex justify-around">
                <div className="flex">
                  <Avatar src={user.profileImage} />
                  <div className="ml-2">
                    <h2 className="font-bold">{user.firstName}</h2>
                    <span>@{user.minietag}</span>
                  </div>
                </div>

                <div>
                  <Button
                    colorScheme={'#F2EEFD'}
                    background={'#F2EEFD'}
                    rounded={'full'}
                    className="exchangeButton"
                  >
                    <img src={exchange} alt="exchange" />
                  </Button>
                </div>

                <div className="flex">
                  <Avatar src={recipient.profileImage} />
                  <div className="ml-2">
                    <h2 className="font-bold">{recipient.firstName}</h2>
                    <span>@{recipient.minietag}</span>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="px-3 mt-6 space-y-4">
              <div>
                <label>Enter amount</label>
                <AmountInput
                  focusBorderColor={'#7A58EF'}
                  setAmount={e => setAmountValue(e)}
                  placeholder={'Enter an amount'}
                />
                <span className="text-red-400">{amountError}</span>
              </div>

              <div>
                <label>Reason for Request</label>
                <Input
                  placeholder={'Reason for request'}
                  onChange={e => setReason(e.target.value)}
                  focusBorderColor={'#7A58EF'}
                />
              </div>
              <Button
                isLoading={btnloading}
                isDisabled={disabled}
                onClick={() => sendMoneyRequest()}
                background={'#7A58EF'}
                colorScheme={'#7A58EF'}
                textColor={'white'}
                className="confirmButton"
                rounded={'full'}
                width={'100%'}
              >
                Request Money
              </Button>
            </div>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default SendMoneyRequest;
