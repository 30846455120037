import React from 'react';
import BottomMenu from '../../../components/BottomMenu/BottomMenu';
import Sidebar from '../../../components/SideBar/SideBar';
import DashboardRight from '../../../components/Dashboard/DashboardRight/DashboardRight';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import CreateAccountNumber from '../../../components/SterlingBank/CreateAccountNumber';
import "./SterlingBank.css"

function AccountNumber() {
  document.title = 'Youngster Account Number | MinieMoney';
  const user = useSelector(state => state.user.value);
  const accountType = window.location.pathname.split('/')[2];
  const history = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (
      sessionStorage.getItem('minieToken') == null &&
      sessionStorage.getItem('userData') == null
    ) {
      history('/login/');
    }
  }, [history, accountType, user]);

  return (
    <div id={"accountNumberPage"}>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          <div>
            <CreateAccountNumber user={user}/>
          </div>

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default AccountNumber;
