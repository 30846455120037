import React from 'react';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Sidebar from '../../components/SideBar/SideBar';
import MinieSavings from '../../components/MinieSavings/MinieSavings';
import DashboardRight from '../../components/Dashboard/DashboardRight/DashboardRight';
import './MinieSavings.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLeaderboards } from '../../store/fetchLeaderBoards';
import { fetchKolosaveParticipants } from '../../store/fetchKolosaveParticipants';
import { fetchKolosaveRevealDates } from '../../store/fetchRevealDates';
import { useNavigate } from 'react-router';
import { fetchBalances } from '../../store/fetchBalances';

function Savings() {
  document.title = 'MinieSavings | MinieMoney';
  const user = useSelector(state => state.user.value);
  const participants = useSelector(state => state.koloparticipants.value);
  const accountType = window.location.pathname.split('/')[2];
  const leaderboard = useSelector(state => state.leaderboard.value);
  const revealDates = useSelector(state => state.revealDates.value);
  const balances = useSelector(state => state.balances.value)
  const dispatch = useDispatch();
  const history = useNavigate()

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchBalances())
    dispatch(fetchKolosaveParticipants());
    dispatch(fetchLeaderboards());
    dispatch(fetchKolosaveRevealDates());
  }, [dispatch]);

  //logout user with invalid session
  React.useEffect(() => {
    if (
      sessionStorage.getItem('minieToken') == null ||
      sessionStorage.getItem('userData') == null
    ) {
      console.log(sessionStorage.getItem('minieToken'));
      history('/login/');
    }
  }, [history]);

  React.useEffect(() => {
    setTimeout(() => {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      };
    }, 500);
  }, []);

  return (
    <div>
      <div className="dashboardPage">
        <div className="sideBar">
          <Sidebar user={user} />
        </div>
        <div className="dashboardComponents">
          {user && (
            <div>
              <MinieSavings
                user={user}
                leaderboard={leaderboard}
                revealDates={revealDates}
                participants={participants}
                balances={balances}
              />
            </div>
          )}

          <div className="hideOnMobile">
            <DashboardRight accountType={accountType} />
          </div>
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default Savings;
