import React from 'react';
import './Forms.css';

function CheckboxGroup(props) {
  return (
    <div className="flex mr-2">
      <div className="round">
        <input readOnly={true} type="checkbox" checked={props.checked} id="checkbox" />
        <label htmlFor="checkbox"></label>
    </div>
    <span>{props.label}</span>
    </div>
  );
}

export default CheckboxGroup;
