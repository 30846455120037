import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  value: "",
  loading: false,
};


export const fetchQuotes = createAsyncThunk("marketing/fetchQuote", async () => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/ai-quotes?prompt=Write a global fun fact about money for me that a child would like. The facts should not only be about the United States of America",
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
            ContentType: "application/json",
        }
      })
      return response.data.data

    } catch (error) {
      if(error.response.status === 401){
        setTimeout(()=>{
          localStorage.removeItem("_expiredTime")
          sessionStorage.removeItem('minieToken');
          sessionStorage.removeItem('userData');
          window.location.href = '/login/';
        },3000)
      }
    }
  });
  
  export const fetchQuote = createSlice({
    name: "quotes",
    initialState,
    extraReducers: {
      [fetchQuotes.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchQuotes.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchQuotes.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchQuote.reducer;