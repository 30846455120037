import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
  value: "",
  loading: false,
};


export const fetchTransactions = createAsyncThunk("user/fetchTransactions", async (e) => {
    try {
      const response = await axios({
        method:"GET",
        url:process.env.REACT_APP_API_URL+"/fetch-transactions?accountType="+e,
        headers:{
            Authorization:"Bearer "+sessionStorage.getItem("minieToken"),
            ContentType: "application/json",
        }
      })
      return response.data.data
    } catch (error) {
      //log user out if there's a 401 error
      if(error.response.status === 401){
        setTimeout(()=>{
          localStorage.removeItem("_expiredTime")
          sessionStorage.removeItem('minieToken');
          sessionStorage.removeItem('userData');
          window.location.href = '/login/';
        },3000)
      }
    }
  });
  
  export const fetchAllTransactions = createSlice({
    name: "transactions",
    initialState,
    extraReducers: {
      [fetchTransactions.pending]: (state, action) => {
        state.loading = true;
      },
      [fetchTransactions.fulfilled]: (state, action) => {
        state.loading = false;
        state.value = action.payload;
      },
      [fetchTransactions.rejected]: (state, action) => [(state.loading = "failed")],
    },
  });
  
  export default fetchAllTransactions.reducer;