import React from 'react';
import { useNavigate } from 'react-router';
import ParentCard from './ParentCard';
import InviteParent from './InviteParent';
import NoParent from './NoParent';
import ParentHeader from './ParentHeader';
import RequestMoneyModal from '../Modal/RequestMoneyModal';


function Parent(props) {
  const history = useNavigate();
  const [pageTitle,setPageTitle] = React.useState("Parent’s Accounts")
  const [parent,setParent] = React.useState("")
  const [showModal,setShowModal] = React.useState(false)


  const closeModal = () =>{
    setShowModal(false)
  }

  const goBack = () => {
    if(pageTitle === 'Parent’s Accounts'){
    history(-1);
    }
    else{
      document.getElementById("parents").style.display = "block"
      document.getElementById("inviteParents").style.display = "none"
      setPageTitle("Parent's Account")
    }
  };

  const inviteParents = () => {
    document.getElementById("parents").style.display = "none"
    document.getElementById("inviteParents").style.display = "block"
    setPageTitle("Invite Parent")
  }

  const selectParent = (parent) =>{
    setParent(parent)
    setShowModal(true)
  }


  return (
    <div className="dashboardCenter">
      <ParentHeader title={pageTitle} goBack={goBack} inviteParents={inviteParents}/>

      <div id="parents">
      {props.parents && props.parents.length === 0 && (
        <div className="my-5">
          <NoParent
            description={
              'Invite your parents to help you start your financial journey and unlock the world of many possibilities.'
            }
            btnTitle={'Invite your parents'}
            inviteParents = {inviteParents}
          />
        </div>
      )}

      {props.parents &&
        props.parents
          .map((parent, i) => (
            <div key={i} className="mt-7">
              <ParentCard parent={parent} selectParent={()=>selectParent(parent)}/>
            </div>
          ))}
      </div>
      <InviteParent id={"inviteParents"} user={props.user} />

      {/* Request Money Modal */}
      <RequestMoneyModal showModal={showModal} setShowModal={()=>setShowModal} closeModal={()=>closeModal()} user={props.user} recipient={parent}/>

    </div>
  );
}

export default Parent;
