import React from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import { useSelector } from 'react-redux'

import RequestMoneyCenter from '../../../components/RequestMoney/RequestMoney';
import SendMoneyRequest from '../../../components/RequestMoney/SendMoneyRequest';


function RequestMoney() {
  const user = useSelector((state) => state.user.value)
  const accountType = window.location.pathname.split('/')[2];


  React.useEffect(()=>{
   window.scrollTo(0,0)
   document.getElementById('request-money').style.display = "block"
  },[])

  return (
    <div>  
      <div className="dashboardPage">
        
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
        <RequestMoneyCenter user={user} id={"request-money"} title={"Fund Wallet"}/>  
        <SendMoneyRequest id={"send-money-request"} user={user}/>
        </div>

        <div className="hideOnMobile">
        <DashboardRight accountType={accountType}/>
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default RequestMoney