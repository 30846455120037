import React from 'react';
import {Skeleton } from '@chakra-ui/react';
import setallowance from "../../../Icons/set-allowance.svg"
import assigntask from "../../../Icons/assign-task.svg"
import sendmoney from "../../../Icons/send-money.svg"
import {Button} from "@chakra-ui/react"

function YoungsterLoading() {
  return (
    <div className="youngsterAccount">
      <div className="flex">
        <Skeleton height="45px" width="45px" borderRadius={'full'}/>
        <h2 className="youngsterName"><Skeleton margin={"2px 0px 0px 0px"} height="20px" width={"200px"}/></h2>
      </div>


      <div className="youngsterbalanceCards">
        <div >
          <Skeleton
            height="60px"
           
            borderRadius={'10px'}
          />
        </div>
        <div >
          <Skeleton
            height="60px"
            borderRadius={'10px'}
           
          />
        </div>
        <div>
          <Skeleton
            height="60px"
        
            borderRadius={'10px'}
           
          />
        </div>
        <div>
          <Skeleton
            height="60px"
          
            borderRadius={'10px'}
           
          />
        </div>
      </div>

      <div>
        <div className="my-6 flex justify-end">
          {/* <div className="flex">
         <img src={eyeslash} alt="eye-slash"/> <span className="balanceText mt-2">Turn off balance amount</span>
          </div> */}
        </div>
        <div className="buttonsGrid">
       <Button variant='outline' className="text-md buttonText" size="lg" rounded={"full"}>
        <img src={setallowance} alt="set-allowance" className="mr-2"/>
        Set allowance
       </Button>

       <Button  variant='outline' className="text-md buttonText" rounded={"full"} size="lg">
        <img src={assigntask} className="mr-2" alt="set-allowance"/>
        Assign task
       </Button>

       <Button  background={"#005FEB"} className="text-md sendMoneyMobile buttonText" color="white" size="lg" variant='outline' rounded={"full"}>
        <img src={sendmoney} className="mr-2" alt="set-allowance"/>
        Send Money
       </Button>
        </div>
        </div>


    </div>
  );
}

export default YoungsterLoading;
