import React, { useEffect, useState } from 'react';
import speaker from '../../Image/speaker.png';
import timer from '../../Image/timer.svg';
import { koloCountdown} from '../../utils/koloCountdown';


function KoloBanner(props) {
  const [countdown, setCountDown] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countdown => !countdown);
      setFadeOut(true);
    }, 3000);
    return () => clearInterval(interval);
  }, [setFadeOut]);

  useEffect(()=>{
    setDaysRemaining(koloCountdown())
  },[setDaysRemaining])

  useEffect(() => {
    if (fadeOut) {
      const timer = setTimeout(() => {
        setFadeOut(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [countdown, fadeOut]);

  return (
    <div className="flex pt-4 ">
      <button
        className=" flex items-center gap-1 h-auto pr-6 pl-3 py-1 sm:py-2 sm:pr-[1.5rem] sm:pl-[0.5rem] md:py-2 w-[170px
        ] text-xs md:text-base text-white bg-[#669F2A] rounded-l-3xl justify-between"
      >
        {countdown && (
          <div className={`flex items-center gap-2 ${fadeOut ? 'fadeout' : 'fadein'}`}>
            <img src={speaker} alt="" />
            <span className=" text-xs">ANNOUNCEMENT</span>
          </div>
        )}
        {!countdown && (
          <div className={`flex items-center gap-2 ${fadeOut ? 'fadeout' : 'fadein'}`}>
            <img src={timer} alt="" />
            <span className=" text-xs">COUNTDOWN</span>
          </div>
        )}
      </button>
      {countdown && (
        <div
           
          className={`h-22
                pl-3
                text-xs
                sm:text-sm
                md:text-base
                border bg-[#F0F5EA]
                rounded-r-3xl
                text-[#151618]
                w-[500px]
                outline-none
                    p-[1px]
                sm:text-center
                flex
                items-center
                ${fadeOut ? 'fadeout' : 'fadein'}
              `}
        >
          {props.participants.length !== 0 && (
            <span className="overflow">
              You and {props.participants && props.participants.length - 1}{' '}
              {props.participants && props.participants.length - 1 === 1
                ? 'person'
                : 'people'}{' '}
              are saving with Kolo Save
            </span>
          )}

          {props.participants.length === 0 && (
            <span> No participants in Kolosave at the moment</span>
          )}
        </div>
      )}
      {!countdown && (
        <div
          className={`h-22
                pl-3
                text-xs
                sm:text-sm
                md:text-base
                border bg-[#F0F5EA]
                rounded-r-3xl
                text-[#151618]
                w-[500px]
                outline-none
                    p-[1px]
                sm:text-center
                flex
                items-center
                transitions
                ${fadeOut ? 'fadeout' : 'fadein'}
              `}
        >
          <span className="overflow">{daysRemaining}  days to the grand breaking kolo day. 🕺</span>
        </div>
      )}
    </div>
  );
}

export default KoloBanner;
