import { Avatar, Circle } from '@chakra-ui/react';
import React, { Fragment, useState } from 'react';
import background from '../../Image/Container1.svg';
import gem2 from '../../Image/Gemsmall.svg';
import gem3 from '../../Image/GemGreen.svg';
import gem4 from '../../Image/GemPurple.svg';
import green from '../../Image/Green2.svg';
import Purple from '../../Image/Purple2.svg';
import Modal from '../Modal/Modal';
import Confetti from 'react-confetti';
import { SocialMediaShare } from './SocialMediaShare';
import img2 from '../../Image/GemB.svg';
import blue from '../../Image/Blue.png';
import Green from '../../Image/Green.png';
import purple from '../../Image/Purple.png';
import gemsh from '../../Image/Gemsh1.png';
import gemsh1 from '../../Image/Gemsh2.png';
import closeButtonGreen from '../../Icons/closeButtonGreen.svg';
import ShareWhite from '../../Icons/share';
import { dateFormatter } from '../../utils/dateUtil';

function ShareMediaModal({
  profileImage,
  firstName,
  lastName,
  minietag,
  position,
}) {
  // const [position ] = useState(1);
  // for share card
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);


  //check if the position mdal has been exceed so you can show the modal
  React.useEffect(()=>{
    if(Date.now() > parseFloat(sessionStorage.getItem("showPositionInterval")) || isNaN(parseFloat(sessionStorage.getItem("showPositionInterval")))){
      setShowModal(true)
    }
  },[])

  // show positions
  const showPosition1 = () => {
    setShowModal1(true);
  };
  const showPosition2 = () => {
    setShowModal2(true);
  };
  const showPosition3 = () => {
    setShowModal3(true);
  };
  // for close modals
  const closeModal1 = () => {
    setShowModal1(false);
    setShowModal(false);
    //prevent modal from showing everytime user logs in using a timestamp difference of 12 hours
    sessionStorage.setItem('showPositionInterval', Date.now() + 10800000);
  };
  const closeModal2 = () => {
    setShowModal2(false);
    setShowModal(false);
    //prevent modal from showing everytime user logs in using a timestamp difference of 12 hours
    sessionStorage.setItem('showPositionInterval', Date.now() + 10800000);
  };
  const closeModal3 = () => {
    setShowModal3(false);
    setShowModal(false);
    //prevent modal from showing everytime user logs in using a timestamp difference of 12 hours
    sessionStorage.setItem('showPositionInterval', Date.now() + 10800000);
  };

  const handlePositionChange = () => {
    setShowModal(false)
    sessionStorage.setItem('showPositionInterval', Date.now() + 10800000);
  }

  return (
    <Fragment>
      <Modal
        closeButtonGreen={closeButtonGreen}
        isVisible={showModal}
        onClose={() => handlePositionChange()}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          className="sm:top-[19%]"
        >
          <Confetti width={600} className="w-full sm:w-[460px]" />
        </div>
        {position === 1 ? (
          <ShareModal
            bgImage={background}
            img1={profileImage}
            title={firstName + ' ' + lastName}
            paragraph={'You’re the number 1 overall top saver.'}
            textColor={'#004EC2'}
            rank={'1'}
            img2={gem2}
            circleColor="#005FEB"
            showBadge={() => showPosition1()}
          />
        ) : position === 2 ? (
          <ShareModal
            bgImage={green}
            img1={profileImage}
            title={firstName + ' ' + lastName}
            paragraph={'You’re the number 2 overall top saver.'}
            textColor={'#107569'}
            rank={'2'}
            img2={gem3}
            circleColor="#0E9384"
            showBadge={() => showPosition2()}
          />
        ) : (
          <ShareModal
            bgImage={Purple}
            img1={profileImage}
            title={firstName + ' ' + lastName}
            textColor={'#6927DA'}
            paragraph={'You’re the number 3 overall top saver.'}
            rank={'3'}
            img2={gem4}
            circleColor="#7839EE"
            showBadge={() => showPosition3()}
          />
        )}
      </Modal>
      <Modal
        closeButtonGreen={closeButtonGreen}
        isVisible={showModal1}
        onClose={() => closeModal1()}
      >
        <SocialMediaShare
          bgImage={blue}
          image={profileImage}
          image2={img2}
          title={firstName + ' ' + lastName}
          userName={'@' + minietag}
          date={dateFormatter(Date.now())}
          rank={'1st'}
          remarkCol={'#003E99'}
        />
      </Modal>
      <Modal
        closeButtonGreen={closeButtonGreen}
        isVisible={showModal2}
        onClose={() => closeModal2()}
      >
        <SocialMediaShare
          bgImage={Green}
          image={profileImage}
          image2={gemsh}
          title={firstName + ' ' + lastName}
          userName={'@' + minietag}
          date={dateFormatter(Date.now())}
          rank={'2nd'}
          remarkCol={'#107569'}
        />
      </Modal>
      <Modal
        closeButtonGreen={closeButtonGreen}
        isVisible={showModal3}
        onClose={() => closeModal3()}
      >
        <SocialMediaShare
          bgImage={purple}
          image={profileImage}
          image2={gemsh1}
          title={firstName + ' ' + lastName}
          userName={'@' + minietag}
          date={dateFormatter(Date.now())}
          rank={'3rd'}
          remarkCol={'#7839EE'}
        />
      </Modal>
    </Fragment>
  );
}

export default ShareMediaModal;

export const ShareModal = ({
  bgImage,
  img1,
  title,
  paragraph,
  textColor,
  rank,
  img2,
  circleColor,
  showBadge,
}) => {
  return (
    <div className="relative flex items-center justify-center flex-col sm:gap-3 w-full sm:pt-12 ">
      <div
        className={`w-[192px] h-[140px]
     px-2 bg-[${bgImage}] text-[${textColor}]`}
        style={{
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderWidth: '1.5px',
          borderColor: textColor,
        }}
      >
        <Avatar
          src={img1}
          alt="leader"
          className={`-top-[36px] left-[58px] mb-0 absolute z-20 max-w-full border-[${circleColor}]`}
          size={'lg'}
        />
        <h3
          className={`-mt-[28px] font-medium text-[${textColor}] text-center`}
          style={{ color: textColor }}
        >
          {title}
        </h3>
        <div className="flex flex-col items-center justify-center my-3">
          <img src={img2} alt="gem" />
          <Circle
            size={'30px'}
            bg={`${circleColor}`}
            color="white"
            className="font-semibold text-xl -mt-4 border-[#fff] border"
          >
            {rank}
          </Circle>
        </div>
      </div>
      <h3 className="mt-[18px] font-bold text-2xl text-[#151618] text-center">
        Hurray
      </h3>
      <p
        className={`text-base font-normal max-w-[456px] text-[#54595E] text-center mt-2 sm:mb-4`}
      >
        {paragraph}
      </p>
      <button
        onClick={showBadge}
        className="w-full mb-4 h-auto flex  items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#669F2A] text-[#E6EFFD] cursor-pointer
             rounded-full mt-2 hover:bg-[#48711E]"
      >
        <div className="flex gap-1 items-center">
          <span className="font-semibold text-base">Share</span>
          <ShareWhite />
        </div>
      </button>
    </div>
  );
};
