import React from 'react';
import flag from '../../../Icons/Flag.svg';
import { InputGroup, InputLeftAddon, Input } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { formatNumber } from '../../../utils/Forms/formatNumber';

function PhoneInput(props) {
  const setPhoneValue = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
    
    if (e.target.value.length === 11 && /^\d+$/.test(e.target.value)) {
      props.setDisabled(false);
    } else {
      props.setDisabled(true);
    }
    props.setPhone(e.target.value);
  };
  return (
    <div>
      <InputGroup size={props.size}>
        <InputLeftAddon padding={"0px 8px 0px 8px"} max-width={"120px"} background={'white'}>
          <img src={flag} alt="flag" />
          <span className="ml-2 phonePrefix">234</span>
          <ChevronDownIcon />
        </InputLeftAddon>
        <Input
          placeholder={'e.g 08012345678'}
          prefix="234"
          type={'number'}
          inputMode={'numeric'}
          onChange={e => setPhoneValue(e)}
          onInput={e => setPhoneValue(e)} // Add onInput event handler
          onKeyPress={formatNumber}
          maxLength={11}
          defaultValue={props.defaultValue ? props.defaultValue : ""}
        ></Input>
      </InputGroup>
    </div>
  );
}

export default PhoneInput;
