import { Button } from '@chakra-ui/react';
import React from 'react';
import WalletScreenHeader from '../../../components/MinieWallet/WalletScreenHeader';
import { useSelector } from 'react-redux';
import { beneficiaryFormatter, referenceFormatter, senderFormatter } from '../../../utils/Transactions/formatSummaryUtil';
import { dateFormatter } from '../../../utils/dateUtil';
import { standardAmountFormat } from '../../../utils/amountUtil';

function TransactionSummary(props) {
  const transaction = useSelector(state => state.transaction.value);
  const accountType = window.location.pathname.split('/')[2];

  const goToWallet = () => {
    window.scrollTo(0, 0);
    const transactions = document.getElementById('transactions-summary');
    const transfer = document.getElementById('overview');
    transfer.style.display = 'block';
    transactions.style.display = 'none';
  };

  // const shareReceipt = () =>{
  //   console.log("hello")
  // }

  return (
    <div id={props.id} className="hiddenForm dashboardCenter pb-12">
      <WalletScreenHeader title={'Transaction Summary'} goBack={goToWallet} />
      <div className="my-8 flex justify-center items-center">
        <img
          width={'60px'}
          src={transaction && transaction.image}
          alt="transaction-summary"
        />
      </div>
      <div className="flex justify-between items-center flex-row">
        <div>
          <p className="text-sm">Decription:</p>
          <p className="text-[#151618] text-sm w-2/3">
            {transaction && transaction.message}
          </p>
        </div>
        <div className="my-3 flex justify-between">
          <div>
            <p className="text-sm text-right">Amount:</p>
            <p className={transaction.type ===
                'allowance' ? accountType : transaction.type}>
            {standardAmountFormat(transaction && transaction.amount)}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex justify-between items-center flex-row mt-2">
        <div>
          <p className="text-sm">Sender:</p>
          <p className="font-bold text-[#151618] text-md">
            {transaction && senderFormatter(transaction,props.user)}
            </p>
        </div>
        <div className="my-3 flex justify-between">
          <div>
            <p className="text-sm text-right">Beneficiary:</p>
            <p className="font-bold text-[#151618]"> {transaction && beneficiaryFormatter(transaction,props.user)}</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex justify-between items-center flex-row mt-2">
        <div>
          <p className="text-sm">Payment Type:</p>
          <p className="font-bold text-[#151618] text-md">
            {transaction && transaction.type}
          </p>
        </div>
        <div className="my-3 flex justify-between">
          <div>
            <p className="text-sm text-right">Reference:</p>
            <p className="font-bold text-[#151618]">{transaction && referenceFormatter(transaction)}</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex justify-between items-center flex-row mt-2">
        <div>
          <p className="text-sm">Date:</p>
          <p className="font-bold text-[#151618] text-md">{transaction && dateFormatter(transaction.date)}</p>
        </div>
        <div className="my-3 flex justify-between">
          <div>
            <p className="text-sm text-right">Status:</p>
            <p className="font-bold text-[#22C55E]">Success</p>
          </div>
        </div>
      </div>
      <div className="my-6">
        <Button
          className="walletButton"
          rounded={'full'}
          background="#7A58EF"
          color="white"
          colorScheme={'#7A58EF'}
          onClick={goToWallet}
          width={'full'}
        >
          Done
        </Button>
        {/* <Button
          className="walletButton flex gap-2"
          color={'#7A58EF'}
          colorScheme={'#7A58EF'}
          onClick={shareReceipt}
          width={'full'}
          variant="link"
        >
          Share
        </Button> */}
      </div>
    </div>
  );
}

export default TransactionSummary;
