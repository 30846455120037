export function amountFormat(amount, showBalance) {
  return showBalance
    ? '₦ ' +
        parseFloat(parseFloat(amount && amount).toFixed(2)).toLocaleString('en',{
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
    : '*******';
}


export function standardAmountFormat(amount){
  return "₦ "+ parseFloat(parseFloat(amount && amount).toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}


export function formatZero(amount,showBalance){
  return showBalance
    ? '₦ ' +
    amount.toFixed(2)
    : '*******';
}