import React, { useState } from 'react';
import EditProfileHeader from '../../components/EditProfile/EditProfileHeader';
import { Button, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
// import axios from 'axios';
// import { fetchUser } from '../../store/userLogin';
import { useNavigate } from 'react-router';
import YoungsterAvatarHeading from '../Account/YoungsterAvatarHeading';
import SelectChip from '../../utils/Forms/SelectChip';
import { monthlyOptions } from '../../utils/Forms/day';
import success from '../../Icons/successIcon.svg';
import Modal from '../Modal/Modal';
import closeButton from '../../Icons/closeButton.svg';
import Swal from 'sweetalert2';
import axios from 'axios';
import Loader from '../../utils/Loaders/Overlay';
import EnterTransactionPinModal from '../Modal/EnterTransactionPinModal';
import PaymentFrequency from './PaymentFrequency';
import { formatNumber } from '../../utils/Forms/formatNumber';

function SetAllowanceComponent(props) {
  const [disabled, setDisabled] = React.useState(true);
  const [amount, setAmount] = React.useState('');
  const [frequency, setFrequency] = React.useState('');
  const [weekDay, setWeekDay] = React.useState('');
  const [monthDay, setMonthDay] = React.useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stoploading, setStopLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [pin, setPin] = React.useState('');
  const [amountInput,setAmountInput] = React.useState("")
  // const youngster = JSON.parse(sessionStorage.getItem('youngster'));
  const history = useNavigate();
  const frequencyOptions = ['Daily', 'Weekly', 'Monthly'];
  const weeklyOptions = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  React.useEffect(()=>{
    if(amountInput >= 200 && frequency === 'Daily'){
      setDisabled(false)
    }

    else if(amountInput >= 200 && frequency === 'Weekly' && weekDay !== ''){
      setDisabled(false)
    }

    else if(amountInput >= 200 && frequency === 'Monthly' && monthDay !== ''){
      setDisabled(false)
    }

    else{
      setDisabled(true)
    }

  },[amountInput,frequency,weekDay,monthDay])


  const goBack = () => {
    history(-1);
  };

  const showPin = () => {
    if (props.youngster.minietag === undefined) {
      Swal.fire({
        icon: 'info',
        title: "Youngster's Minietag is not set ",
        text: 'Please ensure your youngster has set their minietag and try again',
        confirmButtonColor: '#0066f5',
      });
      return;
    }

    if (amountInput < 200) {
      setError(
        'Please set an allowance amount that is greater than or equal to NGN 200'
      );
      return;
    }

    if (frequency === 'Weekly' && weekDay === '') {
      Swal.fire({
        icon: 'info',
        title: 'Week Day Required 🥺',
        text: 'Please select a weekday',
        confirmButtonColor: '#0066f5',
      });
      return;
    }

    if (frequency === 'Monthly' && monthDay === '') {
      Swal.fire({
        icon: 'info',
        title: 'Select Date 🥺',
        text: 'Please select a day of the month',
        confirmButtonColor: '#0066f5',
      });
      return;
    } else {
      setShowModal(true);
      setDisabled(true)
    }
  };

  const setAllowance = () => {
    if(pin.length !== 4){
      return
    }
    setLoading(true);
    setDisabled(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/set-allowance',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
      data: {
        amount:amountInput,
        frequency,
        weekDay,
        monthDay,
        status: true,
        minietag: props.youngster.minietag,
        youngsterId: props.youngster._id,
        youngsterName: props.youngster.firstName,
        youngsterEmail: props.youngster.email,
        reason: 'Allowance payment',
        pin: pin,
      },
    })
      .then(response => {
        console.log(response);
        setMessage(response.data.data.message);
        setLoading(false);
        setDisabled(false);
        setShowModal(false);
        //show the success
        setShowModal1(true);
      })
      .catch(error => {
        setLoading(false);
        setDisabled(false);
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 🤔",
          text: error.response.data.data.message,
          confirmButtonColor: '#0066f5',
        });
      });
  };

  const editAllowance = () => {
    setLoading(true)
    setDisabled(true)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/edit-allowance',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
      data: {
        amount: amount ? parseFloat(amount.replaceAll(",","")) : props.allowanceData.amount,
        frequency: frequency ? frequency : props.allowanceData.frequency,
        weekDay,
        monthDay,
        status: true,
        allowanceId: props.user.minietag + '-' + props.youngster.minietag,
        youngsterId: props.youngster._id,
        youngsterName: props.youngster.firstName,
        youngsterEmail: props.youngster.email,
        reason: 'Allowance payment',
        id: props.allowanceData._id,
      },
    })
      .then(response => {
        console.log(response);
        setMessage(response.data.data.message);
        setLoading(false);
        setDisabled(false)
        setShowModal1(true);
      })
      .catch(error => {
        setLoading(false);
        setDisabled(false)
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 🤔",
          text: error.response.data.data.message,
          confirmButtonColor: '#0066f5',
        });
      });
  };

  const stopAllowance = e => {
    setStopLoading(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/stop-allowance',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
        Accept: '*/*',
      },
      data: {
        id: props.allowanceData._id,
      },
    })
      .then(response => {
        setMessage(response.data.data.message);
        setStopLoading(false);
        window.location.reload();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: "Something's wrong 🤔",
          text: error.response.data.data.message,
          confirmButtonColor: '#0066f5',
        });
        setStopLoading(false);
      });
  };

  const closeAllowance = () => {
    setShowModal(false);
    history(-1);
  };

  const closeStopAllowance = () => {
    setShowModal2(false);
  };

  const setAmountValue = (e) =>{
    const newValue = e.target.value.replace(/[^\d]/g, '');
    setAmountInput(newValue)
    setAmount(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  }



  return (
    <div id={props.id} className="dashboardCenter walletScreensContainer">
      <EditProfileHeader
        goBack={goBack}
        color={'walletAccountArrow'}
        title={
          (props.allowanceData ? 'Edit Allowance for ' : 'Set Allowance for ') +
          props.youngster.firstName
        }
      />

      {props.isLoading && <Loader />}

      <div className="formContainer">
        <YoungsterAvatarHeading youngster={props.youngster} />
        <div>
          <label>Allowance Amount</label>
          <InputGroup size={"lg"}>
            <InputLeftElement className="prefix">₦</InputLeftElement>
            <Input
              onChange={e => setAmountValue(e)}
              focusBorderColor="#0066f5"
              placeholder={'Enter the amount'}
              type="text"
              inputMode="numeric"
              value={amount ? amount: props.allowanceData && props.allowanceData.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              onKeyPress={formatNumber}
            />
          </InputGroup>
          <span className="text-red-400">{error}</span>
        </div>

        <div className="my-3">
          <label>Allowance Frequency</label>
          <SelectChip
            type={'frequency'}
            items={frequencyOptions}
            setValue={e => setFrequency(e)}
            value={frequency}
            defaultValue={
              props.allowanceData && props.allowanceData.frequency
                ? props.allowanceData.frequency
                : frequency
            }
          />
        </div>

        {frequency === 'Weekly' && (
          <div>
            <label>Select Pay Day</label>
            <SelectChip
              type={'week'}
              items={weeklyOptions}
              setValue={e => setWeekDay(e)}
              value={weekDay}
              defaultValue={
                props.allowanceData && props.allowanceData.weekDay
                  ? props.allowanceData.weekDay
                  : weekDay
              }
            />
          </div>
        )}

        {frequency === 'Monthly' && (
          <div>
            <label>Select Pay Day</label>
            <SelectChip
              type={'month'}
              items={monthlyOptions}
              setValue={e => setMonthDay(e)}
              value={monthDay}
              defaultValue={
                props.allowanceData && props.allowanceData.monthDay
                  ? props.allowanceData.monthDay
                  : monthDay
              }
            />
          </div>
        )}

        <PaymentFrequency
          frequency={frequency}
          weekDay={weekDay}
          monthDay={monthDay}
          allowanceData={props.allowanceData && props.allowanceData}
        />

        {!props.allowanceData && (
          <div className="mt-6">
            <Button
              onClick={showPin}
              padding={'28px 32px'}
              width={'100%'}
              disabled={disabled}
              rounded={'full'}
              colorScheme={'#0066f5 '}
              background={'#0066f5 '}
              isLoading={loading}
            >
              Set Allowance
            </Button>
          </div>
        )}

        {props.allowanceData && (
          <div className="mt-6">
            <Button
              onClick={editAllowance}
              padding={'28px 32px'}
              width={'100%'}
              rounded={'full'}
              colorScheme={'#0066f5 '}
              background={'#0066f5 '}
              isLoading={loading}
            >
              Edit Allowance
            </Button>

            <Button
              onClick={() => setShowModal2(true)}
              padding={'28px 32px'}
              width={'100%'}
              rounded={'full'}
              colorScheme={'#FEF2F2'}
              background={'#FEF2F2'}
              isLoading={stoploading}
              margin={'16px 0px'}
              color={'#EF4444'}
            >
              {props.allowanceData && props.allowanceData.status === true
                ? 'Stop Allowance'
                : 'Restart Allowance'}
            </Button>
          </div>
        )}
      </div>

      <Modal
        closeButton={closeButton}
        isVisible={showModal1}
        onClose={() => setShowModal1(false)}
      >
        <div className="p-4 flex justify-between flex-col items-center">
          <img
            src={success}
            alt="error&Success"
            width="92px"
            height="92px"
            className="max-w-[100%]"
          />
          <h5 className="text-2xl font-semibold max-w-[456px] text-center mt-3">
            Allowance Set Successfully
          </h5>
          <p className="text-base font-normal max-w-[350px] text-center my-3 sm:mb-4">
            {message}
          </p>
          <button
            onClick={closeAllowance}
            className="w-full mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#0066f5] text-[#E6EFFD] rounded-full mt-2"
          >
            <span className="font-semibold text-base">Done</span>
          </button>
        </div>
      </Modal>

      <Modal
        closeButton={closeButton}
        isVisible={showModal2}
        onClose={() => setShowModal2(false)}
      >
        <div className="p-4 flex justify-between flex-col items-center">
          <h5 className="text-2xl font-extrabold max-w-[456px] text-center sm:mt-3">
            {props.allowanceData && props.allowanceData.status
              ? 'Stop Allowance'
              : 'Restart Allowance'}
          </h5>
          <p className="text-base font-normal max-w-[456px] text-center mt-2 sm:mb-4">
            {props.allowanceData && props.allowanceData.status
              ? 'Are you sure you want to stop this allowance?'
              : 'Are you sure you want to restart this allowance?'}
          </p>
          <Button
            isLoading={stoploading}
            onClick={() =>
              stopAllowance(props.allowanceData && !props.allowanceData.status)
            }
            padding={'26px 32px'}
            width={'80%'}
            rounded={'full'}
            colorScheme={'#FEF2F2'}
            background={'#EF4444'}
            margin={'8px 0px'}
          >
            {props.allowanceData && props.allowanceData.status
              ? 'Yes, Stop allowance?'
              : 'Yes, Restart Allowance'}
          </Button>

          <button
            onClick={closeStopAllowance}
            className="w-4/5 mb-4 h-auto flex items-center text-center 
            justify-center gap-3 py-3  md:py-4 md:px-4 px-4 text-base
            sm:max-w-[456px] bg-[#E6EFFD] text-[#005FEB] rounded-full mt-2"
          >
            <span className="font-semibold text-base">No</span>
          </button>
        </div>
      </Modal>

      <EnterTransactionPinModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        confirm={() => setAllowance()}
        disabled={disabled}
        loading={loading}
        setLoading={e => setLoading(e)}
        setPin={e => setPin(e)}
        setDisabled={(e) => setDisabled(e)}
        bg={'#005FEB'}
        pinBg={'#E6EFFD'}
        color={'#005FEB'}
        closeButton={'blue'}
        class={"auth"}
      />
    </div>
  );
}

export default SetAllowanceComponent;
