import React from 'react'
import BottomMenu from '../../../components/BottomMenu/BottomMenu'
import Sidebar from '../../../components/SideBar/SideBar';
import SendMoney from '../../../components/SendMoney/SendMoney';
import DashboardRight from "../../../components/Dashboard/DashboardRight/DashboardRight"
import { useSelector } from 'react-redux'

function Dashboard() {
  const user = useSelector((state) => state.user.value)
  const accountType = window.location.pathname.split('/')[2];

  React.useEffect(()=>{
   window.scrollTo(0,0)
  },[])

  return (
    <div>  
      <div className="dashboardPage">
      <div className="sideBar">
      <Sidebar user={user}/>
      </div>
      <div className="dashboardComponents">
        <div>
        <SendMoney id={"overview"} user={user} />
        </div>
        <div className="hideOnMobile">
        <DashboardRight accountType={accountType}/>
      </div>
      </div>

      </div>
      <BottomMenu />
    </div>
  )
}

export default Dashboard