import React from 'react';
import './Notifications.css';
import { Avatar, IconButton, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import notificationBell from '../../Icons/notificationsIcon.svg';
import markasread from '../../Icons/tick-square.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchUser } from '../../store/userLogin';
import { useDispatch } from 'react-redux';
import { dateFormatter } from '../../utils/dateUtil';
import { toast } from 'react-toastify';
// import NotificationsLoading from './NotificationsLoading';
// import notificationsData from "../../data/Notifications.json"

function Notifications(props) {
  const history = useNavigate();
  const dispatch = useDispatch()
  const [isLoading, setLoading] = React.useState(false);
  const [disabled,setDisabled] = React.useState(false)
  const accountType = window.location.pathname.split('/')[2];

  const viewNotifications = React.useCallback(() => {
    axios({
      method: 'GET',
      url:
        process.env.REACT_APP_API_URL+'/view-notifications/' + accountType,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
    });
  }, [accountType]);

  React.useEffect(() => {
    sessionStorage.removeItem('userData');
    viewNotifications();
    dispatch(fetchUser(accountType));
  }, [viewNotifications, accountType, dispatch]);

  const clearNotifications = () => {
    setLoading(true);
    axios({
      method: 'GET',
      url:
        process.env.REACT_APP_API_URL+'/view-notifications/' + accountType,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
      },
    })
      .then(() => {
        dispatch(fetchUser(props.accountType)).then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Notifications marked as read',
            confirmButtonColor: '#005FEB',
          }).then(() => {
            window.location.reload();
          });
          setLoading(false);
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: error.response.data.data.message,
        });

        setLoading(false);
      });
  };

  const approveYoungsterRequest = request => {
    toast.loading("Approving request...")
    setDisabled(true)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/approve-youngster-add',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: request,
    })
      .then(response => {
        dispatch(fetchUser(props.accountType)).then(() => {
          toast.dismiss()
          toast.success("Request Approved")
          setDisabled(false)
          Swal.fire({
            icon: 'success',
            title: 'Request Approved!',
            text: response.data.data.message,
            confirmButtonColor: '#005FEB',
          }).then(() => {
            window.location.reload();
          });
          setLoading(false);
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: error.response.data.data.message,
        });
        setLoading(false);
        setDisabled(false)
      });
  };

  const rejectYoungsterRequest = request => {
    toast.loading("Rejecting request...")
    setDisabled(true)
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/reject-youngster-add',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: request,
    })
      .then(() => {
        dispatch(fetchUser(props.accountType)).then(() => {
          toast.dismiss()
          toast.success("Request declined successfully")
          setDisabled(false)
          Swal.fire({
            icon: 'success',
            title: 'Request Declined Successfully!',
            text:
              'You have successfully denied ' +
              request.name +
              "'s request to add your youngster to their account",
            confirmButtonColor: '#005FEB',
          }).then(() => {
            window.location.reload();
          });
          setLoading(false);
        });
      })
      .catch(error => {
        setDisabled(false)
        toast.dismiss()
        Swal.fire({
          icon: 'error',
          title: 'Something\'s wrong 🤔',
          text:error.response.data.data.message,
          confirmButtonColor: '#005FEB',
        })
      });
  };

  const approveYoungsterRequestMoney = request => {
    setDisabled(true)
    toast.loading("Approving money request...")
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/approve-money-request',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: request,
    })
      .then(response => {
        if (response.status === 201) {
          Swal.fire({
            icon: 'info',
            title: 'Insufficient Funds',
            text: response.data.data.message,
            confirmButtonColor: '#005FEB',
            confirmButtonText: 'Fund wallet',
          }).then(result => {
            if (result.isConfirmed) {
              history('/fund-wallet/adult');
            }
          });
          setDisabled(false)
          toast.dismiss()
          return;
        }

        dispatch(fetchUser(props.accountType)).then(() => {
          toast.dismiss()
          toast.success("Request approved!")
          setDisabled(false)
          Swal.fire({
            icon: 'success',
            title: 'Request Approved!',
            text: response.data.data.message,
            confirmButtonColor: '#005FEB',
          }).then(() => {
            window.location.reload();
          });
          setLoading(false);
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Something\'s wrong 🤔',
          text:error.response.data.data.message,
          confirmButtonColor: '#005FEB',
        })
        setLoading(false);
      });
  };

  const rejectYoungsterRequestMoney = request => {
    toast.loading("Declining request...")
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL+'/reject-money-request',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('minieToken'),
        ContentType: 'application/json',
      },
      data: request,
    })
      .then(response => {
        dispatch(fetchUser(props.accountType)).then(() => {
          toast.dismiss()
          toast.success("Request declined")
          Swal.fire({
            icon: 'success',
            title: 'Request declined successfully!',
            text: "Youngster's Money Request has successfully been declined!",
            confirmButtonColor: '#005FEB',
          }).then(() => {
            window.location.reload();
          });
          setLoading(false);
        });
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'A network issue, perhaps!',
          confirmButtonColor: '#005FEB',
        });
        setLoading(false);
      });
  };

  const goBack = async () => {
    history(-1);
  };

  return (
    <div className="dashboardCenter">
      <IconButton
        background={'#E6EFFD'}
        color={'#0073E5'}
        rounded={'full'}
        icon={<ArrowBackIcon />}
        onClick={() => goBack()}
      />
      <h2 className="youngsterTitle">Notifications</h2>
      <div className="mt-7 mb-4">
        {props.user && props.user.notifications.length === 0 && (
          <div className="notificationsCard">
            <div>
              <div className="my-4 flex justify-center">
                <img src={notificationBell} alt="notificationBell" />
              </div>
              <p>
                You have no new notification. Recent activities on your account will be shown here.
              </p>
            </div>
          </div>
        )}

        {/* {
          props.notificationsLoading && (
           notificationsData.map((notification,i)=>(<NotificationsLoading key={i}/>))
          )
        } */}

        {props.user.notifications.length !== 0 && (
          <div>
            <div className="flex justify-between">
              {props.user.notifications &&
                props.user.notifications.filter(
                  notification => notification.status === 'recent'
                ).length !== 0 && <h2 className="text-xl font-bold">Recent</h2>}

              <div>
                {props.user.notifications &&
                  props.user.notifications.filter(
                    notification => notification.status === 'recent'
                  ).length !== 0 && (
                    <Button
                      isLoading={isLoading}
                      variant={'link'}
                      color="#005FEB"
                      onClick={() => clearNotifications()}
                      className="cursor-pointer brandColor flex"
                    >
                      Mark as read{' '}
                      <img className="ml-2" src={markasread} alt="markasread" />{' '}
                    </Button>
                  )}
              </div>
            </div>

            {props.user.notifications &&
              props.user.notifications
                .filter(notification => notification.status === 'recent')
                .map((notification, i) => (
                  <div key={i}>
                    <div className="notificationItem">
                      <div className="flex">
                        <Avatar src={notification.image} />
                        <div className="ml-4">
                          <h2 className="text-xl font-bold">
                            {notification.title}
                          </h2>
                          <p>{notification.message}</p>
                          {notification.type === 'request-youngster' && (
                            <div className="my-3 flex justify-space-between">
                              <Button
                                onClick={() =>
                                  rejectYoungsterRequest(notification)
                                }
                                backgroundColor={'#feeaf3'}
                                textColor={'red'}
                                rounded={'full'}
                                colorScheme={'#feeaf3'}
                                disabled={disabled}
                              >
                                Decline
                              </Button>
                              <Button
                                onClick={() =>
                                  approveYoungsterRequest(notification)
                                }
                                backgroundColor={'#0066f5'}
                                textColor={'white'}
                                rounded={'full'}
                                colorScheme={'#0066f5'}
                                className="ml-3"
                                disabled={disabled}
                              >
                                Approve
                              </Button>
                            </div>
                          )}

                          {notification.type === 'request-money' && (
                            <div className="my-3 flex justify-space-between">
                              <Button
                                onClick={() =>
                                  rejectYoungsterRequestMoney(notification)
                                }
                                backgroundColor={'#feeaf3'}
                                textColor={'red'}
                                rounded={'full'}
                                colorScheme={'#feeaf3'}
                                disabled={disabled}
                              >
                                Decline
                              </Button>
                              <Button
                                onClick={() =>
                                  approveYoungsterRequestMoney(notification)
                                }
                                backgroundColor={'#0066f5'}
                                textColor={'white'}
                                rounded={'full'}
                                colorScheme={'#0066f5'}
                                className="ml-3"
                                disabled={disabled}
                              >
                                Approve
                              </Button>
                            </div>
                          )}

                          <div className="mt-1 dateStyle">
                            {dateFormatter(notification.date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

            <div>
              <h2 className="text-xl font-bold">Old</h2>
            </div>

            {props.user.notifications &&
              props.user.notifications
                .filter(notification => notification.status === 'old')
                .map((notification, i) => (
                  <div key={i}>
                    <div className="notificationItem">
                      <div className="flex">
                        <Avatar src={notification.image} />
                        <div className="ml-4 w-full">
                          <h2 className="text-xl font-bold">
                            {notification.title}
                          </h2>

                          <p>{notification.message}</p>
                          {notification.type === 'request-youngster' && (
                            <div className="my-3 flex justify-space-between">
                              <Button
                                onClick={() =>
                                  rejectYoungsterRequest(notification)
                                }
                                backgroundColor={'#feeaf3'}
                                textColor={'red'}
                                rounded={'full'}
                                colorScheme={'#feeaf3'}
                                disabled={disabled}
                              >
                                Decline
                              </Button>
                              <Button
                                onClick={() =>
                                  approveYoungsterRequest(notification)
                                }
                                backgroundColor={'#0066f5'}
                                textColor={'white'}
                                rounded={'full'}
                                colorScheme={'#0066f5'}
                                className="ml-3"
                                disabled={disabled}
                              >
                                Approve
                              </Button>
                            </div>
                          )}

                          {notification.type === 'request-money' && (
                            <div className="my-3 flex justify-space-between">
                              <Button
                                onClick={() =>
                                  rejectYoungsterRequestMoney(notification)
                                }
                                backgroundColor={'#feeaf3'}
                                textColor={'red'}
                                rounded={'full'}
                                colorScheme={'#feeaf3'}
                                disabled={disabled}
                              >
                                Decline
                              </Button>
                              <Button
                                onClick={() =>
                                  approveYoungsterRequestMoney(notification)
                                }
                                backgroundColor={'#0066f5'}
                                textColor={'white'}
                                rounded={'full'}
                                colorScheme={'#0066f5'}
                                className="ml-3"
                                disabled={disabled}
                              >
                                Approve
                              </Button>
                            </div>
                          )}

                          <div className="mt-1 dateStyle">
                            {dateFormatter(notification.date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        )}

      </div>
    </div>
  );
}

export default Notifications;
