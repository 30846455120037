import React, { useState, useEffect } from 'react';
import "./ResendOtp.css"

function ResendOtp(props) {
  const [timeLeft, setTimeLeft] = useState(30);

  useEffect(() => {
    // exit early when we reach 0
    props.countDown(timeLeft)
    if (!timeLeft) return;
    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [props,timeLeft]);

  return (
    <span className="resendChip">
      Resend in 00:{timeLeft}s
    </span>
  );
}

export default ResendOtp;
